import React from 'react'
import { PaymentInformation } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useInterestFeeInfoModal } from '../../../../hooks/secure-line-of-credit'
import { useContent } from '../../../../providers/content'
import {
  ExtraSmallText,
  Headline1,
  LineDivider as BaseLineDivider,
  PrimaryButton,
  SmallText,
} from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { ApprovedModalContainer } from '../approved'
import { SimplifiedPaymentChoice, SimplifiedPayments } from './SimplifiedPayments'
import { SimplifiedPaymentsModal } from './SimplifiedPaymentsModal'

type CustomizeYourScheduleSLCProps = {
  isConfirmButtonDisabled: boolean
  onClickConfirmButton: () => void
  loading?: boolean
  simplifiedPaymentChoice: SimplifiedPaymentChoice | undefined
  setSimplifiedPaymentChoice: (value: SimplifiedPaymentChoice) => void
  simplifiedPaymentsInfoVisible: boolean
  setSimplifiedPaymentsInfoVisible: (value: boolean) => void
  paymentInformation: PaymentInformation
  hideMembershipFee?: boolean
  showInterestFee?: boolean
}

export const CustomizeYourScheduleSLC = (props: CustomizeYourScheduleSLCProps) => {
  const theme = useTheme()
  const {
    content: { CUSTOMIZE_YOUR_SCHEDULE },
    replaceContent,
  } = useContent()
  const { showInterestFeeInfoModal } = useInterestFeeInfoModal()

  const feeDisclaimer = replaceContent(
    props.showInterestFee ? CUSTOMIZE_YOUR_SCHEDULE?.INTEREST_FEE : CUSTOMIZE_YOUR_SCHEDULE?.BILL_PAYMENT_FEE,
    {
      fee: props.paymentInformation.processingFeePercentage,
    }
  )

  return (
    <>
      <BasePageLayout>
        <Box minH="32px" />
        <Headline1>Set up your schedule</Headline1>
        <Box minH="16px" />
        <SmallText>Get started by setting up your schedule. You can always update this later in the app.</SmallText>
        <Box minH="32px" />
        <InfoBoxContainer
          title="Deposit"
          rightCornerText={
            props.simplifiedPaymentChoice === 'off' || props.simplifiedPaymentChoice === undefined
              ? 'First time you use Flex'
              : 'When you pay rent'
          }
          subtitle={props.paymentInformation.firstPaymentAmountFormatted}
        >
          <>
            <LineDivider />
            <Box minH="8px" />
            <SimplifiedPayments
              checked={props.simplifiedPaymentChoice}
              onCheck={props.setSimplifiedPaymentChoice}
              onShowSimplifiedPaymentsInfoModal={() => props.setSimplifiedPaymentsInfoVisible(true)}
            />
          </>
        </InfoBoxContainer>
        <Box minH="16px" />
        {props.simplifiedPaymentChoice === 'on' ? (
          <>
            <InfoBoxContainer
              title="Payment"
              rightCornerText="Choose date"
              rightCornerTextColor={theme.colors.dusk}
              subtitle={`${props.paymentInformation.secondPaymentAmountFormatted} ${feeDisclaimer}`}
              onTooltipPressed={
                props.showInterestFee
                  ? () => showInterestFeeInfoModal(props.paymentInformation.processingFeePercentage)
                  : undefined
              }
            />

            <Box minH="16px" />

            {!props.hideMembershipFee && (
              <InfoBoxContainer
                title="Membership Fee"
                rightCornerText="15th of the month"
                subtitle={props.paymentInformation.subscriptionFeeFormatted}
              />
            )}
          </>
        ) : (
          <InfoBoxRentProtectOffContainer
            firstPayment={`${props.paymentInformation.secondPaymentAmountFormatted} ${feeDisclaimer}`}
            secondPayment={props.paymentInformation.firstPaymentAmountFormatted}
            membershipFee={props.paymentInformation.subscriptionFeeFormatted}
            hideMembershipFee={props.hideMembershipFee}
            onTooltipPressed={
              props.showInterestFee
                ? () => showInterestFeeInfoModal(props.paymentInformation.processingFeePercentage)
                : undefined
            }
          />
        )}
        <Box minH="32px" />
        <PrimaryButton
          testID="CustomizeYourScheduleConfirmButton"
          processing={props.loading}
          disabled={props.loading || props.isConfirmButtonDisabled}
          onClick={props.onClickConfirmButton}
        >
          Choose dates
        </PrimaryButton>
      </BasePageLayout>
      <SimplifiedPaymentsModal
        visible={props.simplifiedPaymentsInfoVisible}
        onClose={() => props.setSimplifiedPaymentsInfoVisible(false)}
      />
      <ApprovedModalContainer paymentInformation={props.paymentInformation} />
    </>
  )
}

type InfoBoxProps = {
  title: string
  subtitle: string
  rightCornerText: string
  rightCornerTextColor?: string
  onTooltipPressed?: () => void
}

type InfoBoxProtectOffProps = {
  firstPayment: string
  secondPayment: string
  membershipFee: string
  hideMembershipFee?: boolean
  onTooltipPressed?: () => void
}

const InfoBoxContainer = (props: React.PropsWithChildren<InfoBoxProps>) => {
  return (
    <InfoContainer>
      <InfoText {...props} />
      {props.children}
    </InfoContainer>
  )
}

const InfoBoxRentProtectOffContainer = ({
  firstPayment,
  secondPayment,
  membershipFee,
  hideMembershipFee,
  onTooltipPressed,
}: InfoBoxProtectOffProps) => {
  const theme = useTheme()

  return (
    <InfoContainer>
      <SmallText>Each month</SmallText>
      <LineDivider />
      <InfoText
        title="1st payment"
        subtitle={firstPayment}
        rightCornerText="Choose date"
        rightCornerTextColor={theme.colors.dusk}
        onTooltipPressed={onTooltipPressed}
      />
      <InfoText
        title="2nd payment"
        subtitle={secondPayment}
        rightCornerText="Choose date"
        rightCornerTextColor={theme.colors.dusk}
      />
      {!hideMembershipFee && (
        <InfoText title="Membership fee" subtitle={membershipFee} rightCornerText="15th of the month" />
      )}
    </InfoContainer>
  )
}

const InfoText = ({ title, subtitle, rightCornerText, rightCornerTextColor, onTooltipPressed }: InfoBoxProps) => {
  const theme = useTheme()

  return (
    <InfoTextContainer testID="PaymentContainer">
      <Flex alignItems="center">
        <Dot />
        <Box minW="12px" />
        <InfoTextContainer>
          <TopRowContainer>
            <SmallText>{title}</SmallText>
            <Flex flexDirection="row">
              <SmallText color={rightCornerTextColor}>{rightCornerText}</SmallText>
              <Box minH="16px" />
            </Flex>
          </TopRowContainer>
          <Box minH="4px" />
          <Flex flexDirection="row">
            <SmallText color={theme.colors.dusk}>{subtitle}</SmallText>
            <Box minW="2px" />
            {onTooltipPressed && (
              <Flex>
                <QuestionCircle onClick={onTooltipPressed}>
                  <TextBadge>?</TextBadge>
                </QuestionCircle>
              </Flex>
            )}
          </Flex>
        </InfoTextContainer>
      </Flex>
      <Box minH="12px" />
    </InfoTextContainer>
  )
}

const InfoContainer = styled(Box)`
  flex-direction: column;
  width: 100%;
  background-color: ${(props) => props.theme.colors.wildflower};
  padding-left: ${(props) => props.theme.fixedSizes?.xs};
  padding-right: ${(props) => props.theme.fixedSizes?.xs};
  border-radius: ${(props) => props.theme.radii.xl};
  padding: ${(props) => props.theme.fixedSizes?.spacing_100};
`

const InfoTextContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
`

const TopRowContainer = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
`

const Dot = styled(Flex)`
  width: ${(props) => props.theme.fixedSizes?.spacing_50};
  height: ${(props) => props.theme.fixedSizes?.spacing_50};
  border-radius: ${(props) => props.theme.radii.rounded};
  background-color: ${(props) => props.theme.colors.jewelPurple};
`

const LineDivider = styled(BaseLineDivider)`
  margin-top: ${(props) => props.theme.fixedSizes.sm};
  margin-bottom: ${(props) => props.theme.fixedSizes.sm};
`

const QuestionCircle = styled(Flex)`
  width: ${(props) => props.theme.fixedSizes.spacing_100};
  height: ${(props) => props.theme.fixedSizes.spacing_100};
  border-radius: ${(props) => props.theme.radii.rounded};
  background-color: ${(props) => props.theme.colors.jewelPurple};
  margin-left: ${(props) => props.theme.fixedSizes.spacing_25};
  align-items: center;
  justify-content: center;
`
const TextBadge = styled(ExtraSmallText)`
  color: ${(props) => props.theme.colors.white};
`
