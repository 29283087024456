import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { SLCType } from '@genoa/domain'
import { AmplitudeFeatureFlag } from '@genoa/experiments'
import { initialize, track, updateUser, updateUserEmail } from '@iterable/web-sdk'

import { useAuthState } from '../../contexts'
import { useAmplitudeFeatureFlag } from '../../hooks/use-amplitude-feature-flag'
import { getIterableJWT } from '../growth'
import { useUserAccount } from '../user-account'
import { useDeferredEvents } from './use-deferred-events'
import { useDeferredProperties } from './use-deferred-properties'
import { useDeferredSubscriptions } from './use-deferred-subscriptions'
import { IterableEvent } from './user-events'
import { AddressFields, ContactFields, PropertyFields } from './user-fields'

export interface IterableContext {
  readonly setContactDetails: (details: ContactFields) => void
  readonly setAddressInformation: (address: AddressFields) => void
  readonly setPropertyInformation: (property: PropertyFields) => void
  readonly setAppRentAmount: (amount: number) => void
  readonly setHeardAboutFlex: (medium: string) => void
  readonly setSMSOptInStatus: (enabled: boolean) => void
  readonly addEvent: (event: IterableEvent) => void
  readonly setProductType: (productType: SLCType) => void
  readonly logout: () => void
}

const getNoOpFunction =
  () =>
  (..._args: any) => {}

const getAsyncNoOpFunction =
  () =>
  async (..._args: any) =>
    Promise.resolve({})

const defaultIterableContext: IterableContext = {
  setContactDetails: getNoOpFunction(),
  setAddressInformation: getNoOpFunction(),
  setPropertyInformation: getNoOpFunction(),
  setAppRentAmount: getNoOpFunction(),
  setHeardAboutFlex: getNoOpFunction(),
  setSMSOptInStatus: getNoOpFunction(),
  addEvent: getNoOpFunction(),
  setProductType: getNoOpFunction(),
  logout: getNoOpFunction(),
}

const IterableContext = createContext(defaultIterableContext)

export interface IterableProviderOptions {
  apiKey: string
}

export const IterableProvider = ({ apiKey, children }: React.PropsWithChildren<IterableProviderOptions>) => {
  const { enabled: isIterableEnabled } = useAmplitudeFeatureFlag(AmplitudeFeatureFlag.EnableIterable)
  const { token } = useAuthState()
  const { userAccount } = useUserAccount()

  const [emailInternal, setEmailInternal] = useState<string | undefined>(undefined)
  const [locked, setLocked] = useState(true)

  const { updateDeferredProperties, reset: resetProperties } = useDeferredProperties({
    updateUser,
    locked,
  })

  const { addEvent, reset: resetEvents } = useDeferredEvents({
    trackEvent: track,
    locked,
  })

  const { addUpdate: addSubscriptionUpdate, reset: resetSubscriptions } = useDeferredSubscriptions({
    locked,
  })

  const { setEmail, logout: logoutIterable } = useMemo(() => {
    if (isIterableEnabled && token) {
      const iterable = initialize(apiKey, async (request) => getIterableJWT(token, request).then(({ data }) => data))

      if (iterable !== undefined) {
        return iterable
      }
    }

    return {
      setEmail: getAsyncNoOpFunction(),
      logout: getNoOpFunction(),
    }
  }, [isIterableEnabled, token])

  const logout = () => {
    logoutIterable()
    setEmailInternal(undefined)
    resetProperties()
    resetEvents()
    resetSubscriptions()
    setLocked(true)
  }

  useEffect(() => {
    if (isIterableEnabled && locked && userAccount.email && token) {
      setEmail(userAccount.email).then(() => {
        setEmailInternal(userAccount.email)
        setLocked(false)
      })
    }
  }, [isIterableEnabled, userAccount.email, locked, token])

  useEffect(() => {
    if (!locked && userAccount.email && userAccount.email !== emailInternal) {
      setLocked(true)
      updateUserEmail(userAccount.email).then(() => {
        setEmailInternal(userAccount.email)
        setLocked(false)
      })
    }
  }, [locked, userAccount.email, emailInternal])

  const context = useMemo(
    () => ({
      setContactDetails: (details: ContactFields) => updateDeferredProperties(details),
      setAddressInformation: (address: AddressFields) => updateDeferredProperties(address),
      setPropertyInformation: (property: PropertyFields) => updateDeferredProperties(property),
      setAppRentAmount: (amount: number) => updateDeferredProperties({ app_rent_amount: amount }),
      setHeardAboutFlex: (medium: string) => updateDeferredProperties({ heard_about_flex: medium }),
      setSMSOptInStatus: (enabled: boolean) => addSubscriptionUpdate({ channel: 'sms-marketing', enabled }),
      setProductType: (productType: SLCType) => updateDeferredProperties({ product_type: productType }),
      addEvent,
      logout,
    }),
    [updateDeferredProperties, addSubscriptionUpdate, addEvent, logout]
  )

  return <IterableContext.Provider value={context}>{children}</IterableContext.Provider>
}

export const useIterable = () => useContext(IterableContext)
