import { Flex, IconButton } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { QuestionCircle } from '../../assets'
import { ExtraSmallText } from '../Typography'

export interface PaymentLineItemProps {
  readonly label: string
  readonly amount?: string
  readonly moreInfo?: () => void
}

export const PaymentLineItem = (item: PaymentLineItemProps) => {
  return (
    <ItemRow>
      <LineItemLabelContainer>
        <LineItemText>{item.label}</LineItemText>
        {item.moreInfo && <MoreInfoButton onClick={item.moreInfo} />}
      </LineItemLabelContainer>
      <LineItemText>{item.amount ?? ''}</LineItemText>
    </ItemRow>
  )
}

const MoreInfoButton = ({ onClick }: { readonly onClick: () => void }) => {
  const theme = useTheme()

  return (
    <IconButton
      aria-label="More info"
      isRound
      size="8px"
      backgroundColor={theme.colors.wildflower}
      borderColor={theme.colors.wildflower}
      icon={<QuestionCircle />}
      onClick={onClick}
    />
  )
}

const ItemRow = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`

const LineItemLabelContainer = styled(Flex)`
  gap: ${(props) => props.theme.fixedSizes.spacing_50};
`

const LineItemText = styled(ExtraSmallText)`
  color: ${(props) => props.theme.colors.dusk};
`
