import { ActionLogType } from '@genoa/domain'
import { useRegisterActionLog } from '@genoa/middle-end'
import { AxiosPromise } from 'axios'

import { useAuthState } from '../../../contexts'
import { useLogger } from '../../../providers/logger'

export const useHandleRegisterActionLog = () => {
  const logger = useLogger('useHandleRegisterActionLog')
  const [{ loading: loadingRegisterActionLog }, registerActionLog] = useRegisterActionLog()
  const { user } = useAuthState()

  const handleRegisterActionLog = (actionLogType: ActionLogType): AxiosPromise => {
    if (!user?.uid) {
      logger.error('handleRegisterActionLog', 'handleRegisterActionLog called without a user.uid')
      return Promise.resolve({}) as AxiosPromise
    }
    return registerActionLog({ customerPublicId: user?.uid, action_type: actionLogType })
      .then((response) => {
        if (response.status !== 204) {
          logger.error(
            'registerActionLog',
            `registerActionLog failed to register action log, status: ${response.status}`
          )
        }
        return response
      })
      .catch((error: any) => {
        logger.error('handleRegisterActionLog', `error: ${error?.message}`)
        return error
      })
  }

  return {
    handleRegisterActionLog,
    loadingRegisterActionLog,
  }
}
