import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import { FlexAnywhereHowFlexWorks } from './FlexAnywhereHowFlexWorks'

type FlexAnywhereHowFlexWorksContainerProps = {
  analyticsScreenName: Analytics.Screens
}

export const FlexAnywhereHowFlexWorksContainer = (props: FlexAnywhereHowFlexWorksContainerProps) => {
  const analytics = useAnalytics()
  const navigate = useNavigate()

  const handleGotIt = () => {
    analytics.logEvent(Analytics.Events.OON_HOW_FLEX_WORKS_CTA_CLICKED)
    navigate(Routes.Onboarding.CONGRATS)
  }

  return <FlexAnywhereHowFlexWorks analyticsScreenName={props.analyticsScreenName} onGotIt={handleGotIt} />
}
