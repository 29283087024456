import React from 'react'
import { TextToken } from '@genoa/domain'
import { Text, TextProps } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { MarkdownView } from '../../Markdown/MarkdownView'

type FlexTextProps = { markdown?: boolean } & TextProps
type BaseTextProps = { textToken: TextToken } & FlexTextProps

const BaseText = ({ textToken, ...untypedProps }: BaseTextProps) => {
  const theme = useTheme()
  const props = untypedProps as FlexTextProps

  const TextTokenMap = {
    [TextToken.DISPLAY_LARGE]: {
      lineHeight: 100,
      fontSize: 96,
      fontFamily: theme.fonts.heading,
      fontWeight: 700,
    },
    [TextToken.DISPLAY_MEDIUM]: {
      lineHeight: 80,
      fontSize: 72,
      fontFamily: theme.fonts.heading,
      fontWeight: 700,
    },
    [TextToken.HEADING_EXTRA_LARGE]: {
      lineHeight: 56,
      fontSize: 48,
      fontFamily: theme.fonts.heading,
      fontWeight: 700,
    },
    [TextToken.HEADING_LARGE]: {
      lineHeight: 100,
      fontSize: 36,
      fontFamily: theme.fonts.heading,
      fontWeight: 700,
    },
    [TextToken.HEADING_MEDIUM]: {
      lineHeight: 30,
      fontSize: 24,
      fontFamily: theme.fonts.heading,
      fontWeight: 700,
    },
    [TextToken.HEADING_SMALL]: {
      lineHeight: 24,
      fontSize: 18,
      fontFamily: theme.fonts.heading,
      fontWeight: 700,
    },
    [TextToken.BUTTON_EXTRA_LARGE]: {
      lineHeight: 24,
      fontSize: 20,
      fontFamily: theme.fonts.heading,
      fontWeight: 700,
    },
    [TextToken.BUTTON_LARGE]: {
      lineHeight: 21.6,
      fontSize: 18,
      fontFamily: theme.fonts.heading,
      fontWeight: 700,
    },
    [TextToken.BUTTON_MEDIUM]: {
      lineHeight: 19.2,
      fontSize: 16,
      fontFamily: theme.fonts.heading,
      fontWeight: 700,
    },
    [TextToken.BUTTON_SMALL]: {
      lineHeight: 16.8,
      fontSize: 14,
      fontFamily: theme.fonts.heading,
      fontWeight: 700,
    },
    [TextToken.BUTTON_EXTRA_SMALL]: {
      lineHeight: 21.6,
      fontSize: 18,
      fontFamily: theme.fonts.heading,
      fontWeight: 700,
    },
    [TextToken.LABEL_EXTRA_LARGE]: {
      lineHeight: 21.6,
      fontSize: 18,
      fontFamily: theme.fonts.body,
      fontWeight: 500,
    },
    [TextToken.LABEL_LARGE]: {
      lineHeight: 19.2,
      fontSize: 16,
      fontFamily: theme.fonts.body,
      fontWeight: 500,
    },
    [TextToken.LABEL_MEDIUM]: {
      lineHeight: 16.8,
      fontSize: 14,
      fontFamily: theme.fonts.body,
      fontWeight: 500,
    },
    [TextToken.LABEL_SMALL]: {
      lineHeight: 16.8,
      fontSize: 12,
      fontFamily: theme.fonts.body,
      fontWeight: 500,
    },
    [TextToken.LABEL_EXTRA_SMALL]: {
      lineHeight: 12,
      fontSize: 10,
      fontFamily: theme.fonts.body,
      fontWeight: 500,
    },
    [TextToken.PARAGRAPH_EXTRA_LARGE]: {
      lineHeight: 25.2,
      fontSize: 18,
      fontFamily: theme.fonts.body,
      fontWeight: 400,
    },
    [TextToken.PARAGRAPH_LARGE]: {
      lineHeight: 22.4,
      fontSize: 16,
      fontFamily: theme.fonts.body,
      fontWeight: 400,
    },
    [TextToken.PARAGRAPH_MEDIUM]: {
      lineHeight: 19.6,
      fontSize: 14,
      fontFamily: theme.fonts.body,
      fontWeight: 400,
    },
    [TextToken.PARAGRAPH_SMALL]: {
      lineHeight: 16.8,
      fontSize: 12,
      fontFamily: theme.fonts.body,
      fontWeight: 400,
    },
  }

  const styles = TextTokenMap[textToken]

  if (props.markdown) {
    return <MarkdownView styles={{ ...styles }}>{props.children as string}</MarkdownView>
  }

  return <Text {...styles} {...props} />
}

export const DisplayLarge = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.DISPLAY_LARGE} />
export const DisplayMedium = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.DISPLAY_MEDIUM} />
export const HeadingExtraLarge = (props: BaseTextProps) => (
  <BaseText {...props} textToken={TextToken.HEADING_EXTRA_LARGE} />
)
export const HeadingLarge = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.HEADING_LARGE} />
export const HeadingMedium = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.HEADING_MEDIUM} />
export const HeadingSmall = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.HEADING_SMALL} />
export const ButtonExtraLarge = (props: BaseTextProps) => (
  <BaseText {...props} textToken={TextToken.BUTTON_EXTRA_LARGE} />
)
export const ButtonLarge = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.BUTTON_LARGE} />
export const ButtonMedium = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.BUTTON_MEDIUM} />
export const ButtonSmall = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.BUTTON_SMALL} />
export const ButtonExtraSmall = (props: BaseTextProps) => (
  <BaseText {...props} textToken={TextToken.BUTTON_EXTRA_SMALL} />
)
export const LabelExtraLarge = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.LABEL_EXTRA_LARGE} />
export const LabelLarge = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.LABEL_LARGE} />
export const LabelMedium = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.LABEL_MEDIUM} />
export const LabelSmall = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.LABEL_SMALL} />
export const LabelExtraSmall = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.LABEL_EXTRA_SMALL} />
export const ParagraphExtraLarge = (props: BaseTextProps) => (
  <BaseText {...props} textToken={TextToken.PARAGRAPH_EXTRA_LARGE} />
)
export const ParagraphLarge = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.PARAGRAPH_LARGE} />
export const ParagraphMedium = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.PARAGRAPH_MEDIUM} />
export const ParagraphSmall = (props: BaseTextProps) => <BaseText {...props} textToken={TextToken.PARAGRAPH_SMALL} />
