import React from 'react'

import { useHandleNavigationForCreditBuilderValueProp } from '../../../../hooks'
import { ConfirmAccountContainer } from './ConfirmAccountContainer'

export const OnboardingConfirmAccountContainer = () => {
  const { navigateNext } = useHandleNavigationForCreditBuilderValueProp({ source: 'confirm' })

  return <ConfirmAccountContainer onNext={navigateNext} />
}
