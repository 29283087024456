import { RENT_AMOUNT_LIMITS } from '@genoa/screen-content'
import * as yup from 'yup'

const {
  validatorConfig: { minRentAmount, maxRentAmount },
  textContent,
} = RENT_AMOUNT_LIMITS

export const amountError = textContent
export const rentValidator = yup
  .number()
  .required()
  .min(minRentAmount, amountError)
  .max(maxRentAmount, amountError)
  .typeError(amountError)
