import React, { ReactNode } from 'react'
import MediaQuery from 'react-responsive'

import { queries } from './queries'

export const WhenPhone = (props: any) => {
  return <MediaQuery query={queries.phone} {...props} />
}

export const WhenTablet = (props: any) => {
  return <MediaQuery query={queries.tablet} {...props} />
}

export const WhenDesktop = (props: any) => {
  return <MediaQuery query={queries.desktop} {...props} />
}
