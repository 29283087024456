import { GetPricingOfferResponse, useGetPricingOffer } from '@genoa/middle-end'
import { AxiosPromise } from 'axios'

import {
  resetPricingOfferAction,
  setPricingOfferAction,
  setPricingOfferLoadedAction,
} from '../../../modules/flex2/pricing-offer'
import { ResetLoadedPayload } from '../../../modules/flex2/types'
import { useLogger } from '../../../providers'
import { useReduxAction } from '../../use-redux-action'

export const useGetPricing = () => {
  const loggerV2 = useLogger('useGetPricing')
  const [, getPricing] = useGetPricingOffer()
  const setPricingOfferState = useReduxAction(setPricingOfferAction)
  const resetPricingOffer = useReduxAction<ResetLoadedPayload | void>(resetPricingOfferAction as any)
  const setLoaded = useReduxAction(setPricingOfferLoadedAction)

  const getPricingOffer = (billerAccountPublicId: string): AxiosPromise<GetPricingOfferResponse> => {
    if (!billerAccountPublicId) {
      loggerV2.error('getPricingOffer', 'getPricingOffer called without a biller_account_public_id')
      return Promise.resolve({}) as AxiosPromise<GetPricingOfferResponse>
    }
    return getPricing({ billerAccountPublicId })
      .then((response) => {
        if (response.status === 200 && response.data) {
          setPricingOfferState({ pricing_offer: response.data })
        } else if (response.status !== 404) {
          loggerV2.error('getPricingOffer', 'unexpected status', { status: response.status })
          resetPricingOffer({ loaded: true })
        }
        return response
      })
      .catch((error: any) => {
        setLoaded(true)
        loggerV2.error('getPricingOffer', `error: ${error?.message}`)
        return error
      })
  }

  return {
    getPricingOffer,
  }
}
