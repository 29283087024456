import React from 'react'
import { Button } from '@chakra-ui/react'
import styled from '@emotion/styled'

type SecondaryButtonProps = {
  children: React.ReactNode
  disabled?: boolean
  onClick: () => unknown
  testID?: string
  foreground?: string
  variant?: string
}

export const SecondaryRoundButton = (props: SecondaryButtonProps) => {
  return (
    <RoundButton
      variant={props.variant ? props.variant : 'secondary'}
      data-testid={props.testID}
      onClick={props.onClick}
      isDisabled={props.disabled}
      color={props.foreground}
      _focusVisible={{
        boxShadow: '0px 0px 0px 2px white',
        outline: '1px solid $forward-blue',
        outlineOffset: '2px',
      }}
    >
      {props.children}
    </RoundButton>
  )
}

const RoundButton = styled(Button)`
  border: 2px solid ${(props) => props.theme.colors.midnight};
  border-radius: 100px;
  width: 110px;
`
