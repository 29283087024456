import React, { useMemo, useState } from 'react'
import { Box, Input, InputGroup, InputRightElement } from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import { useTheme } from '@emotion/react'

import { EyeOff, EyeOn } from '../../assets'

type PasswordInputProps = {
  text?: string
  placeholder?: string
  onTextChange: (text: string) => unknown
  onBlur?: () => unknown
  onFocus?: () => unknown
  error?: boolean
  disabled?: boolean
  autoCapitalize?: boolean
  background?: string
  foreground?: string
  maxLength?: number
  defaultValue?: string
  testID?: string
}

export const PasswordInput = (props: PasswordInputProps) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const theme = useTheme()

  const handleInputChangeEvent = (evt: React.ChangeEvent<HTMLInputElement>) => props.onTextChange(evt.target.value)

  const EyeIcon = useMemo(() => (isPasswordVisible ? EyeOn : EyeOff), [isPasswordVisible])

  return (
    <InputGroup size="md">
      <Input
        placeholder={props.placeholder}
        type={isPasswordVisible ? 'text' : 'password'}
        onFocus={props.onFocus}
        isInvalid={props.error}
        value={props.text}
        onChange={handleInputChangeEvent}
        textTransform="none"
        backgroundColor={props.background}
        color={props.foreground}
        maxLength={props.maxLength}
        data-testid={props.testID}
      />
      <InputRightElement>
        <Box onClick={() => setIsPasswordVisible(!isPasswordVisible)} as="button">
          <EyeIcon color={transparentize(props.background || theme.colors.foreground, 0.5)(theme)} />
        </Box>
      </InputRightElement>
    </InputGroup>
  )
}
