import { Controller, useFormContext } from 'react-hook-form'
import { Box, Stack } from '@chakra-ui/react'

import { RadioButton } from '../RadioButton'
import { SmallText } from '../Typography'

// TODO make styles more customizable, for now the design will be hardcoded according to the P2P waitlist form

export interface RadioGroupOption {
  label: string
  value: string
}

export function RadioGroup(props: { name: string; title: string; options: RadioGroupOption[] }) {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field }) => (
        <Stack justifyItems="center" alignItems="start">
          <SmallText fontWeight="bold">{props.title}</SmallText>
          {props.options.map((option) => (
            <RadioButton
              key={option.value}
              label={option.label}
              isSelected={field.value === option.value}
              onClick={() => field.onChange(option.value)}
              testID={`radio-${option.value}`}
            />
          ))}
        </Stack>
      )}
    />
  )
}
