import { useCallback, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { BillerPropertyUnit } from '@genoa/domain'

import { useAuthState } from '../../contexts'
import { RootState } from '../../modules'
import { useAnalytics, useLogger, useUserAccount } from '../../providers'
import { useBillingAddressDetails } from '../../views/pages/onboarding/payment-method/card/register-card/billing-address-details/use-billing-address-details-data'
import { useAccount } from '../use-account'
import { useReduxSelector } from '../use-redux-selector'
import {
  ConnectBillerEventData,
  ConnectBillerHandlerParams,
  ConnectBillerType,
  RentPortalSignInFormData,
} from './types'
import { useHandleConnectBiller } from './use-handle-connect-biller'

export interface UseConnectBillerOptions {
  readonly componentName: string
  readonly connectBillerType: ConnectBillerType
  readonly onConnected: () => void
  readonly onSamePropertyRelink: () => void
  readonly onError: () => void
}

interface ConnectBillerParams {
  unit?: BillerPropertyUnit
  signInData?: RentPortalSignInFormData
}

export const useConnectBiller = ({
  componentName,
  onConnected,
  onError,
  onSamePropertyRelink,
  connectBillerType,
}: UseConnectBillerOptions) => {
  const { user } = useAuthState()
  const { billerConnection } = useAccount()
  const { userAccount } = useUserAccount()
  const analytics = useAnalytics()
  const userInfo = useBillingAddressDetails()
  const loggerV2 = useLogger(`use-connect-biller - ${componentName}`)

  const [connecting, setConnecting] = useState(false)

  const flexAnywhereConnectionTypes = [ConnectBillerType.FLEX_ANYWHERE, ConnectBillerType.OUT_OF_NETWORK]
  const connectionType = flexAnywhereConnectionTypes.includes(connectBillerType)
    ? ConnectBillerType.PORTAL
    : connectBillerType

  const { connectBillerHandler, connecting: connectingBiller } = useHandleConnectBiller({
    componentName,
    onConnected,
    onError,
    onSamePropertyRelink,
    connectionType,
    setConnecting,
  })

  const property = useReduxSelector((state: RootState) => state.propertyLinking.property)
  const portalDetails = useReduxSelector((state: RootState) => state.propertyLinking.portalDetails)
  const genericPortal = useReduxSelector((state: RootState) => state.propertyLinking.genericPortal)

  const connectBiller = useCallback(
    async (isRelink?: boolean, params?: ConnectBillerParams) => {
      const handledParams = params || {}
      const { unit, signInData } = handledParams

      setConnecting(true)

      if (!(user?.uid && userAccount.firstName && userAccount.lastName && userAccount.email && userAccount.phone)) {
        loggerV2.error('Update customer account error', 'Missing user, name, email or phone')
        return onError()
      }

      if (connectionType === ConnectBillerType.PORTAL && portalDetails?.portal_name === undefined) {
        loggerV2.error('Connect portal error', 'Missing portal name')
        return onError()
      }

      const operation = billerConnection ? 'update' : 'create'

      const connectingProperty = property ?? genericPortal

      if (connectingProperty === undefined) {
        loggerV2.error('Connect biller error', 'Missing valid property or portal to connect to')
        return onError()
      }

      const eventData: ConnectBillerEventData = {
        id: connectingProperty?.public_id,
        biller_type: connectionType,
        portal_name: portalDetails?.portal_name,
        operation,
        relink: isRelink ? 'enabled' : undefined,
      }

      analytics.logEvent(Analytics.Events.BILLER_CONNECTION, eventData)
      loggerV2.info('Biller connection attempt', undefined, eventData)

      const connectionData: ConnectBillerHandlerParams = {
        eventData,
        isRelink,
        customerId: user.uid,
        operation,
        userAccount,
        userInfo,
        unit,
        connectingProperty,
        connectionType,
      }

      if (signInData) {
        connectionData.credentials = {
          username: signInData?.username,
          password: signInData?.password,
        }
      }

      return connectBillerHandler(connectionData)
    },
    [
      connecting,
      user?.uid,
      userAccount.firstName,
      userAccount.lastName,
      userAccount.email,
      userAccount.phone,
      property,
      portalDetails,
      userInfo,
      connectBillerHandler,
      onError,
      billerConnection,
    ]
  )

  return { connectBiller, connecting: connecting || connectingBiller }
}
