import { Embed, FeatureStatusState, OnboardingStatusState, UserAccountState } from '@genoa/state-management'

import { useReduxSelector } from '../../hooks'
import { OnboardingState } from '../onboarding'
import { RootState } from '../reducers'
import { CustomerStatusState } from './customer-status'
import { CustomerWalletState } from './customer-wallet'
import { OfferState } from './offer'
import { PricingOfferState } from './pricing-offer'
import { RepayNowState } from './repay-now'

export const useOfferState: () => OfferState = () => useReduxSelector((state: RootState) => state.offerState)

export const usePricingOfferState: () => PricingOfferState = () =>
  useReduxSelector((state: RootState) => state.pricingOffer)

export const useCustomerWalletState: () => CustomerWalletState = () =>
  useReduxSelector((state: RootState) => state.customerWallet)

export const useUserAccountState: () => UserAccountState = () =>
  useReduxSelector((state: RootState) => state.userAccount)

export const useOnboardingStatusState: () => OnboardingStatusState = () =>
  useReduxSelector((state: RootState) => state.onboardingStatus)

export const useFeatureStatusState: () => FeatureStatusState = () =>
  useReduxSelector((state: RootState) => state.featureStatus)

export const useOnboardingState: () => OnboardingState = () => useReduxSelector((state: RootState) => state.onboarding)

export const useEmbedState: () => Embed = () => useReduxSelector((state: RootState) => state.embed)

export const useRepayNowState: () => RepayNowState = () => useReduxSelector((state: RootState) => state.repayNow)

export const useCustomerStatusState: () => CustomerStatusState = () =>
  useReduxSelector((state: RootState) => state.customerStatus)
