import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export interface ScrollableSelectionListProps {}

export const ScrollableSelectionList = (props: React.PropsWithChildren<ScrollableSelectionListProps>) => {
  return (
    <MaxHeightScrollBox>
      <TopDivider />
      <Flex flexDirection="column">
        {React.Children.map(props.children, (child, index) => {
          const key = `divider-${index}`
          return (
            <>
              {child}
              {index !== React.Children.count(props.children) - 1 && <Divider key={key} />}
            </>
          )
        })}
      </Flex>
    </MaxHeightScrollBox>
  )
}

const TopDivider = styled(Box)`
  width: 100%;
  height: 2px;
  margin-bottom: ${(props) => props.theme.fixedSizes.spacing_50};
  background-color: ${(props) => props.theme.colors.lineDivider};
`

const Divider = styled(Box)`
  width: 100%;
  height: 2px;
  margin-top: ${(props) => props.theme.fixedSizes.spacing_50};
  margin-bottom: ${(props) => props.theme.fixedSizes.spacing_50};
  background-color: ${(props) => props.theme.colors.lineDivider};
`

const MaxHeightScrollBox = styled(Box)`
  flex-direction: column;
  flex-grow: 1;
  height: 1px;
  overflow-y: scroll;
  margin-top: 15px;

  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.lineDivider} rgb(0, 0, 0, 0);

  ::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.lineDivider};
    height: 5px;
    border-radius: 10px;
  }
`
