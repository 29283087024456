import { createReducer } from '@reduxjs/toolkit'

import * as Actions from './actions'
import { AuthState } from './types'

export const initialState: AuthState = {
  firebaseInitialized: false,
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions.registerSignOutAction, (state, _action) => {
      state.firebaseInitialized = false
    })
    .addCase(Actions.firebaseInitializedAction, (state, action) => {
      state.firebaseInitialized = action.payload
    })
})
