import React from 'react'
import { BillerPropertyUnit } from '@genoa/middle-end'
import { Box, Flex, SkeletonText } from '@chakra-ui/react'
import { Theme } from '@emotion/react'
import styled from '@emotion/styled'

import { CircleCheckmark } from '../../../../assets'
import { LargeText, SmallText } from '../../../../components'

type DirectIntegrationSelectionListItemProps = {
  onClick: () => unknown
  isSelected: boolean
  theme: Theme
  index: number
  unit: BillerPropertyUnit
}

export const DirectIntegrationUnitSelectionListItem = (props: DirectIntegrationSelectionListItemProps) => {
  return (
    <UnitContainer key={`unit-container-${props.unit.unit}`}>
      <StyledFlex
        flex={1}
        alignItems="center"
        justifyContent="space-between"
        onClick={props.onClick}
        data-testid={`DirectIntegrationUnitSelectionListItem-${props.index}`}
      >
        <Box>
          <Flex direction="column">
            <HeaderText data-testid="UnitLabel" isSelected={props.isSelected}>
              {props.unit.label}
            </HeaderText>
            <InfoText data-testid="UnitAddressLine1" isSelected={props.isSelected}>
              {props.unit.address_line_1}
            </InfoText>
            {props.unit.address_line_2 && (
              <InfoText data-testid="UnitAddressLine2" isSelected={props.isSelected}>
                {props.unit.address_line_2}
              </InfoText>
            )}
            <InfoText data-testid="UnitAddressLine3" isSelected={props.isSelected}>
              {props.unit.city}
              {props.unit.state ? ', ' + props.unit.state : ''}
              {props.unit.postal_code ? ' ' + props.unit.postal_code : ''}
            </InfoText>
          </Flex>
        </Box>
        {props.isSelected && <CircleCheckmark />}
      </StyledFlex>
    </UnitContainer>
  )
}

export const UnitSelectionListSkeletonItem = (props: { key: string | number }) => {
  return (
    <UnitContainer key={props.key}>
      <Flex flex={1} alignItems="center" justifyContent="space-between" padding="12px">
        <Box w="85%">
          <Flex direction="column">
            <SkeletonText />
          </Flex>
        </Box>
      </Flex>
    </UnitContainer>
  )
}

export const UnitSelectionListSkeletonItems = ({ length = 8 }: { length?: number }) => {
  return (
    <>
      {[...Array(length)].map((_, index) => (
        <UnitSelectionListSkeletonItem key={index} />
      ))}
    </>
  )
}

const StyledFlex = styled(Flex)`
  padding: 0px 12px 0px 12px;
`

const UnitContainer = styled(Flex)`
  justify-content: center;
  cursor: pointer;
  padding: 4px;
  &:hover {
    background: ${(props) => props.theme.colors.hoveredContainer};
  }
`

interface IsSelectedProps {
  readonly isSelected: boolean
}

const HeaderText = styled(LargeText)<IsSelectedProps>`
  color: ${(props) => (props.isSelected ? props.theme.colors.brand[600] : props.theme.colors.text[900])};
  font-weight: bold;
`

const InfoText = styled(SmallText)<IsSelectedProps>`
  color: ${(props) => (props.isSelected ? props.theme.colors.brand[600] : props.theme.colors.text.quiet)};
`
