import React, { useMemo } from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { SmallText } from '../../../../../components'
import { TextInput } from '../../../../../components/Input'

type UpdateCardBillingAddressInputProps = {
  error?: FieldError
  label: string
  name: string
  control: any
  testID: string
}

export const UpdateCardBillingAddressInput = (props: UpdateCardBillingAddressInputProps) => {
  const theme = useTheme()

  const error = useMemo(() => {
    if (!props.error || !props.error.message) {
      return undefined
    }

    return props.error.message.replace(props.name, props.label)
  }, [props.error])

  return (
    <Controller
      control={props.control}
      defaultValue=""
      render={({ field: { onChange, onBlur, value } }) => (
        <>
          <SmallText>{props.label}</SmallText>
          <Box minH="5px" />
          <TextInput onBlur={onBlur} onTextChange={onChange} text={value} error={!!error} testID={props.testID} />
          <Box minH="5px" />
          <Box visibility={error ? 'visible' : 'hidden'} color={theme.colors.error}>
            <SmallText hasError={!!error}>{error}</SmallText>
          </Box>
        </>
      )}
      name={props.name}
    />
  )
}
