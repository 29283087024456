import styled from '@emotion/styled'
import MarkDownIt from 'markdown-it'

export type SimpleMarkdownProps = {
  children: string
  styles?: any
}

const markdownit = new MarkDownIt()

export const MarkdownView = (props: SimpleMarkdownProps) => {
  const markdown = markdownit.render(props.children)
  return (
    <StyledMarkdown theme={props.styles} markdownTheme={props.styles} dangerouslySetInnerHTML={{ __html: markdown }} />
  )
}

export const StyledMarkdown = styled.div<{ markdownTheme?: any }>`
  * {
    ${(props) => `color: ${props.markdownTheme.colors.markdown};`}
  }
  p {
    ${(props) =>
      props.markdownTheme.fontFamily &&
      `
      font-family: ${props.markdownTheme.fontFamily};`}

    ${(props) =>
      props.markdownTheme.fontWeight &&
      `
      font-weight: ${props.markdownTheme.fontWeight};`}

    ${(props) =>
      props.markdownTheme.fontSize &&
      `
      font-size: ${props.markdownTheme.fontSize};`}

    ${(props) =>
      props.markdownTheme.lineHeight &&
      `
    line-height: ${props.markdownTheme.lineHeight};`}

    ${(props) =>
      props.markdownTheme.opacity &&
      `
      opacity: ${props.markdownTheme.opacity};`}

    ${(props) =>
      props.markdownTheme.letterSpacing &&
      `
    letter-spacing: ${props.markdownTheme.letterSpacing};`}

    ${(props) =>
      props.markdownTheme.textAlign &&
      `
    text-align: ${props.markdownTheme.textAlign};`}
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${(props) => props.markdownTheme.fontFamily && `text-align: ${props.markdownTheme.fontFamily};`}
    ${(props) => props.markdownTheme.fontSize && `font-size: ${props.markdownTheme.fontSize};`}
    ${(props) => props.markdownTheme.lineHeight && `line-height: ${props.markdownTheme.lineHeight};`}
    opacity: 0.9;
  }

  h1 {
    font-size: ${(props) => props.markdownTheme.theme.fontSizes.lg}px;
    line-height: ${(props) => props.markdownTheme.theme.lineHeights.md}px;
  }
  h2 {
    font-size: 22px;
    line-height: 30px;
  }
  h3 {
    font-size: 20px;
    line-height: 28px;
  }
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  h6 {
    font-size: 14px;
  }
  a {
    color: ${(props) => props.markdownTheme.colors.markdown};
  }
  ol,
  ul {
    margin: 4px;
    margin-bottom: 16px;
  }
  ol li,
  ul li {
    flex: 1;
    font-size: ${(props) => props.markdownTheme.theme.fontSizes.xs}px;
    line-height: ${(props) => props.markdownTheme.theme.lineHeights.sm}px;
    margin-top: 16px;
    min-width: 32px;
    padding-right: 4px;
  }
  table {
    margin: 4px;
    border: 1px solid #222222;
    width: 99%;
  }
  table th {
    font-weigth: ${(props) => props.markdownTheme.theme.fontWeights.bold};
    width: 50%;
  }
  table td {
    padding: 5px;
  }
  table tbody tr:nth-of-type(odd) {
    background-color: rgba(128, 128, 128, 0.1);
  }
`
