import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { initConfigureFlex2API } from '@genoa/middle-end'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import branch from 'branch-sdk'

import { App } from './App'
import { AuthenticationProvider, ModalProvider } from './contexts'
import { ErrorBoundary } from './ErrorBoundary'
import { store } from './modules'
import {
  AmplitudeProvider,
  Config,
  DatadogProvider,
  FirebaseProvider,
  IdentityServiceProvider,
  IterableProvider,
  logger,
  UserAccountProvider,
} from './providers'
import { GTMContainerConfiguration } from './providers/amplitude'
import { ContentProvider } from './providers/content'
import { ProductTypeProvider } from './providers/product-type'
import { FlexThemeProvider } from './providers/theme/flex-theme-provider'

initConfigureFlex2API(Config.FLEX_2_API, Config.ENVIRONMENT === 'local')

const appBuildVersion = Config.VITE_BUILD_VERSION || '0.0.0'

// Mock service worker
if (Config.ENVIRONMENT === 'local') {
  import('./apiMock').then(({ mockWorker }) => {
    mockWorker.start({ onUnhandledRequest: 'bypass' })
  })
}

const DATADOG_CONFIG = {
  clientToken: Config.DATADOG_CLIENT_TOKEN,
  env: Config.DATADOG_ENV,
  site: Config.DATADOG_SITE,
  service: Config.DATADOG_SERVICE_NAME,
  version: appBuildVersion,
  applicationId: Config.DATADOG_APPLICATION_ID,
  allowedTracingUrls: Config.DATADOG_ALLOWED_TRACING_URLS,
  traceSampleRate: Config.DATADOG_TRACE_SAMPLE_RATE,
}

const gtmConfiguration =
  Config.GTM_CONTAINER_ID !== undefined
    ? ({
        isDebug: Config.ENVIRONMENT !== 'production',
        containerId: Config.GTM_CONTAINER_ID,
      } as GTMContainerConfiguration)
    : undefined

const consentManagementConfig =
  Config.CONSENT_MGMT_SRC !== undefined
    ? {
        scriptSrc: Config.CONSENT_MGMT_SRC,
      }
    : undefined

const stripe = loadStripe(Config.STRIPE_APIKEY)

const stripeElementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css2?family=Work+Sans',
    },
  ],
}

branch.init(Config.BRANCH_KEY, undefined, (err, data) => {
  if (err) {
    logger.log('Branch.io', 'Could not init Branch.io: ' + err)
  } else {
    logger.log('Branch.io', 'Initialized - ' + JSON.stringify(data))
  }
})

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <ErrorBoundary>
    <ReduxProvider store={store}>
      <FirebaseProvider>
        <AuthenticationProvider>
          <DatadogProvider initConfig={DATADOG_CONFIG}>
            <AmplitudeProvider
              apiKey={Config.AMPLITUDE_API_KEY}
              deploymentApiKey={Config.AMPLITUDE_DEPLOYMENT_KEY}
              gtmConfig={gtmConfiguration}
              version={appBuildVersion}
              consentManagementConfig={consentManagementConfig}
            >
              <IdentityServiceProvider>
                <FlexThemeProvider>
                  <UserAccountProvider>
                    <IterableProvider apiKey={Config.ITERABLE_API_KEY}>
                      <ModalProvider>
                        <ContentProvider>
                          <Elements stripe={stripe} options={stripeElementsOptions}>
                            <ProductTypeProvider>
                              <App />
                            </ProductTypeProvider>
                          </Elements>
                        </ContentProvider>
                      </ModalProvider>
                    </IterableProvider>
                  </UserAccountProvider>
                </FlexThemeProvider>
              </IdentityServiceProvider>
            </AmplitudeProvider>
          </DatadogProvider>
        </AuthenticationProvider>
      </FirebaseProvider>
    </ReduxProvider>
  </ErrorBoundary>
)
