import { Outlet, useLocation } from 'react-router-dom'
import { SLCType } from '@genoa/domain'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { StripeCardElement } from '@stripe/stripe-js'

import { useSecureLineOfCredit } from '../../../../../../hooks'
import * as Routes from '../../../../../../routing/constants'
import { Headline1, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'
import { BillingAddressDetailsContainer } from './billing-address-details/BillingAddressDetailsContainer'
import { BillingAddress } from './billing-address-types'
import { CardInput } from './card-input/CardInput'

type RegisterCardProps = {
  onCardInputReady: (cardElement: StripeCardElement) => void
  onPressAddCTA: (billingAddress: BillingAddress) => void
  onCardChange: (isCompleted: boolean) => void
  cardFormError: string
  billingAddressError: string
  isLoading: boolean
  processingFeePercentage?: number
  bodyCopy?: string
}

export const RegisterCard = (props: RegisterCardProps) => {
  const theme = useTheme()
  const location = useLocation()
  const { slcType } = useSecureLineOfCredit()

  const isUpdatingCardDetails = location.pathname === Routes.Onboarding.CARD_REGISTER

  return (
    <BasePageLayout>
      <Box hidden={!isUpdatingCardDetails}>
        <Box minH={theme.fixedSizes.spacing_200} px="lg" />

        <Container>
          <Headline1>Add a {slcType === SLCType.CLASSIC ? 'debit card' : 'card'}</Headline1>

          <Box minH={theme.fixedSizes.spacing_150} />

          {props.bodyCopy && (
            <>
              <SmallText>{props.bodyCopy}</SmallText>
              <Box minH={theme.fixedSizes.spacing_50} />
            </>
          )}

          <Box mt={4}>
            <SmallText>Card details</SmallText>
            <CardInput
              onCardInputReady={props.onCardInputReady}
              onCardChange={props.onCardChange}
              cardFormError={props.cardFormError}
            />
          </Box>

          <Box minH={theme.fixedSizes.spacing_200} />

          <BillingAddressDetailsContainer
            billingAddressError={props.billingAddressError}
            onPressAddCTA={props.onPressAddCTA}
            isProcessingRegisterCard={props.isLoading}
            updateBillingNavigate={Routes.Onboarding.CARD_UPDATE_BILLING_ADDRESS}
          />
        </Container>
      </Box>
      <Outlet />
    </BasePageLayout>
  )
}

const Container = styled(Box)`
  height: 100%;
`
