import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { Spacing } from '../../../theme/theme'

export interface GapProps {
  readonly size: Spacing
}
export const Gap = (props: GapProps) => {
  const theme = useTheme()

  return <Box minH={theme.fixedSizes[props.size]} />
}
