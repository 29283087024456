import { Analytics } from '@genoa/analytics'
import { SecuredLineOfCreditExperiment } from '@genoa/experiments'

import { useAnalytics } from '../../providers'
import { useExperimentVariant } from '../use-experiment-variant'

export const useTrackExposureSecureLineOfCredit = () => {
  const analytics = useAnalytics()
  const experimentVariant = useExperimentVariant(SecuredLineOfCreditExperiment)

  const trackSLCExposure = (isEnabledForSLC: boolean) => {
    // we only track the exposure of the user to the experiment if they are control or treatment
    if (experimentVariant) {
      analytics.logEvent(Analytics.Events.SLC_EXPERIMENT_EXPOSURE, { enabled: isEnabledForSLC })
    }
  }

  return { trackSLCExposure }
}
