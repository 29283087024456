import { Agreement, SLCType } from '@genoa/domain'
import {
  SLC_AGREEMENTS_MODAL_CONTENT as legacyContent,
  SLC_CLASSIC_AGREEMENTS_CONTENT as classicContent,
} from '@genoa/screen-content'
import { Box, Checkbox, Flex, useTheme } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAgreementLink, useSecureLineOfCredit } from '../../../../../hooks'
import theme from '../../../../../theme/theme'
import { Headline2, InlineButton, Modal, PrimaryButton, SmallText } from '../../../../components'

export interface SLCAgreementsModalProps {
  readonly visible: boolean
  readonly agreements: Agreement[]
  readonly accepted: boolean
  readonly loading: boolean
  readonly onClose: () => void
  readonly onToggleAccept: (value: boolean) => void
  readonly onAccept: () => void
}

export const SLCAgreementsModal = ({
  visible,
  agreements,
  accepted,
  loading,
  onClose,
  onToggleAccept,
  onAccept,
}: SLCAgreementsModalProps) => {
  const handleAgreementClick = useAgreementLink()
  const { isADO } = useSecureLineOfCredit()
  const content = isADO ? classicContent : legacyContent

  return (
    <Modal visible={visible} title={''} testID="SLCAgreementsModal" canClose={true} onClose={onClose}>
      <ContentContainer>
        <TitleContainer>
          <Headline2>{content.HEADER}</Headline2>
          <SmallText>{content.SUB_HEADER}</SmallText>
        </TitleContainer>
        <AgreementsContainer>
          {isADO && (
            <Box mb={theme.fixedSizes.spacing_50}>
              <SmallText textAlign="left">
                <strong>{content.AGREEMENT}</strong>
              </SmallText>
            </Box>
          )}
          {agreements.map((agreement, index) => (
            <Flex key={`agreement-${index}`}>
              <StyledListItem>
                <SmallText fontWeight="bold">
                  <InlineButton onClick={() => handleAgreementClick(agreement)} textColor={theme.colors.jewelPurple}>
                    {agreement.agreement_type_name}
                  </InlineButton>
                </SmallText>
              </StyledListItem>
            </Flex>
          ))}
        </AgreementsContainer>
        {!isADO && (
          <CheckboxContainer>
            <StyledCheckBox isChecked={accepted} onChange={() => onToggleAccept(!accepted)} />
            <SmallText>{content.AGREEMENT}</SmallText>
          </CheckboxContainer>
        )}
        <PrimaryButton onClick={onAccept} disabled={loading || (!isADO && !accepted)} processing={loading}>
          {content.CTA}
        </PrimaryButton>
      </ContentContainer>
    </Modal>
  )
}

const ContentContainer = styled(Flex)`
  flex-direction: column;
  gap: ${(props) => props.theme.fixedSizes.spacing_200};
`

const TitleContainer = styled(Flex)`
  flex-direction: column;
  gap: ${(props) => props.theme.fixedSizes.spacing_100};
`

const CheckboxContainer = styled(Flex)`
  align-items: center;
  justify-content: center;
  gap: ${(props) => props.theme.fixedSizes.spacing_50};
`

const AgreementsContainer = styled(Flex)`
  flex-direction: column;
  align-self: center;
  justify-content: center;
`

const StyledListItem = styled.li`
  color: ${(props) => props.theme.colors.jewelPurple};
  list-style-position: inside;
`

const StyledCheckBox = styled(Checkbox)`
  > span {
    margin-top: 0px;
  }
`
