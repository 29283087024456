import { SVGProps } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'

import { useAnalytics, useFlexLinks } from '../../providers'
import { AppStoreBadge } from '../assets'

type AppStoreButtonProps = {
  onClickAppStore?: () => void
} & SVGProps<SVGSVGElement>

export function AppStoreButton(props: AppStoreButtonProps) {
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()

  function handleOpenAppleStore() {
    if (props.onClickAppStore) {
      props.onClickAppStore()
    }
    analytics.logEvent(Analytics.Events.CONGRATS_OPEN_APP_STORE_CLICKED)
    flexLinks.open(FlexLinks.appStore)
  }

  return <AppStoreBadge {...props} role="link" tabIndex={0} onClick={handleOpenAppleStore} />
}
