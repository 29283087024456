import React from 'react'
import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'

import { Headline2, InlineButton, PrimaryButton, SmallText } from '../../../components'
import { TextInput } from '../../../components'
import { BasePageLayout } from '../../../layouts'

type FlexAnywhereWhereYouPayProps = {
  onPropertyNameChange: (value: string) => void
  propertyNameError: string | null
  propertyName: string
  onRentPortalChange: (value: string) => void
  rentPortalError: string | null
  rentPortal: string
  analyticsScreenName: Analytics.Screens
  onClickContinue: () => void
  onDontPayOnline: () => void
}

const FlexAnywhereWhereYouPay = (props: FlexAnywhereWhereYouPayProps) => {
  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH="30px" />
      <Flex direction="column">
        <Headline2>Where do you pay rent online?</Headline2>
        <Box minH="15px" />
        <SmallText>We need some additional information about your property.</SmallText>
        <Box minH="30px" />

        <Box position="relative">
          <SmallText>Property Name</SmallText>
          <Box minH="5px" />
          <TextInput
            placeholder=""
            onTextChange={props.onPropertyNameChange}
            error={!!props.propertyNameError}
            text={props.propertyName}
            type="string"
            testID="PropertyNameInput"
          />
          {props.propertyNameError && (
            <Box position="absolute" minH="20px">
              <Box minH="5px" />
              <SmallText hasError>{props.propertyNameError}</SmallText>
            </Box>
          )}
        </Box>

        <Box minH="50px" />

        <Box position="relative">
          <SmallText>Rent portal URL (or app name)</SmallText>
          <Box minH="5px" />
          <TextInput
            placeholder=""
            onTextChange={props.onRentPortalChange}
            error={!!props.rentPortalError}
            text={props.rentPortal}
            type="string"
            testID="RentPortalInput"
          />
          {props.rentPortalError && (
            <Box minH="20px" position="absolute">
              <Box minH="5px" />
              <SmallText hasError>{props.rentPortalError}</SmallText>
            </Box>
          )}
        </Box>
        <Box minH={'64px'} />
        <SmallText fontWeight="bold" textAlign="center" fontFamily={'RebrandDis'}>
          <InlineButton onClick={props.onDontPayOnline}>
            <b>I don&rsquo;t pay rent online</b>
          </InlineButton>
        </SmallText>
        <Box minH="20px" />

        <Box>
          <PrimaryButton
            onClick={props.onClickContinue}
            testID="ContinueButton"
            disabled={props.rentPortalError != undefined || props.propertyNameError != undefined}
          >
            Continue
          </PrimaryButton>
        </Box>
      </Flex>
    </BasePageLayout>
  )
}

export default FlexAnywhereWhereYouPay
