import {
  BillerFlexAnywhereLongTailPortal,
  BillerFlexAnywherePortal,
  BillerGenericPortal,
  BillerOONPortal,
  BillerPropertyDetails,
  BillerPropertyUnit,
} from '@genoa/domain'
import { BillerAccountStatus } from '@genoa/middle-end'

import { UserAccount } from '../../providers'
import { BillingAddress } from '../../views/pages/onboarding/payment-method/card/register-card/billing-address-types'

export enum ConnectBillerType {
  PORTAL = 'portal',
  DIRECT_INTEGRATION = 'directIntegration',
  /** @deprecated use FLEX_ANYWHERE instead */
  OUT_OF_NETWORK = 'outOfNetwork',
  FLEX_ANYWHERE = 'flexAnywhere',
}
export interface PortalCredentials {
  username: string
  password: string
}

export type ConnectingProperty =
  | BillerPropertyDetails
  | BillerGenericPortal
  | BillerOONPortal
  | BillerFlexAnywherePortal
  | BillerFlexAnywhereLongTailPortal

export interface ConnectBillerHandlerParams {
  eventData: ConnectBillerEventData
  isRelink?: boolean
  customerId: string
  operation: string
  credentials?: PortalCredentials
  userAccount: UserAccount
  userInfo: BillingAddress
  unit?: BillerPropertyUnit
  connectingProperty: ConnectingProperty
  connectionType: ConnectBillerType
}

export const validPostMatchStatus = [BillerAccountStatus.PENDING, BillerAccountStatus.ACTIVE]

export type UpsertCustomerOperation = 'create' | 'update'

export interface ConnectBillerEventData {
  readonly id: string
  readonly biller_type: ConnectBillerType | ''
  readonly operation: UpsertCustomerOperation
  readonly portal_name?: string
  readonly relink?: 'enabled'
  readonly status?: string
  readonly optimizations_enabled?: boolean
}

export type RentPortalSignInFormData = { username: string; password: string }

export interface BillerCustomerAccountData {
  readonly account_status: BillerAccountStatus
  readonly billing_response?: string
}

export const isBillerCustomerAccountData = (customerAccount?: any): boolean => {
  return customerAccount && Object.values(BillerAccountStatus).includes(customerAccount.account_status)
}

export const tryGetBillerCustomerAccountData = (customerAccount?: any): BillerCustomerAccountData | undefined => {
  if (!isBillerCustomerAccountData(customerAccount)) {
    return undefined
  }

  return customerAccount
}
