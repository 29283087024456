import { Route } from 'react-router-dom'

import { ApprovedSLCClassic } from '../../views/pages/onboarding/approved/ApprovedSLCClassic'
import {
  OnboardingSLCConfirmScheduleContainer,
  OnboardingSLCCongratsContainer,
  OnboardingSLCCustomizeScheduleContainer,
  OnboardingSLCSoftCreditCheckContainer,
} from '../../views/pages/onboarding/schedule-slc'
import * as Routes from '../constants'
import { getRouteSlugs } from '../utils'

const routeSlugs = getRouteSlugs(Routes.App.ONBOARDING, Routes.Onboarding)

export const SLC_CLASSIC_ONBOARDING_ROUTES = (
  <>
    <Route key="approved" path={routeSlugs.APPROVED} element={<ApprovedSLCClassic />} />
    <Route
      key="soft-credit-check"
      path={routeSlugs.SOFT_CREDIT_CHECK}
      element={<OnboardingSLCSoftCreditCheckContainer />}
    />
    <Route
      key="customize-schedule"
      path={routeSlugs.CUSTOMIZE_SCHEDULE}
      element={<OnboardingSLCCustomizeScheduleContainer />}
    />
    <Route key="offer-details" path={routeSlugs.OFFER_DETAILS} element={<OnboardingSLCConfirmScheduleContainer />} />
    <Route key="congrats" path={routeSlugs.CONGRATS} element={<OnboardingSLCCongratsContainer />} />
  </>
)
