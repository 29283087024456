import React from 'react'
import { Box, Flex, List } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ChevronRight } from '../../../../assets'
import { SmallText } from '../../../../components'
import { Modal } from '../../../../components/Modal'

type DirectIntegrationUnitNotListedProps = {
  visible: boolean
  onStartWithNewAddress: () => unknown
  onContactSupport: () => unknown
  onClose: () => unknown
}

export const DirectIntegrationUnitNotListed = (props: DirectIntegrationUnitNotListedProps) => (
  <Modal
    visible={props.visible}
    canClose={true}
    onClose={props.onClose}
    title="Whoops!"
    data-testid={'DirectIntegrationUnitNotListedModal'}
    hasCloseButton={true}
  >
    <StyledSheetContent>
      <SmallText>Double check that you typed in your unit information correctly or try an option below.</SmallText>
      <Box minH={'30px'} />
      <List>
        <Separator />
        <ListItem data-testid={'DirectIntegrationStartWithNewAddress'} onClick={props.onStartWithNewAddress}>
          <SmallText>Start over with a new address</SmallText>
          <ChevronRight />
        </ListItem>
        <Separator />
        <ListItem testID={'DirectIntegrationContactSupport'} onClick={props.onContactSupport}>
          <SmallText>Contact Support</SmallText>
          <ChevronRight />
        </ListItem>
        <Separator />
      </List>
    </StyledSheetContent>
  </Modal>
)

const ListItem = styled(Flex)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 4px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.colors.hoveredContainer};
  }
`

const Separator = styled(Box)`
  border-bottom-width: 1px;
`

const StyledSheetContent = styled(Box)`
  justify-content: space-between;
`
