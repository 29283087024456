import { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { resetEmbedAction } from '@genoa/state-management'

import { ConnectBillerType, useConnectBiller, useIsEmbed, useReduxAction, useReduxSelector } from '../../../../../hooks'
import { useShowNoMatchError } from '../../../../../hooks/biller/use-show-no-match-error'
import { usePrefills } from '../../../../../hooks/prefills'
import { RootState } from '../../../../../modules'
import { useAnalytics } from '../../../../../providers'
import { DirectIntegrationConfirmAddress } from './DirectIntegrationConfirmAddress'

type DirectIntegrationConfirmAddressContainerProps = {
  analyticsScreenName: Analytics.Screens
  analyticsEventBackClicked: Analytics.Events
  analyticsEventCTAClicked: Analytics.Events
  analyticsEventStartOverClicked: Analytics.Events
  isRelink?: boolean
  onNext: () => unknown
  onStartOver: () => unknown
}

export const DirectIntegrationConfirmAddressContainer = (props: DirectIntegrationConfirmAddressContainerProps) => {
  const analytics = useAnalytics()
  const { resetPrefills, prefillIsActive } = usePrefills()
  const isEmbed = useIsEmbed()

  const resetEmbed = useReduxAction<void>(resetEmbedAction)
  const property = useReduxSelector(({ propertyLinking }: RootState) => propertyLinking.property)!
  const unit = useReduxSelector(({ propertyLinking }: RootState) => propertyLinking.unit)!

  const { renderNoMatchError } = useShowNoMatchError()

  const { connecting, connectBiller } = useConnectBiller({
    componentName: 'DirectIntegrationConfirmAddressContainer',
    onConnected: props.onNext,
    onSamePropertyRelink: props.onNext,
    onError: renderNoMatchError,
    connectBillerType: ConnectBillerType.DIRECT_INTEGRATION,
  })

  const handleBack = () => {
    analytics.logEvent(props.analyticsEventBackClicked)
  }

  const handleStartOver = () => {
    analytics.logEvent(props.analyticsEventStartOverClicked)
    resetPrefills()
    props.onStartOver()
    if (isEmbed) {
      resetEmbed()
    }
  }

  const handleNext = useCallback(async () => {
    analytics.logEvent(props.analyticsEventCTAClicked)
    await connectBiller(props.isRelink, { unit })
  }, [unit])

  return (
    <DirectIntegrationConfirmAddress
      analyticsScreenName={props.analyticsScreenName}
      loading={connecting}
      onBack={handleBack}
      onNext={handleNext}
      onStartOver={handleStartOver}
      property={property}
      unit={unit}
      showPrefills={prefillIsActive}
      isEmbed={isEmbed}
    />
  )
}
