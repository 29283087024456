import React, { createContext, ReactNode, useContext } from 'react'

type HelpMenuAction = {
  action: () => unknown
  label: string
}

type HelpMenuActionsData = HelpMenuAction[]

type HelpMenuActionsProviderProps = {
  actions: HelpMenuAction[]
  children?: ReactNode
}

export const HelpMenuActions = createContext<HelpMenuActionsData>([])

export const HelpMenuActionsProvider = ({ children, actions }: HelpMenuActionsProviderProps) => {
  return <HelpMenuActions.Provider value={actions}>{children}</HelpMenuActions.Provider>
}

export const useHelpMenuActions = () => useContext(HelpMenuActions)
