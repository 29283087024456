import React, { ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { Box } from '@chakra-ui/react'

import { LinkButton, PrimaryButton } from '../views/components'
import { Modal, ModalActions } from '../views/components/Modal/Modal'

export type ModalContent = {
  title: string
  cta?: string
  hasCloseButton?: boolean
  onClose?: () => unknown
  onCTAclicked?: () => unknown
  render: () => ReactNode
  secondaryAction?: string
}

export type ModalContextData = {
  show: (modal: ModalContent) => void
  close: () => void
}

export const ModalContext = React.createContext<ModalContextData>({
  show: (_modal: ModalContent) => {},
  close: () => {},
})

type BackgroundedProviderProps = {
  children?: ReactNode
}

const initialModal = { title: '', render: () => null }

export const ModalProvider = ({ children }: BackgroundedProviderProps) => {
  const [modal, setModal] = useState<ModalContent>(initialModal)
  const [modalVisible, setModalVisible] = useState(false)

  const value = useMemo(
    () => ({
      show: (newModal: ModalContent) => {
        setModal(newModal)
        setModalVisible(true)
      },
      close: () => {
        setModal(initialModal)
        setModalVisible(false)
      },
    }),
    []
  )

  const handleClose = useCallback(() => {
    modal.onClose?.()
    value.close()
  }, [value, modal.onClose])

  const handleCTA = () => {
    handleClose()
    modal.onCTAclicked?.()
  }

  const defaultTitle = 'Whoops!'

  return (
    <ModalContext.Provider value={value}>
      {children}

      <Modal
        hasCloseButton={modal.hasCloseButton}
        visible={modalVisible}
        canClose={true}
        onClose={handleClose}
        title={modal?.title || defaultTitle}
        data-testid="modal"
      >
        {modal && modal.render()}
        {modal.cta && (
          <ModalActions>
            <Box flex={1} flexDirection="column">
              {modal.secondaryAction && (
                <>
                  <LinkButton onClick={handleClose}>{modal.secondaryAction}</LinkButton>
                  <Box minH="12px" />
                </>
              )}
              <PrimaryButton onClick={modal.onCTAclicked ? handleCTA : handleClose} testID="DefaultModalCTAButton">
                {modal.cta}
              </PrimaryButton>
            </Box>
          </ModalActions>
        )}
      </Modal>
    </ModalContext.Provider>
  )
}

export const useModal = () => useContext(ModalContext)
