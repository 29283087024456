import React from 'react'
import { Analytics } from '@genoa/analytics'

import { FlexAnywhereHowFlexWorksContainer } from '../../common/property-linking/FlexAnywhereHowFlexWorksContainer'

type OnboardingFlexAnywhereHowFlexWorksContainerProps = {}

export const OnboardingFlexAnywhereHowFlexWorksContainer = (
  props: OnboardingFlexAnywhereHowFlexWorksContainerProps
) => {
  return (
    <FlexAnywhereHowFlexWorksContainer {...props} analyticsScreenName={Analytics.Screens.ONBOARDING_OON_HOW_YOU_PAY} />
  )
}
