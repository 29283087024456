import { SVGProps } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'

import { useAnalytics, useFlexLinks } from '../../providers'
import { GooglePlayStoreBadge } from '../assets'

type PlayStoreButtonProps = {
  onClickPlayStore?: () => void
} & SVGProps<SVGSVGElement>

export function PlayStoreButton(props: PlayStoreButtonProps) {
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()

  function handleOpenPlayStore() {
    if (props.onClickPlayStore) {
      props.onClickPlayStore()
    }
    analytics.logEvent(Analytics.Events.CONGRATS_OPEN_PLAY_STORE_CLICKED)
    flexLinks.open(FlexLinks.googlePlayStore)
  }

  return <GooglePlayStoreBadge {...props} role="link" tabIndex={0} onClick={handleOpenPlayStore} />
}
