import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import FlexAnywhereWhereYouPay from './FlexAnywhereWhereYouPay'

type FlexAnywhereWhereYouPayContainerProps = {
  analyticsScreenName: Analytics.Screens
}

const FlexAnywhereWhereYouPayContainer = (props: FlexAnywhereWhereYouPayContainerProps) => {
  const [propertyName, setPropertyName] = useState<string>('')
  const [propertyNameError, setPropertyNameError] = useState<string | null>(null)

  const [rentPortal, setRentPortal] = useState<string>('')
  const [rentPortalError, setRentPortalError] = useState<string | null>(null)

  const analytics = useAnalytics()
  const navigate = useNavigate()

  const handlePropertyNameChange = (value: string) => {
    setPropertyName(value)
    if (!value) {
      setPropertyNameError('Property name is required')
    } else {
      setPropertyNameError(null)
    }
  }

  const handleRentPortalChange = (value: string) => {
    setRentPortal(value)
    if (!value) {
      setRentPortalError('Rent portal URL or app name is required')
    } else {
      setRentPortalError(null)
    }
  }

  const onClickContinue = () => {
    analytics.logEvent(Analytics.Events.OON_WHERE_YOU_PAY_CTA_CLICKED)

    if (propertyNameError || rentPortalError) {
      return
    }
    navigate(Routes.Onboarding.FLEX_ANYWHERE_HOW_YOU_PAY)
  }

  const onDontPayOnline = () => {
    analytics.logEvent(Analytics.Events.OON_WHERE_YOU_PAY_DONT_PAY_ONLINE_CLICKED)

    navigate(Routes.Onboarding.FLEX_ANYWHERE_WAITLIST)
  }

  return (
    <FlexAnywhereWhereYouPay
      onPropertyNameChange={handlePropertyNameChange}
      propertyNameError={propertyNameError}
      propertyName={propertyName}
      onRentPortalChange={handleRentPortalChange}
      rentPortalError={rentPortalError}
      rentPortal={rentPortal}
      analyticsScreenName={props.analyticsScreenName}
      onClickContinue={onClickContinue}
      onDontPayOnline={onDontPayOnline}
    />
  )
}

export default FlexAnywhereWhereYouPayContainer
