import { useEffect } from 'react'
import branch from 'branch-sdk'

import { useAuthState } from '../contexts'
import { logger, useAmplitude, useAnalytics, useDatadog } from '../providers'

export const useIdentifyUser = () => {
  const analytics = useAnalytics()
  const { uid, user, isAnonymous } = useAuthState()
  const datadog = useDatadog()
  const { setUserId } = useAmplitude()

  // Handle any provider where we dont want the anonymous uid stored
  useEffect(() => {
    if (!isAnonymous && uid) {
      setUserId(uid)
      datadog.setUser({
        id: uid,
      })
    } else {
      setUserId(null)
    }
  }, [uid, isAnonymous])

  useEffect(() => {
    const userId = uid ?? ''

    if (uid) {
      analytics.setUserId(userId)
      branch.setIdentity(userId)
      branch.logEvent('LOGIN', undefined, undefined, undefined, (err) => {
        if (err) {
          logger.log('use-identify-user', 'Could not log branch event: LOGIN - ' + err)
        }
      })
    }
  }, [uid, user])
}
