import { StoreCustomerCookieConsentsRequest, useStoreCustomerCookieConsents } from '@genoa/middle-end'

import { useAuthState } from '../../contexts'
import { ConsentManagementConsentPayload } from '../amplitude'
import { useLogger } from '../logger'
import { dnt, gpc } from './util'

type StoreCookieConsentRemotelyResolver = (consent: ConsentManagementConsentPayload) => Promise<void>

/**
 * hook returning a function that wraps the POST customer-cookies-consents endpoint mapping a
 * ConsentManagementConsentPayload to the appropriate StoreCustomerCookieConsentsRequest object to be stored as the
 * customer's confirmed consent on our backend
 */
export const useStoreCookieConsentRemotely = (): StoreCookieConsentRemotelyResolver => {
  const logger = useLogger('useStoreCookieConsentRemotely')

  const { uid } = useAuthState()
  const [, storeCookieConsents] = useStoreCustomerCookieConsents()

  return async (consent: ConsentManagementConsentPayload) => {
    if (uid === null || !consent.confirmed) {
      logger.error('store call denied', `either !uid ${uid === null} or !consent.confirmed ${!consent.confirmed}`)
      throw new Error('Unable to store consent remotely!')
    }

    const purposes = consent.purposes
    if (
      purposes.advertising === undefined ||
      purposes.analytics === undefined ||
      purposes.functional === undefined ||
      purposes.saleOfInfo === undefined
    ) {
      logger.error('store call failed', `consent purposes incomplete: ${JSON.stringify(purposes)}`)
      return
    }

    const request: StoreCustomerCookieConsentsRequest = {
      customer_public_id: uid,
      confirmed: consent.confirmed,
      essential: purposes.essential ?? true,
      advertising: purposes.advertising,
      analytics: purposes.analytics,
      functional: purposes.analytics,
      sale_of_info: purposes.saleOfInfo,
      dt_confirmed: consent.dt_confirmed.toISOString(),
      dnt: dnt(),
      gpc: gpc(),
      regimes: consent.regimes,
    }

    await storeCookieConsents(request)
  }
}
