import { useMemo } from 'react'
import { Feature, Status, useGetFeatureStatus } from '@genoa/middle-end'
import { setFeatureStatusAction } from '@genoa/state-management'

import { useAuthState } from '../contexts'
import { useFeatureStatusState } from '../modules/flex2/use-state'
import { useLogger } from '../providers'
import { useReduxAction } from './use-redux-action'

export const useSetFeatureStatusState = () => {
  const loggerV2 = useLogger('useSetFeatureStatusState')
  const { uid } = useAuthState()
  const customerPublicId = uid
  const [, getFeatureStatus] = useGetFeatureStatus()
  const setFeatureStatus = useReduxAction(setFeatureStatusAction)
  const featureStatusState = useFeatureStatusState()

  const fetchFeatureStatuses = async () => {
    if (!customerPublicId) {
      return
    }

    try {
      const response = await getFeatureStatus({ customerPublicId })
      if (response?.status === 200 && response?.data?.feature_statuses) {
        setFeatureStatus({ featureStatuses: response.data.feature_statuses })
      } else {
        loggerV2.error('fetchFeatureStatuses', `Unexpected response code: ${response?.status}`)
      }
    } catch (error: any) {
      loggerV2.error('fetchFeatureStatuses', `Error fetching feature statuses: ${error?.message}`)
    }
  }

  const isFeatureActive = (feature: Feature) => {
    if (featureStatusState?.initialized && featureStatusState?.featureStatuses) {
      const featureActive = featureStatusState.featureStatuses.find(
        (featureStatus) => featureStatus.feature === feature && featureStatus.status === Status.ACTIVE
      )
      if (featureActive) {
        return true
      }
    }
    return false
  }

  const canUserModifyCreditLineUtilization = useMemo(() => {
    return isFeatureActive(Feature.MODIFY_CREDIT_LINE_UTILIZATION)
  }, [featureStatusState])

  return {
    fetchFeatureStatuses,
    canUserModifyCreditLineUtilization,
  }
}
