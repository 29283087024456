import { OfferStates } from '@genoa/middle-end'

import { OfferState } from '../../../modules/flex2/offer'

export const isBlockedOffer = (offerState: OfferState): boolean => {
  if (!offerState.initialized) {
    return false
  }
  const isDenied = offerState.offer.offer_state === OfferStates.DENIED

  return isDenied && (offerState.offer.is_blocking_denial || false)
}
