import { Agreement } from '@genoa/domain'
import { Box, Checkbox } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useContentOverhaul } from '../../../../hooks'
import { Gap } from '../../../components'
import { AgreementsList } from './AgreementsList'

type AcceptanceOfferAuthorizeProps = {
  toggleAcceptTerms: () => unknown
  termsAccepted: boolean
  fetchedAgreements?: Agreement[]
  agreeText?: string
  authorizationText?: string
}

type VerticalAgreementListProps = {
  toggleAcceptTerms?: () => void
  fetchedAgreements?: Agreement[]
  agreeText?: string
}

const VerticalAgreementList = (props: VerticalAgreementListProps) => {
  const theme = useTheme()
  return (
    <>
      <Box onClick={props.toggleAcceptTerms} data-testid="AcceptanceOfferDetailsAgreeText">
        {props.agreeText}
      </Box>
      <Box ml={theme.fixedSizes.spacing_300}>
        <Box>{!!props.fetchedAgreements?.length && <AgreementsList agreements={props.fetchedAgreements} />}</Box>
      </Box>
    </>
  )
}

export const AcceptanceOfferAuthorize = (props: AcceptanceOfferAuthorizeProps) => {
  const { onboardingEnabled } = useContentOverhaul()

  // If there is no text to associate with the checkbox, use the agreement list
  // (even if we're running the onboarding content overhaul experiment).
  const showAgreementListByCheckmark = !onboardingEnabled || !props.authorizationText

  return (
    <Box>
      <AgreementContainer>
        <StyledCheckbox
          isChecked={props.termsAccepted}
          onChange={props.toggleAcceptTerms}
          data-testid="AcceptanceOfferDetailsCheckbox"
        />
        <Box>
          {props.authorizationText && (
            <>
              <Box onClick={props.toggleAcceptTerms}>{props.authorizationText}</Box>
              <Gap size="spacing_75" />
            </>
          )}
          {showAgreementListByCheckmark && (
            <VerticalAgreementList
              agreeText={props.agreeText}
              toggleAcceptTerms={props.toggleAcceptTerms}
              fetchedAgreements={props.fetchedAgreements}
            />
          )}
        </Box>
      </AgreementContainer>
      {!showAgreementListByCheckmark && (
        <VerticalAgreementList agreeText={props.agreeText} fetchedAgreements={props.fetchedAgreements} />
      )}
    </Box>
  )
}

const AgreementContainer = styled(Box)`
  display: flex;
  gap: ${(props) => props.theme.fixedSizes.spacing_75};
`

const StyledCheckbox = styled(Checkbox)`
  display: block;
`
