import React, { useMemo } from 'react'
import { Box, Button, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { BankShield } from '../../../../../../assets'
import { ExtraSmallText, PrimaryButton, SmallText } from '../../../../../../components'
import { BillingAddress } from '../billing-address-types'

type BillingAddressDetailsProps = {
  onPressChangeBillingAddress: () => unknown
  isProcessingRegisterCard: boolean
  billingAddress: BillingAddress
  onPressAddCTA: () => unknown
  billingAddressError?: string
}

export const BillingAddressDetails = (props: BillingAddressDetailsProps) => {
  const theme = useTheme()

  const cityStateZip = useMemo(() => {
    let address = ''

    if (props.billingAddress.city) {
      address = `${props.billingAddress.city}, `
    }

    if (props.billingAddress.state) {
      address = `${address}${props.billingAddress.state} `
    }

    if (props.billingAddress.zip) {
      address = `${address}${props.billingAddress.zip}`
    }

    return address
  }, [props.billingAddress])

  return (
    <>
      <SmallText>Billing information</SmallText>
      <ContentContainer justifyContent={'space-between'} alignItems={'center'}>
        <Flex flexFlow={'column'}>
          <SmallText>{props.billingAddress.name}</SmallText>
          <SmallText>{props.billingAddress.streetAddress}</SmallText>
          {props.billingAddress.unit && <SmallText>{props.billingAddress.unit}</SmallText>}
          <SmallText>{cityStateZip}</SmallText>
        </Flex>
        <Button
          w="84px"
          variant={'small'}
          onClick={props.onPressChangeBillingAddress}
          data-testid="RegisterCardChangeBillingAddressButton"
        >
          Change
        </Button>
      </ContentContainer>
      <Box visibility={!!props.billingAddressError ? 'visible' : 'hidden'} color={theme.colors.error}>
        <SmallText hasError={!!props.billingAddressError}>{props.billingAddressError}</SmallText>
      </Box>
      <Box minH={'52px'} />
      <Flex alignItems={'center'} justifyContent={'center'}>
        <IconWrapper>
          <BankShield />
        </IconWrapper>
        <DisclaimerText>Your data is secured by bank-level encryption</DisclaimerText>
      </Flex>
      <Box />
      <Box minH="20px" />
      <PrimaryButton
        processing={props.isProcessingRegisterCard}
        disabled={props.isProcessingRegisterCard}
        onClick={props.onPressAddCTA}
        testID="RegisterCardAddButton"
      >
        Confirm card
      </PrimaryButton>
    </>
  )
}

export const ContentContainer = styled(Flex)`
  background-color: ${(props) => props.theme.colors.wildflower};
  border: 1px solid ${(props) => props.theme.colors.storm};
  border-radius: 8px;
  padding: 16px;
`

const IconWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  margin-right: 9px;
`

const NameOnCard = styled(Flex)`
  background-color: ${(props) => props.theme.colors.wildflower};
  border: 1px solid ${(props) => props.theme.colors.storm};
  border-radius: 8px;
  padding: 16px;
`
const DisclaimerText = styled(ExtraSmallText)`
  padding-top: 2px;
`
