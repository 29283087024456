import React, { ReactNode, useMemo } from 'react'
import { Navigate, Route, RouteProps } from 'react-router-dom'

import { useAuthState } from '../contexts'
import * as Routes from './constants'

export const PrivateRoute: React.FC<React.PropsWithChildren> = (props) => {
  const auth = useAuthState()
  const authed = auth.authenticated && !auth.isAnonymous

  if (auth.user === null) {
    return null
  }

  if (authed) {
    return props.children as JSX.Element
  }

  return <Navigate to={{ pathname: Routes.Auth.PHONE }} replace />
}
