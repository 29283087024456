import { EMBED_DEFAULT_MODAL, EMBED_MANUAL_TOO_MANY_ATTEMPTS_MODAL } from '@genoa/screen-content'

import { useShowErrorMessageModal } from './use-show-error-message-modal'

export const useDefaultErrorModals = () => {
  const { showErrorMessage } = useShowErrorMessageModal()

  const displayTooManyTriesModal = () => {
    showErrorMessage(
      EMBED_MANUAL_TOO_MANY_ATTEMPTS_MODAL.HEADER,
      EMBED_MANUAL_TOO_MANY_ATTEMPTS_MODAL.BODY,
      EMBED_MANUAL_TOO_MANY_ATTEMPTS_MODAL.CTA
    )
  }

  const displayDefaultErrorModal = () => {
    showErrorMessage(EMBED_DEFAULT_MODAL.HEADER, EMBED_DEFAULT_MODAL.BODY, EMBED_DEFAULT_MODAL.CTA)
  }

  return {
    displayTooManyTriesModal,
    displayDefaultErrorModal,
  }
}
