import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { StatusIconGreen, StatusIconRed } from '../../assets'
import { ShadowedFrame } from '../Frames'
import { LinkText, SmallText } from '../Typography'

export interface AutopayStatusFrameButtonProps {
  readonly status: boolean
  readonly onChange: () => void
}

export const AutopayStatusFrameButton = (props: AutopayStatusFrameButtonProps) => (
  <AutopayStatusContainer>
    <StatusTextContainer>
      {props.status ? <StatusIconGreen /> : <StatusIconRed />}
      <SmallText>
        Rent autopay is <SmallText fontWeight="bold">{props.status ? 'on' : 'off'}</SmallText>
      </SmallText>
    </StatusTextContainer>
    <ChangeText onClick={props.onChange}>Change</ChangeText>
  </AutopayStatusContainer>
)

const ChangeText = styled(LinkText)`
  font-size: ${({ theme }) => theme.fontSizes.xxs};
  font-weight: bold;
`

const StatusTextContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.fixedSizes.spacing_50};
`

const AutopayStatusContainer = styled(ShadowedFrame)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: ${({ theme }) => theme.fixedSizes.spacing_75};
  padding-left: ${({ theme }) => theme.fixedSizes.spacing_100};
  padding-right: ${({ theme }) => theme.fixedSizes.spacing_100};
`
