import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../routing/constants'
import { HowYouUseFlexContainer } from '../../common/slc/how-you-use-flex'

export const OnboardingHowYouUseFlexContainer = () => {
  const navigate = useNavigate()

  return (
    <HowYouUseFlexContainer
      analyticsScreenName={Analytics.Screens.ONBOARDING_HOW_YOU_USE_FLEX}
      onNext={() => navigate(Routes.Onboarding.CREATE_ACCOUNT)}
    />
  )
}
