import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { getProcessingFeesFromPricing } from '@genoa/domain'

import { usePricingOfferState } from '../../../../../../modules/flex2/use-state'
import { useAnalytics, useFees, useLogger } from '../../../../../../providers'
import * as Routes from '../../../../../../routing/constants'
import { V2ConnectCard } from './V2ConnectCard'

export const V2ConnectCardContainer = () => {
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const loggerV2 = useLogger('V2ConnectCardContainer')
  const location = useLocation()

  const [debitProcessingFee, setDebitProcessingFee] = useState(0)
  const [creditProcessingFee, setCreditProcessingFee] = useState(0)

  const { processingFeePercentage } = useFees()

  const pricingOfferState = usePricingOfferState()

  useEffect(() => {
    if (!pricingOfferState.initialized) {
      loggerV2.error(
        'initialize fees',
        `missing data on connect card, pricingOfferState: ${!pricingOfferState.initialized}`
      )
    }
    const pricingProcessingFees = getProcessingFeesFromPricing(pricingOfferState.pricing_offer)
    setCreditProcessingFee(pricingProcessingFees.credit)
    setDebitProcessingFee(pricingProcessingFees.debit)
  }, [pricingOfferState])

  const handleAddCardDetails = useCallback(() => {
    analytics.logEvent(Analytics.Events.CONNECT_CARD_CTA_CLICKED)
    navigate(Routes.Onboarding.CARD_REGISTER, {
      state: {
        analyticsScreenName: Analytics.Screens.ADD_CARD_ONBOARDING_ADD_CARD,
        nextRoute: location.state?.nextRoute ?? Routes.Onboarding.OFFER_DETAILS,
      },
    })
  }, [])

  return (
    <V2ConnectCard
      analyticsScreenName={Analytics.Screens.ONBOARDING_CONNECT_CARD}
      onClickAddCardDetails={handleAddCardDetails}
      processingFeePercentage={processingFeePercentage}
      debitProcessingFee={debitProcessingFee}
      creditProcessingFee={creditProcessingFee}
    />
  )
}
