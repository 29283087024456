import { useMemo } from 'react'
import { useTheme } from '@emotion/react'
import { StripeCardNumberElementOptions } from '@stripe/stripe-js'

export const useStripeOptions = () => {
  const theme = useTheme()

  const cardStyleOptions: StripeCardNumberElementOptions = useMemo(
    () => ({
      iconStyle: 'solid',
      hidePostalCode: true,
      style: {
        base: {
          fontFamily: 'Work Sans, Open Sans, sans-serif',
          iconColor: theme.colors.storm,
          backgroundColor: theme.colors.whitesmoke,
          fontSize: theme.fontSizes.md,
          '::placeholder': {
            color: theme.colors.dusk,
          },
        },
        invalid: {
          iconColor: theme.colors.brand['900'],
          color: theme.colors.red,
        },
      },
    }),
    [theme]
  )

  return {
    cardStyleOptions,
  }
}
