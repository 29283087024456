import React, { useCallback, useEffect } from 'react'
import { FieldError, useForm } from 'react-hook-form'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { ContactState, PhoneNumberState } from '../../../modules'
import { useEnhancedTracking } from '../../../providers'
import { Headline2, InlineButton, PrimaryButton, SmallText } from '../../components'
import { BasePageLayout } from '../../layouts'
import { ConsolidationDisclaimerContainer } from './disclaimer/ConsolidationDisclaimerContainer'
import { FormField } from './FormField'

type PhoneConsolidationProps = {
  phone: string
  onPhoneChange: (phoneNumber: PhoneNumberState) => unknown
  onFieldChange: (field: string, value: string) => unknown
  onNextClick: (contact: ContactState) => unknown
  onAlreadyHaveAnAccount: () => unknown
  autoComplete?: boolean
  coBrandingProperty?: string
  errors: {
    firstName?: FieldError | string
    lastName?: FieldError | string
    email?: FieldError | string
    phoneNumber?: FieldError | string
  }
  contact: ContactState
  isLoading?: boolean
}

const PHONE_NUMBER_DIGITS = 10
const removeMask = (phone: string) => phone.replace(/\D/g, '').substring(0, PHONE_NUMBER_DIGITS)
const maskPhone = (phone: string) => {
  return removeMask(phone)
    .replace(/(\d{3})(\d)/, '($1) $2')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}
const contactSchema = yup.object({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  phoneNumber: yup.string().required('Phone number is required'),
  email: yup.string().email('Must be a valid email').required('Email address is required'),
})

export const PhoneConsolidation = (props: PhoneConsolidationProps) => {
  const { isLoading, errors } = props
  const { trackPhoneConsolidationScreenView } = useEnhancedTracking()

  const unmaskedPhone = removeMask(props.phone)
  const formattedPhone = maskPhone(unmaskedPhone)

  const handlePhoneChange = useCallback(
    (newPhone: string) => {
      const unmaskedNewPhone = removeMask(newPhone)
      if (unmaskedPhone !== unmaskedNewPhone) {
        const formattedNewPhone = maskPhone(unmaskedNewPhone)
        props.onPhoneChange({
          extracted: unmaskedNewPhone,
          formatted: formattedNewPhone,
        })
      }
    },
    [unmaskedPhone, props.onPhoneChange]
  )

  const {
    control,
    handleSubmit,
    formState: { errors: errorsForm },
  } = useForm<ContactState>({
    resolver: yupResolver(contactSchema),
    mode: 'onChange',
  })

  const onSubmit = handleSubmit((contact: ContactState) => {
    if (!isLoading && !errors.phoneNumber) {
      props.onNextClick(contact)
    }
  })

  useEffect(() => {
    trackPhoneConsolidationScreenView()
  }, [])

  return (
    <BasePageLayout coBrandingProperty={props.coBrandingProperty} showLogInButton>
      <Box minH="30px" />

      <Headline2>Create your account</Headline2>

      <Box minH="15px" />

      <SmallText>We need some basic information about you to get started.</SmallText>

      <Box minH="30px" />

      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box>
          <FormField
            label="Legal first name"
            name="firstName"
            control={control}
            error={errorsForm.firstName || errors.firstName}
            defaultValue={props.contact.firstName}
            testID="CreateAccountFirstNameInput"
            autoComplete="given-name"
            onChange={(value: string) => {
              props.onFieldChange('firstName', value)
            }}
          />
        </Box>

        <Box w="20px" />
        <Box>
          <FormField
            label="Legal last name"
            name="lastName"
            control={control}
            error={errorsForm.lastName || errors.lastName}
            defaultValue={props.contact.lastName}
            testID="CreateAccountLastNameInput"
            autoComplete="family-name"
            onChange={(value: string) => {
              props.onFieldChange('lastName', value)
            }}
          />
        </Box>
      </Box>

      <Box minH="30px" />
      <FormField
        label="Email address"
        name="email"
        control={control}
        error={errorsForm.email || errors.email}
        defaultValue={props.contact.email}
        fieldType="email"
        testID="CreateAccountEmailInput"
        autoComplete="email"
        onChange={(value: string) => {
          props.onFieldChange('email', value)
        }}
      />
      <Box minH="30px" />

      <Box>
        <FormField
          label="Phone number"
          name="phoneNumber"
          control={control}
          error={errorsForm.phoneNumber || errors.phoneNumber}
          defaultValue={formattedPhone}
          fieldValue={formattedPhone}
          fieldType="tel"
          placeholder="(xxx) xxx-xxxx"
          testID="CreateAccountLastNameInput"
          autoComplete="phone"
          onChange={handlePhoneChange}
        />
      </Box>

      <Box minH="32px" />
      <Box>
        <Box textAlign="center">
          <SmallText>
            <InlineButton testID="RentAmountLogin" onClick={props.onAlreadyHaveAnAccount}>
              Already have an account? <LoginText>Log in</LoginText>
            </InlineButton>
          </SmallText>
        </Box>
        <Box minH="32px" />
        <PrimaryButton disabled={isLoading} processing={isLoading} onClick={onSubmit} testID="AuthNextButton">
          Create account
        </PrimaryButton>
      </Box>
      <Box minH="16px" />

      <ConsolidationDisclaimerContainer />
    </BasePageLayout>
  )
}

const LoginText = styled(SmallText)`
  text-align: center;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  color: ${(props) => props.theme.colors.jewelPurple};
`
