import { useCallback } from 'react'
import { Navigate } from 'react-router-dom'

import { useBlockCreditBuilderForEmbed, useIsEmbed, useUnload } from '../../hooks'
import { useOfferDenialState } from '../../hooks/flex2/risk/use-offer-denial-state'
import { useAccount } from '../../hooks/use-account'
import { useUserAccountState } from '../../modules/flex2/use-state'
import * as Routes from '../constants'

export function OnboardingRoot() {
  const userAccountState = useUserAccountState()
  const isEmbed = useIsEmbed()
  const { isActive, isAccountLoaded } = useAccount()
  const { loading: loadingOfferDenialStatus, deniedOffer } = useOfferDenialState()

  useBlockCreditBuilderForEmbed()

  useUnload(
    useCallback((e: Event) => {
      e.preventDefault()

      //@ts-ignore
      e.returnValue = 'Are you sure you want to leave?' //For old browsers

      return 'Are you sure you want to leave?'
    }, [])
  )

  if (!userAccountState.loaded || !isAccountLoaded || loadingOfferDenialStatus) {
    return null
  }

  if (isActive) {
    return isEmbed ? <Navigate to={Routes.Embed.SIMPLIFIED_EPISODIC} /> : <Navigate to={Routes.Onboarding.CONGRATS} />
  }

  if (deniedOffer) {
    return <Navigate to={Routes.Onboarding.UPSELL_ELIGIBILITY} replace />
  }

  if (userAccountState.initialized) {
    return <Navigate to={Routes.Onboarding.RESUME_ONBOARDING} replace />
  } else {
    return <Navigate to={Routes.Onboarding.CREATE_ACCOUNT} replace />
  }
}
