import React from 'react'
import { Analytics } from '@genoa/analytics'
import { CREDIT_BUILDER_CONGRATS_CONTENT as content } from '@genoa/screen-content'
import { getDateTime, getResumeDate } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useAutopilotEligibility, useGetAccount } from '../../../../../hooks'
import { HeroBouquet as CreditBuilderCongratsImage, StatusIconOne, StatusIconTwo } from '../../../../assets'
import { FullScreenSpinnerLoading, Gap, Headline1, SmallText, Text } from '../../../../components'
import { AppStoreButton } from '../../../../components/AppStoreButton'
import { PlayStoreButton } from '../../../../components/PlayStoreButton'
import { BasePageLayout } from '../../../../layouts'

type CreditBuilderCongratsProps = {
  analyticsScreenName: Analytics.Screens
}

const storeBadgeStyles = {
  maxHeight: '65px',
  minHeight: '65px',
  transform: 'scale(0.7)',
  cursor: 'pointer',
}

export const CreditBuilderCongrats = (props: CreditBuilderCongratsProps) => {
  const { isLoadingEligibility } = useAutopilotEligibility()
  const theme = useTheme()
  useGetAccount()

  const bodyText = () => {
    const today = getDateTime()
    const resumeDate = getResumeDate(today)
    const month = resumeDate.monthLong!

    return (
      <Text textAlign="center">
        <Text>{content.BODY_1} </Text>
        <Text fontWeight="bold">{month}</Text>
        <Text> {content.BODY_2}</Text>
      </Text>
    )
  }

  if (isLoadingEligibility) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Gap size="spacing_100" />

      <Flex flexGrow={1} flexShrink={0} flexDirection="column" data-testid="CreditBuilderCongratsPage">
        <Flex align="center" justify="center">
          <CreditBuilderCongratsImage />
        </Flex>

        <Gap size="spacing_225" />

        <Headline1 textAlign="center">{content.HEADER}</Headline1>

        <Gap size="spacing_100" />
        {bodyText()}
        <Gap size="spacing_150" />
        <HighlightedContent>
          <Text>{content.HIGHLIGHT.TITLE}</Text>
          <Gap size="spacing_100" />
          <HighlightListRow>
            <HighlightListIconWrapper>
              <StatusIconOne />
            </HighlightListIconWrapper>
            <HighlightListItemText>
              <SmallText fontWeight={'bold'}>{content.HIGHLIGHT.BODY_1_BOLD} </SmallText>
              <SmallText>{content.HIGHLIGHT.BODY_1}</SmallText>
            </HighlightListItemText>
          </HighlightListRow>

          <Gap size={'spacing_100'} />
          <HighlightListRow>
            <HighlightListIconWrapper>
              <StatusIconTwo />
            </HighlightListIconWrapper>
            <HighlightListItemText>
              <SmallText fontWeight={'bold'}>{content.HIGHLIGHT.BODY_2_BOLD} </SmallText>
              <SmallText>{content.HIGHLIGHT.BODY_2}</SmallText>
            </HighlightListItemText>
          </HighlightListRow>
        </HighlightedContent>

        <Gap size="spacing_200" />

        <AppButtonContainer backgroundColor={theme.colors.cloud}>
          <Box {...storeBadgeStyles}>
            <AppStoreButton />
          </Box>
          <Box minH={'24px'} />
          <Box {...storeBadgeStyles}>
            <PlayStoreButton />
          </Box>
        </AppButtonContainer>
      </Flex>

      <Gap size="spacing_200" />
    </BasePageLayout>
  )
}

const AppButtonContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  border-radius: ${(props) => props.theme.radii.lg};
`

const HighlightedContent = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.cloud};
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  border-radius: ${(props) => props.theme.radii.lg};
`

const HighlightListRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const HighlightListItemText = styled(SmallText)`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  padding-left: ${(props) => props.theme.fixedSizes.spacing_50};
`

const HighlightListIconWrapper = styled(Box)`
  line-height: ${(props) => props.theme.lineHeights.xs};
`
