import { Analytics } from '@genoa/analytics'
import { CO_NOT_APPROVED, NOT_APPROVED } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'

import { useContentOverhaul } from '../../../hooks'
import { HeroChecklist as NotApprovedImage } from '../../assets'
import { Headline1, InlineButton, Text } from '../../components'
import { BasePageLayout } from '../../layouts'

type NotApprovedProps = {
  onMessageSupport: () => unknown
}

const imageSize = {
  maxHeight: '280px',
  minHeight: '200px',
}

export const NotApproved = (props: NotApprovedProps) => {
  const { onboardingEnabled } = useContentOverhaul()
  const content = onboardingEnabled ? CO_NOT_APPROVED : NOT_APPROVED

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_NOT_APPROVED}>
      <Flex direction="column" align="center">
        <Box minH={'60px'} />

        <Flex {...imageSize}>
          <NotApprovedImage />
        </Flex>

        <Headline1 textAlign="center">{content.HEADER}</Headline1>

        <Box minH={'24px'} />

        <Text textAlign="center" color={'dusk'} data-testid="NotApprovedBody">
          {content.BODY}
        </Text>

        {!onboardingEnabled && (
          <>
            <Box minH={'24px'} />

            <Flex>
              <InlineButton onClick={props.onMessageSupport}>
                <b data-testid="NotApprovedMessageSupport">{content.MESSAGE_SUPPORT}</b>
              </InlineButton>
            </Flex>
          </>
        )}
      </Flex>
    </BasePageLayout>
  )
}
