import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { StripeCardAddress } from '@genoa/domain'
import { EMBED_CHANGE_CARD_ERROR_HANDLING as content } from '@genoa/screen-content'
import { StripeCardElement } from '@stripe/stripe-js'

import { useAnalytics, useFees } from '../../../../../../providers'
import * as Routes from '../../../../../../routing/constants'
import { BillingAddress } from '../../../../onboarding/payment-method/card/register-card/billing-address-types'
import { useRegisterCardV2 } from '../../../../onboarding/payment-method/card/register-card/hooks/use-register-card-v2'
import { ChangeCard } from './ChangeCard'

export const ChangeCardContainer = () => {
  const { processingFeePercentage } = useFees()
  const analytics = useAnalytics()
  const navigate = useNavigate()

  const [cardElement, setCardElement] = useState<StripeCardElement | null>()
  const [isCardFormFilled, setIsCardFormFilled] = useState(false)
  const [cardFormError, setCardFormError] = useState('')
  const [billingAddressError, setBillingAddressError] = useState('')
  const [startedTyping, setStartedTyping] = useState(false)
  const [isChangeCardVisible, setIsChangeCardVisible] = useState(true)

  const { existingCardData, isLoading, startRegisterCard } = useRegisterCardV2({
    isOnboarding: false,
    onSuccess: () => {
      navigate(Routes.Embed.SIMPLIFIED_EPISODIC)
    },
  })

  const onCardChange = (isCompleted: boolean) => {
    if (!startedTyping) {
      analytics.logEvent(Analytics.Events.STARTED_TYPING_REGISTER_CARD)
      setStartedTyping(true)
    }
    setIsCardFormFilled(isCompleted)
    setCardFormError('')
  }

  const onPressAddCTA = (billingAddress: BillingAddress) => {
    analytics.logEvent(Analytics.Events.ADD_CARD_CTA_CLICKED)

    const isMissingBillingAddress =
      !billingAddress.zip || !billingAddress.streetAddress || !billingAddress.state || !billingAddress.city

    if (!billingAddress.name) {
      setBillingAddressError(content.BILLING_INCLUDE_NAME)
    } else if (isMissingBillingAddress) {
      setBillingAddressError(content.BILLING_REQUIRED)
    } else {
      setBillingAddressError('')
    }

    if (!isCardFormFilled || !cardElement) {
      setCardFormError(content.CARD_REQUIRED)
      return
    }

    if (isMissingBillingAddress || !billingAddress.name) {
      return
    }

    setCardFormError('')
    startRegisterCard(billingAddress, cardElement)
  }

  const stripeCardAddress: StripeCardAddress | undefined = useMemo(() => {
    if (existingCardData) {
      return {
        address_line_one: existingCardData.data.default.address_line_one!,
        address_line_two: existingCardData.data.default.address_line_two!,
        address_city: existingCardData.data.default.address_city!,
        address_state: existingCardData.data.default.address_state!,
        address_zipcode: existingCardData.data.default.address_zipcode!,
      }
    }
  }, [existingCardData])

  return (
    <ChangeCard
      billingAddressError={billingAddressError}
      cardFormError={cardFormError}
      isChangeCardVisible={isChangeCardVisible}
      isLoading={isLoading}
      onCardChange={onCardChange}
      onCardInputReady={setCardElement}
      onPressAddCTA={onPressAddCTA}
      processingFeePercentage={processingFeePercentage}
      setIsChangeCardVisible={setIsChangeCardVisible}
      stripeCardAddress={stripeCardAddress}
    />
  )
}
