import React from 'react'
import { Analytics } from '@genoa/analytics'

import FlexAnywhereWaitlistContainer from '../../common/property-linking/FlexAnywhereWaitlistContainer'

type OnboardingFlexAnywhereWaitlistContainerProps = {}

export const OnboardingFlexAnywhereWaitlistContainer = (props: OnboardingFlexAnywhereWaitlistContainerProps) => {
  return <FlexAnywhereWaitlistContainer {...props} analyticsScreenName={Analytics.Screens.ONBOARDING_OON_WAITLIST} />
}
