import React from 'react'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ExtraSmallText, Headline1, Text } from '../../../../components'

export const DEFAULT_HORIZONTAL_MARGIN = 8

type WrapperStyleProp = {
  size: number
}

type IsSelectedProp = {
  isSelected: boolean
}

type SecondPaymentListItemProps = IsSelectedProp &
  WrapperStyleProp & {
    onClick: () => void
    isMostPopularDay: boolean
    ordinal: string
    day: number
  }

export const SecondPaymentListItem = (props: SecondPaymentListItemProps) => (
  <Container>
    <Wrapper
      data-testid={`SecondPaymentListItemButton-${props.day}`}
      isSelected={props.isSelected}
      onClick={props.onClick}
      size={props.size}
      as="button"
    >
      <Flex>
        <DayText data-testid={`SecondPaymentListItemDayText-${props.day}`} isSelected={props.isSelected} color="red">
          {props.day}
        </DayText>
        <OrdinalText
          data-testid={`SecondPaymentListItemOrdinalText-${props.day}`}
          isSelected={props.isSelected}
          fontFamily="RebrandDis"
          fontWeight="bold"
        >
          {props.ordinal}
        </OrdinalText>
      </Flex>
    </Wrapper>
    {props.isMostPopularDay && (
      <MostPopularText data-testid="SecondPaymentListItemMostPopularText" textAlign="center">
        Most popular
      </MostPopularText>
    )}
  </Container>
)

const MostPopularText = styled(ExtraSmallText)`
  text-align: center;
  color: ${(props) => props.theme.colors.dusk};
  ${(props) => props.theme.media.phone`
  font-size: 10px;
`}
  ${(props) => props.theme.media.tablet`
  font-size: 10px;
`}
`

const DayText = styled(Headline1)<IsSelectedProp>`
  color: ${(props) => (props.isSelected ? props.theme.colors.brand[200] : props.theme.colors.black)};
  ${(props) => props.theme.media.phone`
    font-size: 32px;
  `}
  ${(props) => props.theme.media.tablet`
    font-size: 32px;
  `}
`

const OrdinalText = styled(Text)<IsSelectedProp>`
  color: ${(props) => (props.isSelected ? props.theme.colors.brand[200] : props.theme.colors.black)};
  ${(props) => props.theme.media.phone`
    font-size: 16px;
  `}
  ${(props) => props.theme.media.tablet`
    font-size: 16px;
  `}
`

const Container = styled(Flex)`
  flex-direction: column;
  width: 80px;
  align-items: center;
  margin-left: ${DEFAULT_HORIZONTAL_MARGIN}px;
  margin-right: ${DEFAULT_HORIZONTAL_MARGIN}px;
`

const Wrapper = styled(Flex)<IsSelectedProp & WrapperStyleProp>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.wildflower};
  border-radius: ${(props) => props.theme.radii.xl};
  margin-bottom: ${(props) => props.theme.fixedSizes.xs};
  border: ${(props) => {
    const size = props.isSelected ? '2px' : '1.5px'
    const color = props.isSelected ? props.theme.colors.brand[200] : props.theme.colors.cloud
    return `${size} solid ${color}`
  }};
`
