import React from 'react'
import { Box } from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

interface CircleLoadingProps {
  readonly height?: string
}

export const CircleLoading = (props: CircleLoadingProps) => (
  <Box>
    <CircleLoadingContainer>
      <CircleLoadingSpinner
        height={props.height ?? '40px'}
        width={props.height ?? '40px'}
        role="status"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.7779 9.2C28.9258 8.53726 29.3321 7.05509 28.5053 6.01912C27.2805 4.48461 25.7794 3.18312 24.0724 2.18567C21.672 0.782993 18.9476 0.0299913 16.1676 0.000877322C13.3875 -0.0282367 10.6479 0.667542 8.21864 2.01964C6.49119 2.98113 4.9631 4.25089 3.70648 5.75942C2.85812 6.77784 3.23328 8.26819 4.36705 8.95483V8.95483C5.50082 9.64147 6.96079 9.25539 7.87283 8.29357C8.65228 7.47157 9.55514 6.76917 10.553 6.21375C12.2535 5.26728 14.1713 4.78023 16.1173 4.80061C18.0633 4.82099 19.9704 5.3481 21.6507 6.32997C22.6368 6.90617 23.5247 7.62732 24.2868 8.46547C25.1785 9.44618 26.63 9.86274 27.7779 9.2V9.2Z"
          fill="#B28CF4"
        />
        <path
          d="M27.7779 22.7985C28.9258 23.4613 29.3321 24.9434 28.5053 25.9794C27.2805 27.5139 25.7794 28.8154 24.0724 29.8129C21.672 31.2155 18.9476 31.9685 16.1676 31.9977C13.3875 32.0268 10.6479 31.331 8.21864 29.9789C6.49119 29.0174 4.9631 27.7476 3.70648 26.2391C2.85812 25.2207 3.23328 23.7303 4.36705 23.0437V23.0437C5.50082 22.3571 6.96079 22.7431 7.87283 23.705C8.65228 24.527 9.55514 25.2294 10.553 25.7848C12.2535 26.7313 14.1713 27.2183 16.1173 27.1979C18.0633 27.1775 19.9704 26.6504 21.6507 25.6686C22.6368 25.0924 23.5247 24.3712 24.2868 23.5331C25.1785 22.5524 26.63 22.1358 27.7779 22.7985V22.7985Z"
          fill="currentColor"
        />
      </CircleLoadingSpinner>
    </CircleLoadingContainer>
  </Box>
)

const CircleLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const spinnerAnimation = keyframes`
0% {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
100% {
  -webkit-transform: rotate(359deg);
  transform: rotate(359deg);
}
`

const CircleLoadingSpinner = styled.svg`
  color: rgb(229, 231, 235);
  fill: ${(props) => transparentize(props.theme.colors.brand[200], 0.8)};
  -webkit-animation: ${spinnerAnimation} 2s infinite linear;
  animation: ${spinnerAnimation} 2s infinite linear;
`
