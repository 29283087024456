import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { useFindUserAccount } from '@genoa/middle-end'
import { Box } from '@chakra-ui/react'

import { useReduxAction } from '../../../hooks'
import { RootState } from '../../../modules'
import { useOnboardingState } from '../../../modules/flex2/use-state'
import { setPhoneNumberAction } from '../../../modules/onboarding'
import { useNavigation } from '../../../navigation'
import { Config } from '../../../providers'
import { useAnalytics } from '../../../providers/analytics'
import * as Routes from '../../../routing/constants'
import { PhoneNumber } from './PhoneNumber'

export const PhoneNumberContainer = () => {
  const navigate = useNavigate()
  const analytics = useAnalytics()
  const onboardingState = useOnboardingState()
  const { navigateToSignIn, navigateToDevAuthFromModal } = useNavigation()
  const setPhoneNumber = useReduxAction(setPhoneNumberAction)
  const phoneNumber = useSelector((state: RootState) => state.onboarding.phoneNumber)
  const enableDevMenu = Config.ENVIRONMENT !== 'production'

  const [{ loading: loadingFindAccount }] = useFindUserAccount()

  const handleNext = async () => {
    analytics.logEvent(Analytics.Events.SIGNIN_PHONE_NUMBER_CTA_CLICKED)
    navigate(Routes.Auth.PIN)
  }

  const coBrandingProperty = useMemo(() => {
    if (onboardingState.coBrandingProperty !== undefined) {
      // track if the property name is available in the url but has no value
      analytics.logEvent(Analytics.Events.CO_BRANDING_PROPERTY_DISPLAYED, {
        has_empty_value: onboardingState.coBrandingProperty === '',
      })
    }
    return onboardingState.coBrandingProperty || ''
  }, [onboardingState.coBrandingProperty])

  const handleDontHaveAnAccount = () => {
    analytics.logEvent(Analytics.Events.DONT_HAVE_ACCOUNT_MODAL_SHOWN)
    navigateToSignIn()
  }

  return (
    <Box>
      <PhoneNumber
        phone={phoneNumber.extracted}
        onPhoneChange={setPhoneNumber}
        onNextClick={handleNext}
        coBrandingProperty={coBrandingProperty}
        onDontHaveAnAccount={handleDontHaveAnAccount}
        loading={loadingFindAccount}
        enableDevMenu={enableDevMenu}
        onDevAuth={navigateToDevAuthFromModal}
      />
    </Box>
  )
}
