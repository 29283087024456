import React from 'react'
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import styled from '@emotion/styled'

type MoneyInputProps = {
  value?: string
  onTextChange: (value: string) => unknown
  defaultValue?: string
  background?: string
  foreground?: string
  testID?: string
  error?: boolean
  maxLength?: number
  disabled?: boolean
  placeholder?: string
}

export const MoneyInput = (props: MoneyInputProps) => {
  const [moneyValue, setMoneyValue] = React.useState(props.defaultValue)

  const handleInputChangeEvent = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (props.maxLength && evt.target.value?.length > props.maxLength) {
      return
    }
    const firstEffectText = evt.target.value.startsWith('.') ? `0${evt.target.value}` : evt.target.value
    if (/^(\d+)?(\.\d{0,2})?$/.test(firstEffectText)) {
      props.onTextChange?.(firstEffectText)
      setMoneyValue(firstEffectText)
    } else if (firstEffectText.endsWith('.') && /^(\d+)?(\.\d{0,2})?$/.test(firstEffectText.slice(0, -1))) {
      props.onTextChange?.(firstEffectText.slice(0, -1))
      setMoneyValue(firstEffectText.slice(0, -1))
    }
  }

  return (
    <InputGroup>
      <InputLeftElement h="full" pointerEvents="none" color={props.disabled ? 'gray.300' : 'gray.700'}>
        $
      </InputLeftElement>
      <Input
        disabled={!!props.disabled}
        isInvalid={props.error}
        data-testid={props.testID}
        value={moneyValue}
        onChange={handleInputChangeEvent}
        backgroundColor={props.background}
        color={props.foreground}
        defaultValue={props.defaultValue}
        placeholder={props.placeholder || '0.00'}
      />
    </InputGroup>
  )
}
