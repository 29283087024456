import { useMemo } from 'react'
import { Analytics } from '@genoa/analytics'
import { ReinforcementMessage, SLCType } from '@genoa/domain'
import {
  CLASSIC_SLC_CUSTOMIZE_SCHEDULE_CONTENT as classicContent,
  CLASSIC_SLC_CUSTOMIZE_SCHEDULE_CONTENT as classicSlcContent,
  deepReplaceContent,
  FA_SLC_CUSTOMIZE_SCHEDULE_CONTENT as flexAnywhereSlcContent,
  SLC_CUSTOMIZE_SCHEDULE_CONTENT as legacyContent,
} from '@genoa/screen-content'
import { getDateTime, getResumeDate, PaymentInformation, toFormattedDollars } from '@genoa/utils'
import { Box, HStack } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useModal } from '../../../../../contexts/modals-context'
import { useSecureLineOfCredit } from '../../../../../hooks'
import { useAnalytics, useFees } from '../../../../../providers'
import { WhenDesktop, WhenTablet } from '../../../../../theme/media'
import theme from '../../../../../theme/theme'
import { QuestionCircle } from '../../../../assets'
import {
  Gap,
  Headline2,
  InlineButton,
  NumberDateDropdown,
  PaymentSchedule,
  PaymentScheduleItemProps,
  PaymentScheduleTitle,
  PrimaryButton,
  SmallText,
  Text,
} from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { SLCClassicHowFlexWorks } from '../how-flex-works/SLCClassicHowFlexWorks'
import { ReinforcementMessageWidget } from './ReinforcementMessageWidget'
import { DaySelectedState, PaymentDays } from './SLCCustomizeScheduleContainer'

const FIRST_PAYMENT_RECOMMENDED_DAY = 15
const SECOND_PAYMENT_RECOMMENDED_DAY = 28

interface SLCCustomizeSchedule {
  onSelectPaymentDay: (secondPaymentDay: number, dayToUpdate: PaymentDays) => void
  filteredFirstPaymentDays: number[]
  filteredSecondPaymentDays: number[]
  onClickConfirmButton: () => void
  daysSelected?: DaySelectedState
  loadingScheduleOptions?: boolean
  showReinforcementMessage: ReinforcementMessage | undefined
  loading?: boolean
  showApprovedModal: boolean
  paymentInformation: PaymentInformation
  isSimplifiedPaymentsEnabled: boolean
  displayError: boolean
  formatter: (day: number) => string
}

export const SLCCustomizeSchedule = (props: SLCCustomizeSchedule) => {
  const { isADO, slcType } = useSecureLineOfCredit()
  const today = getDateTime()
  const { monthLong, year } = getResumeDate(today)
  const analytics = useAnalytics()

  const content = useMemo(() => {
    if (slcType === SLCType.CLASSIC) {
      return deepReplaceContent(classicSlcContent, { date: `${monthLong} ${year}` })
    } else if (slcType === SLCType.FLEX_ANYWHERE) {
      return flexAnywhereSlcContent
    }
    return legacyContent
  }, [slcType])

  const payments: PaymentScheduleItemProps[] = [
    {
      label: content.DEPOSIT.TITLE,
      amount: props.paymentInformation.firstPaymentAmountFormatted,
      lineItems: [{ label: content.DEPOSIT.SUBTITLE }],
    },
    {
      label: content.FIRST_PAYMENT.TITLE,
      amount: isADO
        ? toFormattedDollars(props.paymentInformation.secondPaymentBaseAmount)
        : props.paymentInformation.secondPaymentAmountFormatted,
    },
    {
      label: content.SECOND_PAYMENT.TITLE,
      amount: props.isSimplifiedPaymentsEnabled
        ? content.SECOND_PAYMENT.SUBTITLE
        : props.paymentInformation.firstPaymentAmountFormatted,
    },
  ]
  const fees = useFees()
  const modal = useModal()

  function handleQuestionIconClick() {
    analytics.logEvent(Analytics.Events.HOW_FLEX_WORKS_SLC_CLASSIC_CTA)
    modal.show({
      render: () => (
        <SLCClassicHowFlexWorks
          deposit={props.paymentInformation.firstPaymentAmountFormatted}
          rent={props.paymentInformation.rentAmountFormatted}
          baseAmount={toFormattedDollars(props.paymentInformation.secondPaymentBaseAmount)}
          processingPercent={`${fees.processingFeePercentage}`}
        />
      ),
      title: ' ',
      hasCloseButton: true,
    })
  }

  return (
    <BasePageLayout>
      <Gap size="spacing_200" />

      <Headline2>{content.HEADER}</Headline2>
      <Gap size="spacing_100" />

      <SmallText>{content.SUB_HEADER}</SmallText>
      <Gap size="spacing_200" />
      {!props.isSimplifiedPaymentsEnabled && (
        <>
          <PaymentScheduleTitle title={content.FIRST_TIME} />
          <Gap size="spacing_100" />

          <PaymentSchedule payments={[payments[0]]} />
          <Gap size="spacing_100" />
        </>
      )}
      <PaymentScheduleTitle title={content.EACH_MONTH} />
      <Gap size="spacing_100" />

      {props.isSimplifiedPaymentsEnabled && (
        <>
          <PaymentSchedule payments={[payments[0]]} />
          <Gap size="spacing_100" />
        </>
      )}

      <PaymentSchedule payments={[payments[1]]}>
        <DateDropdownContainer>
          <NumberDateDropdown
            selectedDay={props.daysSelected?.firstDay}
            schedulableDays={props.filteredFirstPaymentDays}
            paymentToUpdate={PaymentDays.FIRST_DAY}
            defaultCenter={FIRST_PAYMENT_RECOMMENDED_DAY}
            onSelectDay={props.onSelectPaymentDay}
            formatLabel={props.formatter}
          />
        </DateDropdownContainer>
        {!!props.showReinforcementMessage && isADO && (
          <ReinforcementMessageWidget showReinforcementMessage={props.showReinforcementMessage} />
        )}
      </PaymentSchedule>
      <Gap size="spacing_100" />

      {!isADO && (
        <PaymentSchedule payments={[payments[2]]}>
          {!props.isSimplifiedPaymentsEnabled && (
            <DateDropdownContainer>
              <NumberDateDropdown
                selectedDay={props.daysSelected?.secondDay}
                schedulableDays={props.filteredSecondPaymentDays}
                paymentToUpdate={PaymentDays.SECOND_DAY}
                defaultCenter={SECOND_PAYMENT_RECOMMENDED_DAY}
                onSelectDay={props.onSelectPaymentDay}
                formatLabel={props.formatter}
              />
            </DateDropdownContainer>
          )}
        </PaymentSchedule>
      )}
      <Gap size="spacing_200" />
      {!!props.showReinforcementMessage && !isADO && (
        <ReinforcementMessageWidget showReinforcementMessage={props.showReinforcementMessage} />
      )}
      <WhenDesktop>
        <Gap size="spacing_100" />
      </WhenDesktop>
      <WhenTablet>
        <Gap size="spacing_50" />
      </WhenTablet>

      {props.displayError ? (
        <Box alignSelf="center">
          <Text color={theme.colors.red}>
            {props.isSimplifiedPaymentsEnabled ? content.ERROR_RP_ON : content.ERROR_RP_OFF}
          </Text>
        </Box>
      ) : (
        <Gap size="spacing_175" />
      )}
      <Gap size="spacing_100" />
      {isADO && (
        <HStack
          borderRadius={theme.radii.lg}
          py={theme.fixedSizes.spacing_50}
          backgroundColor={theme.colors.cloud}
          mb={theme.fixedSizes.spacing_100}
          justifyContent="center"
        >
          <SmallText>{content.DISCLAIMER}</SmallText>
          <InlineButton onClick={handleQuestionIconClick}>
            <QuestionCircle />
          </InlineButton>
        </HStack>
      )}
      <PrimaryButton
        testID="SLCCustomizeScheduleConfirmButton"
        disabled={props.loading}
        processing={props.loading}
        onClick={props.onClickConfirmButton}
      >
        {content?.CTA}
      </PrimaryButton>
    </BasePageLayout>
  )
}

const DateDropdownContainer = styled(Box)`
  width: 100%;
`
