import React from 'react'
import { Flex } from '@chakra-ui/react'

import { SpinnerLoading } from '../../components'
import { BasePageLayout } from '../../layouts'

export const FullScreenSpinnerLoading = () => (
  <BasePageLayout>
    <Flex flexGrow={1} flexShrink={0} alignItems="center" alignContent="center" justifyContent="center" px="md">
      <SpinnerLoading />
    </Flex>
  </BasePageLayout>
)
