import styled from '@emotion/styled'

import { Frame } from '../Frames'
import { PaymentScheduleItem, PaymentScheduleItemProps } from './PaymentScheduleItem'
import { PaymentScheduleTitle } from './PaymentScheduleTitle'

export interface PaymentScheduleProps {
  readonly payments: readonly PaymentScheduleItemProps[]
  readonly title?: string
  readonly showReloadIcon?: boolean
}

export const PaymentSchedule = (props: React.PropsWithChildren<PaymentScheduleProps>) => {
  return (
    <PaymentScheduleItemContainer>
      {props.title && <PaymentScheduleTitle title={props.title} showReloadIcon={props.showReloadIcon} />}
      {props.payments.map((payment, index) => (
        <PaymentScheduleItem key={`payment-${index}`} {...payment} />
      ))}
      {props.children}
    </PaymentScheduleItemContainer>
  )
}

const PaymentScheduleItemContainer = styled(Frame)`
  min-height: ${(props) => props.theme.fixedSizes.spacing_400};
`
