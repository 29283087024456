import React from 'react'
import { Analytics } from '@genoa/analytics'
import { CREDIT_BUILDER_UPSELL as content } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { CheckmarkCirclePurple, CreditScoreIncreaseIcon } from '../../../../assets'
import { ExtraSmallText, Gap, Headline2, InlineButton, PrimaryButton, SmallText } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'

interface CreditBuilderUpsellProps {
  onClickLearnMore: () => void
  onClickContinue: () => void
  onClickNoThanks: () => void
  loadingSubmit: boolean
}

export const CreditBuilderUpsell = (props: CreditBuilderUpsellProps) => {
  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_CB_UPSELL_INTRO}>
      <MainContentStack direction="column">
        <Gap size="spacing_100" />
        <IconWrapper display="flex" justifyContent="center">
          <CreditScoreIncreaseIcon />
        </IconWrapper>
        <Gap size="spacing_100" />
        <Headline2>{content.HEADER}</Headline2>
        <Gap size="spacing_250" />
        <ItemListContainer display="flex" flexDirection="column">
          <SmallText fontWeight={'bold'}>{content.LIST_HEADER}</SmallText>
          <Gap size="spacing_100" />
          {content.BULLETS.map((item, index) => (
            <ListItemWrapper key={`credit-builder-value-prop-item-${index + 1}`} justifyContent={'spaceBetween'}>
              <ListItem>
                <CheckmarkCirclePurple />
                <ItemTextWrapper flexWrap="wrap" flex="1">
                  <ItemText fontWeight={item.BOLD ? 'bold' : undefined}>{item.BODY}</ItemText>
                </ItemTextWrapper>
              </ListItem>
            </ListItemWrapper>
          ))}
        </ItemListContainer>
        <Gap size="spacing_100" />
        <DisclaimerText>
          <DisclaimerText color={'dusk'}>{content.DISCLAIMER} </DisclaimerText>
          <LearnMoreLink color={'jewelPurple'} fontWeight={'bold'} onClick={props.onClickLearnMore}>
            {content.DISCLAIMER_LINK}
          </LearnMoreLink>
        </DisclaimerText>
        <Gap size="spacing_100" />
        <DisclaimerText color={'dusk'}>{content.DISCLAIMER_TWO}</DisclaimerText>
      </MainContentStack>
      <Gap size="spacing_200" />
      <Flex justifyContent="center">
        <InlineButton onClick={props.onClickNoThanks}>
          <b>{content.REJECT_CTA}</b>
        </InlineButton>
      </Flex>
      <Gap size="spacing_100" />
      <PrimaryButton
        processing={props.loadingSubmit}
        disabled={props.loadingSubmit}
        onClick={props.onClickContinue}
        testID="CreditBuilderUpsellNextButton"
      >
        {content.CTA}
      </PrimaryButton>
    </BasePageLayout>
  )
}

const IconWrapper = styled(Box)`
  align-items: center;
`

const MainContentStack = styled(Flex)`
  width: 100%;
`

const ItemListContainer = styled(Box)`
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  padding-bottom: 0;
  background-color: ${(props) => props.theme.colors.cloud};
  border-radius: ${(props) => props.theme.fixedSizes.spacing_50};
`

const ListItemWrapper = styled(Box)`
  display: flex;
  padding-bottom: ${(props) => props.theme.fixedSizes.spacing_100};
`

const ListItem = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-self: flex-start;
`

const DisclaimerText = styled(ExtraSmallText)`
  color: ${(props) => props.theme.colors.dusk};
`

const ItemTextWrapper = styled(Box)`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`

const ItemText = styled(SmallText)`
  padding-left: ${(props) => props.theme.fixedSizes.spacing_100};
  flex-wrap: wrap;
`

const LearnMoreLink = styled(ExtraSmallText)`
  cursor: pointer;
`
