import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { EmbedPayNowEligibilityFailureCode } from '@genoa/domain'
import { ordinalSuffixFor } from '@genoa/utils'
import { DateTime } from 'luxon'

import { useEmbedAnalytics } from '../../../../../../../hooks'
import { useEmbedPrefills } from '../../../../../../../hooks/prefills/use-embed-prefills'
import { useAnalytics } from '../../../../../../../providers'
import * as Routes from '../../../../../../../routing/constants'
import { FullScreenSpinnerLoading } from '../../../../../../components'

export const VerifyEmbedUser = () => {
  const navigate = useNavigate()
  const analytics = useAnalytics()
  const { embedFlow } = useEmbedAnalytics()
  const { handlePrefillRoot } = useEmbedPrefills()

  const redirectUserEmbedLandingScreen = async () => {
    try {
      const embeddedResident = await handlePrefillRoot()
      const eligibility = embeddedResident?.data?.eligibility

      if (!eligibility) {
        return navigate(Routes.Auth.PHONE)
      }

      const bpMonth = eligibility.bp_end_date
        ? DateTime.fromFormat(eligibility.bp_end_date, 'yyyy-MM-dd').monthLong
        : ''
      const failureCode = eligibility.failure_code
      const firstName = embeddedResident?.data?.resident?.first_name || ''

      if (eligibility.eligible && !failureCode) {
        return navigate(Routes.EmbedLanding.START_PAYMENT, { replace: true, state: { bpMonth } })
      }

      switch (failureCode) {
        case EmbedPayNowEligibilityFailureCode.SIGNUP:
        case EmbedPayNowEligibilityFailureCode.COMPLETE_SIGNUP:
          navigate(Routes.EmbedLanding.SIGNUP, { replace: true, state: { failureCode, firstName } })
          break
        case EmbedPayNowEligibilityFailureCode.AUTOPAY_ON:
          navigate(Routes.EmbedLanding.ALREADY_FLEXING_YOUR_RENT, { replace: true, state: { bpMonth } })
          break
        case EmbedPayNowEligibilityFailureCode.BP_WINDOW:
          let bpStartDate = ''
          if (eligibility.bp_start_date) {
            const bpDate = DateTime.fromFormat(eligibility.bp_start_date, 'yyyy-MM-dd')
            bpStartDate = `${bpDate.monthLong} ${ordinalSuffixFor(bpDate.day)}`
          }
          navigate(Routes.EmbedLanding.NOT_RENT_DAY, { replace: true, state: { bpMonth, bpStartDate } })
          break
        case EmbedPayNowEligibilityFailureCode.RELINK_PROPERTY:
          navigate(Routes.Auth.PHONE)
          break
        case EmbedPayNowEligibilityFailureCode.RENT_PAYMENT_IN_PROGRESS:
        case EmbedPayNowEligibilityFailureCode.RENT_PAID:
          navigate(Routes.EmbedLanding.RENT_SPLIT_PROGRESS, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.INVALID_PRODUCT:
          navigate(Routes.EmbedLanding.BUILD_CREDIT_IN_APP, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.INVALID_BALANCE:
          navigate(Routes.EmbedLanding.SERVICE_ISSUE, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.CREDIT_LINE_DECLINED:
          navigate(Routes.EmbedLanding.CREDIT_LINE_DECLINED, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.CREDIT_LINE_SUSPENDED:
          navigate(Routes.EmbedLanding.CREDIT_LINE_SUSPENDED, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.UNKNOWN:
          navigate(Routes.EmbedLanding.SOMETHING_WENT_WRONG, { replace: true })
          break
        default:
          navigate(Routes.EmbedLanding.SOMETHING_WENT_WRONG)
          break
      }
    } catch (error) {
      navigate(Routes.Auth.PHONE)
    }
  }

  useEffect(() => {
    analytics.logScreenView(Analytics.Screens.EMBED_DYN_INT_VERIFY, { embedFlow })
    redirectUserEmbedLandingScreen()
  }, [])

  return <FullScreenSpinnerLoading />
}
