import React, { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'
import { Box, Checkbox } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAnalytics, useFlexLinks } from '../../../providers'
import { LinkText, PrimaryButton, SmallText } from '../../components'
import { Modal, ModalActions } from '../../components/Modal'

const TermsAndConditionsLink = () => {
  const flexLinks = useFlexLinks()
  const analytics = useAnalytics()

  const handleOpenTermsOfService = useCallback(() => {
    analytics.logEvent(Analytics.Events.SIGNUP_TERMS_OF_SERVICE_CLICKED, { screen: 'sms-opt-in' })
    flexLinks.open(FlexLinks.termsOfService)
  }, [flexLinks.open])

  return (
    <LinkText onClick={handleOpenTermsOfService} testID="SMSOptInTermsOfServiceLink">
      <b>Terms and Conditions</b>
    </LinkText>
  )
}

const PrivacyPolicyLink = () => {
  const flexLinks = useFlexLinks()
  const analytics = useAnalytics()

  const handleOpenPrivacyPolicy = useCallback(() => {
    analytics.logEvent(Analytics.Events.SIGNUP_PRIVACY_POLICY_CLICKED, { screen: 'sms-opt-in' })
    flexLinks.open(FlexLinks.privacyPolicy)
  }, [flexLinks.open])

  return (
    <LinkText onClick={handleOpenPrivacyPolicy} testID="SMSOptInPrivacyPolicyLink">
      <b>Privacy Policy</b>
    </LinkText>
  )
}

type SuccessfulPinProps = {
  visible: boolean
  onClose: () => unknown
  onSMSOptIn: () => unknown
  smsNotificationsAccepted: boolean
  loading?: boolean
}

export const SuccessfulPinContent = (props: SuccessfulPinProps) => {
  return (
    <>
      <SmallText data-testid="SuccessfulPinBody">Your phone number has been verified.</SmallText>
      <Box>
        <Box height="56px" />
        <OptInCheckboxContainer>
          <LeftPaddedCheckbox
            testID={'AcceptSMSNotificationsCheckbox'}
            checked={props.smsNotificationsAccepted}
            onChange={props.onSMSOptIn}
          />
          {` `}
          <SmallTextStyled>
            I agree to receive text messages with updates and news about Flex. These communications may be delivered
            using an autodialer. Consent is not a condition of application or use. Msg frequency varies. Msg and data
            rates may apply. Reply STOP to unsubscribe. More info in Flex&rsquo;s <TermsAndConditionsLink /> and{' '}
            <PrivacyPolicyLink />.
          </SmallTextStyled>
        </OptInCheckboxContainer>
        <Box height="26px" />
      </Box>
      <ModalActions>
        <PrimaryButton
          onClick={props.onClose}
          processing={props.loading}
          disabled={props.loading}
          testID="ConfirmOtpSuccessModalNextButton"
        >
          Continue
        </PrimaryButton>
      </ModalActions>
    </>
  )
}

export const SuccessfulPin = (props: SuccessfulPinProps) => {
  return (
    <Modal
      testID="SuccessfulPinModal"
      title="Success!"
      canClose={false}
      onClose={props.onClose}
      visible={props.visible}
    >
      <SuccessfulPinContent {...props} />
    </Modal>
  )
}

const LeftPaddedCheckbox = styled(Checkbox)`
  padding-left: 4px;
`

const OptInCheckboxContainer = styled(SmallText)`
  display: flex;
  align-items: flex-start;
`
const SmallTextStyled = styled(SmallText)`
  padding-top: 4px;
  padding-left: 16px;
  text-align: left;
`
