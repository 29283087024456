import { useEffect } from 'react'
import { Analytics } from '@genoa/analytics'
import { CONGRATS_CONTENT, OLD_CONGRATS_CONTENT, replaceContent } from '@genoa/screen-content'
import { getDateTime, getResumeDate } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'

import {
  useAutopilotEligibility,
  useContentOverhaul,
  useEmbedAnalytics,
  useFlexAnywhereUser,
  useGetAccount,
  useIsEmbed,
} from '../../../hooks'
import { useAnalytics } from '../../../providers'
import {
  CongratsFlag as CongratsImageFlag,
  HeroBouquet as CongratsImageBouquet,
  StatusIconOne,
  StatusIconTwo,
} from '../../assets'
import { Headline1, Text } from '../../components'
import { AppStoreButton } from '../../components/AppStoreButton'
import { PlayStoreButton } from '../../components/PlayStoreButton'
import { FullScreenSpinnerLoading } from '../../components/SpinnerLoading/FullScreenSpinnerLoading'
import { BasePageLayout } from '../../layouts'

type CongratsProps = {
  analyticsScreenName: Analytics.Screens
}

const storeBadgeStyles = {
  maxHeight: '65px',
  minHeight: '65px',
  transform: 'scale(0.7)',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  marginTop: '16px',
}

export const Congrats = (props: CongratsProps) => {
  const isOutOfNetworkUser = useFlexAnywhereUser()
  const { isUserAutopayEnabled, isLoadingEligibility } = useAutopilotEligibility()
  const { embedFlow, embedProperties } = useEmbedAnalytics()
  const analytics = useAnalytics()
  const isEmbed = useIsEmbed()
  useGetAccount()
  const { onboardingEnabled } = useContentOverhaul()

  const image = isUserAutopayEnabled ? <CongratsImageFlag /> : <CongratsImageBouquet />

  const content = onboardingEnabled ? CONGRATS_CONTENT.CO_CONTENT : OLD_CONGRATS_CONTENT
  const rememberContent = isOutOfNetworkUser ? CONGRATS_CONTENT.CO_FA_REMEMBER_CARD : CONGRATS_CONTENT.CO_REMEMBER_CARD

  const today = getDateTime()
  const resumeDate = getResumeDate(today)
  const renderNextAvailableResumeDate = () => {
    return `${resumeDate.monthLong} ${resumeDate.year}`
  }

  const onClickAppStore = () => {
    if (isEmbed) {
      analytics.logEvent(Analytics.Events.EMBED_CONGRATS_AUTOPAY_ON_CTA_APPLE, {
        embedFlow,
      })
    }
  }

  const onClickPlayStore = () => {
    if (isEmbed) {
      analytics.logEvent(Analytics.Events.EMBED_CONGRATS_AUTOPAY_ON_CTA_GOOGLE, {
        embedFlow,
      })
    }
  }

  useEffect(() => {
    if (isEmbed) {
      analytics.logScreenView(Analytics.Screens.EMBED_CONGRATS_AUTOPAY_ON, embedProperties)
    }
  }, [isEmbed])

  if (isLoadingEligibility) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH={isUserAutopayEnabled ? '15px' : '42px'} />

      <Flex flexGrow={1} flexShrink={0} flexDirection="column" data-testid="CongratsPage">
        <Flex align="center" justify="center">
          {image}
        </Flex>
        <Box minH={isUserAutopayEnabled ? '36px' : '20px'} />
        <Headline1 textAlign="center" data-testid="CongratsHeader">
          {content.HEADER}
        </Headline1>
        <Box minH={'16px'} />
        <Text textAlign="center" data-testid="CongratsBody">
          {content.BODY}
        </Text>
        <Text textAlign="center" data-testid="CongratsBodyTwo">
          {onboardingEnabled && (
            <>
              {content.BODY_ONE}
              <Text fontWeight="bold"> {resumeDate.monthLong} </Text>
              {content.BODY_TWO}
            </>
          )}
        </Text>
        <Box minH={'32px'} />
        {!onboardingEnabled && (
          <>
            <Text textAlign="center" fontWeight={'bold'} data-testid="CongratsBodyBold">
              {replaceContent(content.BODY_BOLD, { date: renderNextAvailableResumeDate() })}
            </Text>

            <Box minH={'32px'} />

            <Text textAlign="center" fontWeight={'bold'} data-testid="CongratsDownload">
              {content.DOWNLOAD}
            </Text>
          </>
        )}

        {onboardingEnabled && (
          // Remember component with tips 1 and 2
          <Flex flexShrink={0} direction="column" bg="cloud" borderRadius="8px" p="16px" mb="32px">
            <Text fontWeight="bold" data-testid="CongratsRememberHeader">
              {rememberContent.HEADER}
            </Text>
            <Flex pt="16px" alignItems="flex-start">
              <Box pr="4px" mt="5px" flexShrink={0}>
                <StatusIconOne />
              </Box>
              <Text fontSize="12px" ml="5px" lineHeight="16.8px" data-testid="RememberCardBodyOne">
                <Text fontSize="12px" fontWeight="bold">
                  {rememberContent.BODY_ONE_BOLD}{' '}
                </Text>
                {rememberContent.BODY_ONE}
              </Text>
            </Flex>
            <Flex mt="16px" alignItems="flex-start">
              <Box pr="4px" mt="5px" flexShrink={0}>
                <StatusIconTwo />
              </Box>
              <Text fontSize="12px" ml="5px" lineHeight="16.8px" data-testid="RememberCardBodyTwo">
                <Text fontSize="12px" fontWeight="bold">
                  {rememberContent.BODY_TWO_BOLD}{' '}
                </Text>
                {rememberContent.BODY_TWO}
              </Text>
            </Flex>
          </Flex>
        )}
        <Flex
          flexShrink={0}
          direction="column"
          align="center"
          justify="center"
          bg="cloud"
          borderRadius={'8px'}
          px={'15px'}
          py={'30px'}
        >
          {onboardingEnabled && (
            <Text fontSize="12px" textAlign="center" data-testid="CongratsDownload">
              {content.DOWNLOAD}
            </Text>
          )}

          <Flex {...storeBadgeStyles}>
            <AppStoreButton onClickAppStore={onClickAppStore} />
            <PlayStoreButton onClickPlayStore={onClickPlayStore} />
          </Flex>
        </Flex>
      </Flex>

      <Box minH={'30px'} />
    </BasePageLayout>
  )
}
