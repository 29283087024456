import { useState } from 'react'
import { Analytics } from '@genoa/analytics'
import {
  actionLogToSelectedEvent,
  ActionLogType,
  FlexLinks,
  LocalStorageProductType,
  ProductOption,
} from '@genoa/domain'
import { toFormattedDollars } from '@genoa/utils'

import { useSecureLineOfCredit } from '../../../../../hooks'
import { useHandleRegisterActionLog } from '../../../../../hooks/flex2/onboarding-status'
import { useOfferState } from '../../../../../modules/flex2/use-state'
import { useAnalytics, useFlexLinks } from '../../../../../providers'
import { Gap } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { HowYouUseFlex } from './HowYouUseFlex'

export interface HowYouUseFlexContainerProps {
  analyticsScreenName: Analytics.Screens
  onNext: () => void
}

// Hardcoded fee percentages for July BP
const CREDIT_BUILDER_FEE_PERCENTAGE = 0.0075
const FLEXIBLE_RENT_FEE_PERCENTAGE = 0.01
const FLEXIBLE_RENT_BASE_PRICE = 1499

export const HowYouUseFlexContainer = (props: HowYouUseFlexContainerProps) => {
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()
  const { refetchSLC, isRefetchingSLC } = useSecureLineOfCredit()
  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()

  const [selectedOption, setSelectedOption] = useState<ProductOption | undefined>(undefined)

  const onSelectOption = (option: ProductOption) => {
    setSelectedOption(option)
    return analytics.logEvent(actionLogToSelectedEvent[option])
  }

  const onConfirm = async () => {
    if (!selectedOption) {
      return undefined
    }

    if (
      (selectedOption === ActionLogType.SELECT_PRODUCT_CREDIT_BUILDER &&
        localStorage.getItem('flexapp/product') === LocalStorageProductType.CREDIT_BUILDER) ||
      (selectedOption === ActionLogType.SELECT_PRODUCT_SPLIT_RENT &&
        localStorage.getItem('flexapp/product') === LocalStorageProductType.RENT_SPLIT)
    ) {
      return props.onNext()
    } else {
      if (selectedOption === ActionLogType.SELECT_PRODUCT_CREDIT_BUILDER) {
        localStorage.setItem('flexapp/product', LocalStorageProductType.CREDIT_BUILDER)
      } else {
        localStorage.setItem('flexapp/product', LocalStorageProductType.RENT_SPLIT)
      }
    }

    await handleRegisterActionLog(selectedOption)
    await refetchSLC()

    return props.onNext()
  }
  // Hardcoded fees for July BP
  const offerState = useOfferState()
  const creditBuilderFee = toFormattedDollars(
    offerState.offer.estimated_bill_amount_cent * CREDIT_BUILDER_FEE_PERCENTAGE
  )
  const flexibleRentFee = toFormattedDollars(
    FLEXIBLE_RENT_BASE_PRICE + offerState.offer.estimated_bill_amount_cent * FLEXIBLE_RENT_FEE_PERCENTAGE
  )
  const estimatedRent = toFormattedDollars(offerState.offer.estimated_bill_amount_cent)

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Gap size="spacing_300" />
      <HowYouUseFlex
        loading={loadingRegisterActionLog || !!isRefetchingSLC}
        selectedOption={selectedOption}
        onSelectOption={onSelectOption}
        onConfirm={onConfirm}
        onLearnMore={() => flexLinks.open(FlexLinks.creditBuilderDisclosure)}
        creditBuilderFee={creditBuilderFee}
        flexibleRentFee={flexibleRentFee}
        estimatedRent={estimatedRent}
      />
    </BasePageLayout>
  )
}
