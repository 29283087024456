import { useCallback, useState } from 'react'

export const usePollingRequest = <T>(callback: () => Promise<T | boolean>, timeoutMs: number, intervalMs: number) => {
  const [loading, setLoading] = useState<boolean>(false)

  const startPolling = useCallback((): Promise<T | undefined> => {
    return new Promise((res: any, rej: any) => {
      setLoading(true)

      const intervalInstance = setInterval(async () => {
        const result = await callback()
        // if result from callback is truthy we need to stop the interval
        if (result) {
          clear()
          res(result)
        }
      }, intervalMs)

      const timeoutInstance = setTimeout(() => {
        // timeout expired before getting a successful result from the interval callback
        if (intervalInstance) {
          clear()
          rej('Timed-out waiting for portal matching')
        }
      }, timeoutMs + 1000)

      const clear = () => {
        clearInterval(intervalInstance)
        clearTimeout(timeoutInstance)
        setLoading(false)
      }
    })
  }, [callback, timeoutMs, intervalMs, setLoading])

  return {
    startPolling,
    loading,
  }
}
