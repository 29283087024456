import React, { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from '../../../../../providers'
import { SecondPaymentList, SecondPaymentListBaseProps } from './SecondPaymentList'
import { useScrollSecondPaymentList } from './useScrollSecondPaymentList'

export const SecondPaymentListContainer = (props: SecondPaymentListBaseProps) => {
  const scrollSecondPaymentList = useScrollSecondPaymentList({ secondPaymentDays: props.secondPaymentDays })
  const analytics = useAnalytics()

  const handleClickLeftArrowButton = useCallback(() => {
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_LEFT_ARROW_CLICKED)
    scrollSecondPaymentList.translateXMovingLeft()
  }, [scrollSecondPaymentList.translateXMovingLeft])

  const handleClickRightArrowButton = useCallback(() => {
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_RIGHT_ARROW_CLICKED)
    scrollSecondPaymentList.translateXMovingRight()
  }, [scrollSecondPaymentList.translateXMovingRight])

  return (
    <SecondPaymentList
      {...props}
      isNextDaysListButtonDisabled={scrollSecondPaymentList.translateX === scrollSecondPaymentList.MAX_SLIDING_RIGHT}
      isPreviousDaysListButtonDisabled={scrollSecondPaymentList.translateX === scrollSecondPaymentList.MAX_SLIDING_LEFT}
      mostPopularDay={scrollSecondPaymentList.MOST_POPULAR_DAY}
      onClickRightArrowButton={handleClickRightArrowButton}
      onClickLeftArrowButton={handleClickLeftArrowButton}
      itemSize={scrollSecondPaymentList.listItemWidth}
      translateX={scrollSecondPaymentList.translateX}
      paymentTitle={props.paymentTitle}
    />
  )
}
