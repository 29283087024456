import React from 'react'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { SmallText } from '../../../../components'

type MembershipFeeReasonProps = {
  membershipFeeReason: string
}

export const MembershipFeeReason = (props: MembershipFeeReasonProps) => {
  return (
    <MembershipReasonBox>
      <StarBox>
        <SmallText>⭐️</SmallText>
      </StarBox>
      <SmallText>️{props.membershipFeeReason}</SmallText>
    </MembershipReasonBox>
  )
}

const MembershipReasonBox = styled(Flex)`
  width: 100%;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.cloud};
  padding: ${(props) => props.theme.fixedSizes.spacing_50};
  border-radius: ${(props) => props.theme.fixedSizes.spacing_50};
`

const StarBox = styled(Flex)`
  padding-right: ${(props) => props.theme.fixedSizes.spacing_50};
`
