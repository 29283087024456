import { Dropdown } from '../Dropdown'

enum PaymentDays {
  FIRST_DAY = 'firstDay',
  SECOND_DAY = 'secondDay',
}

export interface NumberDropdownProps {
  readonly selectedDay: number | undefined
  readonly schedulableDays: readonly number[]
  readonly defaultCenter?: number
  readonly placeholder?: string
  readonly onSelectDay: (day: number, paymentToUpdate: PaymentDays) => void
  readonly paymentToUpdate: PaymentDays
  readonly formatLabel: (day: number) => string
}

export const NumberDateDropdown = (props: NumberDropdownProps) => {
  const placeholder = props.placeholder ?? 'Choose date'

  const selectDay = (day: number) => props.onSelectDay(day, props.paymentToUpdate)

  return (
    <Dropdown<number>
      selected={props.selectedDay}
      selections={props.schedulableDays}
      defaultCenter={props.defaultCenter}
      placeholder={placeholder}
      onSelect={selectDay}
      formatLabel={props.formatLabel}
      isEqual={(value, comparison) => value === comparison}
    />
  )
}
