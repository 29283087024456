import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import { SLCConfirmScheduleContainer } from '../../common/slc/confirm-schedule'

export const OnboardingSLCConfirmScheduleContainer = () => {
  const analytics = useAnalytics()
  const navigate = useNavigate()

  const onRegisterCard = () => {
    analytics.logEvent(Analytics.Events.CONNECT_CARD_CTA_CLICKED)
    return navigate(Routes.Onboarding.CARD_REGISTER, {
      state: {
        analyticsScreenName: Analytics.Screens.ADD_CARD_ONBOARDING_ADD_CARD,
        nextRoute: Routes.Onboarding.OFFER_DETAILS,
      },
    })
  }

  const onChangeAutopay = () => {
    analytics.logEvent(Analytics.Events.CHANGE_AUTOPAY_CLICKED)
    return navigate(Routes.Onboarding.AUTOPAY_SETUP, {
      state: { prefillSelection: true, nextRoute: Routes.Onboarding.OFFER_DETAILS },
    })
  }

  return (
    <SLCConfirmScheduleContainer
      analyticsScreenName={Analytics.Screens.ONBOARDING_CONFIRM_SCHEDULE}
      onNext={() => navigate(Routes.Onboarding.CONGRATS)}
      onRegisterCard={onRegisterCard}
      onChangeAutopay={onChangeAutopay}
    />
  )
}
