import { css } from '@emotion/react'

import { queries } from './queries'

// Iterate through the sizes and create a media template
export const media = {
  phone: (...args: any[]) => css`
    @media ${queries.phone} {
      ${css(...args)}
    }
  `,
  tablet: (...args: any[]) => css`
    @media ${queries.tablet} {
      ${css(...args)}
    }
  `,
  desktop: (...args: any[]) => css`
    @media ${queries.desktop} {
      ${css(...args)}
    }
  `,
}
