export const breakpoints = {
  tablet: 1024,
  phone: 480,
}

export const queries = {
  phone: `(max-width: ${breakpoints.phone}px)`,
  tablet: `(min-width: ${breakpoints.phone + 1}px) and (max-width: ${breakpoints.tablet}px)`,
  desktop: `(min-width: ${breakpoints.tablet + 1}px)`,
}

const mediaSize = {
  mobileS: '320px',
  mobileM: '375px',
  mobileL: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
}

export const mediaDevice = {
  mobileS: `(min-width: ${mediaSize.mobileS})`,
  mobileM: `(min-width: ${mediaSize.mobileM})`,
  mobileL: `(min-width: ${mediaSize.mobileL})`,
  tablet: `(min-width: ${mediaSize.tablet})`,
  laptop: `(min-width: ${mediaSize.laptop})`,
  laptopL: `(min-width: ${mediaSize.laptopL})`,
  desktop: `(min-width: ${mediaSize.desktop})`,
  desktopL: `(min-width: ${mediaSize.desktop})`,
}
