const mask = (ssn: string): string => {
  // 000-00-0000

  return ssn
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{2})(\d)/, '$1-$2')
    .replace(/(-\d{4})\d+?$/, '$1')
}

const maxLength = 11

const removeMask = (ssn: string) => ssn.split('-').join('')

export { mask, maxLength, removeMask }
