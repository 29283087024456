import React from 'react'
import { createContext } from 'react'
import { ChakraProvider } from '@chakra-ui/react'

import theme from '../../theme/theme'

const ThemeContext = createContext({
  theme,
})

export const FlexThemeProvider = (props: React.PropsWithChildren) => {
  return (
    <ThemeContext.Provider value={{ theme }}>
      <ChakraProvider theme={theme} resetCSS>
        {props.children}
      </ChakraProvider>
    </ThemeContext.Provider>
  )
}
