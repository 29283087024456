import { createAction } from '@reduxjs/toolkit'

import { ContactState, PhoneNumberState, RentAmountState, SoftCreditCheckState } from './types'

export const resetOnboardingAction = createAction<void>('onboarding/reset-onboarding')

export const setContactAction = createAction<ContactState>('onboarding/set-contact')
export const setConsolidatedExperienceAction = createAction<boolean>('onboarding/set-consolidated-experience')
export const setCoBrandingPropertyAction = createAction<string | undefined>('onboarding/set-co-branding-property')

export const setPhoneNumberAction = createAction<PhoneNumberState>('onboarding/set-phone-number')
export const setRentAmountAction = createAction<RentAmountState>('onboarding/set-rent-amount')

export const setSignUpCompletedAction = createAction<{ id: string; signUpCompleted: boolean }>(
  'onboarding/sign-up-completed'
)
export const setSoftCreditCheckInfo = createAction<SoftCreditCheckState>('onboarding/set-soft-check-check-info')
