import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { resetPrefillsAction } from '@genoa/state-management'
import branch from 'branch-sdk'
import { ApplicationVerifier, getAuth, signInWithPhoneNumber as fbSignInWithPhoneNumber } from 'firebase/auth'

import { useIsEmbed, useReduxAction } from '../../hooks'
import { setCoBrandingPropertyAction } from '../../modules'
import { registerSignOutAction } from '../../modules/auth'
import { resetCustomerStatusAction } from '../../modules/flex2/customer-status'
import { rootApi } from '../../modules/flexApi'
import * as Routes from '../../routing/constants'
import { useFirebase } from '../firebase/firebase-provider'
import { useIterable } from '../iterable'

export const useAuth = () => {
  const registerSignOut = useReduxAction<void>(registerSignOutAction)
  const iterable = useIterable()
  const navigate = useNavigate()
  const firebaseApp = useFirebase()
  const setCoBrandingProperty = useReduxAction(setCoBrandingPropertyAction)
  const resetCustomerStatus = useReduxAction<void>(resetCustomerStatusAction)
  const resetPrefills = useReduxAction<void>(resetPrefillsAction)
  const auth = getAuth(firebaseApp)
  const dispatch = useDispatch()
  const isEmbed = useIsEmbed()

  const signOut = useCallback(() => {
    navigate(Routes.Auth.PHONE, { replace: true })
    branch.logout()
    registerSignOut()
    setCoBrandingProperty(undefined)
    if (isEmbed) {
      resetCustomerStatus()
      resetPrefills()
    }
    auth.signOut()
    iterable.logout()
    dispatch(rootApi.util.resetApiState())
  }, [isEmbed])

  const signInWithPhoneNumber = useCallback((phoneNumber: string, applicationVerifier: ApplicationVerifier) => {
    return fbSignInWithPhoneNumber(auth, phoneNumber, applicationVerifier)
  }, [])

  return {
    signOut,
    signInWithPhoneNumber,
  }
}
