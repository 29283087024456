import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from '@chakra-ui/react'

import { queries } from '../../../../../theme/media/queries'
import { LIST_WRAPPER_ID } from './PaymentList'
import { DEFAULT_HORIZONTAL_MARGIN as PAYMENT_DAY_LIST_ITEM_MARGIN } from './PaymentListItem'

const MOST_POPULAR_DAY = 1 // Will be centered in the middle of the list in the first render
const MAX_SLIDING_LEFT = 0
const MOVING_RATIO = 2.5

type UseScrollPaymentListProps = {
  paymentDays: number[]
  mostPopularDay?: number
}

export type scrollPaymentListProps = {
  translateXMovingRight: () => void
  translateXMovingLeft: () => void
  MOST_POPULAR_DAY: number
  MAX_SLIDING_RIGHT: number
  MAX_SLIDING_LEFT: number
  listItemWidth: number
  translateX: number
}

export const useScrollPaymentList = (props: UseScrollPaymentListProps): scrollPaymentListProps => {
  const [isDesktop] = useMediaQuery(queries.desktop)
  const [isTablet] = useMediaQuery(queries.tablet)
  const [isPhone] = useMediaQuery(queries.phone)

  const listItemWidth = useMemo(() => {
    if (isDesktop) {
      return 80
    }
    if (isTablet) {
      return 80
    }
    if (isPhone) {
      return 65
    }
    return 80
  }, [isDesktop, isTablet, isPhone])

  const listItemTotalWidth = useMemo(() => listItemWidth + PAYMENT_DAY_LIST_ITEM_MARGIN * 2, [listItemWidth])

  const calculateDesktopTabletInitialTranslateX = useCallback(
    (indexFocusedDay: number) => {
      // -listItemTotalWidth * indexFocusedDay: Takes the MOST_POPULAR_DAY to beginning of the list (considering the margins)
      // 2 * listItemTotalWidth: Takes the MOST_POPULAR_DAY to the middle of the list
      return -listItemTotalWidth * indexFocusedDay + 2 * listItemTotalWidth
    },
    [listItemTotalWidth]
  )

  const calculateMobileInitialTranslateX = useCallback(
    (indexFocusedDay: number) => {
      // listItemTotalWidth * indexFocusedDay: Takes the MOST_POPULAR_DAY to beginning of the list (considering the margins)
      // 1.5 * listItemTotalWidth: Takes the MOST_POPULAR_DAY to the middle of the list
      return listItemTotalWidth * indexFocusedDay - listItemTotalWidth * 1.5
    },
    [listItemTotalWidth]
  )

  const initialTranslateX = useMemo(() => {
    const indexFocusedDay = props.paymentDays.findIndex((secondpaymentDay) => {
      return secondpaymentDay === (props.mostPopularDay ? props.mostPopularDay : MOST_POPULAR_DAY)
    })
    if (!indexFocusedDay) {
      return 0
    }
    if (isDesktop || isTablet) {
      return calculateDesktopTabletInitialTranslateX(indexFocusedDay)
    }

    return calculateMobileInitialTranslateX(indexFocusedDay)
  }, [
    calculateDesktopTabletInitialTranslateX,
    calculateMobileInitialTranslateX,
    props.paymentDays,
    isDesktop,
    isTablet,
  ])

  // Media query changed, or initialized
  useEffect(() => {
    setTranslateX(initialTranslateX)
  }, [initialTranslateX])

  const [translateX, setTranslateX] = useState(initialTranslateX)

  const movingPixels = useMemo(() => listItemTotalWidth * MOVING_RATIO, [listItemTotalWidth])

  const moveListOnPhoneOrTablet = () => {
    const listWrapperDOMRef = document.getElementById(LIST_WRAPPER_ID)
    if (!listWrapperDOMRef) {
      return
    }
    listWrapperDOMRef.scrollTo(Math.abs(initialTranslateX), 0)
  }

  useEffect(() => {
    if (isDesktop) {
      return
    }
    moveListOnPhoneOrTablet()
  }, [initialTranslateX])

  const MAX_SLIDING_RIGHT = useMemo(
    () => -(props.paymentDays.length / MOVING_RATIO - 2) * movingPixels,
    [props.paymentDays.length, movingPixels]
  )

  const translateXMovingLeft = useCallback(
    () =>
      setTranslateX((x) => {
        if (x === MAX_SLIDING_LEFT) {
          return MAX_SLIDING_LEFT
        }
        const nextX = x + movingPixels
        if (Math.abs(nextX) < movingPixels) {
          return MAX_SLIDING_LEFT
        }
        return x + movingPixels
      }),
    [movingPixels]
  )

  const translateXMovingRight = useCallback(
    () =>
      setTranslateX((x) => {
        if (x === MAX_SLIDING_RIGHT) {
          return MAX_SLIDING_RIGHT
        }
        const nextX = x - movingPixels
        if (nextX < MAX_SLIDING_RIGHT) {
          return MAX_SLIDING_RIGHT
        }
        return nextX
      }),
    [movingPixels]
  )

  return {
    translateXMovingRight,
    translateXMovingLeft,
    MOST_POPULAR_DAY,
    MAX_SLIDING_RIGHT,
    MAX_SLIDING_LEFT,
    listItemWidth,
    translateX,
  }
}
