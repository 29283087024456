import styled from '@emotion/styled'

import { ShadowedFrame } from '../Frames'
import { PaymentScheduleFrameItem, PaymentScheduleFrameItemProps } from './PaymentScheduleFrameItem'
import { PaymentScheduleFrameTitle } from './PaymentScheduleFrameTitle'

export interface PaymentScheduleFrameProps {
  readonly payments: readonly PaymentScheduleFrameItemProps[]
  readonly title?: string
}

export const PaymentScheduleFrame = (props: React.PropsWithChildren<PaymentScheduleFrameProps>) => {
  return (
    <PaymentScheduleItemContainer>
      {props.title && <PaymentScheduleFrameTitle title={props.title} />}
      {props.payments.map((payment, index) => (
        <PaymentScheduleFrameItem key={`payment-${index}`} {...payment} />
      ))}
      {props.children}
    </PaymentScheduleItemContainer>
  )
}

const PaymentScheduleItemContainer = styled(ShadowedFrame)`
  min-height: ${({ theme }) => theme.fixedSizes.spacing_400};
`
