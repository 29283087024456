import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useSecureLineOfCredit } from '../../../../hooks'
import * as Routes from '../../../../routing/constants'
import { InitiateOfferRentAmountContainer } from '../../common/rent-amount/InitiateOfferRentAmountContainer'

type OnboardingRentAmountContainerProps = {}

export const OnboardingRentAmountContainer = ({}: OnboardingRentAmountContainerProps) => {
  const navigate = useNavigate()
  const { refetchSLC } = useSecureLineOfCredit()

  async function handleOnNext() {
    await refetchSLC()
    navigate(Routes.Onboarding.SOFT_CREDIT_CHECK)
  }

  return (
    <InitiateOfferRentAmountContainer
      analyticsScreenName={Analytics.Screens.ONBOARDING_RENT_AMOUNT}
      analyticsEventCTAClicked={Analytics.Events.RENT_AMOUNT_CTA_CLICKED}
      analyticsEventCTAError={Analytics.Events.RENT_AMOUNT_CTA_ERROR}
      onNext={handleOnNext}
    />
  )
}
