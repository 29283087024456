import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { SmallText } from '../Typography'
import { PaymentLineItemFrame, PaymentLineItemFrameProps } from './PaymentLineItemFrame'

export interface PaymentScheduleFrameItemProps {
  readonly label: string
  readonly amount: string
  readonly lineItems?: readonly PaymentLineItemFrameProps[]
}

export const PaymentScheduleFrameItem = (props: PaymentScheduleFrameItemProps) => (
  <PaymentScheduleItemContainer>
    <PaymentsColumn>
      <PaymentRow>
        <SmallText>{props.label}</SmallText>
        <SmallText>{props.amount}</SmallText>
      </PaymentRow>
      {props.lineItems && (
        <LineItemsColumn>
          {props.lineItems?.map((item, index) => (
            <PaymentLineItemFrame key={`item-${index}`} {...item} />
          ))}
        </LineItemsColumn>
      )}
    </PaymentsColumn>
  </PaymentScheduleItemContainer>
)

const PaymentScheduleItemContainer = styled(Flex)`
  width: 100%;
`

const PaymentsColumn = styled(Flex)`
  align-items: flex-start;
  flex-direction: column;
  flex-grow: 1;
`

const PaymentRow = styled(Flex)`
  justify-content: space-between;
  width: 100%;
`

const LineItemsColumn = styled(PaymentsColumn)`
  gap: ${({ theme }) => theme.fixedSizes.spacing_50};
  width: 100%;
`
