import { createAction } from '@reduxjs/toolkit'

import { OfferState } from './types'

export const resetOfferAction = createAction<void>('offer/reset-offer')

export const setOfferAction = createAction<OfferState>('offer/set-offer')

export const setEstimatedRentAmountAction = createAction<OfferState>('offer/set-estimated-rent-amount')

export const setRepaymentDayAction = createAction<number>('offer/set-repayment-day')

export const setSecondRepaymentDayAction = createAction<number>('offer/set-second-repayment-day')
