import React, { useCallback, useEffect, useState } from 'react'

import { useModal } from '../../../../contexts'
import { useReduxAction, useReduxSelector } from '../../../../hooks'
import { RootState } from '../../../../modules'
import { OfferState, setRepaymentDayAction } from '../../../../modules/flex2/offer'
import { SmallText } from '../../../components'
import { useSetScheduleRange } from '../use-set-schedule-range'
import { ConfirmDateModal } from './ConfirmDateModal'

type ConfirmDateModalContainerProps = {
  initialDaySelected: number
  onClose: () => void
  isOpen: boolean
}

const defaultScheduleRange = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22]

export const ConfirmDateModalContainer = (props: ConfirmDateModalContainerProps) => {
  const [scheduleRange, setScheduleRange] = useState<number[]>(defaultScheduleRange)
  const offerState: OfferState = useReduxSelector((state: RootState) => state.offerState)
  const [indexDateSelected, setIndexDateSelected] = useState(0)
  const setRepaymentDay = useReduxAction(setRepaymentDayAction)
  const modal = useModal()
  const setCurrentSchedule = useSetScheduleRange({ componentName: 'ConfirmDateModalContainer' })

  const setScheduleRangeForAPI = (schedule: number[]) => {
    const rangeLength = schedule[1] - schedule[0]
    const range = Array.from({ length: rangeLength + 1 }, (_, i) => i + schedule[0])
    setScheduleRange(range)
  }

  useEffect(() => {
    if (props.initialDaySelected < 0) {
      return
    }

    setIndexDateSelected(scheduleRange.indexOf(props.initialDaySelected))
  }, [props.initialDaySelected])

  useEffect(() => {
    if (!offerState?.offer?.offer_id) {
      return
    }

    setCurrentSchedule.setScheduleRange(setScheduleRangeForAPI)
  }, [offerState?.offer?.offer_id])

  const onClickConfirmDate = () => {
    setRepaymentDay(scheduleRange[indexDateSelected])
    props.onClose()
  }

  const handleLearnMore = useCallback(() => {
    modal.show({
      title: 'Second payment date',
      cta: 'Close',
      render: () => (
        <SmallText>
          Missed or late payments to Flex may cause interruptions to your rent getting paid the following month. For
          this reason, we do not recommend second payment dates to fall in the last week of the month.
        </SmallText>
      ),
    })
  }, [])

  return (
    <ConfirmDateModal
      onSelectDate={(indexDateSelected: number) => setIndexDateSelected(indexDateSelected)}
      onClickConfirmDate={onClickConfirmDate}
      isConfirmDateModalOpen={props.isOpen}
      indexDateSelected={indexDateSelected}
      onCloseConfirmDate={props.onClose}
      handleLearnMore={handleLearnMore}
      flexibleDays={scheduleRange}
    />
  )
}
