import { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { SLCType } from '@genoa/domain'
import { GetScheduleOptionsResponse, useGetScheduleOptions } from '@genoa/middle-end'
import { AxiosResponse } from 'axios'

import { useAuthState } from '../../../contexts'
import { useReduxSelector } from '../../../hooks'
import { useSecureLineOfCredit } from '../../../hooks/secure-line-of-credit'
import { RootState } from '../../../modules'
import { OfferState } from '../../../modules/flex2/offer'
import { useAnalytics, useLogger } from '../../../providers'

type UseSetScheduleRangeProps = {
  componentName: string
}

export const useSetScheduleRange = (params: UseSetScheduleRangeProps) => {
  const loggerV2 = useLogger(params.componentName)
  const { uid } = useAuthState()
  const offerState: OfferState = useReduxSelector((state: RootState) => state.offerState)
  const [{ loading: loadingScheduleOptions }, getScheduleOptions] = useGetScheduleOptions()
  const { isEnabledForSLC, isSimplifiedPaymentsEnabled, slcType } = useSecureLineOfCredit()

  const analytics = useAnalytics()

  const dayParser = (day: string) => parseInt(day.replace(/[PD]/g, ''), 10)
  // const extractDays = (monthDay: string) => {
  //   const regex = /P.*?(\d+)D/
  //   const match = monthDay.match(regex)
  //   return match ? parseInt(match[1], 10) : 2
  // }

  const setScheduleRangeForCreditBuilder = (
    response: AxiosResponse<GetScheduleOptionsResponse>,
    onSetScheduleRangeSuccess: (rangeOptions: any) => void
  ) => {
    // const rangeOptions = response?.data?.slc_schedule
    const startOne = 28
    const endOne = 3
    // const startOne = dayParser(rangeOptions.repayment_one.earliest_due_simple)
    // const endOne = extractDays(rangeOptions.repayment_one.latest_due_simple)

    analytics.logEvent(Analytics.Events.GET_SCHEDULE_OPTIONS_SCHEDULE_FETCHED, {
      location: params.componentName,
      startOne,
      endOne,
    })

    onSetScheduleRangeSuccess([startOne, endOne])
  }

  const setScheduleRangeForFaSlcSpOff = (
    response: AxiosResponse<GetScheduleOptionsResponse>,
    onSetScheduleRangeSuccess: (schedule: number[]) => void
  ) => {
    const rangeOptions = response.data.slc_schedule
    const startOne = dayParser(rangeOptions.repayment_one.earliest_due_simple)
    const endOne = 28
    const startTwo = dayParser(rangeOptions.repayment_two.earliest_due_simple)
    const endTwo = 28

    analytics.logEvent(Analytics.Events.GET_SCHEDULE_OPTIONS_SCHEDULE_FETCHED, {
      location: params.componentName,
      startOne,
      endOne,
      startTwo,
      endTwo,
    })

    onSetScheduleRangeSuccess([startOne, endOne, startTwo, endTwo])
  }

  const setScheduleRangeForFaSlcSpOn = (
    response: AxiosResponse<GetScheduleOptionsResponse>,
    onSetScheduleRangeSuccess: (schedule: number[]) => void
  ) => {
    const rangeOptions = response.data.slc_schedule
    const startOne = dayParser(rangeOptions.repayment_one.earliest_due_simple)
    const endOne = 28

    analytics.logEvent(Analytics.Events.GET_SCHEDULE_OPTIONS_SCHEDULE_FETCHED, {
      location: params.componentName,
      startOne,
      endOne,
    })

    onSetScheduleRangeSuccess([startOne, endOne])
  }

  const setScheduleRangeNormal = (
    response: AxiosResponse<GetScheduleOptionsResponse>,
    onSetScheduleRangeSuccess: (schedule: number[]) => void
  ) => {
    if (response?.data?.simple_one_repayment_schedule) {
      const rangeOptions = response.data.simple_one_repayment_schedule
      const start = rangeOptions.earliest_repayment_day_of_month
      const end = rangeOptions.latest_repayment_day_of_month

      analytics.logEvent(Analytics.Events.GET_SCHEDULE_OPTIONS_SCHEDULE_FETCHED, {
        location: params.componentName,
        start,
        end,
      })

      onSetScheduleRangeSuccess([start, end])
    }
  }
  const setScheduleRange = useCallback(
    async (onSetScheduleRangeSuccess: (schedule: number[]) => void) => {
      if (!offerState?.offer?.offer_id || !uid) {
        return
      }
      try {
        const response = await getScheduleOptions({
          offerId: offerState.offer.offer_id,
          customerPublicId: uid,
        })
        if (response.status !== 200) {
          loggerV2.error('handleGetScheduleOptions', 'Error fetching schedule options', { status: response.status })
        } else {
          if (slcType === SLCType.CREDIT_BUILDER) {
            return setScheduleRangeForCreditBuilder(response, onSetScheduleRangeSuccess)
          }
          if (isEnabledForSLC && !isSimplifiedPaymentsEnabled && response?.data?.slc_schedule) {
            setScheduleRangeForFaSlcSpOff(response, onSetScheduleRangeSuccess)
          } else if (isEnabledForSLC && response?.data?.slc_schedule) {
            setScheduleRangeForFaSlcSpOn(response, onSetScheduleRangeSuccess)
          } else {
            setScheduleRangeNormal(response, onSetScheduleRangeSuccess)
          }
        }
      } catch (error: any) {
        loggerV2.error('handleGetScheduleOptions - catch', 'Error fetching schedule options', {
          error: error?.message || '',
        })
      }
    },
    [isEnabledForSLC, isSimplifiedPaymentsEnabled]
  )

  return {
    setScheduleRange,
    loadingOptions: loadingScheduleOptions,
  }
}
