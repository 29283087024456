import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { AccountErrorCode, useQueryError } from '@genoa/state-management'
import { Stack } from '@chakra-ui/react'

import { useVerifyEmailLinkMutation } from '../../../modules/flexApi'
import { loggerV2, useAnalytics } from '../../../providers'
import { useContent } from '../../../providers/content'
import { Congrats, QuestionsAndComments } from '../../assets'
import { Headline1, SmallText, SpinnerLoading } from '../../components'
import { AppStoreButton } from '../../components/AppStoreButton'
import { PlayStoreButton } from '../../components/PlayStoreButton'
import { BasePageLayout } from '../../layouts'

const CUSTOMER_ID_QUERY_PARAM = 'cid'
const TOKEN_QUERY_PARAM = 'token'

type LocalErrorCode = AccountErrorCode | 1

function ErrorView(props: { type: LocalErrorCode }) {
  const { content } = useContent()
  const CONTENT = content.VERIFY_EMAIL_LINK

  return (
    <Stack spacing="5" alignItems="center">
      <QuestionsAndComments />
      <Headline1>{CONTENT.ERROR_TITLE}</Headline1>
      <SmallText textAlign="center">{CONTENT.ERROR_SUBTITLE}</SmallText>
    </Stack>
  )
}

const logTitle = VerifyEmailLinkView.name

export function VerifyEmailLinkView() {
  const [verifyEmail, { isLoading, isUninitialized, isSuccess, isError, error }] = useVerifyEmailLinkMutation()
  const [localErrorCode, setLocalErrorCode] = useState<LocalErrorCode>()
  const [searchParams] = useSearchParams()
  const customerId = searchParams.get(CUSTOMER_ID_QUERY_PARAM)
  const token = searchParams.get(TOKEN_QUERY_PARAM)
  const { content } = useContent()
  const CONTENT = content.VERIFY_EMAIL_LINK
  const analytics = useAnalytics()

  useEffect(() => {
    isSuccess && analytics.logEvent(Analytics.Events.VERIFY_EMAIL_LINK_SUCCESS)
  }, [isSuccess])

  useQueryError(error, {
    onFlexApiError: ({ data: { error } }) => {
      setLocalErrorCode(error.code as AccountErrorCode)
    },
    onHttpError() {
      setLocalErrorCode(1)
    },
    onAllErrors() {
      analytics.logEvent(Analytics.Events.VERIFY_EMAIL_LINK_FAILURE, { customerId, isTokenQueryParamPresent: !!token })
    },
  })

  useEffect(() => {
    if (customerId && token) {
      verifyEmail({ customerId: `${customerId}`, token: `${token}` })
    }
  }, [customerId, token])

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.VERIFY_EMAIL_SCREEN}>
      <Stack mt="20" alignItems="center">
        {(isLoading || isUninitialized) && <SpinnerLoading />}

        {!!localErrorCode && <ErrorView type={localErrorCode} />}

        {isSuccess && (
          <Stack textAlign="center" alignItems="center">
            <Congrats />
            <Stack mt="6" spacing="5">
              <Headline1>{CONTENT.TITLE}</Headline1>
              <SmallText>{CONTENT.SUBTITLE}</SmallText>
              <SmallText fontWeight="bold">{CONTENT.DOWNLOAD_TITLE}</SmallText>
            </Stack>
            <Stack
              spacing="2"
              rounded="xl"
              width="full"
              alignItems="center"
              py="8"
              px="10"
              mt="5"
              backgroundColor="gray.200"
            >
              <AppStoreButton transform="scale(0.7)" />
              <PlayStoreButton transform="scale(0.7)" />
            </Stack>
          </Stack>
        )}
      </Stack>
    </BasePageLayout>
  )
}
