import { useIterable } from '../../providers'
import { BillerPropertyDetailsWithLocation } from './biller'

export const useHandleIterableDetails = () => {
  const iterable = useIterable()

  const handleIterableDetails = ({ name, location }: BillerPropertyDetailsWithLocation) => {
    iterable.setAddressInformation({
      address_line_1: location.address_line_1,
      address_line_2: location.address_line_2 ?? undefined,
      city: location.city,
      state: location.state,
      postal_code: location.postal_code,
    })

    iterable.setPropertyInformation({
      property_name: name,
    })
  }

  return handleIterableDetails
}
