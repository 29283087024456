import { useCallback, useEffect, useMemo, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAnalytics, useLogger, useMarkdownStyles } from '../../../providers'
import { interpolate } from '../../../tools/flex-documents/interpolate'
import { MarkdownView } from '../Markdown/MarkdownView'
import { SpinnerLoading } from '../SpinnerLoading/SpinnerLoading'

type MarkdownFileProps = {
  url: string
  params?: any
  analyticsName?: string
}

export const MarkdownFile = (props: MarkdownFileProps) => {
  const logger = useLogger('MarkdownFile')
  const markdownStyles = useMarkdownStyles()
  const analytics = useAnalytics()
  const [markdownText, setMarkdownText] = useState('')
  const [loading, setLoading] = useState(true)

  const fetchMarkdownFromURL = async () => {
    try {
      const res = await fetch(props.url)
      const text = await res.text()
      if (res.ok) {
        setLoading(false)
        setMarkdownText(text)
      }
    } catch (error: any) {
      logger.warn('Uncaught error fetching markdown file from url', error?.message)
    }
  }

  useEffect(
    useCallback(() => {
      analytics.logScreenView(Analytics.Screens.MARKDOWN_FILE, { type: props.analyticsName })
      fetchMarkdownFromURL()
    }, [])
  )

  const markdown = useMemo(() => {
    if (!markdownText) {
      return ''
    }
    return interpolate(markdownText, props.params)
  }, [markdownText, props.params])

  return (
    <Container>
      {loading && <SpinnerLoading />}
      <MarkdownView styles={markdownStyles}>{markdown}</MarkdownView>
    </Container>
  )
}

const Container = styled(Box)`
  overflow-y: auto;
  margin: 0px 10px;
`
