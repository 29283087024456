import React, { useMemo } from 'react'
import { Controller } from 'react-hook-form'
import { states } from '@genoa/domain'
import { Box, Select } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { ChevronRightPurple } from '../../../../assets'
import { SmallText } from '../../../../components'

type PortalAddressEntryStateDropdownProps = {
  control: any
  name: string
  label: string
  error: any
  readonly testID: string
}

export const PortalAddressEntryStateDropdown = (props: PortalAddressEntryStateDropdownProps) => {
  const error = useMemo(() => {
    if (!props.error || !props.error.message) {
      return undefined
    }

    return props.error.message.replace(props.name, props.label)
  }, [props.error])

  const theme = useTheme()
  return (
    <Box>
      <SmallText>{props.label}</SmallText>
      <Box minH="5px" />
      <Controller
        name={props.name}
        control={props.control}
        render={({ field }) => (
          <StyledSelect
            sx={{
              '&:focus': {
                borderWidth: theme.fixedSizes.spacing_12_5,
              },
            }}
            focusBorderColor={theme.colors.jewelPurple}
            testID={props.testID}
            icon={<ChevronDown />}
            {...field}
          >
            <>
              <option key={0} value="" />

              {states.map((state, index) => (
                <option key={index + 1} value={state}>
                  {state}
                </option>
              ))}
            </>
          </StyledSelect>
        )}
      />
      <Box visibility={error ? 'visible' : 'hidden'} color={theme.colors.error}>
        <SmallText hasError={!!error}>{error}</SmallText>
      </Box>
    </Box>
  )
}

const ChevronDown = styled(ChevronRightPurple)`
  transform: rotate(90deg);
  font-size: ${(props) => props.theme.fixedSizes.md};
`
const StyledSelect = styled(Select)`
  width: 165px;
  height: 60px;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.storm};
  border-radius: ${(props) => props.theme.fixedSizes.sm};
  background-color: ${(props) => props.theme.colors.wildflower};
`
