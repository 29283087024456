import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../routing/constants'
import { RentPortalSelectionContainer } from '../../common/property-linking/RentPortalSelectionContainer'

type OnboardingRentPortalSelectionContainerProps = {}

export const OnboardingRentPortalSelectionContainer = (props: OnboardingRentPortalSelectionContainerProps) => {
  const navigate = useNavigate()

  const handlePortalNotSeen = () => {
    navigate(Routes.Onboarding.FLEX_ANYWHERE_HOW_YOU_PAY)
  }

  return (
    <RentPortalSelectionContainer
      {...props}
      analyticsScreenName={Analytics.Screens.ONBOARDING_RENT_PORTAL_SELECTION}
      analyticsEventBackClicked={Analytics.Events.RENT_PORTAL_SELECTION_BACK_CLICKED}
      analyticsEventNoPortalSeenClicked={Analytics.Events.RENT_PORTAL_SELECTION_NOT_SEEN_CLICKED}
      analyticsEventPortalSelected={Analytics.Events.RENT_PORTAL_SELECTION_PORTAL_SELECTED}
      onPortalSelected={() => {
        navigate(Routes.Onboarding.RENT_PORTAL_SIGNIN, {
          state: {
            signingInTo: 'portal',
          },
        })
      }}
      onPortalNotSeen={handlePortalNotSeen}
    />
  )
}
