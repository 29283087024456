import { logger } from '../providers/logger'

const DEFAULT_FIELD_PATHS = ['state.phone', 'state.email', 'state.name', 'state.address', 'state.paymentMethod']
const HIDDEN_FIELD_SYMBOL = 'XXXXX'

type ObjectLiteral = {
  [key: string]: any
}
const hide = (data: ObjectLiteral, field: string) => {
  const fieldsToHide = field.split('.')
  while (fieldsToHide.length > 1) {
    const currentField = fieldsToHide.shift() as keyof ObjectLiteral
    if (data[currentField]) {
      data = data[currentField]
    }
  }
  const lastField = fieldsToHide.shift() as keyof ObjectLiteral
  data[lastField] = HIDDEN_FIELD_SYMBOL
}

export const hideSensitiveFields = (rawData: ObjectLiteral, fields: string[] = DEFAULT_FIELD_PATHS) => {
  try {
    if (!fields.length || !rawData) {
      return rawData
    }
    // Just didn't want to modify the input object, but we could remove this copy and use the "rawData" instead (keep in mind the possible side-effects of it!)
    const data = JSON.parse(JSON.stringify(rawData))
    fields.forEach((field) => {
      hide(data, field)
    })
    return data
  } catch (err: any) {
    logger.error('hideSensitiveFields', JSON.stringify({ object: rawData, fields }))
  }
}
