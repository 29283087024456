import { Analytics } from '@genoa/analytics'
import { CO_CONTACT_SUPPORT, CONTACT_SUPPORT } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useContentOverhaul } from '../../../hooks'
import { HeroSpill as SupportImage } from '../../assets'
import { Headline1, PrimaryButton, Text } from '../../components'
import { BasePageLayout } from '../../layouts'

type ContactSupportProps = {
  onMessageSupport: () => unknown
  analyticsScreenName: Analytics.Screens
}

export const ContactSupport = (props: ContactSupportProps) => {
  const { onboardingEnabled } = useContentOverhaul()
  const content = onboardingEnabled ? CO_CONTACT_SUPPORT : CONTACT_SUPPORT

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH={'60px'} />
      <Flex direction="column" align="center">
        <Container align="center" justify="center">
          <SupportImage />
        </Container>

        <Box minH={'24px'} />

        <Headline1 textAlign="center">{content.HEADER}</Headline1>

        <Box minH={'24px'} />

        <Text textAlign="center" color={'dusk'}>
          {content.BODY_1}
        </Text>

        <Box minH={'24px'} />

        <Text textAlign="center" color={'dusk'} data-testid="ContactSupportBody2">
          {content.BODY_2}
        </Text>

        <Box minH={'30px'} />

        <PrimaryButton testID={'ContactSupportMessageSupport'} onClick={props.onMessageSupport}>
          {content.CTA}
        </PrimaryButton>
      </Flex>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  height: 100%;
`
