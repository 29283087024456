import React from 'react'
import { Installment } from '@genoa/domain'
import { toDollars } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import { Theme, useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useContent } from '../../../../providers/content'
import { NumberCircleOne, NumberCircleTwo } from '../../../assets'
import { ExtraSmallText, Headline2, PrimaryButton, SmallText, Text } from '../../../components'
import { BasePageLayout } from '../../../layouts'

type Approved = {
  loading?: boolean
  handleContinue: () => void
  installments: Installment[]
  processingFeePercentage: number
  membershipFee: number
}
const minProportion = 40

const normalizeProportions = (firstProportion: number, secondProportion: number) => {
  if (firstProportion < minProportion) {
    return [minProportion, 60]
  }
  if (secondProportion < minProportion) {
    return [60, minProportion]
  }
  return [firstProportion, secondProportion]
}

export const Approved = (props: Approved) => {
  const theme = useTheme()

  const {
    content: { APPROVED },
    replaceContent,
  } = useContent()

  const firstPayment = props.installments.find((installment) => {
    return installment.day === 0
  })
  const secondPayment = props.installments.find((installment) => {
    return installment.day !== 0
  })

  const firstPaymentProportion = Math.round(firstPayment?.proportion || 0)
  const secondPaymentProportion = 100 - firstPaymentProportion

  const firstPaymentAmount = firstPayment?.baseAmount || 0
  const secondPaymentAmount = secondPayment?.baseAmount || 0

  const rentAmount = firstPaymentAmount + secondPaymentAmount

  const firstPaymentAmountFormatted = toDollars(firstPaymentAmount)
  const secondPaymentAmountFormatted = toDollars(secondPaymentAmount)
  const rentAmountFormatted = toDollars(rentAmount)

  const [firstPaymentDisplayProportion, secondPaymentDisplayProportion] = normalizeProportions(
    firstPaymentProportion,
    secondPaymentProportion
  )

  const listData = [
    {
      text: replaceContent(APPROVED.LIST_ONE, {
        bill: firstPaymentAmountFormatted,
      }),
      number: <NumberCircleOne />,
    },
    {
      text: replaceContent(APPROVED.LIST_TWO, {
        bill: secondPaymentAmountFormatted,
      }),
      number: <NumberCircleTwo />,
    },
  ]

  return (
    <BasePageLayout>
      <Box minH={theme.fixedSizes.spacing_200} />
      <Headline2>{APPROVED?.HEADER}</Headline2>
      <Box minH={theme.fixedSizes.spacing_100} />
      <SmallText>
        {replaceContent(APPROVED.BODY, {
          creditLine: secondPaymentAmountFormatted,
        })}
      </SmallText>
      <Box minH={theme.fixedSizes.spacing_200} />
      <MonthlyBreakdownContainer>
        <BreakdownContainer row>
          <FirstPaymentContainer style={{ width: `${firstPaymentDisplayProportion}%` }}>
            <Box minH={theme.fixedSizes.spacing_50} />
            <PaymentNumberAmount data-testid="ApprovedFirstPaymentAmount">
              ${firstPaymentAmountFormatted}
            </PaymentNumberAmount>
            <ExtraSmallText>{APPROVED.FIRST_PAYMENT}</ExtraSmallText>
            <Box minH={theme.fixedSizes.spacing_50} />
          </FirstPaymentContainer>
          <SecondPaymentContainer style={{ width: `${secondPaymentDisplayProportion}%` }}>
            <PaymentNumberAmount color={theme.colors.white} data-testid="ApprovedSecondPaymentAmount">
              ${secondPaymentAmountFormatted}
            </PaymentNumberAmount>
            <ExtraSmallText color={theme.colors.white}>{APPROVED.SECOND_PAYMENT}</ExtraSmallText>
          </SecondPaymentContainer>
        </BreakdownContainer>
        <Box>
          <NumberListContainer>
            <NumberListHeader>{`${APPROVED.LIST_HEADER} $${rentAmountFormatted}...`}</NumberListHeader>
            {listData.map((item, index) => (
              <NumberListItem text={item.text} theme={theme} number={item.number} key={`NumberedListItem${index}`} />
            ))}
            <Box minH={theme.fixedSizes.spacing_100} />
          </NumberListContainer>
        </Box>
      </MonthlyBreakdownContainer>
      <Box minH={theme.fixedSizes.spacing_400} />
      <Box>
        <PrimaryButton
          onClick={props.handleContinue}
          testID="ApprovedCTA"
          disabled={props.loading}
          processing={props.loading}
        >
          {APPROVED.CTA}
        </PrimaryButton>
        <Box minH={theme.fixedSizes.spacing_100} />
      </Box>
    </BasePageLayout>
  )
}

const NumberListItem = ({ text, number, theme }: { text: string; number: any; theme: Theme }) => (
  <>
    <Box minH={theme.fixedSizes.spacing_100} />
    <NumberListItemContainer>
      {number}
      <NumberListText>{text}</NumberListText>
    </NumberListItemContainer>
  </>
)
const MonthlyBreakdownContainer = styled(Box)`
  background-color: ${(props) => props.theme.colors.wildflower};
  border-radius: ${(props) => props.theme.fixedSizes.spacing_100};
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
`
const BreakdownContainer = styled(Flex)`
  margin-bottom: ${(props) => props.theme.fixedSizes.spacing_100};
  border-radius: ${(props) => props.theme.fixedSizes.spacing_50};
  flex-direction: row;
`

const FirstPaymentContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.softLilac};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom-left-radius: ${(props) => props.theme.fixedSizes.spacing_50};
  border-top-left-radius: ${(props) => props.theme.fixedSizes.spacing_50};
}
`

const SecondPaymentContainer = styled(Flex)`
  background: ${(props) => props.theme.colors.jewelPurple};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-bottom-right-radius: ${(props) => props.theme.fixedSizes.spacing_50};
  border-top-right-radius: ${(props) => props.theme.fixedSizes.spacing_50};
}
`

const NumberListContainer = styled(Box)`
  padding-vertical: ${(props) => props.theme.fixedSizes.spacing_50};
`

const NumberListItemContainer = styled(Flex)`
  flex-direction: row;
`

const NumberListText = styled(SmallText)`
  padding-left: ${(props) => props.theme.fixedSizes.spacing_100};
`

const NumberListHeader = styled(Headline2)`
  font-size: ${(props) => props.theme.fixedSizes.spacing_125};
  padding-bottom: ${(props) => props.theme.fixedSizes.spacing_75};
`

const PaymentNumberAmount = styled(Text)`
  font-weight: 600;
`
