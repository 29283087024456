// US locale
// mm/dd/yyyy

export const removeMask = (date: string) => date.replace(/\D/g, '')

export const mask = (date: string): string => {
  return removeMask(date)
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1')
}

export const parseDate = (date: string): Date => {
  const unmasked = removeMask(mask(date) || '')

  const month = parseInt(unmasked.replace(/(\d{2}).*/g, '$1') || '0')
  const day = parseInt(unmasked.replace(/\d{2}(\d{2}).*/g, '$1') || '0')
  const year = parseInt(unmasked.replace(/\d{4}(\d+)/g, '$1') || '0')

  return new Date(year, month - 1, day)
}
