import { transparentize } from '@chakra-ui/theme-tools'

export default {
  baseStyle: {
    fontFamily: 'RebrandDis',
    fontSize: 'button',
    borderRadius: 'md',
  },

  variants: {
    primary: {
      bg: 'black',
      textColor: 'white',
      h: '56px',
      minH: '56px',
      fontSize: 'button',
      fontWeight: 700,
      borderRightRadius: '100px',
      borderLeftRadius: '100px',

      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },

      _hover: {
        bg: transparentize('dusk', 1.0),

        _disabled: {
          bg: 'storm',
        },
      },

      _disabled: {
        bg: 'storm',
      },
    },

    secondary: {
      bg: transparentize('black', 0.05),
      textColor: transparentize('black', 0.6),
      h: '30px',
      minH: '30px',
      fontSize: 'xs',
      fontWeight: 700,
      lineHeight: 'xs',
      borderRadius: '100px',

      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },

      _hover: {
        bg: transparentize('brand.900', 1.0),
        textColor: transparentize('white', 1.0),

        _disabled: {
          bg: transparentize('black', 0.05),
          textColor: transparentize('black', 0.6),
        },
      },
    },

    small: {
      bg: transparentize('jewelPurple', 1.0),
      textColor: transparentize('white', 1.0),
      h: '32px',
      minH: '32px',
      fontSize: 'xs',
      fontWeight: 700,
      lineHeight: 'xs',
      borderRadius: '100px',

      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },

      _hover: {
        bg: transparentize('flavender', 1.0),

        _disabled: {
          bg: transparentize('softLilac', 1.0),
        },
      },

      _disabled: {
        bg: 'softLilac',
      },
    },

    help: {
      bg: transparentize('white', 0.05),
      textColor: transparentize('black', 1.0),
      h: '30px',
      minH: '30px',
      fontSize: 'xs',
      fontWeight: 700,
      lineHeight: 'xs',

      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },

      _hover: {
        bg: transparentize('white', 0.1),

        _disabled: {
          bg: transparentize('white', 0.05),
        },
      },
    },
    inline: {
      bg: 'transparent',
      textColor: 'brand.800',
      height: 'auto',
      fontFamily: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      lineHeight: 'inherit',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: '1px',
      minWidth: 0,
      b: {
        textColor: 'jewelPurple',
        outline: 'none',
        boxShadow: 'none',
      },

      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },

      _hover: {
        bg: 'transparent',

        _disabled: {
          bg: 'transparent',
        },
      },
    },

    transparentCard: {
      bg: 'brand.100',
      textColor: 'brand.900',
      height: '34px',
      minHeight: '34px',
      lineHeight: '34px',
      fontSize: '12px',
      fontWeight: 600,
      borderRadius: '100px',

      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },

      _hover: {
        bg: transparentize('brand.900', 1.0),
        textColor: transparentize('white', 1.0),

        _disabled: {
          bg: 'grey',
        },
      },
    },
  },
}
