import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useModal } from '../contexts'
import * as Routes from '../routing/constants'

export const useNavigation = () => {
  const navigate = useNavigate()
  const modal = useModal()
  const navigateToLogIn = () => {
    navigate(Routes.Auth.PHONE)
  }

  const navigateToSignIn = () => {
    navigate(Routes.Auth.PHONE_CREATE_ACCOUNT)
  }

  const navigateToDevAuth = () => {
    navigate(Routes.Auth.DEV)
  }

  const navigateToDevAuthFromModal = () => {
    navigate(Routes.Auth.DEV)
    modal.close()
  }

  return {
    navigateToSignIn,
    navigateToLogIn,
    navigateToDevAuth,
    navigateToDevAuthFromModal,
  }
}
