import React, { Context, useContext, useMemo } from 'react'

import { useUserAccountState } from '../../modules/flex2/use-state'

export interface UserAccount {
  firstName?: string
  lastName?: string
  email?: string
  phone?: string
}

type UserAccountContextData = {
  userAccount: UserAccount
}

const UserAccountContextValues: UserAccountContextData = {
  userAccount: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  },
}

export const UserAccountContext: Context<UserAccountContextData> = React.createContext(UserAccountContextValues)

export const UserAccountProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const userAccountState = useUserAccountState()

  const userAccountContextData: UserAccountContextData = useMemo(
    () => ({
      userAccount: {
        firstName: userAccountState.user_account.first_name,
        lastName: userAccountState.user_account.last_name,
        email: userAccountState.user_account.email,
        phone: userAccountState.user_account.phone_number,
      },
    }),
    [userAccountState]
  )

  if (!children) {
    return null
  }

  return <UserAccountContext.Provider value={userAccountContextData}>{children}</UserAccountContext.Provider>
}

export const useUserAccount = () => useContext(UserAccountContext)
