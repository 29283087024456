import { useModal } from '../../../../../contexts'
import { SmallText } from '../../../../components'

export const useAcceptOfferModal = () => {
  const modal = useModal()

  const showRateLimitExceededModal = () => {
    modal.show({
      title: 'Too many attempts',
      cta: 'Close',
      render: () => (
        <SmallText>
          You&apos;ve tried too many times, and we&apos;re running into some issues processing your request. Please try
          again later.
        </SmallText>
      ),
    })
  }

  const showDataErrorModal = (content: string) => {
    modal.show({
      title: 'Something went wrong',
      cta: 'Close',
      render: () => <SmallText>{content}</SmallText>,
    })
  }

  const showGenericErrorModal = () => {
    modal.show({
      title: 'Something went wrong',
      cta: 'Try again',
      render: () => <SmallText>We couldn&apos;t process your request.</SmallText>,
    })
  }

  return {
    showRateLimitExceededModal,
    showDataErrorModal,
    showGenericErrorModal,
  }
}
