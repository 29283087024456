import React from 'react'
import { Analytics } from '@genoa/analytics'
import { CO_CONFIRM_PIN, CONFIRM_PIN } from '@genoa/screen-content'
import { Box, HStack, PinInput, PinInputField } from '@chakra-ui/react'

import { useContentOverhaul } from '../../../hooks'
import { Headline2, SecondaryButton, SmallText, Text } from '../../components'
import { BasePageLayout } from '../../layouts'

type ConfirmPINProps = {
  phoneNumber: string
  allowResend: boolean
  onPINChange: (pin: string) => unknown
  onResendCodeClick: () => unknown
  isDisabled: boolean
  pin: string
}

export const ConfirmPIN = (props: ConfirmPINProps) => {
  const { onboardingEnabled } = useContentOverhaul()
  const content = onboardingEnabled ? CO_CONFIRM_PIN : CONFIRM_PIN

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.SIGNIN_CONFIRM_PIN}>
      <Box minH="30px" />

      <Headline2 data-testid="ConfirmPinHeading">{content.HEADING}</Headline2>

      <Box minH="15px" />

      <Text>
        {content.BODY_1}
        <b data-testid="PhoneNumberText">{props.phoneNumber}.</b> {content.BODY_2}
      </Text>

      <Box minH="30px" />
      <SmallText data-testid="ConfirmPinCodeLabel">
        <b>{content.CODE_LABEL}</b>
      </SmallText>
      <Box minH="5px" />
      <HStack>
        <PinInput
          focusBorderColor="brand.900"
          isDisabled={props.isDisabled}
          placeholder=""
          onChange={props.onPINChange}
          value={props.pin}
          size="lg"
        >
          <PinInputField autoComplete="one-time-code" data-testid="OtpDigit1" />
          <PinInputField autoComplete="one-time-code" data-testid="OtpDigit2" />
          <PinInputField autoComplete="one-time-code" data-testid="OtpDigit3" />
          <PinInputField autoComplete="one-time-code" data-testid="OtpDigit4" />
          <PinInputField autoComplete="one-time-code" data-testid="OtpDigit5" />
          <PinInputField autoComplete="one-time-code" data-testid="OtpDigit6" />
        </PinInput>
      </HStack>

      <Box minH="30px" />

      <Box>
        <SecondaryButton
          disabled={!props.allowResend}
          onClick={props.onResendCodeClick}
          testID="ConfirmOtpResendCodeButton"
          variant={'small'}
        >
          {content.SEND_CODE_CTA}
        </SecondaryButton>
      </Box>
    </BasePageLayout>
  )
}
