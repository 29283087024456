import React from 'react'

import { useNavigateToOnboardingPropertySelection } from '../../../../hooks/navigation'
import { ConfirmPinContainer } from '../../auth/ConfirmPINContainer'

export const OnboardingConfirmPinContainer = () => {
  const navigateToPropertySelection = useNavigateToOnboardingPropertySelection()

  return <ConfirmPinContainer onNext={navigateToPropertySelection} />
}
