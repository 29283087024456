import { Route } from 'react-router-dom'

import {
  OnboardingCreditBuilderConfirmScheduleContainer,
  OnboardingCreditBuilderCustomizeScheduleContainer,
} from '../../views/pages/onboarding/credit-builder'
import { OnboardingCreditBuilderCongratsContainer } from '../../views/pages/onboarding/credit-builder/OnboardingCreditBuilderCongratsContainer'
import { OnboardingSLCSoftCreditCheckContainer } from '../../views/pages/onboarding/schedule-slc'
import * as Routes from '../constants'
import { getRouteSlugs } from '../utils'

const routeSlugs = getRouteSlugs(Routes.App.ONBOARDING, Routes.Onboarding)

export const SCHEDULE_SETUP_CREDIT_BUILDER_ROUTES = (
  <>
    <Route
      key="soft-credit-check"
      path={routeSlugs.SOFT_CREDIT_CHECK}
      element={<OnboardingSLCSoftCreditCheckContainer />}
    />
    <Route
      key="customize-schedule"
      path={routeSlugs.CUSTOMIZE_SCHEDULE}
      element={<OnboardingCreditBuilderCustomizeScheduleContainer />}
    />
    <Route
      key="offer-details"
      path={routeSlugs.OFFER_DETAILS}
      element={<OnboardingCreditBuilderConfirmScheduleContainer />}
    />
    <Route key="congrats" path={routeSlugs.CONGRATS} element={<OnboardingCreditBuilderCongratsContainer />} />
  </>
)
