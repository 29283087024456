import { createReducer } from '@reduxjs/toolkit'

import * as Actions from './actions'
import { BillingState } from './types'

export const billingInitialState: BillingState = {
  address: {
    city: '',
    name: '',
    state: '',
    streetAddress: '',
    zip: '',
  },
}

export const reducer = createReducer(billingInitialState, (builder) => {
  builder
    .addCase(Actions.setBillingAddressAction, (state, action) => {
      state.address = { ...action.payload }
    })
    .addCase(Actions.resetBillingAction, () => {
      return { ...billingInitialState }
    })
})
