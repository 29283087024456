import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const LineDivider = styled(Box)`
  width: 100%;
  height: 2px;
  background-color: ${(props) => props.theme.colors.cloud};
  margin-top: ${(props) => props.theme.fixedSizes.md};
  margin-bottom: ${(props) => props.theme.fixedSizes.md};
`

export const NoMarginLineDivider = styled(LineDivider)`
  margin: 0px;
`
