import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { CheckmarkCirclePurple, CirclePurple } from '../../../assets'
import { Text, TextTitle } from '../../../components'
import { Modal } from '../../../components/Modal'

export interface SimplifiedPaymentsModalProps {
  readonly visible: boolean
  readonly onClose: () => void
}

export const SimplifiedPaymentsModal = (props: SimplifiedPaymentsModalProps) => {
  return (
    <Modal
      testID="SimplifiedPaymentsModal"
      title="How does Rent Protect work?"
      canClose
      onClose={props.onClose}
      visible={props.visible}
    >
      <ModalTextContainer>
        <Text>
          Your deposit activates your credit line. Rent Protect gives you options on how you use your deposit.
        </Text>
        <Box minH="32px" />
        <SectionTitleContainer>
          <CheckmarkCirclePurple />
          <SectionTitleText>Rent Protect ON (recommended)</SectionTitleText>
        </SectionTitleContainer>
        <Text>
          We turn your deposit into a payment and use it to automatically pay off your credit line, so you won&rsquo;t
          have to make another payment at the end of the month. To use Flex again, you&rsquo;ll need to add another
          deposit next month when rent is due.
        </Text>
        <Box minH="32px" />
        <SectionTitleContainer>
          <CirclePurple />
          <SectionTitleText>Rent Protect OFF</SectionTitleText>
        </SectionTitleContainer>
        <Text>
          We&rsquo;ll process another payment at the end of the month to pay off your credit line. Your deposit that was
          used to activate your credit line will remain untouched, and you&rsquo;ll be ready to use Flex again next
          month.
        </Text>
      </ModalTextContainer>
    </Modal>
  )
}

const ModalTextContainer = styled(Box)`
  text-align: left;
`

const SectionTitleText = styled(TextTitle)`
  margin: 0px;
  margin-left: 8px;
  margin-bottom: 8px;
`

const SectionTitleContainer = styled(Flex)``
