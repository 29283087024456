import { useCallback } from 'react'
import { PaymentMethodCreateParams, Stripe, StripeCardElement } from '@stripe/stripe-js'

export type CardDetails = {
  cardLast4Digits: string
  funding: string
  brand: string
}

type PaymentMethod = {
  country: string | null | undefined
  card: CardDetails
  id: string
}

type CreatePaymentMethodProps = {
  billingDetails: PaymentMethodCreateParams.BillingDetails
  handleError: (reason: string) => void
  cardElement: StripeCardElement
  initialCardData: CardDetails
  stripe: Stripe
}

export const useCreatePaymentMethod = () => {
  const createPaymentMethod = useCallback(
    async ({
      billingDetails,
      initialCardData,
      handleError,
      cardElement,
      stripe,
    }: CreatePaymentMethodProps): Promise<PaymentMethod | void> => {
      const payload = await stripe.createPaymentMethod({
        billing_details: billingDetails,
        card: cardElement,
        type: 'card',
      })

      if (payload.error) {
        return handleError(payload.error.message || 'Unknown message')
      }

      return {
        card: {
          cardLast4Digits: payload.paymentMethod.card?.last4 || initialCardData.cardLast4Digits,
          funding: payload.paymentMethod.card?.funding || initialCardData.funding,
          brand: payload.paymentMethod.card?.brand || initialCardData.brand,
        },
        id: payload.paymentMethod?.id,
        country: payload.paymentMethod.card?.country,
      }
    },
    []
  )

  return {
    createPaymentMethod,
  }
}
