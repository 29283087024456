import { useEffect, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { BillerFlexAnywherePortal, FlexAnywhereGenericPortal } from '@genoa/domain'
import { setBillerGenericPortalAction } from '@genoa/state-management'

import { useAnalytics } from '../../providers'
import { useOutOfNetworkSkipPortalSelectionExperiment } from '../experiments'
import { useReduxAction } from '../use-redux-action'

export const useHandleSetFlexAnywherePortal = () => {
  const [flexAnywherePortal, setFlexAnywherePortal] = useState<BillerFlexAnywherePortal>()
  const { isOutOfNetworkSkipPortalSelectionEnabled } = useOutOfNetworkSkipPortalSelectionExperiment()

  const setBillerGenericPortal = useReduxAction(setBillerGenericPortalAction)
  const analytics = useAnalytics()

  useEffect(() => {
    if (isOutOfNetworkSkipPortalSelectionEnabled) {
      setFlexAnywherePortal(FlexAnywhereGenericPortal)
    }
  }, [isOutOfNetworkSkipPortalSelectionEnabled])

  useEffect(() => {
    if (flexAnywherePortal) {
      analytics.logEvent(Analytics.Events.RENT_PORTAL_SELECTION_PORTAL_SELECTED, {
        portalId: flexAnywherePortal.public_id,
      })

      setBillerGenericPortal(flexAnywherePortal)
    }
  }, [flexAnywherePortal])
}
