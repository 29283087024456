import React, { useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Close } from '../../assets'
import { PrimaryButton } from '..'
import { DatePickerContainer } from '../date-picker/DatePickerContainer'
import { Modal } from '.'

type DatePickerModalPros = {
  onSelectDate: (date: Date) => void
  errorMessage?: string
  onClose: () => void
  isOpen: boolean
  minDate?: Date
  maxDate?: Date
  date: Date
}

export const DatePickerModal = (props: DatePickerModalPros) => {
  const [dateSelected, setDateSelected] = useState<Date>(props.date)

  const theme = useTheme()

  return (
    <Modal visible={props.isOpen} onClose={props.onClose} canClose title="">
      <DatePickerModalContainer className="date-picker-modal-container" direction="column" alignItems="flex-end">
        <Box
          marginBottom={theme.fixedSizes.sm}
          marginRight={theme.fixedSizes.lg}
          marginTop={theme.fixedSizes.md}
          onClick={props.onClose}
          as="button"
        >
          <Close />
        </Box>
        <DatePickerContainer
          onSelectDate={setDateSelected}
          errorMessage={props.errorMessage}
          dateSelected={dateSelected}
          maxDate={props.maxDate}
          minDate={props.minDate}
        />
        <Box
          paddingBottom={theme.fixedSizes.md}
          paddingRight={theme.fixedSizes.lg}
          paddingLeft={theme.fixedSizes.lg}
          justifyContent="center"
          alignItems="center"
          display="flex"
          width="100%"
        >
          <PrimaryButton onClick={() => props.onSelectDate(dateSelected)}>Select birthday</PrimaryButton>
        </Box>
      </DatePickerModalContainer>
    </Modal>
  )
}

const DatePickerModalContainer = styled(Flex)`
  max-width: 100%;
  min-width: 100%;
`
