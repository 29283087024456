import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { NoMarginLineDivider } from '../Layout'
import { SmallText } from '../Typography'

interface PaymentScheduleFrameTitleProps {
  readonly title: string
  readonly autopayStatus?: boolean
}

export const PaymentScheduleFrameTitle = (props: PaymentScheduleFrameTitleProps) => (
  <TitleContainer>
    <TitleRow>
      <TitleText>{props.title}</TitleText>
    </TitleRow>
    <NoMarginLineDivider />
  </TitleContainer>
)

const TitleContainer = styled(Flex)`
  flex-direction: column;
  gap: ${({ theme }) => theme.fixedSizes.spacing_75};
  width: 100%;
`

const TitleRow = styled(Flex)`
  align-items: center;
  justify-content: space-between;
`

const TitleText = styled(SmallText)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`
