import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from '../../../../../../../providers'
import { BillingAddress } from '../billing-address-types'
import { BillingAddressDetails } from './BillingAddressDetails'
import { useBillingAddressDetails } from './use-billing-address-details-data'

type BillingAddressDetailsContainerProps = {
  onPressAddCTA: (billingAddress: BillingAddress) => void
  isProcessingRegisterCard: boolean
  billingAddressError: string
  updateBillingNavigate: string
}

type BillingAddressDetailsContainerNavigationParams = {
  billingAddressUpdated?: BillingAddress
}

export const BillingAddressDetailsContainer = (props: BillingAddressDetailsContainerProps) => {
  const location = useLocation()
  const locationState = location.state ? (location.state as BillingAddressDetailsContainerNavigationParams) : null

  const billingAddressDetails = useBillingAddressDetails()

  const billingAddressInfo = locationState?.billingAddressUpdated || billingAddressDetails

  const analytics = useAnalytics()
  const navigate = useNavigate()

  const handlePressChangeBillingAddress = useCallback(() => {
    analytics.logEvent(Analytics.Events.CHANGE_BILLING_ADDRESS_CLICKED)
    navigate(props.updateBillingNavigate, {
      state: {
        billingAddressInfo,
      },
    })
  }, [billingAddressInfo])

  const handlePressAddCTA = useCallback(() => {
    props.onPressAddCTA(billingAddressInfo)
  }, [props.onPressAddCTA, billingAddressInfo])

  return (
    <BillingAddressDetails
      onPressAddCTA={handlePressAddCTA}
      onPressChangeBillingAddress={handlePressChangeBillingAddress}
      isProcessingRegisterCard={props.isProcessingRegisterCard}
      billingAddress={billingAddressInfo}
      data-dd-action-name="Billing Address"
      billingAddressError={props.billingAddressError}
    />
  )
}
