import { OutOfNetworkSkipPortalSelectionExperiment } from '@genoa/experiments'

import { useExperimentVariant } from '../use-experiment-variant'

export const useOutOfNetworkSkipPortalSelectionExperiment = () => {
  const OutOfNetworkSkipPortalSelectionExperimentVariant = useExperimentVariant(
    OutOfNetworkSkipPortalSelectionExperiment
  )
  const isOutOfNetworkSkipPortalSelectionEnabled =
    OutOfNetworkSkipPortalSelectionExperimentVariant ===
    OutOfNetworkSkipPortalSelectionExperiment.variantValues.Treatment
  return {
    isOutOfNetworkSkipPortalSelectionEnabled,
  }
}
