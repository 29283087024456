import React, { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes as RouterRoutes } from 'react-router-dom'

import { useAuthState } from '../contexts'
import { useIdentifyUser } from '../hooks'
import { useAccount } from '../hooks/use-account'
import { useUserAccountState } from '../modules/flex2/use-state'
import { OnboardingStatus, setOnboardingStatus, setUserSignupStatus } from '../providers'
import { AcceptTermsLinkView } from '../views/pages/accept-terms-link'
import { AppRedirectView } from '../views/pages/app-redirect'
import { NotFound } from '../views/pages/errors/NotFound'
import { HealthcheckPage } from '../views/pages/Healthcheck'
import { TryAgainView } from '../views/pages/TryAgainView'
import { VerifyEmailLinkView } from '../views/pages/verify-email-link'
import { AuthRoutes } from './AuthRoutes'
import * as Routes from './constants'
import { EmbedLandingRoutes } from './EmbedLandingRoutes'
import { EmbedRoutes } from './EmbedRoutes'
import { OnboardingRoutesContainer } from './onboarding/OnboardingRoutesContainer'
import { PrivateRoute } from './PrivateRoute'
import { PublicOnlyRoute } from './PublicOnlyRoute'

type RoutingProps = {}

export const Routing: React.FC = (_props: RoutingProps) => {
  const { isAnonymous } = useAuthState()
  const { isActive } = useAccount()

  const userAccount = useUserAccountState()

  // Set status related to amplitude analytics, so we can track onboarding and sign up on events
  useEffect(() => {
    // If a customer is already logged in
    if (!isAnonymous && userAccount.initialized) {
      setUserSignupStatus()
    }
    // If a customer is onboarding
    if (!isAnonymous && userAccount.initialized && !isActive) {
      setOnboardingStatus(OnboardingStatus.Onboarding)
    }
    // If a customer finished onboarding
    if (!isAnonymous && isActive) {
      setOnboardingStatus(OnboardingStatus.Active)
    }
  }, [isAnonymous, userAccount.initialized])

  useIdentifyUser()

  return (
    <BrowserRouter>
      <RouterRoutes>
        <Route path="/" element={<Navigate replace to={Routes.Auth.PHONE} />} />
        <Route path={Routes.App.HEALTHCHECK} element={<HealthcheckPage />} />
        <Route path={Routes.App.ACCEPT_TERMS} element={<AcceptTermsLinkView />} />
        <Route path={Routes.App.VERIFY_EMAIL} element={<VerifyEmailLinkView />} />
        <Route path={Routes.App.APP_REDIRECT} element={<AppRedirectView />} />
        <Route path={Routes.App.TRY_AGAIN} element={<TryAgainView />} />
        <Route path={Routes.App.LANDING} element={<Navigate replace to={Routes.Auth.PHONE} />} />
        <Route
          path={`${Routes.App.AUTH}/*`}
          element={
            <PublicOnlyRoute>
              <AuthRoutes />
            </PublicOnlyRoute>
          }
        />
        <Route
          path={`${Routes.App.EMBED}/*`}
          element={
            <PrivateRoute>
              <EmbedRoutes />
            </PrivateRoute>
          }
        />
        <Route path={`${Routes.App.EMBED_LANDING}/*`} element={<EmbedLandingRoutes />} />
        <Route
          path={`${Routes.App.ONBOARDING}/*`}
          element={
            <PrivateRoute>
              <OnboardingRoutesContainer />
            </PrivateRoute>
          }
        />
        <Route path="*" Component={NotFound} />
      </RouterRoutes>
    </BrowserRouter>
  )
}
