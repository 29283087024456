import React, { useEffect, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { PaymentInformation } from '@genoa/utils'

import { useReduxSelector } from '../../../../hooks'
import { useSecureLineOfCredit } from '../../../../hooks/secure-line-of-credit'
import { useSetFeatureStatusState } from '../../../../hooks/use-set-feature-status-state'
import { RootState } from '../../../../modules'
import { useAnalytics, useEnhancedTracking, useFees } from '../../../../providers'
import { ApprovedModal } from './ApprovedModal'
import { ApprovedModalSLC } from './ApprovedModalSLC'

type ApprovedModalContainerProps = {
  paymentInformation: PaymentInformation
}

export const ApprovedModalContainer = ({ paymentInformation }: ApprovedModalContainerProps) => {
  const [isApprovedModalOpen, setIsApprovedModalOpen] = useState(true)
  const analytics = useAnalytics()
  const { trackClickedContinueOnLoadApprovalModal } = useEnhancedTracking()
  const fees = useFees()
  const { canUserModifyCreditLineUtilization } = useSetFeatureStatusState()
  const { isEnabledForSLC } = useSecureLineOfCredit()
  const rentAmount = useReduxSelector(({ onboarding }: RootState) => onboarding?.rentAmount?.amount)

  useEffect(() => {
    if (fees.creditUtilization) {
      analytics.setUserProperty(Analytics.UserProperties.NEW_RISK_POLICY_ENABLED, 'enabled')

      analytics.logEvent(Analytics.Events.NEW_RISK_POLICY_ENABLED, {
        creditLessThanHalfRent: paymentInformation.isCreditBelowThreshold,
        firstPaymentProportion: paymentInformation.firstPaymentDisplayProportion,
        secondPaymentProportion: paymentInformation.secondPaymentDisplayProportion,
        creditUtilization: fees.creditUtilization,
      })
    }
  }, [fees.creditUtilization])

  useEffect(() => {
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_OPEN_APPROVED_MODAL)
  }, [])

  const handleContinue = () => {
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_CLICK_CONTINUE_APPROVED_MODAL)
    trackClickedContinueOnLoadApprovalModal(rentAmount)

    setIsApprovedModalOpen(false)
  }

  if (isEnabledForSLC) {
    return (
      <ApprovedModalSLC
        onClickContinue={handleContinue}
        visible={isApprovedModalOpen}
        rentAmountFormatted={paymentInformation.rentAmountFormatted}
        showModifyUtilizationText={canUserModifyCreditLineUtilization}
      />
    )
  }

  return (
    <ApprovedModal
      onClickContinue={handleContinue}
      visible={isApprovedModalOpen}
      firstPaymentAmountFormatted={paymentInformation.firstPaymentAmountFormatted}
      firstPaymentDisplayProportion={paymentInformation.firstPaymentDisplayProportion ?? 0}
      secondPaymentAmountFormatted={paymentInformation.secondPaymentAmountFormatted}
      secondPaymentDisplayProportion={paymentInformation.secondPaymentDisplayProportion ?? 0}
      rentAmountFormatted={paymentInformation.rentAmountFormatted}
      showModifyUtilizationText={canUserModifyCreditLineUtilization}
    />
  )
}
