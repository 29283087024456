import { useEffect, useReducer, useState } from 'react'
import type { InAppTrackRequestParams, track as trackType } from '@iterable/web-sdk'

import { useRetryLock } from '../../hooks'
import { logger } from '../logger'
import { IterableEvent } from './user-events'

interface ResetAction {
  readonly type: 'reset'
}

interface AddEventAction {
  readonly type: 'add'
  readonly event: InAppTrackRequestParams
}

interface UpdateAction {
  readonly type: 'update'
  readonly events: readonly InAppTrackRequestParams[]
}

type DeferredEventsAction = ResetAction | AddEventAction | UpdateAction

// how to do events one at a time tho?
const deferredEventsReducer = (
  state: readonly InAppTrackRequestParams[],
  action: DeferredEventsAction
): readonly InAppTrackRequestParams[] => {
  switch (action.type) {
    case 'add':
      return state.concat([action.event])
    case 'update':
      return action.events
    case 'reset':
    default:
      return []
  }
}

export interface UseDeferredEventsOptions {
  readonly trackEvent: typeof trackType
  readonly locked: boolean
}

export const useDeferredEvents = ({ trackEvent, locked }: UseDeferredEventsOptions) => {
  const [inflightEvent, setInflightEvent] = useState<InAppTrackRequestParams | undefined>(undefined)
  const [events, dispatch] = useReducer(deferredEventsReducer, [])
  const { lock: retryLock, incrementFailures, resetFailures } = useRetryLock()

  const reset = () => {
    dispatch({ type: 'reset' })
  }

  useEffect(() => {
    if (!locked && !inflightEvent && events.length > 0) {
      const [event, ...otherEvents] = events
      setInflightEvent(event)
      dispatch({ type: 'update', events: otherEvents })
    }
  }, [locked, retryLock, events, inflightEvent])

  useEffect(() => {
    if (!locked && !retryLock && inflightEvent) {
      trackEvent(inflightEvent)
        .then((response) => {
          if (response.status === 200) {
            logger.log('useDeferredEvents', `tracked event ${inflightEvent.eventName} successfully`)
            setInflightEvent(undefined)
            resetFailures()
          }
        })
        .catch((error: any) => {
          logger.error(
            'useDeferredEvents',
            `failed to track event ${inflightEvent.eventName}, error: ${error?.message}`
          )
          incrementFailures()
        })
    }
  }, [locked, inflightEvent, retryLock])

  return {
    addEvent: (event: IterableEvent) => dispatch({ type: 'add', event }),
    reset,
  }
}
