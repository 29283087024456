import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { SLCType } from '@genoa/domain'

import * as Routes from '../../routing/constants'
import { useSecureLineOfCredit } from '../secure-line-of-credit'
import { useIsEmbed } from './use-is-embed'

export const useBlockCreditBuilderForEmbed = () => {
  const navigate = useNavigate()
  const { slcType } = useSecureLineOfCredit()
  const isCreditBuilderEnabled = slcType === SLCType.CREDIT_BUILDER
  const isEmbed = useIsEmbed()

  useEffect(() => {
    if (isCreditBuilderEnabled && isEmbed) {
      navigate(Routes.Embed.BUILD_CREDIT_IN_APP, { replace: true })
    }
  }, [isCreditBuilderEnabled, isEmbed])
}
