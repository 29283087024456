import { Button, ButtonProps } from '@chakra-ui/react'

interface InlineButtonProps extends ButtonProps {
  testID?: string
}

export const InlineButton = (props: InlineButtonProps) => {
  const { testID, ...restProps } = props

  return (
    <Button
      variant="inline"
      display={'inline-block'}
      maxWidth={'100%'}
      whiteSpace={'normal'}
      textAlign={'left'}
      data-testid={testID}
      _focusVisible={{
        boxShadow: '0px 0px 0px 2px white',
        outline: '2px solid #1C53BA',
        outlineOffset: '2px',
      }}
      {...restProps}
    >
      {props.children}
    </Button>
  )
}
