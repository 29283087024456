import { IntegrationType, OON_BILLER_ID } from '@genoa/domain'

import { RootState } from '../../modules'
import { useAccount } from '../use-account'
import { useReduxSelector } from '../use-redux-selector'

export const useFlexAnywhereUser = () => {
  const genericPortal = useReduxSelector((state: RootState) => state.propertyLinking.genericPortal)
  const openSearchProperty = useReduxSelector((state: RootState) => state.propertyLinking.openSearchProperty)
  const { billerConnection } = useAccount()

  const isOutOfNetwork =
    openSearchProperty?.is_out_of_network || openSearchProperty?.integration_type === IntegrationType.FLEX_ANYWHERE

  return (
    isOutOfNetwork ||
    genericPortal?.public_id === OON_BILLER_ID ||
    billerConnection?.biller?.public_id === OON_BILLER_ID
  )
}
