import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export const Table = styled(Flex)`
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.mercuryGrey};
  border-radius: ${(props) => props.theme.radii.lg};
`
export const TableRow = styled(Flex)<{ alignCenter?: boolean }>`
  flex: 1;
  flex-direction: ${(props) => props.flexDirection || 'row'};
  justify-content: space-between;
  align-items: ${(props) => (props.alignCenter ? 'center' : 'left')};
  padding: 15px;
`
export const TableSeparator = styled(Flex)`
  background-color: ${(props) => props.theme.colors.mercuryGrey};
  height: 1px;
  width: 100%;
`
