import { useEffect } from 'react'

import { useAuthState } from '../../../contexts'
import { useLazyGetAccountQuery } from '../../../modules/flexApi'

export function useGetAccount() {
  const [getAccountQuery, { data: getAccountData, isLoading: isGetAccountLoading }] = useLazyGetAccountQuery()
  const data = getAccountData?.data
  const { uid } = useAuthState()

  useEffect(() => {
    if (uid) {
      getAccountQuery({ customerId: uid })
    }
  }, [])

  return { data, isGetAccountLoading }
}
