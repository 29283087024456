import { useNavigate } from 'react-router-dom'

import { ProductType, useProductType } from '../../providers/product-type'
import * as Routes from '../../routing/constants'

export const useNavigateFromOnboardingPropertySelection = () => {
  const navigate = useNavigate()
  const productType = useProductType()

  /**
   * Move-in product does not have an associated rent amount
   * so move directly to KYC.
   */
  const route =
    productType === ProductType.MOVE_IN ? Routes.Onboarding.SOFT_CREDIT_CHECK : Routes.Onboarding.RENT_AMOUNT

  return () => navigate(route)
}
