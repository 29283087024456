import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BillerType, useGetStatus } from '@genoa/middle-end'
import { OfferStatus } from '@genoa/state-management'
import { GeneralApiErrorCodes, useQueryError } from '@genoa/state-management'
import { getDateTime } from '@genoa/utils'
import { DateTime } from 'luxon'

import { useAuthState } from '../../../../../../contexts'
import { useCheckPayNowEligibility } from '../../../../../../hooks/embed/use-check-pay-now-eligibility'
import { useResolveCustomerType } from '../../../../../../hooks/flex2/customer-status/use-resolve-customer-type'
import { useAccountStates } from '../../../../../../hooks/flex2/identity/use-account-states'
import { useReduxAction } from '../../../../../../hooks/use-redux-action'
import {
  resetCustomerStatusAction,
  ResetLoadedPayload,
  setCustomerStatusAction,
} from '../../../../../../modules/flex2/customer-status'
import { useCustomerStatusState } from '../../../../../../modules/flex2/use-state'
import { useLogger } from '../../../../../../providers'
import * as Routes from '../../../../../../routing/constants'
import { useGetAccount } from './use-get-account'

export const useCurrentGetCustomerStatus = () => {
  const navigate = useNavigate()
  const [, getStatus] = useGetStatus()

  // This hack needed to fix a weird edge case where the request aborts if there is one already in flight.
  // this causes an error to flash on the rent screen for a second
  const [inFlight, setInFlight] = useState(false)
  const [customerStatusLoaded, setCustomerStatusLoaded] = useState(false)

  const setCustomerStatusState = useReduxAction(setCustomerStatusAction)
  const resetCustomerStatus = useReduxAction<ResetLoadedPayload | void>(resetCustomerStatusAction as any)

  const today = getDateTime()
  const { resolveCustomerType } = useResolveCustomerType()
  const flexAnywhereBillerTypes = [BillerType.OUT_OF_NETWORK, BillerType.FLEX_ANYWHERE]
  const loggerV2 = useLogger('useSetFeatureStatusState')

  const { uid, isAnonymous } = useAuthState()

  const { resolveCustomerStatusAccountState } = useAccountStates()
  const { customer, billerConnection, getAccountError, isAccountActive, getAccountQuery } = useGetAccount()
  const offerStatus = billerConnection?.offer_status
  const { embedPayNowEligibility } = useCheckPayNowEligibility()
  const {
    customerStatus: { actual_amount: actualAmount },
  } = useCustomerStatusState()

  async function doFetch(date: DateTime) {
    const queryDate = date.toFormat('yyyy-MM-dd')
    const response = await getStatus({
      customerId: customer?.public_id || '',
      billerAccountPublicId: billerConnection?.biller_account_public_id || '',
      queryDate,
    })
    if (response.status !== 200) {
      loggerV2.warn(
        'useCurrentGetCustomerStatus - doFetch',
        response.statusText || `${response.status} for ${queryDate}`
      )
      resetCustomerStatus({ loaded: true })
      navigate(Routes.Embed.CONGRATS, { state: { errorCode: 'default' } })
      return null
    }
    return response
  }

  async function refreshCurrentCustomerStatus() {
    if (!inFlight && isAccountActive) {
      setInFlight(true)
      return doFetch(today.set({ day: 1 }))
        .then((response) => {
          if (response) {
            const customerStatus = { ...response.data }
            if (customerStatus?.actual_amount === 0) {
              return navigate(Routes.Embed.SERVICE_ISSUE, { replace: true })
            }

            setCustomerStatusState({
              initialized: true,
              loaded: true,
              customerStatus,
              accountState: resolveCustomerStatusAccountState(response.data),
              userType: resolveCustomerType(response.data),
              isFlexAnywhere: flexAnywhereBillerTypes.includes(response.data.biller_type),
            })
          }
          setCustomerStatusLoaded(true)
        })
        .catch((error: any) => {
          loggerV2.error('useCurrentGetCustomerStatus - refreshCurrentCustomerStatus', `error: ${error?.message}`)
          setCustomerStatusLoaded(false)
          navigate(Routes.Embed.CONGRATS, { state: { errorCode: 'default' } })
        })
        .finally(() => {
          setCustomerStatusLoaded(true)
          setInFlight(false)
        })
    }
  }

  useEffect(() => {
    if (embedPayNowEligibility && uid && !isAnonymous) {
      getAccountQuery({ customerId: uid! })
    }
  }, [embedPayNowEligibility])

  useEffect(() => {
    if (embedPayNowEligibility && isAccountActive) {
      refreshCurrentCustomerStatus()
    }
  }, [embedPayNowEligibility, isAccountActive])

  useEffect(() => {
    if (offerStatus && offerStatus !== OfferStatus.ACCEPTED) {
      navigate(Routes.Embed.CONGRATS, { state: { errorCode: 'default' } })
    }
  }, [offerStatus])

  useQueryError(getAccountError, {
    onFlexApiError({ data: { error } }) {
      if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        navigate(Routes.Embed.CONGRATS, { state: { errorCode: GeneralApiErrorCodes.RATE_LIMIT } })
        return true
      }
    },
    onAllErrors() {
      navigate(Routes.Embed.CONGRATS, { state: { errorCode: 'default' } })
    },
  })

  let isCustomerStatusReady = !!embedPayNowEligibility && !!isAccountActive && !!actualAmount && !!customerStatusLoaded

  return { isCustomerStatusReady }
}
