import { useState } from 'react'
import { Analytics } from '@genoa/analytics'

import { useAuthState } from '../../contexts'
import { useConvertOfferToCreditBuilderMutation } from '../../modules/flexApi'
import { useAnalytics } from '../../providers'
import { useGetPricing } from '../flex2/pricing'
import { useGetOffer } from '../flex2/risk'
import { useSecureLineOfCredit } from '../secure-line-of-credit'
import { useAccount } from '../use-account'

export const useConvertCreditBuilderOffer = () => {
  const analytics = useAnalytics()
  const { uid } = useAuthState()
  const { billerConnection } = useAccount()
  const { refetchSLC } = useSecureLineOfCredit()

  const { getOffer } = useGetOffer()
  const { getPricingOffer } = useGetPricing()
  const [convertOfferToCreditBuilder] = useConvertOfferToCreditBuilderMutation()

  const [isLoading, setIsLoading] = useState(false)

  const convertOffer = async () => {
    analytics.logEvent(Analytics.Events.UPSELL_CONVERT_CB_OFFER)

    setIsLoading(true)
    try {
      const response = await convertOfferToCreditBuilder({ customerId: uid! }).unwrap()

      if (!response.ok || response.error) {
        analytics.logEvent(Analytics.Events.UPSELL_CONVERT_CB_OFFER_FAIL)
        return false
      }

      analytics.logEvent(Analytics.Events.UPSELL_CONVERT_CB_OFFER_SUCC)
      await getOffer(billerConnection?.biller_account_public_id!)
      await getPricingOffer(billerConnection?.biller_account_public_id!)
      await refetchSLC()

      return true
    } catch {
      analytics.logEvent(Analytics.Events.UPSELL_CONVERT_CB_OFFER_FAIL)
    } finally {
      setIsLoading(false)
    }

    return false
  }

  return { convertOffer, isLoading }
}
