import React from 'react'
import { DEV_MENU as content } from '@genoa/screen-content'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { ChevronRight } from '../../assets'
import { LargeText } from '../../components'

type DevMenuProps = {
  navigateToDevAuth: () => void
}

export const DevMenu = (props: DevMenuProps) => {
  const theme = useTheme()
  const onDevAuth = () => {
    props.navigateToDevAuth()
  }

  return (
    <Box>
      <OptionWrapper>
        <ListItem onClick={onDevAuth} as="button" data-testid="DevAuthListItem">
          <LargeText>{content.DEV_AUTH}</LargeText>
          <ChevronRight />
        </ListItem>
      </OptionWrapper>
      <Separator />
      <Box minH={theme.fixedSizes.md} />
    </Box>
  )
}

const OptionWrapper = styled(Box)`
  width: 100%;
`

const ListItem = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
`

const Separator = styled(Box)`
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  margin-top: ${(props) => props.theme.fixedSizes.md};
`
