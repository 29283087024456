import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useOutOfNetworkSkipPortalSelectionExperiment } from '../../../../hooks'
import * as Routes from '../../../../routing/constants'
import { PortalAddressEntryContainer } from '../../common/property-linking/portal-address-entry'

type OnboardingPortalAddressEntryContainerProps = {}

export const OnboardingPortalAddressEntryContainer = (props: OnboardingPortalAddressEntryContainerProps) => {
  const navigate = useNavigate()
  const { isOutOfNetworkSkipPortalSelectionEnabled } = useOutOfNetworkSkipPortalSelectionExperiment()

  const handleOnContinue = () => {
    if (isOutOfNetworkSkipPortalSelectionEnabled) {
      navigate(Routes.Onboarding.FLEX_ANYWHERE_HOW_YOU_PAY, { replace: true })
    } else {
      navigate(Routes.Onboarding.RENT_PORTAL_SELECTION, { replace: true })
    }
  }

  return (
    <PortalAddressEntryContainer
      {...props}
      analyticsScreen={Analytics.Screens.ONBOARDING_RENT_PORTAL_ADDRESS}
      onContinueEvent={Analytics.Events.RENT_PORTAL_ADDRESS_CONTINUE_CLICKED}
      onContinue={handleOnContinue}
    />
  )
}
