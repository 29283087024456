import React from 'react'
import { Input } from '@chakra-ui/react'

type PhoneInputProps = {
  phone?: string
  onPhoneChange: (phone: string) => unknown
  background?: string
  foreground?: string
  testID?: string
  autoComplete?: string
}

export const PhoneInput = (props: PhoneInputProps) => {
  const handleInputChangeEvent = (evt: React.ChangeEvent<HTMLInputElement>) => props.onPhoneChange(evt.target.value)

  return (
    <Input
      data-testid={props.testID}
      type="tel"
      placeholder="(xxx) xxx-xxxx"
      value={props.phone}
      onChange={handleInputChangeEvent}
      backgroundColor={props.background}
      color={props.foreground}
      autoComplete={props.autoComplete}
    />
  )
}
