import { useNavigate } from 'react-router-dom'

import * as Routes from '../../routing/constants'
import { PrefillRedirects, usePrefills } from '../prefills/use-prefills'

/**
 * helper hook to consolidate the logic that handles prefills and
 * sends a user to the start of property selection
 *
 * in a mobile implementation we would need a `navigateToDashboardPropertySelection` equivalent
 */
export const useNavigateToOnboardingPropertySelection = () => {
  const navigate = useNavigate()
  const { handlePrefill } = usePrefills()

  const prefillRedirects: PrefillRedirects = {
    navigateToAddressSelection: () => navigate(Routes.Onboarding.ADDRESS),
    navigateToRentPortalSignIn: () => navigate(Routes.Onboarding.RENT_PORTAL_SIGNIN),
    navigateToUnitSelection: () => navigate(Routes.Onboarding.DIRECT_INTEGRATION_UNIT),
    navigateToConfirmAddress: () => navigate(Routes.Onboarding.DIRECT_INTEGRATION_CONFIRMATION),
  }

  return () => handlePrefill(prefillRedirects)
}
