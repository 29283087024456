import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { ActionCreatorWithPayload } from '@reduxjs/toolkit'

export function useReduxAction<Payload>(action: ActionCreatorWithPayload<Payload>) {
  const dispatch = useDispatch()
  const dispatcher = useCallback((payload: Payload) => dispatch(action(payload)), [action, dispatch])

  return dispatcher
}
