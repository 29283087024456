import { useMemo, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { SmallText } from '../Typography'

type ToggleTabsProps = {
  defaultTab?: number
  onChange?: (id: number) => void
  tabs: string[]
  testID?: string
}

export const ToggleTabs = (props: ToggleTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(props.defaultTab || 0)

  const tabsCount = useMemo(() => props.tabs.length, [props.tabs])
  const changeTab = (index: number) => {
    setSelectedTab(index)
    props.onChange?.(index)
  }

  return (
    <ToggleContainer testID={props.testID}>
      <ToggleKnobContainer>
        <ToggleKnob selected={selectedTab} count={tabsCount} />
      </ToggleKnobContainer>
      <ToggleTextContainer>
        {props.tabs.map((tab, index) => (
          <TextContainerBox key={tab} onClick={() => changeTab(index)} count={tabsCount} label={tab}>
            <TextToggle selected={index === selectedTab}>{tab}</TextToggle>
          </TextContainerBox>
        ))}
      </ToggleTextContainer>
    </ToggleContainer>
  )
}

const ToggleContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.cloud};
  border-radius: ${({ theme }) => theme.fixedSizes.spacing_200};
  flex-grow: 1;
  height: ${({ theme }) => theme.fixedSizes.spacing_300};
  justify-content: center;
  padding: ${({ theme }) => theme.fixedSizes.spacing_25};
  position: relative;
`

const ToggleTextContainer = styled(Flex)`
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-around;
  position: relative;
  z-index: 3;
`

const ToggleKnobContainer = styled(Box)`
  left: ${({ theme }) => theme.fixedSizes.spacing_25};
  position: absolute;
  right: ${({ theme }) => theme.fixedSizes.spacing_25};
  top: ${({ theme }) => theme.fixedSizes.spacing_25};
  z-index: 2;
`

const ToggleKnob = styled(Box)<{ selected: number; count: number }>`
  background-color: ${({ theme }) => theme.colors.white};
  border: ${({ theme }) => theme.fixedSizes.spacing_6_25} solid #0000000a;
  border-radius: ${({ theme }) => theme.fixedSizes.spacing_200};
  height: ${({ theme }) => theme.fixedSizes.spacing_250};
  position: absolute;
  top: 0;
  transform: translate3d(${({ selected }) => selected * 100}%, 0, 0);
  transition: all 0.5s ease;
  width: ${({ count }) => 100 / count}%;
`

const TextContainerBox = styled(Flex)<{ count: number }>`
  align-items: center;
  cursor: pointer;
  height: 100%;
  justify-content: center;
  width: ${({ count }) => 100 / count}%;
`

const TextToggle = styled(SmallText)<{ selected: boolean }>`
  color: ${({ selected, theme }) => (selected ? theme.colors.jewelPurple : theme.colors.dusk)};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: ${({ theme }) => theme.fixedSizes.spacing_200};
`
