import { ReactNode, useEffect, useRef, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'
import { EmbedPayNowEligibilityFailureCode } from '@genoa/domain'
import { EMBED_SIGNUP as content } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useEmbedFlow, useIsInEmbedFrame } from '../../../../../../hooks'
import { useFlexLinks } from '../../../../../../providers'
import { breakpoints } from '../../../../../../theme/media/queries'
import { ExtraSmallText, Headline2 } from '../../../../../components/Typography'
import { BasePageLayout, HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from '../../../../../layouts'

const IFRAME_MAX_HEIGHT = 1000
const FIXED_MODAL_CONTAINER_HEIGHT = 160

interface RentPaidLandingProps {
  children: ReactNode
  cta: string
  failureCode: EmbedPayNowEligibilityFailureCode.SIGNUP | EmbedPayNowEligibilityFailureCode.COMPLETE_SIGNUP
  onSubmit: () => void
}

const checkIsPhone = (): boolean => window.innerWidth <= breakpoints.phone

export const LandingContainer = (props: RentPaidLandingProps) => {
  const embedFlow = useEmbedFlow()
  const isInEmbedFrame = useIsInEmbedFrame()
  const links = useFlexLinks()
  const [isPhone, setIsPhone] = useState(checkIsPhone())
  const headerHeight = isPhone ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT_DESKTOP
  const isSignup = props.failureCode === EmbedPayNowEligibilityFailureCode.SIGNUP

  const onOpenPrivacyPolicy = () => {
    links.open(FlexLinks.privacyPolicy)
  }

  const onOpenPrivacyNotice = () => {
    links.open(FlexLinks.privacyNotice)
  }

  const onOpenFlexHome = () => {
    links.open(FlexLinks.helpHome)
  }

  const [isScrollVisible, setIsScrollVisible] = useState(false)
  const contentRef = useRef<HTMLDivElement>()
  const scrollRef = useRef<HTMLDivElement>()
  const actionRef = useRef<HTMLDivElement>()
  const actionRefHeight = useRef<number>(FIXED_MODAL_CONTAINER_HEIGHT + headerHeight)

  const getIFrameHeight = () => {
    const height = window.innerHeight
    if (height > IFRAME_MAX_HEIGHT) {
      return IFRAME_MAX_HEIGHT
    }
    return height
  }

  const [iFrameHeight, setIFrameHeight] = useState(getIFrameHeight())
  const scrollHeight = iFrameHeight - actionRefHeight.current

  const onSetScrollVisibile = () => {
    const contentElem = contentRef.current
    const scrollContainerElem = scrollRef.current
    const actionElem = actionRef.current

    if (contentElem && scrollContainerElem && actionElem) {
      actionRefHeight.current = actionElem.offsetHeight + headerHeight || 0
      const contentHeight = contentElem.scrollHeight || 0
      const containerHeight = scrollContainerElem.offsetHeight || 0
      setIsScrollVisible(contentHeight > containerHeight)
    }
  }

  useEffect(() => {
    const onResize = () => {
      setIsPhone(checkIsPhone())
      setIFrameHeight(getIFrameHeight())
      onSetScrollVisibile()
    }

    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [iFrameHeight])

  useEffect(() => {
    onSetScrollVisibile()
  }, [headerHeight])

  const analyticsScreenName = isSignup ? Analytics.Screens.EMBED_DYN_INT_NEW : Analytics.Screens.EMBED_DYN_INT_EXISTING

  return (
    <BasePageLayout
      analyticsScreenName={analyticsScreenName}
      analyticsScreenParams={{ embedFlow }}
      isEmbedSignup={true}
    >
      <BodyScrollArea scrollHeight={scrollHeight} isInEmbedFrame={isInEmbedFrame} ref={scrollRef}>
        <Container isInEmbedFrame={isInEmbedFrame} ref={contentRef}>
          <Content>
            {props.children}
            <BoxSpace />
          </Content>
          <GetStartedContainer isInEmbedFrame={isInEmbedFrame} isScrollVisible={isScrollVisible} ref={actionRef}>
            <NoticeContainer>
              <ExtraSmallText>
                {content.NOTICE_1}
                <LinkText onClick={onOpenPrivacyPolicy}>Privacy Policy</LinkText> and{' '}
                <LinkText onClick={onOpenPrivacyNotice}>Notice</LinkText>
                {content.NOTICE_2}
                <LinkText onClick={onOpenFlexHome}>getflex.com</LinkText>
                {content.NOTICE_3}
              </ExtraSmallText>
            </NoticeContainer>
            <GetStarted onClick={props.onSubmit}>
              <ButtonText>{props.cta}</ButtonText>
            </GetStarted>
          </GetStartedContainer>
        </Container>
      </BodyScrollArea>
    </BasePageLayout>
  )
}

const BodyScrollArea = styled(Box, {
  shouldForwardProp: (prop: string) => prop !== 'isInEmbedFrame' && prop !== 'scrollHeight',
})<{ scrollHeight: number; isInEmbedFrame: boolean }>`
  min-height: ${({ scrollHeight, isInEmbedFrame }) => (isInEmbedFrame ? `${scrollHeight}px` : 'auto')};
  overflow-y: auto;
  position: relative;
`

const Container = styled(Flex, {
  shouldForwardProp: (prop: string) => prop !== 'isInEmbedFrame',
})<{ isInEmbedFrame: boolean }>`
  bottom: 0;
  flex-direction: column;
  left: 0;
  position: ${({ isInEmbedFrame }) => (isInEmbedFrame ? 'absolute' : 'relative')};
  right: 0;
  top: 0;
`

const Content = styled(Flex)`
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.fixedSizes.spacing_150};
`

const NoticeContainer = styled(Flex)`
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.fixedSizes.spacing_100};
  text-align: left;
`

const LinkText = styled.span`
  color: ${({ theme }) => theme.colors.jewelPurple};
  cursor: pointer;
`

const GetStartedContainer = styled(Flex, {
  shouldForwardProp: (prop: string) => prop !== 'isInEmbedFrame' && prop !== 'isScrollVisible',
})<{ isInEmbedFrame: boolean; isScrollVisible: boolean }>`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  ${({ isInEmbedFrame, isScrollVisible }) =>
    isScrollVisible &&
    isInEmbedFrame &&
    `
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1)
  `};
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
  padding-bottom: ${({ theme }) => theme.fixedSizes.spacing_150};
  position: ${({ isInEmbedFrame }) => (isInEmbedFrame ? 'fixed' : 'relative')};
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`

const GetStarted = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.midnight};
  border-radius: ${({ theme }) => theme.radii.rounded};
  cursor: pointer;
  justify-content: center;
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
  width: 100%;
`

const ButtonText = styled(Headline2)`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fixedSizes.spacing_125};
  line-height: ${({ theme }) => theme.fixedSizes.spacing_150};
`

const BoxSpace = styled(Box)`
  min-height: ${({ theme }) => theme.fixedSizes.spacing_200};
  width: ${({ theme }) => theme.fixedSizes.spacing_200};
`
