import React, { useCallback, useEffect } from 'react'
import { Controller, FieldError, useForm } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { isValidName, isValidTLD } from '@genoa/domain'
import { CO_CREATE_ACCOUNT, CREATE_ACCOUNT } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import lowerCase from 'lodash/lowerCase'
import * as yup from 'yup'

import { useContentOverhaul, useIsEmbed } from '../../../../hooks'
import { ContactState } from '../../../../modules/onboarding'
import { Headline2, LinkText, PrimaryButton, SmallText } from '../../../components'
import { TextInput } from '../../../components/Input'
import { BasePageLayout } from '../../../layouts'

export type CreateAccountProps = {
  contact: ContactState
  onOpenTermsOfService: () => unknown
  onOpenPrivacyPolicy: () => unknown
  onOpenPrivacyNotice: () => unknown
  onOpenESignConsent: () => unknown
  resetContact: () => unknown
  onChangeFirstName: () => unknown
  onChangeLastName: () => unknown
  onChangeEmail: () => unknown
  onDone: (state: ContactState) => unknown
  isLoading: boolean
  errors: {
    firstName?: FieldError | string
    lastName?: FieldError | string
    email?: FieldError | string
  }
  analyticsScreenName?: Analytics.Screens
  hideTerms?: boolean
  headerText?: string
  subheaderText?: string
  submitButtonText?: string
}

const contactSchema = yup.object({
  firstName: yup
    .string()
    .required('First name is required')
    .trim()
    .test({
      name: 'is-valid-name',
      message: 'Must be a valid first name',
      test: (value) => isValidName(value ?? ''),
    }),
  lastName: yup
    .string()
    .required('Last name is required')
    .trim()
    .test({
      name: 'is-valid-name',
      message: 'Must be a valid last name',
      test: (value) => isValidName(value ?? ''),
    }),
  email: yup
    .string()
    .email('This looks like an invalid email address')
    .required('Email address is required')
    .test({
      name: 'is-valid-tld',
      message: 'This looks like an invalid email address',
      test: (value) => isValidTLD(value ?? ''),
    }),
})

export function CreateAccount(props: CreateAccountProps) {
  const { isLoading, errors } = props

  const { onboardingEnabled } = useContentOverhaul()
  const content = onboardingEnabled ? CO_CREATE_ACCOUNT : CREATE_ACCOUNT

  const headerContent = props.headerText || content.HEADER
  const subHeaderContent = props.subheaderText || content.SUBHEADER
  const submitButtonContent = props.submitButtonText || content.CTA

  const hideSubheaderContent = onboardingEnabled && !props.subheaderText

  const isEmbed = useIsEmbed()

  const {
    control,
    handleSubmit,
    formState: { errors: errorsForm, isValid },
    getValues,
    reset,
  } = useForm<ContactState>({
    resolver: yupResolver(contactSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    const currentValues = getValues()
    const dirtyFieldsValues = Object.keys(currentValues).reduce((accumulator, current) => {
      const fieldKey = current as keyof typeof currentValues

      if (currentValues[fieldKey]) {
        return { ...accumulator, [current]: currentValues[fieldKey] }
      }

      return accumulator
    }, {})

    reset({ ...props.contact, ...dirtyFieldsValues })
  }, [props.contact])

  const onSubmit = handleSubmit((contact: ContactState) => {
    if (!isLoading) {
      props.onDone(contact)
    }
  })

  const handleOpenTermsOfService = useCallback(() => {
    props.onOpenTermsOfService()
  }, [props.onOpenTermsOfService])

  const handleOpenPrivacyPolicy = useCallback(() => {
    props.onOpenPrivacyPolicy()
  }, [props.onOpenPrivacyPolicy])

  const handleOpenPrivacyNotice = useCallback(() => {
    props.onOpenPrivacyNotice()
  }, [props.onOpenPrivacyNotice])

  const handleOpenESignConsent = useCallback(() => {
    props.onOpenESignConsent()
  }, [props.onOpenESignConsent])

  // TODO: clean up TermsSection when we decide how we want to handle agreements across the board
  const TermsSection = () => (
    <Box>
      <SmallText>
        {content.DISCLOSURES_LINE_1}
        <LinkText onClick={handleOpenESignConsent} testID="CreateAccountESignConsentTextButton">
          <b>E-Sign Consent Agreement</b>
        </LinkText>
      </SmallText>
      {!isEmbed && (
        <SmallText>
          ,{' '}
          <LinkText onClick={handleOpenTermsOfService} testID="CreateAccountTermsOfServiceTextButton">
            <b>Terms &amp; Conditions</b>
          </LinkText>
          ,{' '}
          <LinkText onClick={handleOpenPrivacyNotice} testID="CreateAccountPrivacyNoticeTextButton">
            <b>Privacy Notice</b>
          </LinkText>{' '}
          and{' '}
          <LinkText onClick={handleOpenPrivacyPolicy} testID="CreateAccountPrivacyPolicyTextButton">
            <b>Policy</b>
          </LinkText>
          .
        </SmallText>
      )}
      {isEmbed && (
        <SmallText>
          {' and '}
          <LinkText onClick={handleOpenTermsOfService} testID="CreateAccountTermsOfServiceTextButton">
            <b>Terms &amp; Conditions</b>
          </LinkText>
          .
        </SmallText>
      )}
    </Box>
  )

  return (
    <BasePageLayout>
      <Box minH="30px" />
      <Flex direction="column">
        <Headline2 data-testid="CreateAccountHeader">{headerContent}</Headline2>
        <Box minH="15px" />
        {!hideSubheaderContent && (
          <>
            <SmallText data-testid="CreateAccountSubheader">{subHeaderContent}</SmallText>
            <Box minH="15px" />
          </>
        )}
        <Box minH="15px" />
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box>
            <Field
              label={content.FIRST_NAME_LABEL}
              name="firstName"
              control={control}
              error={errorsForm.firstName || errors.firstName}
              defaultValue={props.contact.firstName}
              testID="CreateAccountFirstNameInput"
              autoComplete="given-name"
              onChange={() => props.onChangeFirstName()}
            />
          </Box>

          <Box w="20px" />
          <Box>
            <Field
              label={content.LAST_NAME_LABEL}
              name="lastName"
              control={control}
              error={errorsForm.lastName || errors.lastName}
              defaultValue={props.contact.lastName}
              testID="CreateAccountLastNameInput"
              autoComplete="family-name"
              onChange={() => props.onChangeLastName()}
            />
          </Box>
        </Box>

        <Box minH="30px" />
        <Field
          label="Email address"
          name="email"
          control={control}
          error={errorsForm.email || errors.email}
          defaultValue={props.contact.email}
          testID="CreateAccountEmailInput"
          autoComplete="email"
          onChange={() => props.onChangeEmail()}
        />
        <Box minH="20px" />
        {!props.hideTerms && (
          <>
            <TermsSection />
            <Box minH="20px" />
          </>
        )}
        <PrimaryButton
          onClick={onSubmit}
          processing={isLoading}
          disabled={isLoading || !isValid}
          testID="CreateAccountNextButton"
        >
          {submitButtonContent}
        </PrimaryButton>
      </Flex>
    </BasePageLayout>
  )
}

type FieldProps = {
  label: string
  name: string
  defaultValue?: string
  control: any
  error?: FieldError | string
  testID?: string
  autoComplete?: string
  onChange?: () => void
}

function Field(props: FieldProps) {
  const { control, label, error, name, defaultValue, testID } = props

  let errorMessage: string
  if (error && typeof error === 'object' && error.message) {
    const formatted = lowerCase(name)
    errorMessage = error.message.replace(name, formatted)
  } else if (typeof error === 'string') {
    errorMessage = error
  }

  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <>
          <SmallText>{label}</SmallText>
          <Box minH="5px" />
          <TextInput
            onBlur={onBlur}
            onTextChange={(text) => {
              if (props.onChange) {
                props.onChange()
              }

              onChange(text.normalize('NFKC'))
            }}
            text={value}
            error={!!errorMessage}
            testID={testID}
            autoComplete={props.autoComplete}
          />
          <Box minH="5px" />
          <Box visibility={errorMessage ? 'visible' : 'hidden'} color="#E53A3A">
            <SmallText hasError={!!errorMessage}>{errorMessage}</SmallText>
          </Box>
        </>
      )}
      name={name}
      defaultValue={defaultValue || ''}
    />
  )
}
