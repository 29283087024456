import React from 'react'

import { PrimaryButton, SmallText } from '../../components'
import { Modal, ModalActions } from '../../components/Modal'

type NewCodeSentProps = {
  visible: boolean
  onClose: () => unknown
  formattedPhone: string
}

export const NewCodeSent = (props: NewCodeSentProps) => (
  <Modal
    testID="NewCodeSentModal"
    title={'New Code Sent'}
    canClose={true}
    onClose={props.onClose}
    visible={props.visible}
  >
    <SmallText>
      We texted you a new code to <b>{props.formattedPhone}</b>. Please enter it below. It will be valid for 10 minutes.
    </SmallText>

    <ModalActions>
      <PrimaryButton onClick={props.onClose}>Got it</PrimaryButton>
    </ModalActions>
  </Modal>
)
