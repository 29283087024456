import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { ActionLogType } from '@genoa/domain'
import { SLCClassicExperiment } from '@genoa/experiments'
import { deepReplaceContent, SLC_CLASSIC_APPROVED_CONTENT } from '@genoa/screen-content'
import { toFormattedDollars } from '@genoa/utils'
import { Stack, Text } from '@chakra-ui/react'

import { useExperimentVariant } from '../../../../hooks'
import { useHandleRegisterActionLog } from '../../../../hooks/flex2/onboarding-status'
import { useAnalytics, useFees } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import theme from '../../../../theme/theme'
import { RocketShip } from '../../../assets'
import { Headline1, PrimaryButton } from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { ApprovedSLCClassic as SeptemberExperiment } from './september-experiment/ApprovedSLCClassic'

export function ApprovedSLCClassic() {
  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()
  const navigate = useNavigate()
  const { offerMonthlyRent } = useFees()
  const analytics = useAnalytics()
  const variant = useExperimentVariant(SLCClassicExperiment)

  const content = deepReplaceContent(SLC_CLASSIC_APPROVED_CONTENT, { rentAmount: toFormattedDollars(offerMonthlyRent) })

  async function onClick() {
    analytics.logEvent(Analytics.Events.APPROVED_SLC_SCREEN_CONTINUE_PRESSED)
    await handleRegisterActionLog(ActionLogType.APPROVE_CONFIRMATION)
    navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE)
  }
  if (variant === SLCClassicExperiment.variantValues.Treatment_2) {
    return <SeptemberExperiment />
  }

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_SLC_APPROVED}>
      <Stack
        mt={theme.fixedSizes.spacing_100}
        gap={theme.fixedSizes.spacing_200}
        textAlign="center"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <RocketShip />
        <Headline1>{content.HEADER}</Headline1>
        <Text>{content.BODY}</Text>
        <PrimaryButton processing={loadingRegisterActionLog} onClick={onClick}>
          {content.CTA}
        </PrimaryButton>
      </Stack>
    </BasePageLayout>
  )
}
