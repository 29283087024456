import { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { Agreement, AgreementSourceType } from '@genoa/domain'

import { useModal } from '../../../contexts'
import { useAnalytics } from '../../../providers'
import { MarkdownFile } from '../../../views/components'
import { useTermsAndConditions } from './use-terms-and-conditions'

export interface MarkdownAgreementProps {
  readonly url: string
  readonly analyticsName?: string
  readonly params?: any
}

export interface UseAgreementLinkOptions {
  renderMarkdown: (props: MarkdownAgreementProps) => React.ReactNode
}

export const useAgreementLink = () => {
  const analytics = useAnalytics()
  const modal = useModal()
  const { parameters } = useTermsAndConditions()

  return useCallback(
    (agreement: Agreement) => {
      analytics.logEvent(Analytics.Events.AGREEMENT_CLICK, {
        type: agreement.agreement_type,
        version: agreement.agreement_version,
      })
      if (agreement.agreement_url_source_type === AgreementSourceType.MARKDOWN) {
        modal.show({
          title: agreement.agreement_type_name,
          cta: 'Close',
          render: () => (
            <MarkdownFile
              url={agreement.agreement_url}
              analyticsName={agreement.agreement_type}
              params={agreement.agreement_data || parameters}
            />
          ),
        })
      } else {
        window.open(agreement.agreement_url, '_blank')
      }
    },
    [analytics, parameters]
  )
}
