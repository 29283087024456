import { createReducer, PayloadAction } from '@reduxjs/toolkit'

import { ResetLoadedPayload } from '../types'
import * as Actions from './actions'
import { PricingOfferState } from './types'

export const initialState: PricingOfferState = {
  pricing_offer: {
    debit_card_fee_percent: -1,
    debit_card_fee_bips: -1,
    debit_card_fee_amount: -1,
    credit_card_fee_percent: -1,
    credit_card_fee_bips: -1,
    credit_card_fee_amount: -1,
    membership_fee_amount: -1,
  },
  initialized: false,
  loaded: false,
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions.resetPricingOfferAction, (_state, action: PayloadAction<ResetLoadedPayload | void>) => {
      const loaded = action.payload ? action.payload.loaded : false
      return { ...initialState, loaded: loaded || false }
    })
    .addCase(Actions.setPricingOfferAction, (_state, action) => {
      return { ...action.payload, initialized: true, loaded: true }
    })
    .addCase(Actions.setPricingOfferLoadedAction, (state, action) => {
      return { ...state, loaded: action.payload }
    })
})
