import {
  BillerFlexAnywhereLongTailPortal,
  BillerFlexAnywherePortal,
  BillerGenericPortal,
  BillerOONPortal,
  BillerPropertyDetails,
  FlexAnywherePortalName,
  IntegrationType,
  isBillerFlexAnywhereLongTailPortal,
  isBillerOONPortal,
} from '@genoa/domain'

export interface CreateIterableEventOptionsNoFields {
  readonly createdAt?: number
}

export interface IterableEventNoFields {
  readonly eventName: string
  readonly createdAt: number
}

export interface CreateIterableEventOptionsWithFields<TFields> {
  readonly dataFields: TFields
  readonly createdAt?: number
}

export interface IterableEventWithFields<TFields> {
  readonly eventName: string
  readonly createdAt: number
  readonly dataFields: TFields
}

export type IterableEvent = IterableEventNoFields | IterableEventWithFields<any>

const getIterableEventFactoryNoFields =
  (eventName: string) =>
  (options: CreateIterableEventOptionsNoFields = {}): IterableEventNoFields => {
    const { createdAt: createdAtIn, ...rest } = options
    const createdAt = createdAtIn ?? Date.now()

    return {
      eventName,
      createdAt,
      ...rest,
    }
  }

// the typings of this are pretty rigid so we need 2 functions that do the same thing with different types
const getIterableEventFactoryWithFields =
  <TFields>(eventName: string) =>
  (options: CreateIterableEventOptionsWithFields<TFields>): IterableEventWithFields<TFields> => {
    const { createdAt: createdAtIn, ...rest } = options
    const createdAt = createdAtIn ?? Date.now()

    return {
      eventName,
      createdAt,
      ...rest,
    }
  }

// empty fields events
export const createPhoneVerifiedEvent = getIterableEventFactoryNoFields('phone_verified')
export const createAccountCreatedEvent = getIterableEventFactoryNoFields('account_created')
export const createSignUpCompletedEvent = getIterableEventFactoryNoFields('sign_up_completed')
export const createUnderwritingApprovedEvent = getIterableEventFactoryNoFields('underwriting_approved')

// events with dataFields
export interface UnderwritingDeniedFields {
  readonly denial_reason: string
}

export const createUnderwritingDeniedEvent =
  getIterableEventFactoryWithFields<UnderwritingDeniedFields>('underwriting_denied')

export interface BillerConnectionSuccessAPIFields {
  readonly type: IntegrationType.YARDI | IntegrationType.REALPAGE | IntegrationType.DIRECT_INTEGRATION
  readonly pmc_id: string
  readonly property_id: string
}

export interface BillerConnectionSuccessPortalFields {
  readonly type: IntegrationType.PORTAL
  readonly portal_name: string
  readonly property_id?: string
  readonly portal_id: string
}

export interface BillerConnectionSuccessFlexAnywhereFields {
  readonly type: IntegrationType.FLEX_ANYWHERE
  readonly portal_name: string
  readonly public_id: string
}

export interface BillerConnectionSuccessUnknownFields {
  readonly type: 'N/A'
}

export type BillerConnectionSuccessFields =
  | BillerConnectionSuccessAPIFields
  | BillerConnectionSuccessPortalFields
  | BillerConnectionSuccessFlexAnywhereFields
  | BillerConnectionSuccessUnknownFields

const createBillerConnectionSuccessEventInternal =
  getIterableEventFactoryWithFields<BillerConnectionSuccessFields>('biller_connection_success')

const isBillerPropertyDetails = (
  portalOrProperty:
    | BillerPropertyDetails
    | BillerGenericPortal
    | BillerOONPortal
    | BillerFlexAnywherePortal
    | BillerFlexAnywhereLongTailPortal
): portalOrProperty is BillerPropertyDetails => {
  if ((portalOrProperty as any)?.integration_type !== undefined) {
    return true
  }

  return false
}

export const createBillerConnectionSuccessEvent = (
  portalOrProperty:
    | BillerPropertyDetails
    | BillerGenericPortal
    | BillerOONPortal
    | BillerFlexAnywherePortal
    | BillerFlexAnywhereLongTailPortal
) => {
  try {
    if (isBillerPropertyDetails(portalOrProperty)) {
      switch (portalOrProperty.integration_type) {
        case IntegrationType.REALPAGE:
        case IntegrationType.YARDI:
        case IntegrationType.DIRECT_INTEGRATION:
          return createBillerConnectionSuccessEventInternal({
            dataFields: {
              type: portalOrProperty.integration_type,
              pmc_id: portalOrProperty.pmc_id.toString(),
              property_id: portalOrProperty.property_id.toString(),
            },
          })
        case IntegrationType.PORTAL:
          return createBillerConnectionSuccessEventInternal({
            dataFields: {
              type: portalOrProperty.integration_type,
              portal_name: portalOrProperty.name,
              property_id: portalOrProperty.property_id.toString(),
              portal_id: portalOrProperty.portal_id.toString(),
            },
          })
        default:
          throw new Error('Unhandled BillerPropertyDetails integration_type')
      }
    }

    if (isBillerFlexAnywhereLongTailPortal(portalOrProperty)) {
      return createBillerConnectionSuccessEventInternal({
        dataFields: {
          type: IntegrationType.FLEX_ANYWHERE,
          portal_name: portalOrProperty.portal_name
            ? portalOrProperty.portal_name
            : FlexAnywherePortalName.FLEX_ANYWHERE,
          public_id: portalOrProperty.public_id,
        },
      })
    }

    if (isBillerOONPortal(portalOrProperty)) {
      return createBillerConnectionSuccessEventInternal({
        dataFields: {
          type: IntegrationType.FLEX_ANYWHERE,
          portal_name: portalOrProperty.portal_name,
          public_id: portalOrProperty.public_id,
        },
      })
    }

    return createBillerConnectionSuccessEventInternal({
      dataFields: {
        type: IntegrationType.PORTAL,
        portal_name: portalOrProperty.portal_name,
        portal_id: portalOrProperty.id.toString(),
      },
    })
  } catch {
    return createBillerConnectionSuccessEventInternal({
      dataFields: { type: 'N/A' },
    })
  }
}
