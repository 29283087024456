import React, {
  MutableRefObject,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

export type KeyboardAccessoryContextValue = {
  add: (ref: KeyboardAccessoryRefObject) => unknown
  delete: (ref: KeyboardAccessoryRefObject) => unknown
  has: (direction: 'prev' | 'next') => boolean
  prev: () => unknown
  next: () => unknown
  // layout
  setHeight: (height: number) => void
  height: number
}
export type KeyboardAccessoryRefObject = MutableRefObject<KeyboardAccessoryRef>
export type KeyboardAccessoryRef = { focus: () => unknown; blur: () => unknown; isFocused: () => boolean } | undefined

const KeyboardAccessoryContext = React.createContext<KeyboardAccessoryContextValue | null>(null)

export function KeyboardAccessoryProvider({ autoFocus, children }: { autoFocus?: boolean; children: ReactNode }) {
  const refs = useMemo(() => new Set<KeyboardAccessoryRefObject>(), [])
  const [cursor, setCursor] = useState<null | number>(autoFocus ? 0 : null)
  const [delta, setDelta] = useState(0)
  const [height, setHeight] = useState(0)

  useLayoutEffect(() => {
    const focusedIndex = findFocusedIndex(refs)
    setCursor(focusedIndex == null ? null : focusedIndex + delta)
  }, [delta, refs])

  useLayoutEffect(() => {
    setDelta(0)
    if (cursor != null) {
      const ref = Array.from(refs.values())[Math.max(0, Math.min(refs.size - 1, cursor))]

      ref?.current?.focus()

      return () => {
        ref?.current?.focus()
      }
    }
  }, [cursor, refs])

  const callbacks = useMemo(() => {
    return {
      prev() {
        setDelta((v) => (v <= 0 ? v - 1 : -1))
      },
      next() {
        setDelta((v) => (v >= 0 ? v + 1 : +1))
      },
      add(ref: KeyboardAccessoryRefObject) {
        refs.add(ref)
      },
      delete(ref: KeyboardAccessoryRefObject) {
        refs.delete(ref)
      },
      has(direction: 'prev' | 'next') {
        const index = findFocusedIndex(refs)
        return index == null
          ? false
          : direction === 'prev'
          ? index >= 0
          : direction === 'next'
          ? index + 1 < refs.size
          : false
      },
    }
  }, [refs])

  return (
    <KeyboardAccessoryContext.Provider
      value={useMemo(() => ({ height, setHeight, ...callbacks }), [refs, cursor, height, callbacks])}
    >
      {children}
    </KeyboardAccessoryContext.Provider>
  )
}

export function useKeyboardAccessoryRef<T extends KeyboardAccessoryRef>() {
  const ref = useRef<T>()
  const context = useContext(KeyboardAccessoryContext)

  useEffect(() => {
    context?.add(ref)

    return () => {
      context?.delete(ref)
    }
  }, [context?.add, context?.delete])

  return ref
}

export function useKeyboardAccessory() {
  const context = useContext(KeyboardAccessoryContext)

  const prev = useCallback(() => {
    context?.prev()
  }, [context?.prev])

  const next = useCallback(() => {
    context?.next()
  }, [context?.next])

  const setHeight = useCallback(
    (height: number) => {
      context?.setHeight(height)
    },
    [context?.setHeight]
  )

  return {
    next,
    prev,
    height: context?.height || 0,
    setHeight,
  }
}

function findFocusedIndex(refs: Set<KeyboardAccessoryRefObject>) {
  const index = Array.from(refs.values()).findIndex((ref) => ref?.current?.isFocused())

  return index >= 0 ? index : null
}
