import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from '../../../../providers'
import * as Routes from '../../../../routing/constants'
import { CreditBuilderConfirmScheduleContainer } from '../../common/credit-builder/confirm-schedule'

export const OnboardingCreditBuilderConfirmScheduleContainer = () => {
  const analytics = useAnalytics()
  const navigate = useNavigate()

  const onRegisterCard = () => {
    analytics.logEvent(Analytics.Events.CONNECT_CARD_CTA_CLICKED)
    return navigate(Routes.Onboarding.CARD_REGISTER, {
      state: {
        analyticsScreenName: Analytics.Screens.ADD_CARD_ONBOARDING_ADD_CARD,
        nextRoute: Routes.Onboarding.OFFER_DETAILS,
      },
    })
  }

  return (
    <CreditBuilderConfirmScheduleContainer
      analyticsScreenName={Analytics.Screens.ONBOARDING_CONFIRM_SCHEDULE}
      onNext={() => navigate(Routes.Onboarding.CONGRATS)}
      onRegisterCard={onRegisterCard}
    />
  )
}
