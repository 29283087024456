import React, { Context, ReactNode, useContext, useEffect, useMemo } from 'react'
import { datadogLogs } from '@datadog/browser-logs'
import { getAuth } from 'firebase/auth'

import { useAuthState } from '../../contexts'
import { useFirebase } from '../firebase/firebase-provider'
import { Datadog } from './datadog'

export const DatadogContext: Context<typeof Datadog> = React.createContext(Datadog)

type DatadogProviderProps = {
  children?: ReactNode
  initConfig: any
}

export const DatadogProvider = ({ initConfig, children }: DatadogProviderProps) => {
  const datadog = useContext(DatadogContext) || Datadog
  const firebaseApp = useFirebase()
  const auth = getAuth(firebaseApp)
  const { uid, isAnonymous } = useAuthState()

  const datadogContext = useMemo(() => {
    // Initialize DataDog
    datadog.init({
      clientToken: initConfig.clientToken,
      site: initConfig.site || 'datadoghq.com',
      env: initConfig.env,
      service: initConfig.service,
      version: initConfig.version,
      applicationId: initConfig.applicationId,
      allowedTracingUrls: initConfig.allowedTracingUrls || [],
      traceSampleRate: initConfig.traceSampleRate || 0,
      sessionSampleRate: 0,
      sessionReplaySampleRate: 0,
      defaultPrivacyLevel: 'mask-user-input',
      silentMultipleInit: true,
      trackUserInteractions: false,
      trackFrustrations: false,
      trackResources: true,
      trackLongTasks: true,
    })
    // Init logs
    datadogLogs.init({
      clientToken: initConfig.clientToken,
      service: initConfig.service,
      env: initConfig.env,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      silentMultipleInit: true,
      beforeSend(log: any) {
        // Add userId for all logs
        const currentUser = auth.currentUser
        if (currentUser) {
          const { isAnonymous, uid } = currentUser
          if (!isAnonymous && uid) {
            log.usr = { id: uid }
          }
        }
      },
      version: initConfig.version,
    })
    return datadog
  }, [initConfig, datadog])

  useEffect(() => {
    if (!isAnonymous && uid) {
      datadogContext.setUser({
        id: uid,
      })
    }
  }, [uid, isAnonymous])

  if (!children) {
    return null
  }

  return <DatadogContext.Provider value={datadogContext}>{children}</DatadogContext.Provider>
}

export const useDatadog = () => useContext(DatadogContext)
