import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { RadioButton } from '../RadioButton'
import { ExtraSmallText, SmallText } from '../Typography'

export interface RadioBoxProps {
  readonly isSelected: boolean
  readonly onClick: () => void
  readonly title?: React.ReactNode | string
}

export const SelectableCard = (props: React.PropsWithChildren<RadioBoxProps>) => {
  const theme = useTheme()

  const content =
    typeof props.children === 'string' ? (
      <ExtraSmallText color={theme.colors.dusk}>{props.children}</ExtraSmallText>
    ) : (
      props.children
    )

  const title = typeof props.title === 'string' ? <SmallText fontWeight="bold">{props.title}</SmallText> : props.title

  return (
    <SelectableCardContainer isSelected={props.isSelected} onClick={props.onClick}>
      <RadioButton {...props} style="checkmark" />
      <SelectableCardContent>
        {title}
        {content}
      </SelectableCardContent>
    </SelectableCardContainer>
  )
}

interface SelectableCardContainerProps {
  readonly isSelected: boolean
}

const SelectableCardContainer = styled(Box)<SelectableCardContainerProps>`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  gap: ${(props) => props.theme.fixedSizes.spacing_75};

  background-color: ${(props) => props.theme.colors.winterWhite};
  border-radius: ${(props) => props.theme.fixedSizes.spacing_100};
  border-width: ${(props) => props.theme.fixedSizes.spacing_12_5};
  border-color: ${(props) => (props.isSelected ? props.theme.colors.jewelPurple : props.theme.colors.cloud)};

  transition: border-color 0.2s ease-in;

  :hover {
    cursor: pointer;
  }
`

const SelectableCardContent = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 1;
`
