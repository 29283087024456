import { AddCardPaymentMethod } from './AddCardPaymentMethod'
import { ChangeCardPaymentMethod } from './ChangeCardPaymentMethod'
import { CardDetails } from './common'

export interface AddChangeCardPaymentMethodProps {
  readonly cardDetails?: CardDetails
  readonly onChangeCard: () => void
  readonly onAddCard: () => void
}

export const AddChangeCardPaymentMethod = (props: AddChangeCardPaymentMethodProps) => {
  if (props.cardDetails) {
    return <ChangeCardPaymentMethod onClick={props.onChangeCard} cardDetails={props.cardDetails} />
  }

  return <AddCardPaymentMethod onClick={props.onAddCard} />
}
