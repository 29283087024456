import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../routing/constants'
import { AddressSelectionContainer } from '../../common/property-linking/address-selection/'

type OnboardingAddressSelectionContainerProps = {}

export const OnboardingAddressSelectionContainer = (props: OnboardingAddressSelectionContainerProps) => {
  const navigate = useNavigate()

  return (
    <AddressSelectionContainer
      {...props}
      analyticsEventSigninClicked={Analytics.Events.RENT_PORTAL_ADDRESS_SELECTION_PORTAL_SIGNIN_CLICKED}
      analyticsScreenName={Analytics.Screens.ONBOARDING_ADDRESS_SELECTION}
      continueEvent={Analytics.Events.PROPERTY_SEARCH_ADDRESS_SELECTION_CONFIRM_CLICKED}
      onDontSeeMyProperty={() => {
        navigate(Routes.Onboarding.PORTAL_ADDRESS_ENTRY)
      }}
      onDirectIntegrationPropertySelected={() => {
        navigate(Routes.Onboarding.DIRECT_INTEGRATION_UNIT)
      }}
      onGenericPortalPropertySelected={() => {
        navigate(Routes.Onboarding.RENT_PORTAL_DETAILS)
      }}
      onNonGenericPortalPropertySelected={() => {
        navigate(Routes.Onboarding.RENT_PORTAL_SIGNIN, { state: { signingInTo: 'property' } })
      }}
      onNavigateToFAHowYouPay={() => {
        navigate(Routes.Onboarding.FLEX_ANYWHERE_HOW_YOU_PAY)
      }}
    />
  )
}
