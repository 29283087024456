import { ResidentDataPrefillsExperiment } from '@genoa/experiments'

import { useExperimentVariant } from '../use-experiment-variant'

export const useResidentDataPrefillsExperiment = () => {
  const prefillsExperimentVariant = useExperimentVariant(ResidentDataPrefillsExperiment)
  const isPrefillsEnabled = prefillsExperimentVariant === ResidentDataPrefillsExperiment.variantValues.Treatment

  return {
    isPrefillsEnabled,
  }
}
