import React from 'react'
import { Box } from '@chakra-ui/react'

import { ExtraSmallText, LinkText } from '../../../components'

type ConsolidationDisclaimerProps = {
  onClickTermsOfService: () => void
  onClickPrivacyPolicy: () => void
  onClickPrivacyNotice: () => void
  onClickESignConsent: () => void
}

export const ConsolidationDisclaimer = (props: ConsolidationDisclaimerProps) => {
  return (
    <Box style={{ zIndex: 1, textAlign: 'justify' }}>
      <ExtraSmallText>
        By creating an account, you agree to Flex&rsquo;s{' '}
        <LinkText onClick={props.onClickESignConsent} testID="CreateAccountESignConsentTextButton">
          <b>E-Sign Consent Agreement</b>
        </LinkText>
        ,{' '}
        <LinkText onClick={props.onClickTermsOfService} testID="CreateAccountTermsOfServiceTextButton">
          <b>Terms &amp; Conditions</b>
        </LinkText>
        ,{' '}
        <LinkText onClick={props.onClickPrivacyNotice} testID="CreateAccountPrivacyNoticeTextButton">
          <b>Privacy Notice</b>
        </LinkText>{' '}
        and{' '}
        <LinkText onClick={props.onClickESignConsent} testID="CreateAccountPrivacyPolicyTextButton">
          <b>Policy</b>
        </LinkText>
        , and consent to receive a one-time text message from Flex. Standard message and data rates apply.
      </ExtraSmallText>
    </Box>
  )
}
