import { createReducer } from '@reduxjs/toolkit'

import * as Actions from './actions'
import { LayoutState } from './types'

export const initialState: LayoutState = {
  tabBarHeight: 0,
}

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(Actions.setTabBarHeightAction, (state, action) => {
    state.tabBarHeight = action.payload
  })
})
