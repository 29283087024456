import { compileBillerInformation, integrationTypeToMarketingTrackingValue } from '@genoa/domain'

import { useAccount } from '../../hooks/use-account'
import { filterEmptyValues, mapValuesToHashedSha256, useOffer, useProperty, useWalletCard } from './util'

type EmptyOr<T> = {} | T

type CustomerInfo = EmptyOr<{
  customerId: string
  firstName: string
  lastName: string
  email: string
  phone: string
}>

type BillerInfo = EmptyOr<{
  propName: string
  integrationType: string
}>

type IntegrationInfo = EmptyOr<
  {
    propName: string
    pmcName: string
    integrationType: string
  } & Partial<{
    currentTier: string
    qualifiedTier: string
  }>
>

type UnderwritingInfo = EmptyOr<{
  rentAmount: string
  productType: string
}>

type WalletInfo = EmptyOr<{
  cardNetwork: string
}>

type AggregateUserInfo = CustomerInfo & BillerInfo & IntegrationInfo & UnderwritingInfo & WalletInfo

const useCustomerInformation = (): CustomerInfo => {
  const { customer } = useAccount()
  if (customer === undefined) {
    return {}
  }

  const { public_id: customerId, first_name: firstName, last_name: lastName, email, phone } = customer
  const formattedPhone = (() => {
    if (phone.length === 11 && phone.charAt(0) === '1') {
      return phone.substring(1)
    }
    return phone
  })()

  return {
    customerId,
    ...mapValuesToHashedSha256({
      firstName,
      lastName,
      email,
      phone: formattedPhone,
    }),
  }
}

const useBillerInformation = (): BillerInfo => {
  const { billerConnection } = useAccount()
  if (billerConnection === undefined || billerConnection.biller === undefined) {
    return {}
  }

  const { propertyName, integrationType } = compileBillerInformation(billerConnection.biller)

  return {
    propName: propertyName,
    integrationType,
  }
}

const useIntegrationInformation = (): IntegrationInfo => {
  const property = useProperty()
  if (property === undefined) {
    return {}
  }

  const {
    pmc_name: pmcName,
    name: propName,
    integration_type: integrationType,
    billing_integration_type: billingIntegrationType,
    current_tier: currentTier,
    qualified_tier: qualifiedTier,
  } = property

  return filterEmptyValues({
    propName,
    pmcName,
    integrationType: integrationTypeToMarketingTrackingValue(integrationType, billingIntegrationType),
    currentTier,
    qualifiedTier,
  })
}

const useUnderwritingInformation = (): UnderwritingInfo => {
  const offer = useOffer()
  if (offer === undefined) {
    return {}
  }

  const { estimated_bill_amount_cent } = offer
  const rentAmount = (estimated_bill_amount_cent / 100).toFixed(2)
  return { rentAmount }
}

const useWalletInformation = (): WalletInfo => {
  const walletCard = useWalletCard()
  if (walletCard === undefined) {
    return {}
  }

  const { brand: cardNetwork } = walletCard
  return { cardNetwork }
}

export const useAggregateUserInformation = (): AggregateUserInfo => {
  return {
    ...useCustomerInformation(),
    ...useBillerInformation(),
    ...useIntegrationInformation(),
    ...useUnderwritingInformation(),
    ...useWalletInformation(),
  }
}
