import { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { useCancelSetupCard } from '@genoa/middle-end'
import { PaymentMethodCreateParams, SetupIntentResult, Stripe, StripeCardElement, StripeError } from '@stripe/stripe-js'

import {
  StripeDenialText,
  StripeErrorMapping,
  StripeErrorMessageContent,
  StripeErrorMessageTypes,
  StripeTitleDenial,
} from '../../../../../../../errors/stripe-errors'
import { logger } from '../../../../../../../providers'
import { useAnalytics } from '../../../../../../../providers'
import { hideSensitiveFields } from '../../../../../../../tools'

// stripe clientSecret is of the form `${intentId}_secret_${sharedSecret}`
const extractIntentId = (secret: string) => {
  const index = secret.indexOf('_secret_')

  if (index === -1) {
    return undefined
  }

  return secret.slice(0, index)
}

type ConfirmSetupPaymentProps = {
  handleError: (reason: string, modalTitle?: string, modalDescription?: string) => void
  clientSecret: string
  stripe: Stripe
  customerId: string
}

export const useConfirmSetupPayment = () => {
  const analytics = useAnalytics()
  const [, cancelSetupIntent] = useCancelSetupCard()

  const handleCancelSetupIntent = useCallback(
    async (customerId: string, intentId: string) => {
      try {
        const { status } = await cancelSetupIntent({
          customerId: customerId,
          stripeSetupIntentId: intentId,
        })
        if (status >= 200 && status <= 300) {
          return analytics.logEvent(Analytics.Events.CARD_INTENT_CANCEL_SUCCESS, {
            reason: 'Successfully canceled card setup intent',
          })
        }

        throw new Error(`Got status ${status} in response to canceling card intent`)
      } catch (error: any) {
        analytics.logEvent(Analytics.Events.CARD_INTENT_CANCEL_FAILURE, {
          reason: error?.message ?? 'Unknown error canceling card intent',
        })
      }
    },
    [analytics]
  )

  /**
   * Attempt to create a reasonable sounding error in response to a failure of attempting to add a card to stripe.
   * @param err - StripeError: This is the object used to determine what the error is and what kind of message
   * should be provided to the user.
   */
  const handleStripeFailure = useCallback(
    async (err: StripeError | undefined, handleError: any, customerId: string, clientSecret: string) => {
      try {
        const stripeErrorObfuscated = err ? hideSensitiveFields(err, ['payment_method.billing_details']) : {}
        logger.error('handleStripeFailure', JSON.stringify(stripeErrorObfuscated))

        // declineCode can be set to undefined so this process could cause a failure.
        const declineCode = err?.decline_code
        const stripeErr: StripeErrorMessageContent = StripeErrorMapping[declineCode as keyof StripeErrorMessageTypes]

        return handleError(stripeErr.message, stripeErr.title, stripeErr.message)
      } catch (err: any) {
        logger.error('handleStripeFailure Failed', err.message, err)

        return handleError(StripeDenialText, StripeTitleDenial, StripeDenialText)
      } finally {
        // finally we try to cancel the setup on our backend
        const intentId = extractIntentId(clientSecret)
        if (customerId && intentId) {
          return handleCancelSetupIntent(customerId, intentId)
        }
      }
    },
    []
  )
  const confirmSetupPayment = useCallback(
    async ({ handleError, clientSecret, stripe, customerId }: ConfirmSetupPaymentProps) => {
      try {
        const confirmSetupIntentResult = await stripe.confirmCardSetup(clientSecret)

        if (confirmSetupIntentResult?.setupIntent?.status !== 'succeeded') {
          return await handleStripeFailure(confirmSetupIntentResult.error, handleError, customerId, clientSecret)
        }

        if (confirmSetupIntentResult && confirmSetupIntentResult.setupIntent.payment_method) {
          return confirmSetupIntentResult.setupIntent.payment_method
        } else {
          handleError(`handleConfirmSetupPayment payment method id does not exist`)
        }
      } catch (error: any) {
        handleError(`confirmSetupPayment ${error?.message}`)
      }
    },
    []
  )

  return {
    confirmSetupPayment,
  }
}
