import { useCallback, useState } from 'react'

export const useTriggerOnce = (func: () => void) => {
  const [triggered, setTriggered] = useState(false)

  const execute = useCallback(() => {
    if (triggered) {
      return
    }

    func()
    setTriggered(true)
  }, [triggered])

  return execute
}
