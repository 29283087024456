import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { EmbedPayNowEligibilityFailureCode } from '@genoa/domain'
import { EMBED_SIGNUP, EMBED_SIGNUP_COMPLETED, replaceContent } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useEmbedAnalytics } from '../../../../../../hooks'
import { useAnalytics } from '../../../../../../providers'
import * as Routes from '../../../../../../routing/constants'
import { CheckmarkCirclePurple, SLCHowFlexWorks } from '../../../../../assets'
import { Headline2, SmallText, Text } from '../../../../../components/Typography'
import { LandingContainer } from './LandingContainer'

export const EmbedSignup = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { failureCode, firstName } = location.state
  const analytics = useAnalytics()
  const isSignup = failureCode === EmbedPayNowEligibilityFailureCode.SIGNUP
  const { embedFlow } = useEmbedAnalytics()

  const header = isSignup ? EMBED_SIGNUP.HEADER : replaceContent(EMBED_SIGNUP_COMPLETED.HEADER, { firstName })
  const body = isSignup ? EMBED_SIGNUP.BODY : EMBED_SIGNUP_COMPLETED.BODY
  const cta = isSignup ? EMBED_SIGNUP.CTA : EMBED_SIGNUP_COMPLETED.CTA

  const onSubmit = () => {
    analytics.logEvent(
      isSignup ? Analytics.Events.EMBED_DYN_INT_NEW_CLICK : Analytics.Events.EMBED_DYN_INT_EXISTING_CLICK,
      { embedFlow }
    )
    navigate(Routes.Auth.PHONE)
  }

  return (
    <LandingContainer cta={cta} failureCode={failureCode} onSubmit={onSubmit}>
      <HeaderContainer>
        <SLCHowFlexWorks />
      </HeaderContainer>
      <ContentContainer>
        <TitleText>{header}</TitleText>
      </ContentContainer>
      <ContentContainer>
        <SmallText>{body}</SmallText>
      </ContentContainer>
      <ListContainer>
        <ListHeader>{EMBED_SIGNUP.LIST.HEADER}</ListHeader>
        <Row>
          <ListIcon>
            <CheckmarkCirclePurple />
          </ListIcon>
          <ListSmallText>{EMBED_SIGNUP.LIST.ITEM_1}</ListSmallText>
        </Row>
        <Row>
          <ListIcon>
            <CheckmarkCirclePurple />
          </ListIcon>
          <ListSmallText>{EMBED_SIGNUP.LIST.ITEM_2}</ListSmallText>
        </Row>
        <Row>
          <ListIcon>
            <CheckmarkCirclePurple />
          </ListIcon>
          <ListSmallText>{EMBED_SIGNUP.LIST.ITEM_3}</ListSmallText>
        </Row>
      </ListContainer>
    </LandingContainer>
  )
}

const HeaderContainer = styled(Flex)`
  align-items: center;
  padding: ${({ theme }) => theme.fixedSizes.spacing_150} ${({ theme }) => theme.fixedSizes.spacing_100};
`

const ContentContainer = styled(Flex)`
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.fixedSizes.spacing_75};
  text-align: center;
`

const TitleText = styled(Headline2)`
  font-size: ${({ theme }) => theme.fixedSizes.spacing_150};
  line-height: ${({ theme }) => theme.fixedSizes.spacing_200};
  padding-left: ${({ theme }) => theme.fixedSizes.spacing_250};
  padding-right: ${({ theme }) => theme.fixedSizes.spacing_250};
`

const ListContainer = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.wildflower};
  border-radius: ${({ theme }) => theme.radii.modal};
  flex-direction: column;
  margin-top: ${({ theme }) => theme.fixedSizes.spacing_50};
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
  width: 100%;
`

const ListHeader = styled(Text)`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-bottom: ${({ theme }) => theme.fixedSizes.spacing_100};
  text-align: left;
`

const ListIcon = styled(Flex)`
  flex-shrink: 0;
`

const ListSmallText = styled(SmallText)`
  margin-left: ${({ theme }) => theme.fixedSizes.spacing_75};
`

const Row = styled(Flex)`
  align-items: center;
  flex-direction: row;
  margin-bottom: ${({ theme }) => theme.fixedSizes.spacing_100};
  text-align: left;
`
