import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

/** Frame container used across several components non-explicitly */
export const Frame = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  gap: ${(props) => props.theme.fixedSizes.spacing_100};

  border-radius: ${(props) => props.theme.fixedSizes.spacing_75};
  background: ${(props) => props.theme.colors.wildflower};
`
