import React, { useMemo } from 'react'
import { Box, Text } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

type Modifiers = {
  outside?: boolean
}

type DatePickerModalListItemProps = {
  onSelectDate: () => void
  modifiers: Modifiers
  isSelected: boolean
  date: Date
}

const selectedItemColorsScheme = (theme: Record<string, any>) => ({
  cellBackground: theme.colors.brand['900'],
  textColor: 'white',
})

const defaultItemColorsScheme = (theme: Record<string, any>) => ({
  cellBackground: theme.colors.white,
  textColor: theme.colors.text['900'],
})

const outsideItemColorsScheme = (theme: Record<string, any>) => ({
  cellBackground: theme.colors.white,
  textColor: theme.colors.text['100'],
})

export const DatePickerModalListItem = (props: DatePickerModalListItemProps) => {
  const theme = useTheme()

  const colorScheme = useMemo(() => {
    if (props.modifiers.outside) {
      return outsideItemColorsScheme(theme)
    }

    if (props.isSelected) {
      return selectedItemColorsScheme(theme)
    }

    return defaultItemColorsScheme(theme)
  }, [props.isSelected, props.modifiers, props.date])

  return (
    <ItemContent
      isSelected={props.isSelected}
      onClick={props.onSelectDate}
      hoverColor={theme.colors.brand['100']}
      backgroundColor={colorScheme.cellBackground}
    >
      <Text color={colorScheme.textColor} alignSelf="center">
        {props.date.getDate()}
      </Text>
    </ItemContent>
  )
}

const ItemContent = styled(Box)<{ isSelected: boolean; hoverColor: string }>`
  display: flex;
  justify-content: center;
  border-radius: ${(props) => props.theme.radii.rounded};
  &:hover {
    background-color: ${({ backgroundColor, isSelected, hoverColor }) => (isSelected ? backgroundColor : hoverColor)};
  }
  ${(props) => props.theme.media.phone`
    width: 32px;
    height: 32px;
  `}
  ${(props) => props.theme.media.desktop`
    width: 42px;
    height: 42px;
  `}
  ${(props) => props.theme.media.tablet`
    width: 42px;
    height: 42px;
  `}
`
