import { forwardRef, useEffect, useRef, useState } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { SmallText } from '../Typography'

export interface GuidedListProps {
  readonly title?: string
  readonly items: readonly GuidedListItemProps[]
}

export const GuidedList = (props: GuidedListProps) => {
  const firstItemRef = useRef<any>(null)
  const lastItemRef = useRef<any>(null)
  const [trackStyle, setTrackStyle] = useState<React.CSSProperties>({ display: 'none' })

  useEffect(() => {
    if (firstItemRef.current !== null && lastItemRef.current !== null) {
      // + 3 to adjust for the radius of the bubble
      const firstBubbleTop = firstItemRef.current.offsetTop + 3
      const lastBubbleTop = lastItemRef.current.offsetTop + 3
      const trackHeight = lastBubbleTop - firstBubbleTop

      const offsetLeft = firstItemRef.current.offsetLeft + 3

      return setTrackStyle({
        position: 'absolute',
        height: `${trackHeight}px`,
        left: `${offsetLeft}px`,
        top: `${firstBubbleTop}px`,
      })
    }

    setTrackStyle({ display: 'none' })

    return () => setTrackStyle({ display: 'none' })
  }, [firstItemRef.current, lastItemRef.current, props.items])

  return (
    <GuidedListContainer>
      {props.title && (
        <>
          <GuidedListTitle>
            <SmallText fontWeight={'bold'}>{props.title}</SmallText>
          </GuidedListTitle>
          <Divider />
        </>
      )}
      <GuidedTrackContainer>
        {trackStyle && <Track style={trackStyle} />}
        {props.items.map((item, index) => {
          const ref = index === 0 ? firstItemRef : index === props.items.length - 1 ? lastItemRef : undefined
          return (
            <Box key={`step-${index}`}>
              <GuidedListStep {...item} ref={ref} />
            </Box>
          )
        })}
      </GuidedTrackContainer>
    </GuidedListContainer>
  )
}

export interface GuidedListItemProps {
  readonly label: string
  readonly content: string
}

const GuidedListStep = forwardRef((props: React.PropsWithRef<GuidedListItemProps>, ref) => (
  <StepContainer>
    <BubbleContainer>
      <Bubble ref={ref} />
    </BubbleContainer>
    <StepContentContainer>
      <StepLabel>{props.label}</StepLabel>
      <StepContent>{props.content}</StepContent>
    </StepContentContainer>
  </StepContainer>
))

const GuidedListContainer = styled(Flex)`
  flex-direction: column;
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
`

const GuidedListTitle = styled(Flex)`
  width: 100%;
`

const Divider = styled(Box)`
  width: 100%;
  margin-top: ${(props) => props.theme.fixedSizes.spacing_75};
  margin-bottom: ${(props) => props.theme.fixedSizes.spacing_75};
  border-bottom-width: ${(props) => props.theme.fixedSizes.spacing_12_5};
  background-color: ${(props) => props.theme.colors.dusk};
`

const GuidedTrackContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  padding-left: ${(props) => props.theme.fixedSizes.spacing_0};
  padding-top: ${(props) => props.theme.fixedSizes.spacing_0};

  > :not(:last-child) {
    padding-bottom: ${(props) => props.theme.fixedSizes.spacing_100};
  }
`

const StepContainer = styled(Flex)`
  flex-direction: row;
  align-items: baseline;
`

const BubbleContainer = styled(Box)`
  margin-right: ${(props) => props.theme.fixedSizes.spacing_100};
`

const Bubble = styled(Box)`
  border-radius: ${(props) => props.theme.fixedSizes.spacing_400};
  background-color: ${(props) => props.theme.colors.flavender};
  height: ${(props) => props.theme.fixedSizes.spacing_75};
  width: ${(props) => props.theme.fixedSizes.spacing_75};
`

const StepContentContainer = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
`

const StepLabel = styled(SmallText)`
  font-weight: 700;
  color: ${(props) => props.theme.colors.midnight};
`

const StepContent = styled(SmallText)`
  color: ${(props) => props.theme.colors.midnight};
`

const Track = styled(Box)`
  border-radius: ${(props) => props.theme.fixedSizes.spacing_400};
  background-color: ${(props) => props.theme.colors.flavender};
  width: 5px;
`
