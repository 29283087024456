import { useLocation, useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { deepReplaceContent, EMBED_START_PAYMENT } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useEmbedAnalytics } from '../../../../../../hooks'
import { useAnalytics } from '../../../../../../providers'
import * as Routes from '../../../../../../routing/constants'
import { Clock } from '../../../../../assets'
import { Gap, Headline2, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

export const EmbedStartPayment = () => {
  const analytics = useAnalytics()
  const { embedFlow } = useEmbedAnalytics()
  const navigate = useNavigate()
  const location = useLocation()
  const content = deepReplaceContent(EMBED_START_PAYMENT, {
    bpMonth: location.state.bpMonth,
  })

  const onStartPayment = () => {
    analytics.logEvent(Analytics.Events.EMBED_DYN_INT_START_PAY_CLICK, { embedFlow })
    navigate(Routes.Auth.PHONE)
  }

  return (
    <BasePageLayout
      analyticsScreenName={Analytics.Screens.EMBED_DYN_INT_START_PAY}
      analyticsScreenParams={{ embedFlow }}
    >
      <Container>
        <Gap size="spacing_300" />
        <Clock />
        <Gap size="spacing_200" />
        <Headline2>{content.HEADER}</Headline2>
        <Gap size="spacing_100" />
        <SmallText textAlign="center">{content.BODY}</SmallText>
        <Gap size="spacing_200" />
        <StartPaymentButton onClick={onStartPayment}>{content.CTA}</StartPaymentButton>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
`

const StartPaymentButton = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.midnight};
  border-radius: ${({ theme }) => theme.radii.rounded};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  justify-content: center;
  padding: ${({ theme }) => theme.fixedSizes.spacing_100};
  width: 100%;
`
