import React from 'react'
import { OpenSearchProperty } from '@genoa/domain'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ChevronRightPurple } from '../../../../assets'
import { LargeText, SmallText } from '../../../../components'

interface AddressSelectionListItemProps {
  readonly onClick: () => unknown
  readonly index: number
  readonly property: OpenSearchProperty
}

export const AddressSelectionListItem = (props: AddressSelectionListItemProps) => {
  return (
    <UnitContainer key={`property-container-${props.property.integration_type}-${props.property.property_id}`}>
      <StyledFlex
        flex={1}
        alignItems="center"
        justifyContent="space-between"
        onClick={props.onClick}
        data-testid={`AddressSelectionListItem-${props.index}`}
      >
        <Box>
          <Flex direction="column">
            <HeaderText data-testid="PropertyName">{props.property.property_name ?? ''}</HeaderText>
            <SmallText data-testid="PropertyAddressLine1">{props.property.address_line_1 ?? ''}</SmallText>
            <SmallText data-testid="PropertyAddressCityStateZip">
              {`${props.property.city ?? ''}, ${props.property.state ?? ''} ${props.property.postal_code ?? ''}`}
            </SmallText>
          </Flex>
        </Box>
        <ChevronRightPurple />
      </StyledFlex>
    </UnitContainer>
  )
}

const StyledFlex = styled(Flex)`
  padding: 0px 12px 0px 12px;
`

const UnitContainer = styled(Flex)`
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.theme.colors.hoveredContainer};
  }
`

const HeaderText = styled(LargeText)`
  font-weight: bold;
`
