import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { Box, Stack } from '@chakra-ui/react'

import { useContent } from '../../providers/content'
import { QuestionsAndComments } from '../assets'
import { Headline1, PrimaryButton, SmallText } from '../components'
import { BasePageLayout } from '../layouts'

export function TryAgainView() {
  const navigate = useNavigate()
  const {
    content: { TRY_AGAIN },
  } = useContent()

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.TRY_AGAIN}>
      <Stack mt="20" spacing="5" alignItems="center">
        <QuestionsAndComments />
        <Headline1>{TRY_AGAIN.TITLE}</Headline1>
        <SmallText color="gray.600" textAlign="center">
          {TRY_AGAIN.SUBTITLE}
        </SmallText>
        <SmallText color="gray.600" textAlign="center">
          {TRY_AGAIN.DIRECTIONS}
        </SmallText>
        <Box width="xs">
          <PrimaryButton onClick={() => navigate(-1)}>{TRY_AGAIN.CTA}</PrimaryButton>
        </Box>
      </Stack>
    </BasePageLayout>
  )
}
