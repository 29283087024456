import { useEffect, useMemo, useState } from 'react'
import sha256 from 'crypto-js/sha256'

import { RootState } from '../../../modules'
import { useFees, useUserAccount } from '../../../providers'
import { useAccount } from '../../use-account'
import { useReduxSelector } from '../../use-redux-selector'

// TODO refactor and consolidate into @genoa/domain
export const useTermsAndConditions = () => {
  const [verification, setVerification] = useState('')
  const offerState = useReduxSelector((state: RootState) => state.offerState)
  const offerBody = offerState.offer
  const { installments, subscriptionFee, processingFeePercentage } = useFees()
  const { userAccount } = useUserAccount()
  const { customer } = useAccount()

  const monthlyFeeKey = 'mmf'
  const annualFeeKey = 'amf'

  const parameters = useMemo(() => {
    if (!offerState.initialized || installments.length <= 0) {
      return {
        name: '',
        [monthlyFeeKey]: '$0.00',
        [annualFeeKey]: '$0.00',
        address: '',
        firstPaymentPercentage: '100%',
      }
    }

    let offer = { fee: subscriptionFee, limit: { percentage: installments[0].proportion } }
    const firstPayment = 100 - offer.limit.percentage

    const unitNumber = customer?.address.line_2 ? ` Apt ${customer?.address.line_2},` : ''
    const address = `${customer?.address.line_1},${unitNumber} ${customer?.address.city}, ${customer?.address.state} ${customer?.address.zip_code}`

    return {
      name: [userAccount.firstName, userAccount.lastName].join(' '),
      [monthlyFeeKey]: `$${(offer.fee / 100).toFixed(2)}`,
      [annualFeeKey]: `$${((offer.fee / 100) * 12).toFixed(2)}`,
      address: address || '',
      firstPaymentPercentage: `${firstPayment}%`,
      apr: processingFeePercentage * 12,
    }
  }, [offerState, offerBody, userAccount.firstName, userAccount.lastName, customer])

  const documents = useMemo(() => {
    const defaultDocuments = {
      cla: { version: 'legacy' },
      tila: { version: 'legacy' },
    }

    if (!offerState.initialized) {
      return defaultDocuments
    }

    if (!offerBody || !offerBody.documents) {
      return defaultDocuments
    }

    return {
      cla: offerBody.documents.cla,
      tila: offerBody.documents.tila,
    }
  }, [offerState, offerBody])

  useEffect(() => {
    const id = `${documents.cla.version || 'legacy'}|${documents.tila.version || 'legacy'}`

    const hash = sha256(id).toString()
    setVerification(hash)
  }, [documents])

  return {
    verification,
    documents,
    parameters,
  }
}
