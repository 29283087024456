import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../routing/constants'
import { RentPortalDetailsContainer } from '../../common/property-linking/RentPortalDetailsContainer'

type OnboardingRentPortalDetailsContainerProps = {}

export const OnboardingRentPortalDetailsContainer = (props: OnboardingRentPortalDetailsContainerProps) => {
  const navigate = useNavigate()

  return (
    <RentPortalDetailsContainer
      {...props}
      analyticsScreenName={Analytics.Screens.ONBOARDING_RENT_PORTAL_DETAILS}
      analyticsEventBackClicked={Analytics.Events.RENT_PORTAL_DETAILS_BACK_CLICKED}
      analyticsEventSigninClicked={Analytics.Events.RENT_PORTAL_DETAILS_SIGNIN_CLICKED}
      analyticsEventWhatIfNoPortalClicked={Analytics.Events.RENT_PORTAL_DETAILS_WHAT_IF_NO_PORTAL_CLICKED}
      analyticsEventStartOverClicked={Analytics.Events.RENT_PORTAL_DETAILS_START_OVER_CLICKED}
      onSignInClicked={() => {
        navigate(Routes.Onboarding.RENT_PORTAL_SIGNIN, {
          state: {
            signingInTo: 'property',
          },
        })
      }}
      onStartOver={() => {
        navigate(Routes.Onboarding.ADDRESS)
      }}
    />
  )
}
