import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { ActionLogType } from '@genoa/domain'

import { useHandleRegisterActionLog } from '../../../../hooks/flex2/onboarding-status'
import { useAnalytics, useFees } from '../../../../providers'
import { useScreenView } from '../../../../providers/analytics/use-screen-view'
import * as Routes from '../../../../routing/constants'
import { Approved } from './Approved'

export const ApprovedContainer = () => {
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()

  useScreenView(Analytics.Screens.ONBOARDING_APPROVED)

  const handleContinue = async () => {
    analytics.logEvent(Analytics.Events.APPROVED_SCREEN_CONTINUE_PRESSED)
    await handleRegisterActionLog(ActionLogType.APPROVE_CONFIRMATION)
    navigate(Routes.Onboarding.AUTOPILOT, { replace: true })
  }

  const fees = useFees()

  return (
    <Approved
      loading={loadingRegisterActionLog}
      handleContinue={handleContinue}
      installments={fees.installments}
      processingFeePercentage={fees.processingFeePercentage}
      membershipFee={fees.subscriptionFee}
    />
  )
}

export default ApprovedContainer
