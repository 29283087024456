import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { DotPurple } from '../../assets'
import { SmallText } from '../Typography'
import { PaymentLineItem, PaymentLineItemProps } from './PaymentLineItem'

export interface PaymentScheduleItemProps {
  readonly label: string
  readonly amount: string
  readonly lineItems?: readonly PaymentLineItemProps[]
}

export const PaymentScheduleItem = (props: PaymentScheduleItemProps) => {
  return (
    <PaymentScheduleItemContainer>
      <BulletColumn>
        <DotPurple />
      </BulletColumn>
      <PaymentsColumn>
        <PaymentRow>
          <SmallText>{props.label}</SmallText>
          <SmallText>{props.amount}</SmallText>
        </PaymentRow>
        {props.lineItems && (
          <LineItemsColumn>
            {props.lineItems?.map((item, index) => (
              <PaymentLineItem key={`item-${index}`} {...item} />
            ))}
          </LineItemsColumn>
        )}
      </PaymentsColumn>
    </PaymentScheduleItemContainer>
  )
}

const PaymentScheduleItemContainer = styled(Flex)`
  width: 100%;
`

const PaymentsColumn = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
`

const BulletColumn = styled(PaymentsColumn)`
  flex-grow: 0;
  width: ${(props) => props.theme.fixedSizes.spacing_150};
`

const PaymentRow = styled(Flex)`
  width: 100%;
  justify-content: space-between;
`

const LineItemsColumn = styled(PaymentsColumn)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_50};
`
