import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import { useNavigateFromOnboardingPropertySelection } from '../../../../hooks/navigation'
import * as Routes from '../../../../routing/constants'
import { DirectIntegrationUnitSelectionContainer } from '../../common/property-linking/direct-integration-unit-selection/DirectIntegrationUnitSelectionContainer'

type OnboardingDirectIntegrationUnitSelectionContainerProps = {}

export const OnboardingDirectIntegrationUnitSelectionContainer = (
  props: OnboardingDirectIntegrationUnitSelectionContainerProps
) => {
  const navigate = useNavigate()
  const navigateNext = useNavigateFromOnboardingPropertySelection()

  return (
    <DirectIntegrationUnitSelectionContainer
      {...props}
      analyticsScreenName={Analytics.Screens.ONBOARDING_ADDRESS_UNIT_SELECTION}
      analyticsEventNoneOfTheAboveClicked={Analytics.Events.DIRECT_INTEGRATION_UNIT_SELECTION_NONE_OF_THE_ABOVE_CLICKED}
      analyticsEventStartOverClicked={
        Analytics.Events.DIRECT_INTEGRATION_UNIT_SELECTION_RESTART_WITH_NEW_ADDRESS_CLICKED
      }
      analyticsEventUnitSelected={Analytics.Events.DIRECT_INTEGRATION_UNIT_SELECTION_SELECTED_CLICKED}
      analyticsEventUnitConfirmClicked={Analytics.Events.DIRECT_INTEGRATION_CONFIRM_ADDRESS_CLICKED}
      analyticsEventContactSupportClicked={Analytics.Events.DIRECT_INTEGRATION_UNIT_SELECTION_CONTACT_SUPPORT_CLICKED}
      onStartOver={() => {
        navigate(Routes.Onboarding.ADDRESS)
      }}
      onNext={navigateNext}
    />
  )
}
