import { createReducer } from '@reduxjs/toolkit'

import * as AuthActions from '../auth/actions'
import * as Actions from './actions'
import { OnboardingState } from './types'

export const initialState: OnboardingState = {
  contact: {},
  phoneNumber: {
    formatted: '',
    extracted: '',
  },
  rentAmount: {
    amount: '',
  },
  signUpCompleted: {},
  softCreditCheck: {},
  coBrandingProperty: undefined,
  consolidatedExperience: false,
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(AuthActions.registerSignOutAction, (_state, _action) => {
      return { ...initialState }
    })
    .addCase(Actions.resetOnboardingAction, (_state, _action) => {
      return { ...initialState }
    })
    .addCase(Actions.setContactAction, (state, action) => {
      state.contact = action.payload
    })
    .addCase(Actions.setConsolidatedExperienceAction, (state, action) => {
      state.consolidatedExperience = action.payload
    })
    .addCase(Actions.setCoBrandingPropertyAction, (state, action) => {
      state.coBrandingProperty = action.payload
    })
    .addCase(Actions.setSoftCreditCheckInfo, (state, action) => {
      state.softCreditCheck = action.payload
    })
    .addCase(Actions.setPhoneNumberAction, (state, action) => {
      state.phoneNumber = action.payload
    })
    .addCase(Actions.setRentAmountAction, (state, action) => {
      state.rentAmount = action.payload
    })
    .addCase(Actions.setSignUpCompletedAction, (state, action) => {
      state.signUpCompleted[action.payload.id] = action.payload.signUpCompleted
    })
})
