import React from 'react'

import { useIsEmbed } from '../../../../hooks'
import { ConfirmSMSText } from './ConfirmSMSText'
import { EmbedConfirmSMSText } from './EmbedConfirmSMSText'

export const ConfirmSMSTextContainer = () => {
  const isEmbed = useIsEmbed()
  if (isEmbed) {
    return <EmbedConfirmSMSText />
  }
  return <ConfirmSMSText />
}
