import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { CircleCheckmark } from '../../../assets'
import { Text } from '../../../components'

type ResumeOnboardingStepProps = {
  stepName: string
  isCompleted?: boolean
  isCurrentStep?: boolean
  onStepClicked: (step: string) => void
}

export const ResumeOnboardingStep = (props: ResumeOnboardingStepProps) => {
  const handleOnStepClicked = () => {
    props.onStepClicked(props.stepName)
  }

  return (
    <OnboardingStepContainer
      data-testid="ResumeOnboardingContainer"
      isCurrentStep={props.isCurrentStep}
      onClick={handleOnStepClicked}
    >
      <StepDetailContainer flex={1} alignItems="center" justifyContent="left">
        {props.isCompleted ? <CircleCheckmark /> : <StepNotStarted />}
        <Box>
          <Flex direction="column">
            <StepName data-testid="UnitAddressLine1" isNotStarted={!props.isCompleted && !props.isCurrentStep}>
              {props.stepName}
            </StepName>
          </Flex>
        </Box>
      </StepDetailContainer>
    </OnboardingStepContainer>
  )
}

interface OnboardingStepProps {
  readonly isCurrentStep?: boolean
}

const OnboardingStepContainer = styled(Flex)<OnboardingStepProps>`
  background-color: ${(props) => (props.isCurrentStep ? props.theme.colors.wildflower : props.theme.colors.white)};
  border-radius: 8px;
  height: 54px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 12px;
  padding-left: 2px;
  ${(props) => (props.isCurrentStep ? 'border: 2px solid #b28cf4;' : 'border: 2px solid transparent;')}
`

const StepDetailContainer = styled(Flex)`
  padding: 0px 12px 0px 12px;
`
const StepNotStarted = styled(Flex)`
  border: 2px solid ${(props) => props.theme.colors.storm};
  border-radius: 100px;
  height: 24px;
  width: 24px;
`

interface StepTextStatusProps {
  readonly isNotStarted?: boolean
}

const StepName = styled(Text)<StepTextStatusProps>`
  color: ${(props) => (props.isNotStarted ? props.theme.colors.text[100] : props.theme.colors.text[900])};
  padding-left: 12px;
`
