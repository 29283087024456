import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { DATE_LENGTH, isDOBValid, isValidITIN, isValidSSN, SLCType } from '@genoa/domain'
import {
  OfferStates,
  OrchestrationEvaluateOfferResponse,
  OrchestrationOfferResponse,
  useEvaluateOffer,
} from '@genoa/middle-end'
import { yupResolver } from '@hookform/resolvers/yup'
import { AxiosResponse } from 'axios'
import * as yup from 'yup'

import { useAuthState } from '../../../../../contexts'
import { useAutopilotEligibility, useGeometricInterval, useReduxAction, useReduxSelector } from '../../../../../hooks'
import { useSecureLineOfCredit, useTrackExposureSecureLineOfCredit } from '../../../../../hooks/secure-line-of-credit'
import { useAccount } from '../../../../../hooks/use-account'
import { RootState } from '../../../../../modules'
import { setOfferAction } from '../../../../../modules/flex2/offer'
import { setPricingOfferAction } from '../../../../../modules/flex2/pricing-offer'
import { useOfferState } from '../../../../../modules/flex2/use-state'
import { useAnalytics, useEnhancedTracking, useIterable, useLogger } from '../../../../../providers'
import {
  createUnderwritingApprovedEvent,
  createUnderwritingDeniedEvent,
} from '../../../../../providers/iterable/user-events'
import * as Routes from '../../../../../routing/constants'
import { DateOfBirth, ssn as SSNMask } from '../../../../components/Input'
import { CreditBuilderWaitingOffer } from '../../credit-builder/soft-credit-check'
import { SLCSoftCreditCheck, SoftCreditCheckState } from './SLCSoftCreditCheck'
import { useIncomeCollectionExperiment } from './use-income-collection-experiment'
import { useSoftCreditCheckModal } from './use-soft-credit-check-modal'
import { WaitingOffer } from './WaitingOffer'

const ssnPlaceholder = 'XXX - XX - XXXX'

const isValidResponseData = (data: any): data is OrchestrationOfferResponse => {
  return typeof data === 'object' && 'risk_offer' in data
}

const softCreditCheckSchema = yup.object({
  dob: yup.string().required('Date of Birth is required').trim(),
  ssn: yup.string().required('SSN is required').trim(),
  income: yup.string(),
})

export const SLCSoftCreditCheckContainer = () => {
  const [loadingEvaluation, setLoadingEvaluation] = useState(false)

  const logger = useLogger('SoftCreditCheckContainer')
  const setOfferState = useReduxAction(setOfferAction)
  const setPricingOfferState = useReduxAction(setPricingOfferAction)
  const isIncomeCollectionEnabled = useIncomeCollectionExperiment()
  const navigate = useNavigate()
  const analytics = useAnalytics()
  const { trackUnderwritingRequested } = useEnhancedTracking()
  const rentAmount = useReduxSelector(({ onboarding }: RootState) => onboarding?.rentAmount?.amount)
  const iterable = useIterable()
  const { refetchEligibility, isUserEligibleForAutopilot } = useAutopilotEligibility()
  const { isEnabledForSLC, slcType, isADO } = useSecureLineOfCredit()
  const { trackSLCExposure } = useTrackExposureSecureLineOfCredit()
  const softCreditCheckModal = useSoftCreditCheckModal()

  const {
    clearErrors,
    control,
    formState: { errors: formErrors },
    getValues,
    handleSubmit,
    setError,
    setValue,
  } = useForm<SoftCreditCheckState>({
    resolver: yupResolver(softCreditCheckSchema),
    mode: 'onChange',
  })

  const { start, stop } = useGeometricInterval(
    useCallback((interval, elapsed) => {
      analytics.logEvent(Analytics.Events.ONBOARDING_WAITING_OFFER, { interval, elapsed })
    }, []),
    15000
  )

  const redirectToContactSupport = () => {
    navigate(Routes.Onboarding.CONTACT_SUPPORT, { replace: true })
  }

  const offerState = useOfferState()
  const { billerConnection } = useAccount()
  const { uid } = useAuthState()

  const [{ response }, evaluateOffer] = useEvaluateOffer()

  const redirectToNext = async () => {
    trackSLCExposure(isEnabledForSLC)
    if (isADO) {
      return navigate(Routes.Onboarding.APPROVED)
    }
    return navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE, { replace: true })
  }

  const handleOfferEvaluationResponse = async (
    response: AxiosResponse<OrchestrationEvaluateOfferResponse> | undefined
  ) => {
    if (response) {
      stop()
      if (response.status === 201 && isValidResponseData(response.data)) {
        const offer = response.data.risk_offer
        const pricingoffer = response.data.pricing_offer

        if (!offer || !pricingoffer) {
          logger.error('Evaluate Offer Failed', `missing data, risk_offer: ${!offer}, pricing_offer: ${!pricingoffer}`)
          redirectToContactSupport()
          return
        }

        setOfferState({ initialized: true, offer })
        setPricingOfferState({ pricing_offer: pricingoffer })

        analytics.logEvent(Analytics.Events.ONBOARDING_WAITING_OFFER_FOUND)
        switch (offer.offer_state) {
          case OfferStates.PENDING_ACCEPT:
            iterable.addEvent(createUnderwritingApprovedEvent())
            redirectToNext()
            break
          case OfferStates.DENIED:
            const dataFields = { denial_reason: 'Denied' }
            iterable.addEvent(createUnderwritingDeniedEvent({ dataFields }))
            navigate(Routes.Onboarding.UPSELL_ELIGIBILITY, { replace: true })
            break
          default:
            logger.error('Evaluate Offer Failed', `offer_state is not PendingAccept on offer: ${offer.offer_id}`)
            redirectToContactSupport()
            break
        }
      } else if (response.status >= 400 && response.status < 500) {
        const errorReason = response.data && !isValidResponseData(response.data) ? response.data.reason : 'unknown'
        logger.error('Evaluate Offer Failed', `status: ${status} - isRelink: ${false} - reason: ${errorReason}`)
        softCreditCheckModal.openErrorModal(response.status, response.data)
      } else {
        redirectToContactSupport()
        logger.error('Evaluate Offer Failed', `Status: ${response.status}`)
      }
    }
  }

  useEffect(() => {
    if (slcType) {
      iterable.setProductType(slcType)
    }
  }, [slcType])

  useEffect(() => {
    if (response && !loadingEvaluation) {
      handleOfferEvaluationResponse(response)
    }
  }, [response, stop, loadingEvaluation, isUserEligibleForAutopilot])

  const onSetSSN = (ssn: string) => {
    const maskedSSN = SSNMask.mask(ssn)
    clearErrors('ssn')
    setValue('ssn', maskedSSN)
  }

  const onSetDate = (dateOfBirth: string) => {
    const maskedDate = DateOfBirth.mask(dateOfBirth)
    clearErrors('dob')
    setValue('dob', maskedDate)
  }

  const onSetIncome = (income: string) => {
    clearErrors('income')
    setValue('income', income)
  }

  const getDoB = () => {
    const date = getValues('dob')
    const asDate = DateOfBirth.parseDate(date)
    const day = asDate.getDate() <= 9 ? `0${asDate.getDate()}` : `${asDate.getDate()}`

    const month = asDate.getMonth() + 1 <= 9 ? `0${asDate.getMonth() + 1}` : `${asDate.getMonth() + 1}`

    return `${asDate.getFullYear()}-${month}-${day}`
  }

  const checkIsDobValid = () => {
    const date = getValues('dob')
    if (date.length < DATE_LENGTH - 2 || date.length > DATE_LENGTH) {
      return 'Invalid date'
    }

    return isDOBValid(date) ? '' : 'You must be at least 18 years old'
  }

  const onClickContinue = handleSubmit(async (formValues: SoftCreditCheckState) => {
    const { ssn, income } = formValues
    analytics.logEvent(Analytics.Events.SOFT_CREDIT_CHECK_CTA_CLICKED)

    if (!uid || !billerConnection?.biller_account_public_id) {
      logger.error('evaluateOffer', `info: missing customerAccountState data`)
      return
    }

    const isSSNValid = isValidSSN(ssn)
    const dobError = checkIsDobValid()
    const incomeError = isIncomeCollectionEnabled && income.length > 8

    if (isValidITIN(ssn)) {
      setError('ssn', { type: 'Social security number', message: 'ITIN is not currently supported' })
    } else if (!isSSNValid) {
      setError('ssn', { type: 'Social security number', message: 'Social security number is invalid' })
    } else {
      clearErrors('ssn')
    }

    if (dobError) {
      setError('dob', { type: 'Invalid date', message: dobError })
    } else {
      clearErrors('dob')
    }

    if (incomeError) {
      setError('income', { type: 'Income', message: 'Income is too high' })
    } else {
      clearErrors('income')
    }

    if (!isSSNValid || dobError || incomeError) {
      return
    }
    start()
    setLoadingEvaluation(true)
    trackUnderwritingRequested(slcType, rentAmount)

    try {
      await evaluateOffer({
        customerId: uid,
        biller_account_public_id: billerConnection.biller_account_public_id,
        offer_id: offerState.offer.offer_id,
        date_of_birth: getDoB(),
        estimated_income_cent: isIncomeCollectionEnabled ? +income * 100 : undefined,
        ssn,
      })
      await refetchEligibility()
    } catch (error: any) {
      setLoadingEvaluation(false)
      logger.error('evaluateOffer - catch', `info: ${error?.message}`)
      redirectToContactSupport()
      stop()
    } finally {
      setLoadingEvaluation(false)
    }
  })

  if (loadingEvaluation) {
    if (slcType === SLCType.CREDIT_BUILDER) {
      return <CreditBuilderWaitingOffer isLoading={loadingEvaluation} />
    } else {
      return <WaitingOffer />
    }
  }

  return (
    <SLCSoftCreditCheck
      analyticsScreenName={Analytics.Screens.SOFT_CREDIT_CHECK}
      onClickContinue={onClickContinue}
      ssnPlaceholder={ssnPlaceholder}
      onDateChange={onSetDate}
      onSetSSN={onSetSSN}
      onSetIncome={onSetIncome}
      isLoading={loadingEvaluation}
      control={control}
      formErrors={formErrors}
      getValues={getValues}
    />
  )
}
