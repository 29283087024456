import { Analytics } from '@genoa/analytics'
import { EMBED_SIMPLIFIED_PAYMENTS_PAY_NOW_LOADING as content } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { HeroSocks as HangTightImage } from '../../../../../assets'
import { Gap, Headline1, SmallText, Text } from '../../../../../components'
import { SpinnerLoading } from '../../../../../components/SpinnerLoading/SpinnerLoading'
import { BasePageLayout } from '../../../../../layouts'

export const PayNowLoading = () => (
  <BasePageLayout analyticsScreenName={Analytics.Screens.EMBED_PAY_NOW_LOADING}>
    <Gap size="spacing_400" />

    <Flex align="center" justify="center">
      <HangTightImage />
    </Flex>

    <Gap size="spacing_350" />

    <Container px="lg">
      <Headline1>{content.HEADER}</Headline1>
      <Gap size="spacing_150" />

      <Text>{content.BODY}</Text>
      <Gap size="spacing_150" />

      <SmallText fontWeight="bold">{content.NOTICE}</SmallText>
      <Gap size="spacing_400" />

      <Box>
        <SpinnerLoading />
      </Box>
    </Container>
  </BasePageLayout>
)

const Container = styled(Flex)`
  align-items: center;
  flex-direction: column;
  height: 100%;
`
