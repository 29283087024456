import { createReducer } from '@reduxjs/toolkit'

import * as Actions from './actions'
import { CustomerWalletState } from './types'

export const initialState: CustomerWalletState = {
  defaultCard: {
    brand: '',
    card_mask: '',
    card_type: '',
    stripe_customer_id: '',
    is_active: false,
    stripe_payment_method_id: '',
    fingerprint: '',
    issuer: '',
    is_stripe_connect_setup: false,
  },
  initialized: false,
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions.resetCustomerCardAction, (_state, _action) => {
      return { ...initialState }
    })
    .addCase(Actions.setCustomerCardAction, (_state, action) => {
      return { ...action.payload, initialized: true }
    })
})
