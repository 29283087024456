import { GetOnboardingStatusResponse, useGetOnboardingStatus } from '@genoa/middle-end'
import {
  ResetLoadedPayload,
  resetOnboardingStatusAction,
  setOnboardingStatusAction,
  setOnboardingStatusLoadedAction,
} from '@genoa/state-management'
import { AxiosPromise } from 'axios'

import { useLogger } from '../../../providers/logger'
import { useReduxAction } from '../../use-redux-action'

export const useHandleGetOnboardingStatus = () => {
  const loggerV2 = useLogger('useHandleGetOnboardingStatus')
  const [{ loading: loadingOnboardingStatus }, getOnboardingStatus] = useGetOnboardingStatus()
  const setOnboardingStatusState = useReduxAction(setOnboardingStatusAction)
  const reset = useReduxAction<ResetLoadedPayload | void>(resetOnboardingStatusAction as any)
  const setLoaded = useReduxAction(setOnboardingStatusLoadedAction)

  const handleGetOnboardingStatus = (
    customerPublicId: string,
    variantId?: string
  ): AxiosPromise<GetOnboardingStatusResponse> => {
    if (!customerPublicId) {
      loggerV2.error('handleGetOnboardingStatus', 'handleGetOnboardingStatus called without a customerPublicId')
      return Promise.resolve({}) as AxiosPromise<GetOnboardingStatusResponse>
    }
    return getOnboardingStatus({ customerPublicId, variantId })
      .then((response) => {
        if (response.status === 200 && response.data) {
          setOnboardingStatusState({ ...response.data })
        } else if (response.status !== 404) {
          loggerV2.error('handleGetOnboardingStatus', 'unexpected status', { status: response.status })
          reset({ loaded: true })
        } else {
          reset({ loaded: true })
        }
        return response
      })
      .catch((error: any) => {
        setLoaded(true)
        loggerV2.error('handleGetOnboardingStatus', `error: ${error?.message}`)
        return error
      })
  }

  return {
    handleGetOnboardingStatus,
    loadingOnboardingStatus,
  }
}
