import { OfferStates } from '@genoa/middle-end'
import { createReducer, PayloadAction } from '@reduxjs/toolkit'

import * as Actions from './actions'
import { OfferState } from './types'

export const initialState: OfferState = {
  offer: {
    offer_id: '',
    offer_state: OfferStates.INITIATED,
    fees: {
      membership_fee: {
        amount_cent: 0,
        schedule: 'P1M',
      },
      processing_fee: {
        debit_card_processing_fee_bps: 0,
      },
      property_passthrough_fee_amount: 300,
    },
    estimated_bill_amount_cent: 0,
    documents: {
      cla: {
        version: '',
      },
      tila: {
        version: '',
      },
    },
    installments: [
      {
        proportion: 0,
        due_offset: '',
      },
      {
        proportion: 0,
        due_offset: '',
      },
    ],
    kyc_pass: false,
    credit_limit_pass: false,
    credit_limit_amount_cent: 0,
    initiation_time: new Date().toISOString(),
    underwriting_completion_time: null,
    acceptance_time: null,
  },
  initialized: false,
  repayment_day: undefined,
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions.resetOfferAction, (_state, _action) => {
      return { ...initialState }
    })
    .addCase(Actions.setOfferAction, (_state, action) => {
      return { ...action.payload }
    })
    .addCase(Actions.setEstimatedRentAmountAction, (_state, action) => {
      return { ...action.payload }
    })
    .addCase(Actions.setRepaymentDayAction, (state, action) => {
      state.repayment_day = action.payload
    })
})
