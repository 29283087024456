import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { AmplitudeFeatureFlag, VariantValue } from '@genoa/experiments'
import * as amplitude from '@amplitude/analytics-browser'
import { Identify } from '@amplitude/analytics-core'
import { AmplitudeReturn, BaseEvent, EventOptions, LogLevel, Result } from '@amplitude/analytics-types'
import { Experiment, ExperimentClient } from '@amplitude/experiment-js-client'
import { getAuth } from 'firebase/auth'

import { useAuthState } from '../../contexts'
import { useReduxSelector } from '../../hooks'
import { RootState } from '../../modules'
import { SignupStatus, USER_SIGNUP_STATUS } from '../analytics'
import { useFirebase } from '../firebase/firebase-provider'
import { logger } from '../logger'
import { useConsentManagement } from './consent-management'
import { useSharedEventProperties } from './enhanced-tracking'
import { GoogleTagManagerDestinationPlugin } from './plugins/gtm'
import { AmplitudeContextData, AmplitudeContextValues, AmplitudeProviderProps, FLAG_ON } from './types'

type AmplitudeProxy = {
  setUserId(userId: string | undefined): void
  setDeviceId(deviceId: string): void
  logEvent(
    eventInput: string | BaseEvent,
    eventProperties?: Record<string, any> | undefined,
    eventOptions?: EventOptions | undefined
  ): AmplitudeReturn<Result>
  identify(identify: Identify, eventOptions?: EventOptions | undefined): AmplitudeReturn<Result>
  Identify: typeof Identify
}

// useRef() wrapper around {uid, isAnonymous} of useAuthState() to ensure isEnhancedTrackingEnabled function doesn't
// evaluate stale auth values
const useAuthStateRef = () => {
  const { uid, isAnonymous } = useAuthState()
  const ref = useRef({ uid, isAnonymous })
  useEffect(() => {
    ref.current.uid = uid
    ref.current.isAnonymous = isAnonymous
  }, [uid, isAnonymous])

  return ref
}

export const AmplitudeContext = createContext<AmplitudeContextData>(AmplitudeContextValues)

export const AmplitudeProvider = ({
  children,
  apiKey,
  deploymentApiKey,
  version,
  gtmConfig,
  consentManagementConfig,
}: AmplitudeProviderProps) => {
  const { uid, isAnonymous } = useAuthState()
  const [experimentLoaded, setExperimentLoaded] = useState(false)
  const firebaseInitialized = useReduxSelector((state: RootState) => state.auth.firebaseInitialized)
  const firebaseApp = useFirebase()
  const [amplitudeDeviceId, setAmplitudeDeviceId] = useState<string>()

  // Fetch all variants
  const fetchVariants = async (experiment: ExperimentClient, userId?: string) => {
    // Make sure that even if this call fails we set experiments loaded so we can display the app
    try {
      const experimentInstance = await experiment.fetch(userId ? { user_id: userId } : undefined)
      setExperimentLoaded(true)
      return experimentInstance
    } catch (error: any) {
      logger.error(
        'AmplitudeProvider - fetchVariants',
        error?.message || 'There was an unknown error fetching variants'
      )
      setExperimentLoaded(true)
    }
  }

  // Get amplitude to reuse on provider
  const amplitudeInstance = useMemo(() => {
    amplitude
      .init(apiKey, {
        logLevel: LogLevel.Error,
        appVersion: version,
      })
      .promise.then(() => {
        setAmplitudeDeviceId(amplitude.getDeviceId())
      })

    const { setUserId, logEvent, identify, setDeviceId } = amplitude
    return {
      setUserId,
      logEvent,
      identify,
      Identify: amplitude.Identify,
      setDeviceId,
    } as AmplitudeProxy
  }, [apiKey])

  // Set amplitude userId and avoid setting when a user is Anonymous
  useEffect(() => {
    if (!isAnonymous && uid) {
      amplitudeInstance.setUserId(uid)
    } else {
      amplitudeInstance.setUserId(undefined)
    }
  }, [uid, isAnonymous, amplitudeInstance])

  // Init amplitude and experiment
  const experiment: ExperimentClient = useMemo(() => {
    const experiment = Experiment.initializeWithAmplitudeAnalytics(deploymentApiKey, {
      automaticExposureTracking: true,
    })
    try {
      // If the user_signup_status exists, user has already signed up
      const userSignupStatus = localStorage.getItem(USER_SIGNUP_STATUS)
      if (userSignupStatus === SignupStatus.SignedUp) {
        setUserProperty(Analytics.UserProperties.SIGNED_UP, 'signed_up')
      }
    } catch (error: any) {
      logger.error(
        'AmplitudeProvider - localStorage.getItem',
        error?.message || 'There was an unknown error reading from localstorage'
      )
    }

    return experiment
  }, [apiKey, deploymentApiKey, amplitudeInstance])

  useEffect(() => {
    if (uid) {
      const auth = getAuth(firebaseApp)
      if (!isAnonymous) {
        const firebaseLastSignInTime = auth.currentUser?.metadata.lastSignInTime
        if (firebaseLastSignInTime) {
          const lastSignInTime = new Date(firebaseLastSignInTime).toISOString().slice(0, 19)
          setUserProperty(Analytics.UserProperties.LAST_SIGN_IN_TIMESTAMP, lastSignInTime)
        }
        // Based on firebase user creationTime, identify once the onboarding started user property
        const firebaseCreationTime = auth.currentUser?.metadata.creationTime
        if (firebaseCreationTime) {
          const creationTime = new Date(firebaseCreationTime).toISOString().slice(0, 19)
          setUserProperty(Analytics.UserProperties.ONBOARDING_STARTED_TIMESTAMP, creationTime, true)
        }
        // Set signed up user property when we have a uid that is not anonymous(this means user has signed up)
        setUserProperty(Analytics.UserProperties.SIGNED_UP, 'signed_up')
      }
    }
  }, [uid, isAnonymous, experiment])

  useEffect(() => {
    if (firebaseInitialized && experiment && !experimentLoaded) {
      if (uid && !isAnonymous) {
        fetchVariants(experiment, uid)
      } else {
        fetchVariants(experiment)
      }
    }
  }, [firebaseInitialized, experiment, experimentLoaded, uid, isAnonymous])

  const refetchExperimentsForUser = useCallback(
    (userId: string) => {
      return fetchVariants(experiment, userId)
    },
    [experiment]
  )

  // Get experiment variant but only return the value
  const getVariantValue = useCallback(
    (variantId: string) => {
      const variant = experiment.variant(variantId)
      if (variant) {
        return variant.value
      }
    },
    [experiment]
  )

  const isFeatureFlagEnabled = useCallback(
    (featureFlag: string) => getVariantValue(featureFlag) === FLAG_ON,
    [getVariantValue]
  )

  // Get experiment variant but only return the payload, can use a generic type to define the payload
  const getVariantPayload = useCallback(
    function getVariantPayload<T>(variantId: string): T | null {
      const variantObject = experiment.variant(variantId)

      return variantObject.payload as T
    },
    [experiment]
  )

  // Get experiment variant (value and payload), can use a generic type to define the payload
  const getVariant = useCallback(
    function getVariant(variantId: string): VariantValue {
      const variant = experiment.variant(variantId)
      return {
        value: variant?.value,
      }
    },
    [experiment]
  )
  // Set user properties using identify api
  const setUserProperty = useCallback(
    (propertyName: Analytics.UserProperties, propertyValue: any, setOnce?: boolean) => {
      const identify = new amplitudeInstance.Identify()
      if (setOnce) {
        identify.setOnce(propertyName, propertyValue)
      } else {
        identify.set(propertyName, propertyValue)
      }
      amplitudeInstance.identify(identify)
    },
    [amplitudeInstance]
  )

  // Set user id using amplitude api
  const setUserId = useCallback(
    (userId: string | null) => {
      if (userId === null) {
        amplitudeInstance.setUserId(undefined)
      } else {
        if (userId.length < 5) {
          const newUserId = `mock${userId}`
          logger.warn(
            'AmplitudeProvider - setUserId',
            `Unable to set user id of length less than 5 characters; provided '${userId}', instead setting '${newUserId}'`
          )
          amplitudeInstance.setUserId(newUserId)
        } else {
          amplitudeInstance.setUserId(userId)
        }
      }
    },
    [amplitudeInstance]
  )

  // set device id using the Amplitude API
  const setDeviceId = useCallback(
    (id: string) => {
      amplitudeInstance.setDeviceId(id)
    },
    [amplitudeInstance]
  )

  // Log event using amplitude api
  const logEvent = useCallback(
    (eventInput: string, eventProperties?: Record<string, any>) => {
      amplitudeInstance.logEvent(eventInput, eventProperties)
    },
    [amplitudeInstance]
  )

  // Consent Management
  const {
    isInitialized: isConsentManagementInitialized,
    isLoaded: isConsentManagementLoaded,
    consentManagementProxy,
  } = useConsentManagement(
    uid,
    isAnonymous,
    () => isFeatureFlagEnabled(AmplitudeFeatureFlag.ConsentManagementKillSwitch),
    consentManagementConfig?.scriptSrc
  )

  // Enhanced Tracking and Google Tag Manager integration...
  const {
    properties: enhancedTrackingSharedEventProperties,
    addProperties: addAndGetAllEnhancedTrackingSharedEventProperties,
  } = useSharedEventProperties()

  const authStateRef = useAuthStateRef()
  const isEnhancedTrackingEnabled = () => {
    const { uid, isAnonymous } = authStateRef.current
    return uid !== null && !isAnonymous && experiment.variant('consumer-marketing-tags').value === 'on'
  }

  const getEnhancedTrackingSharedEventProperties = () => enhancedTrackingSharedEventProperties

  // add Google Tag Manager Destination Plugin if enhanced tracking is on
  const [gtmLoaded, setGtmLoaded] = useState(false)
  useEffect(() => {
    if (isConsentManagementInitialized && !gtmLoaded && isEnhancedTrackingEnabled()) {
      const gtmPlugin = GoogleTagManagerDestinationPlugin.newInstance(gtmConfig)
      if (gtmPlugin !== undefined) {
        amplitude.add(gtmPlugin)
        if (isConsentManagementLoaded) {
          // https://docs.transcend.io/docs/articles/consent-management/configuration/google-consent-mode
          gtmPlugin.writeToDataLayer('set', 'developer_id.dODQ2Mj', true)
        }
      }
      setGtmLoaded(true)
    }
  }, [isEnhancedTrackingEnabled, amplitudeInstance, gtmLoaded, isConsentManagementInitialized])

  // Build context values
  const amplitudeContext: AmplitudeContextData = {
    experiment,
    experimentLoaded,
    enhancedTracking: {
      isEnabled: isEnhancedTrackingEnabled,
      addAndGetAllSharedEventProperties: addAndGetAllEnhancedTrackingSharedEventProperties,
      getAllSharedEventProperties: getEnhancedTrackingSharedEventProperties,
    },
    consentManagement: consentManagementProxy,
    getVariantPayload,
    getVariantValue,
    getVariant,
    setUserProperty,
    setUserId,
    setDeviceId,
    logEvent,
    refetchExperimentsForUser,
    isFeatureFlagEnabled,
    deviceId: amplitudeDeviceId,
  }

  if (!children) {
    return null
  }

  return <AmplitudeContext.Provider value={amplitudeContext}>{children}</AmplitudeContext.Provider>
}

export const useAmplitude = () => useContext(AmplitudeContext)
