import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { SLCType } from '@genoa/domain'

import { useSecureLineOfCredit } from '../../../../hooks'
import * as Routes from '../../../../routing/constants'
import { SLCCustomizeScheduleContainer } from '../../common/slc/customize-your-schedule'

export const OnboardingSLCCustomizeScheduleContainer = () => {
  const navigate = useNavigate()
  const { isADO } = useSecureLineOfCredit()
  const nextRoute = isADO ? Routes.Onboarding.CARD_STAGE_SELECTION : Routes.Onboarding.OFFER_DETAILS

  return (
    <SLCCustomizeScheduleContainer
      analyticsScreenName={Analytics.Screens.CUSTOMIZE_YOUR_SCHEDULE}
      onNext={() =>
        navigate(nextRoute, {
          state: isADO ? { nextRoute: Routes.Onboarding.OFFER_DETAILS } : undefined,
        })
      }
    />
  )
}
