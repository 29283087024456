import { useMemo } from 'react'
import { Agreement, Installment, PaymentMethodType } from '@genoa/domain'
import { deepReplaceContent, EMBED_RELINK_ACCEPT_OFFER_DETAILS, getAcceptanceOfferContent } from '@genoa/screen-content'
import { getDateTime, getResumeDate } from '@genoa/utils'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { useContentOverhaul, useFlexAnywhereUser, useIsEmbed } from '../../../../hooks'
import { useSecureLineOfCredit } from '../../../../hooks/secure-line-of-credit'
import { useCustomerWalletState } from '../../../../modules/flex2/use-state'
import { AutopayToggle, Headline1, PrimaryButton, SmallText } from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { AcceptanceOfferAuthorize } from './AcceptanceOfferAuthorize'
import { PaymentSchedule } from './payment-schedule'

type AcceptanceOfferDetailsProps = {
  onLearnMore: () => unknown
  onMembershipLearnMore: () => unknown
  onChooseDate: () => unknown
  onAcceptTerms: () => unknown
  onNext: () => unknown
  loading: boolean
  termsAccepted: boolean
  rentAmount: number
  offerFee: number
  payments: Installment[]
  ctaTitle: string
  showExtraFees?: boolean
  processingFeePercentage?: number
  membershipFeeReason?: string
  agreements: Agreement[]
  isRelink?: boolean
}

export const AcceptanceOfferDetails = (props: AcceptanceOfferDetailsProps) => {
  const customerWalletState = useCustomerWalletState()
  const { isSimplifiedPaymentsEnabled, isEnabledForSLC, isPricingEnabled } = useSecureLineOfCredit()
  const isOutOfNetworkUser = useFlexAnywhereUser()
  const theme = useTheme()
  const isEmbed = useIsEmbed()

  const { onboardingEnabled } = useContentOverhaul()

  const content = useMemo(() => {
    const acceptanceOfferContent = getAcceptanceOfferContent({
      isOutOfNetworkUser,
      isEnabledForSLC,
      contentOverhaulEnabled: onboardingEnabled,
      isEmbed,
    })

    const today = getDateTime()
    const resumeDate = getResumeDate(today)

    return deepReplaceContent(acceptanceOfferContent, {
      date: resumeDate.monthLong || 'next',
      fee: String(props.processingFeePercentage || 0),
    })
  }, [isOutOfNetworkUser, isEnabledForSLC, onboardingEnabled, isEmbed, props.processingFeePercentage])

  const disclaimer =
    customerWalletState?.defaultCard?.card_type === PaymentMethodType.CREDIT
      ? content.FEE_DISCLAIMER_CREDIT
      : content.FEE_DISCLAIMER

  return (
    <BasePageLayout>
      <Box height={theme.fixedSizes.spacing_200} />

      <Headline1 data-testid="AcceptanceOfferDetailsHeader">
        {props.isRelink ? EMBED_RELINK_ACCEPT_OFFER_DETAILS.HEADER : content.HEADER}
      </Headline1>

      <Box minH={theme.fixedSizes.spacing_150} />

      <SmallText data-testid="AcceptanceOfferDetailsBody">
        {content.BODY_1}
        {content.BODY_BOLD && <b>{content.BODY_BOLD}</b>}
        {content.BODY_2}
      </SmallText>

      <Box minH={theme.fixedSizes.spacing_250} />

      <PaymentSchedule
        payments={props.payments}
        fee={props.offerFee}
        onLearnMore={props.onLearnMore}
        onMembershipLearnMore={props.onMembershipLearnMore}
        onRepaymentDayChange={props.onChooseDate}
        showExtraFees={props.showExtraFees}
        showProcessingFeePercentage={!!props.processingFeePercentage}
        membershipFeeReason={props.membershipFeeReason}
        feeDisclaimerLabel={disclaimer}
        initialPaymentLabel={content.INITIAL_PAYMENT_LABEL}
        hideChangeDate={isEnabledForSLC}
        isSimplifiedPaymentsEnabled={isSimplifiedPaymentsEnabled}
        isEnabledForSLC={isEnabledForSLC}
        showInterestFee={isPricingEnabled}
      />

      <Box height="32px" />

      {isEmbed && (
        <>
          <AutopayToggle />
          <Box height={theme.fixedSizes.spacing_150} />
        </>
      )}

      <AcceptanceOfferAuthorize
        agreeText={props.isRelink ? EMBED_RELINK_ACCEPT_OFFER_DETAILS.AUTHORIZATION : content.AGREE}
        authorizationText={!props.isRelink ? content.AUTHORIZATION : undefined}
        toggleAcceptTerms={props.onAcceptTerms}
        termsAccepted={props.termsAccepted}
        fetchedAgreements={props.agreements}
      />

      <Box height={theme.fixedSizes.spacing_150} />

      <PrimaryButton
        testID="AcceptanceOfferDetailsNext"
        onClick={props.onNext}
        disabled={!props.termsAccepted || props.loading}
        processing={props.loading}
      >
        {props.ctaTitle}
      </PrimaryButton>

      <Box minH={theme.fixedSizes.spacing_100} />
    </BasePageLayout>
  )
}
