const searchValue = /{{([\s\S]+?)}}/g

const hasValue = <T = any>(value: T | undefined | null): value is T => typeof value !== 'undefined' && value !== null

const isObject = (value: any): value is Record<string, unknown> =>
  !!value && !Array.isArray(value) && typeof value === 'object'

const interpolateTerms = (document: string) => {
  const render = (params: Record<string, unknown>) => {
    return document.replace(searchValue, (_, key: any) => {
      let value: Record<string, unknown> | unknown = params
      let keyName = key

      while (keyName.includes('.')) {
        const currentKey: string = keyName.split('.')[0]
        value = isObject(value) ? value?.[currentKey] : undefined

        keyName = keyName.split('.').slice(1).join('.')
      }

      const finalValue = isObject(value) ? value[keyName] : ''
      return hasValue(finalValue) ? `${finalValue}` : ''
    })
  }

  return render
}

export const interpolate = (document: string, variables: { [key: string]: string }) => {
  const compiledTerms = interpolateTerms(document)
  return compiledTerms(variables)
}
