import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { EmbedPayNowEligibility, EmbedPayNowEligibilityFailureCode } from '@genoa/domain'
import { BusinessLogicExceptionErrorCode, GeneralApiErrorCodes, useQueryError } from '@genoa/state-management'
import { ordinalSuffixFor } from '@genoa/utils'
import { DateTime } from 'luxon'

import { useAuthState } from '../../contexts'
import { useLazyEmbedPayNowEligibilityQuery } from '../../modules/flexApi'
import * as Routes from '../../routing/constants'
import { useEmbed } from './use-embed'

type FlexError = { code: string; message?: string }

export const useEmbedBlockScreens = () => {
  const navigate = useNavigate()
  const { client, token } = useEmbed()
  const { uid } = useAuthState()
  const [embedPayNowEligibility, setEmbedPayNowEligibility] = useState<EmbedPayNowEligibility>()
  const [error, setError] = useState<FlexError>()

  const [embedPayNowEligibilityQuery] = useLazyEmbedPayNowEligibilityQuery()

  useEffect(() => {
    const runQuery = async () => {
      try {
        const { data, error } = await embedPayNowEligibilityQuery({ client, customerId: uid!, token }).unwrap()
        if (data) {
          setEmbedPayNowEligibility(data)
        }
        if (error) {
          setError(error)
        }
      } catch (error) {
        setError({ code: BusinessLogicExceptionErrorCode.B_UNCAUGHT_EXCEPTION })
      }
    }
    if (!embedPayNowEligibility) {
      runQuery()
    }
  }, [embedPayNowEligibility])

  useQueryError(error, {
    onFlexApiError({ data: { error } }) {
      if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        navigate(Routes.Embed.CONGRATS, { state: { errorCode: GeneralApiErrorCodes.RATE_LIMIT } })
        return true
      }
    },
    onAllErrors() {
      navigate(Routes.Embed.CONGRATS, { state: { errorCode: 'default' } })
    },
  })

  function checkBlockingStates() {
    if (embedPayNowEligibility && !embedPayNowEligibility?.eligible) {
      const bpMonth = embedPayNowEligibility.bp_end_date
        ? DateTime.fromFormat(embedPayNowEligibility.bp_end_date, 'yyyy-MM-dd').monthLong
        : ''
      switch (embedPayNowEligibility?.failure_code) {
        case EmbedPayNowEligibilityFailureCode.AUTOPAY_ON:
          navigate(Routes.Embed.ALREADY_FLEXING_YOUR_RENT, { replace: true, state: { bpMonth } })
          break
        case EmbedPayNowEligibilityFailureCode.BP_WINDOW:
          const bpDate = DateTime.fromFormat(embedPayNowEligibility.bp_start_date, 'yyyy-MM-dd')
          const bpStartDate = `${bpDate.monthLong} ${ordinalSuffixFor(bpDate.day)}`
          navigate(Routes.Embed.NOT_RENT_DAY, {
            replace: true,
            state: { bpMonth, bpStartDate },
          })
          break
        case EmbedPayNowEligibilityFailureCode.RELINK_PROPERTY:
          navigate(Routes.Embed.RELINK_DIRECT_INTEGRATION_CONFIRMATION)
          break
        case EmbedPayNowEligibilityFailureCode.RENT_PAYMENT_IN_PROGRESS:
        case EmbedPayNowEligibilityFailureCode.RENT_PAID:
          navigate(Routes.Embed.RENT_SPLIT_PROGRESS, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.INVALID_PRODUCT:
          navigate(Routes.Embed.BUILD_CREDIT_IN_APP, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.INVALID_BALANCE:
          navigate(Routes.Embed.SERVICE_ISSUE, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.CREDIT_LINE_DECLINED:
          navigate(Routes.Embed.CREDIT_LINE_DECLINED, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.CREDIT_LINE_SUSPENDED:
          navigate(Routes.Embed.CREDIT_LINE_SUSPENDED, { replace: true })
          break
        case EmbedPayNowEligibilityFailureCode.UNKNOWN:
          navigate(Routes.Embed.SOMETHING_WENT_WRONG, { replace: true })
          break
        default:
          navigate(Routes.Embed.SOMETHING_WENT_WRONG)
          break
      }
    }
  }

  return { checkBlockingStates, embedPayNowEligibility }
}
