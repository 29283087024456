import { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'

import {
  ConnectBillerType,
  useConnectBiller,
  useIsEmbed,
  useReduxAction,
  useReduxSelector,
} from '../../../../../../hooks'
import { useShowNoMatchError } from '../../../../../../hooks/biller/use-show-no-match-error'
import { usePrefills } from '../../../../../../hooks/prefills'
import { RootState } from '../../../../../../modules'
import { useAnalytics } from '../../../../../../providers'
import { DirectIntegrationConfirmAddress } from './DirectIntegrationConfirmAddress'

type DirectIntegrationConfirmAddressContainerProps = {
  analyticsScreenName: Analytics.Screens
  analyticsEventCTAClicked: Analytics.Events
  analyticsEventAddressNotRightClicked: Analytics.Events
  isRelink?: boolean
  onNext: () => unknown
  onAddressNotRight: () => unknown
}

export const DirectIntegrationConfirmAddressContainer = (props: DirectIntegrationConfirmAddressContainerProps) => {
  const analytics = useAnalytics()
  const { prefillIsActive } = usePrefills()
  const isEmbed = useIsEmbed()

  const property = useReduxSelector(({ propertyLinking }: RootState) => propertyLinking.property)!
  const unit = useReduxSelector(({ propertyLinking }: RootState) => propertyLinking.unit)!

  const { renderNoMatchError } = useShowNoMatchError()

  const { connecting, connectBiller } = useConnectBiller({
    componentName: 'DirectIntegrationConfirmAddressContainer',
    onConnected: props.onNext,
    onSamePropertyRelink: props.onNext,
    onError: renderNoMatchError,
    connectBillerType: ConnectBillerType.DIRECT_INTEGRATION,
  })

  const handleNext = useCallback(async () => {
    analytics.logEvent(props.analyticsEventCTAClicked)
    await connectBiller(props.isRelink, { unit })
  }, [unit])

  const handleAddressNotRight = () => {
    analytics.logEvent(props.analyticsEventAddressNotRightClicked)
    props.onAddressNotRight()
  }

  return (
    <DirectIntegrationConfirmAddress
      analyticsScreenName={props.analyticsScreenName}
      loading={connecting}
      onNext={handleNext}
      onAddressNotRight={handleAddressNotRight}
      property={property}
      unit={unit}
      showPrefills={prefillIsActive}
      isEmbed={isEmbed}
    />
  )
}
