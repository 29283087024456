import React from 'react'
import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'

import { FlexAnywherePayProperty } from '../../../assets'
import { Headline2, PrimaryButton } from '../../../components'
import { BasePageLayout } from '../../../layouts'
import { FlexAnywhereHowFlexWorksGuidedList } from './FlexAnywhereHowFlexWorksGuidedList'

export interface FlexAnywhereHowFlexWorksProps {
  readonly analyticsScreenName: Analytics.Screens
  readonly onGotIt: () => unknown
}

export const FlexAnywhereHowFlexWorks = (props: FlexAnywhereHowFlexWorksProps) => {
  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH="30px" />
      <Flex direction="column">
        <FlexAnywherePayProperty width="auto" height="150px" />
        <Headline2 textAlign="center">How Flex Works</Headline2>
        <Box minH="24px" />

        <FlexAnywhereHowFlexWorksGuidedList />

        <Box minH="64px" />

        <Box>
          <PrimaryButton onClick={props.onGotIt} testID="ContinueButton">
            Let&rsquo;s go
          </PrimaryButton>
        </Box>
      </Flex>
    </BasePageLayout>
  )
}
