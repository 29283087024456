export default {
  variants: {
    default: {
      field: {
        fontSize: 'sm',
        lineHeight: 'xs',
        fontFamily: 'Lexend',
        fontWeight: 400,
        h: '60px',
        minH: '60px',

        border: '1px solid',
        borderColor: 'storm',
        borderRadius: '8px',
        bg: 'wildflower',

        _readOnly: {
          boxShadow: 'none !important',
          userSelect: 'all',
        },
        _disabled: {
          opacity: 0.4,
          cursor: 'not-allowed',
        },
        _invalid: {
          borderColor: 'brand',
          boxShadow: `0 0 0 1px brand`,
        },
        _focus: {
          zIndex: 1,
          borderColor: 'jewelPurple',
          boxShadow: `0 0 0 2px #6A3DB8`,
        },
      },

      focusBorderColor: 'jewelPurple',
      errorBorderColor: 'error',
    },
  },

  defaultProps: {
    variant: 'default',
  },
}
