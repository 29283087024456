import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ExtraSmallText } from '../Typography'

export interface PillProps {
  readonly label: string
}

export const Pill = (props: PillProps) => {
  return (
    <PillContainer>
      <PillText>{props.label}</PillText>
    </PillContainer>
  )
}

const PillContainer = styled(Box)`
  display: inline-flex;
  padding: ${(props) => props.theme.fixedSizes.spacing_25} ${(props) => props.theme.fixedSizes.spacing_62_5};
  border-radius: ${(props) => props.theme.radii.rounded};
  background-color: ${(props) => props.theme.colors.cloud};
`

const PillText = styled(ExtraSmallText)`
    font-size: ${(props) => props.theme.fixedSizes.spacing_62_5};
    font-weight: ${(props) => props.theme.fontWeights.medium}
    line-height: 120%;
  `
