import { BillerPropertyUnit } from '@genoa/domain'

export type CustomerAddress = {
  city?: string
  zip?: string
  country?: string
  streetAddress?: string
  address_line1?: string
  state?: string
  name?: string
}

export const resolveAddress = (customerAddress: CustomerAddress, unit?: BillerPropertyUnit, unitString?: string) => {
  const unitCode = unit?.unit || unitString || ''

  const address_line1 = unit?.address_line_1 ?? customerAddress.streetAddress ?? ''
  const city = unit?.city ?? customerAddress.city ?? ''
  const state = unit?.state ?? customerAddress.state ?? ''
  const zip = unit?.postal_code ?? customerAddress.zip ?? ''

  const address = {
    address_line1,
    city,
    state,
    zip,
    unit_nbr: unitCode,
    geohash: '',
  }

  return address
}
