import { useEffect, useState } from 'react'
import {
  ONBOARDING_AGREEMENT_TYPES,
  ONBOARDING_SKIP_AUTOPILOT_AGREEMENT_TYPES,
  SLC_CLASSIC_ONBOARDING_AGREEMENT_TYPES,
  SLC_FULLY_SECURED_ONBOARDING_AGREEMENT_TYPES,
  SLC_ONBOARDING_AGREEMENT_TYPES,
  SLC_PRICING_ONBOARDING_AGREEMENT_TYPES,
  SLCType,
} from '@genoa/domain'
import { useGetAgreements } from '@genoa/middle-end'

import { useAuthState } from '../../../contexts'
import { useLogger } from '../../../providers'
import { useAutopilotEligibility } from '../../autopilot'
import { useSecureLineOfCredit } from '../../secure-line-of-credit'

const resolveAgreementTypes = (
  isEnabledForSLC: boolean,
  isUserAutopayEnabled: boolean,
  isPricingEnabled: boolean,
  slcType?: SLCType
) => {
  if (isEnabledForSLC && slcType === SLCType.CREDIT_BUILDER) {
    return SLC_FULLY_SECURED_ONBOARDING_AGREEMENT_TYPES
  }
  if (isEnabledForSLC && (slcType === SLCType.CLASSIC || slcType === SLCType.FLEX_ANYWHERE)) {
    return SLC_CLASSIC_ONBOARDING_AGREEMENT_TYPES
  }
  if (isPricingEnabled) {
    return SLC_PRICING_ONBOARDING_AGREEMENT_TYPES
  }
  if (isEnabledForSLC) {
    return SLC_ONBOARDING_AGREEMENT_TYPES
  }
  if (isUserAutopayEnabled) {
    return ONBOARDING_AGREEMENT_TYPES
  }
  return ONBOARDING_SKIP_AUTOPILOT_AGREEMENT_TYPES
}

export interface UseGetAgreementsOptions {
  readonly onError: () => void
}

export const useAgreements = ({ onError }: UseGetAgreementsOptions) => {
  const logger = useLogger('useAgreements')
  const { uid } = useAuthState()
  const { refetchEligibility } = useAutopilotEligibility()
  const { isEnabledForSLC, isPricingEnabled, isLoadingSLC, slcType } = useSecureLineOfCredit()
  const [{ response: agreementsResponse }, getAgreementsInternal] = useGetAgreements()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const handleSetup = async () => {
      try {
        const autopilotEligibility = await refetchEligibility()
        const agreementTypes = resolveAgreementTypes(
          isEnabledForSLC,
          autopilotEligibility.isUserAutopayEnabled!,
          isPricingEnabled,
          slcType
        )

        await getAgreementsInternal({
          customerId: uid!,
          agreementTypes,
          onlyFinancialPartnerSpecific: true,
        })
      } catch (error: any) {
        logger.error('Error in setup', error?.message)
        onError()
      } finally {
        setLoading(false)
      }
    }

    if (!isLoadingSLC) {
      handleSetup()
    }
  }, [uid, isEnabledForSLC, isLoadingSLC, isPricingEnabled])

  return {
    agreements: agreementsResponse?.data.data?.agreements?.pending || [],
    loading,
  }
}
