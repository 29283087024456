import React from 'react'

import { SecondaryButton } from './SecondaryButton'

type SmallButtonProps = {
  children: React.ReactNode
  disabled?: boolean
  processing?: boolean
  onClick: () => unknown
  testID?: string
  background?: string
  foreground?: string
}

export const SmallButton = (props: SmallButtonProps) => {
  return (
    <SecondaryButton {...props} variant="small">
      {props.children}
    </SecondaryButton>
  )
}
