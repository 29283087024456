import { useEffect } from 'react'
import { Analytics } from '@genoa/analytics'

import { useAuthState } from '../../contexts'
import { RootState } from '../../modules'
import { useGetAutopilotEligibilityQuery } from '../../modules/flexApi'
import { useAnalytics } from '../../providers'
import { useReduxSelector } from '../use-redux-selector'

export const useAutopilotEligibility = () => {
  const { uid, isAnonymous } = useAuthState()
  const firebaseInitialized = useReduxSelector((state: RootState) => state.auth?.firebaseInitialized)
  const analytics = useAnalytics()

  const handleEligibility = (response: any) => {
    const isUserEligibleForAutopilot = response?.data?.eligible
    const isUserConfiguredForAutopilot = isUserEligibleForAutopilot && response?.data?.configured

    return {
      isUserEligibleForAutopilot,
      isUserAutopayEnabled: !isUserEligibleForAutopilot || isUserConfiguredForAutopilot,
    }
  }

  const {
    data: autopilotEligibilityData,
    isLoading: isLoadingEligibility,
    refetch,
    isUninitialized,
    isSuccess,
  } = useGetAutopilotEligibilityQuery(
    { customerId: uid! },
    {
      skip: !firebaseInitialized || isAnonymous || isAnonymous === null,
    }
  )

  const { isUserEligibleForAutopilot, isUserAutopayEnabled } = handleEligibility(autopilotEligibilityData)

  useEffect(() => {
    if (isSuccess) {
      analytics.setUserProperty(Analytics.UserProperties.EPISODIC_ELIGIBLE, isUserEligibleForAutopilot)
      analytics.setUserProperty(Analytics.UserProperties.RENT_AUTOPAY_ENABLE, isUserAutopayEnabled)
    }
  }, [isSuccess, isUserEligibleForAutopilot, isUserAutopayEnabled])

  const refetchEligibility = async () => {
    const response = await refetch()
    return handleEligibility(response?.data)
  }

  return {
    isUserEligibleForAutopilot,
    isLoadingEligibility: isLoadingEligibility && isUninitialized,
    isUserAutopayEnabled,
    refetchEligibility,
  }
}
