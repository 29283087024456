import cleanLogData from '@genoa/utils/lib/clean-log-data'
import { datadogLogs } from '@datadog/browser-logs'

const isAnalyticsLog = (title: string) => {
  return title === 'Analytics'
}

export const log = (title: string, message: string) => {
  const newData = cleanLogData(message)
  if (!isAnalyticsLog(title)) {
    datadogLogs.logger.info(title, { content: newData })
  }
}

export const warn = (title: string, message: string) => {
  const newData = cleanLogData(message)
  datadogLogs.logger.warn(title, { content: newData })
}

export const error = (title: string, message: string, stack?: string) => {
  const newData = cleanLogData(message)
  datadogLogs.logger.error(title, { content: newData, stack })
}

export const logger = {
  log,
  warn,
  error,
}
