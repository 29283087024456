import { Analytics } from '@genoa/analytics'
import { EMBED_SERVICE_ISSUE as content } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useEmbedAnalytics } from '../../../../../../hooks'
import { useAnalytics } from '../../../../../../providers'
import { HeroWarning } from '../../../../../assets'
import { AppStore, Gap, Headline2, SmallText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

export const EmbedServiceIssue = () => {
  const analytics = useAnalytics()
  const { embedFlow, embedProperties } = useEmbedAnalytics()

  const onClickAppStore = () => {
    analytics.logEvent(Analytics.Events.EMBED_BLOCKING_GENERIC_CTA_APPLE, { embedFlow })
  }

  const onClickPlayStore = () => {
    analytics.logEvent(Analytics.Events.EMBED_BLOCKING_GENERIC_CTA_GOOGLE, { embedFlow })
  }

  return (
    <BasePageLayout
      analyticsScreenName={Analytics.Screens.EMBED_BLOCKING_GENERIC}
      analyticsScreenParams={embedProperties}
    >
      <Container>
        <Gap size="spacing_300" />
        <HeroWarning />
        <Gap size="spacing_200" />
        <Headline2>{content.HEADER}</Headline2>
        <Gap size="spacing_100" />
        <SmallText textAlign="center">{content.BODY}</SmallText>
        <Gap size="spacing_200" />
        <AppStore notice={content.NOTICE} onClickAppStore={onClickAppStore} onClickPlayStore={onClickPlayStore} />
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  align-items: center;
`
