import { useEffect, useRef } from 'react'

type UnloadCallback = (event: Event) => void

export const useUnload = (onUnload: UnloadCallback) => {
  const callback = useRef(onUnload)

  useEffect(() => {
    callback.current = onUnload
  }, [onUnload])

  useEffect(() => {
    const handleBeforeunload = (event: Event) => {
      callback.current?.(event)
    }

    window.addEventListener('beforeunload', handleBeforeunload)
    window.onpopstate = () => {
      window.removeEventListener('beforeunload', handleBeforeunload)
    }
    return () => window.removeEventListener('beforeunload', handleBeforeunload)
  }, [])
}
