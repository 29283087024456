import { useEffect } from 'react'
import WebFont from 'webfontloader'

import './fonts/fonts.css'

export const useFontStyles = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Work Sans', 'Lexend'],
      },
      custom: {
        families: ['RebrandDis'],
      },
    })
  }, [])
}
