import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { ReloadBlack } from '../../assets'
import { NoMarginLineDivider } from '../Layout'
import { SmallText } from '../Typography'

interface PaymentScheduleTitleProps {
  readonly title: string
  readonly autopayStatus?: boolean
  readonly showReloadIcon?: boolean
}

export const PaymentScheduleTitle = (props: PaymentScheduleTitleProps) => {
  return (
    <TitleContainer>
      <TitleRow>
        {props.showReloadIcon && (
          <ReloadIconWrapper>
            <ReloadBlack />
          </ReloadIconWrapper>
        )}
        <TitleText>{props.title}</TitleText>
      </TitleRow>
      <NoMarginLineDivider />
    </TitleContainer>
  )
}

const TitleContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_75};
`

const TitleRow = styled(Flex)`
  flex-direction: row;
  align-items: center;
`

const TitleText = styled(SmallText)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`

const ReloadIconWrapper = styled(Flex)`
  padding-right: ${(props) => props.theme.fixedSizes.spacing_50};
`
