import React from 'react'
import { Analytics } from '@genoa/analytics'

import { useHandleNavigationForCreditBuilderValueProp } from '../../../../hooks/'
import { CreateAccountContainer } from './CreateAccountContainer'

export const OnboardingCreateAccountContainer = () => {
  const { navigateNext } = useHandleNavigationForCreditBuilderValueProp({ source: 'create' })

  return (
    <CreateAccountContainer
      analyticsScreenName={Analytics.Screens.SIGNUP_ACCOUNT_CREATION}
      firstNameEditEvent={Analytics.Events.SIGNUP_ACCOUNT_CREATION_FIRST_NAME_EDIT}
      lastNameEditEvent={Analytics.Events.SIGNUP_ACCOUNT_CREATION_LAST_NAME_EDIT}
      emailEditEvent={Analytics.Events.SIGNUP_ACCOUNT_CREATION_EMAIL_EDIT}
      termsOfServiceClickedEvent={Analytics.Events.SIGNUP_TERMS_OF_SERVICE_CLICKED}
      privacyPolicyClickedEvent={Analytics.Events.SIGNUP_PRIVACY_POLICY_CLICKED}
      privacyNoticeClickedEvent={Analytics.Events.SIGNUP_PRIVACY_NOTICE_CLICKED}
      errorOnCTAEvent={Analytics.Events.SIGNUP_ERROR_ON_CTA}
      accountCreationClickedEvent={Analytics.Events.SIGNUP_ACCOUNT_CREATION_CTA_CLICKED}
      emailAlreadyInUseEvent={Analytics.Events.SIGNUP_EMAIL_ALREADY_IN_USE}
      onNext={navigateNext}
    />
  )
}
