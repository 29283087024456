import React, { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'

import { useAnalytics, useHelpLinks } from '../../../providers'
import { LinkText, PrimaryButton, SmallText } from '../../components'
import { Modal, ModalActions } from '../../components/Modal'

const UserDisabledHelpLink = () => {
  const helpLinks = useHelpLinks()
  const analytics = useAnalytics()

  const handleOpenUserDisabledHelp = useCallback(() => {
    analytics.logEvent(Analytics.Events.SIGNIN_USER_DISABLED_HELP_CLICKED)
    helpLinks.open(FlexLinks.flexAccountDisabledHelp)
  }, [helpLinks.open])

  return (
    <LinkText onClick={handleOpenUserDisabledHelp} testID="UserDisabledHelpLink">
      <b>customer support</b>
    </LinkText>
  )
}

interface UserDisabledProps {
  readonly visible: boolean
  readonly onClose: () => unknown
}

export const UserDisabled = (props: UserDisabledProps) => {
  return (
    <Modal testID="UserDisabledModal" title={'Whoops!'} canClose={true} onClose={props.onClose} visible={props.visible}>
      <SmallText>
        Your account has been disabled. Please reach out to <UserDisabledHelpLink /> for assistance.
      </SmallText>

      <ModalActions>
        <PrimaryButton onClick={props.onClose}>Go back</PrimaryButton>
      </ModalActions>
    </Modal>
  )
}
