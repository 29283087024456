export default {
  baseStyle: {
    control: {
      border: '1px solid',
      borderRadius: 'sm',
      borderColor: 'storm',
      marginTop: '5px',

      _hover: {
        borderColor: 'storm',
      },
    },
  },

  sizes: {
    sm: {
      control: {
        h: '15px',
        w: '15px',
        borderRadius: 'md',
      },
      label: { fontSize: 'sm' },
      icon: { fontSize: '10px' },
    },
    md: {
      control: {
        w: '24px',
        h: '24px',
        borderRadius: 'md',
      },
      label: { fontSize: 'sm' },
      icon: { fontSize: '12px' },
    },
    lg: {
      control: {
        w: '45px',
        h: '45px',
        borderRadius: 'md',
      },
      label: { fontSize: 'md' },
      icon: { fontSize: '16px' },
    },
  },

  variants: {
    default: {},
  },

  defaultProps: {
    variant: 'default',
    colorScheme: 'brand',
  },
}
