import React, { Context, useContext, useMemo, useState } from 'react'
import { CONTENT, ContentProps, DEFAULT_CONTENT, replaceContent, ScreenContentProps } from '@genoa/domain'
import merge from 'lodash/merge'

type ContentContextData = {
  content: ScreenContentProps
  setUserContentRule: (userContentRule: keyof ContentProps) => void
  replaceContent: typeof replaceContent
}

const ContentContextValues: ContentContextData = {
  content: DEFAULT_CONTENT,
  setUserContentRule: () => {},
  replaceContent: () => '',
}

export const ContentContext: Context<ContentContextData> = React.createContext(ContentContextValues)

export const ContentProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [userContentRule, setUserContentRule] = useState<keyof ContentProps>()

  const contentRules = useMemo(() => {
    return [
      {
        isEnabled: userContentRule !== undefined && userContentRule in CONTENT,
        content: userContentRule ? CONTENT[userContentRule] : undefined,
      },
    ]
  }, [userContentRule])

  const content = useMemo(() => {
    return contentRules.reduce((currentContent, contentRule) => {
      return contentRule.isEnabled ? merge({}, currentContent, contentRule.content) : currentContent
    }, DEFAULT_CONTENT)
  }, [contentRules])

  const contentContextData: ContentContextData = useMemo(
    () => ({
      content,
      setUserContentRule,
      replaceContent,
    }),
    [content, userContentRule]
  )

  if (!children) {
    return null
  }

  return <ContentContext.Provider value={contentContextData}>{children}</ContentContext.Provider>
}

/** @deprecated use `@genoa/screen-content` */
export const useContent = () => useContext(ContentContext)
