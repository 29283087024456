import { ConsentManagementConsentPayload, useAmplitude } from '../amplitude'
import { useRemoteCookieConsent } from './use-remote-cookie-consent'

export type ReconciledCookieConsent = {
  source: 'local' | 'remote'
  consent: ConsentManagementConsentPayload
}

type ReconciledCookieConsentResolver = () => Promise<ReconciledCookieConsent>

/**
 * hook returning a function that:
 * - requests the user's stored cookie consent from our backend servers, then,
 * - compares remote consent, if available, against the local consent, and,
 * - returns the cookie consent that takes precedence
 */
export const useReconciledCookieConsent = (): ReconciledCookieConsentResolver => {
  const { consentManagement } = useAmplitude()
  const remoteConsentResolver = useRemoteCookieConsent()

  return async () => {
    if (consentManagement === undefined) {
      throw new Error('Unable to reconcile cookie consents when Consent Management has not been initialized!')
    }

    const remoteConsent = await remoteConsentResolver()
    const localConsent = consentManagement.getConsent()

    if (remoteConsent !== undefined) {
      // we resolved a Customer Cookie Consent stored on our backend
      if (!localConsent.confirmed) {
        // but no explicit Cookie Consent was given in the current session locally, so Remote Consent must apply!
        return {
          source: 'remote',
          consent: remoteConsent,
        }
      }

      // user confirmed Cookie Consent choices locally; going to compare confirmation timestamps to break the tie...
      const remoteConsentedOn = remoteConsent.dt_confirmed.getTime()
      const localConsentedOn = localConsent.dt_confirmed.getTime()
      if (remoteConsentedOn > localConsentedOn) {
        // remote Customer Cookie Consent was provided at a later time versus the locally stored Consent, so Remote Consent takes precedence
        return {
          source: 'remote',
          consent: remoteConsent,
        }
      } else if (localConsentedOn > remoteConsentedOn) {
        // local Customer Cookie Consent was provided at a later time versus Cookie Consent stored on our backend, so Local Consent takes precedence
        return {
          source: 'local',
          consent: localConsent,
        }
      }
    }

    // if we reached here, it means either:
    // -- a Customer Cookie Consent was not found in our backend; or,
    // -- a Customer Cookie Consent was found in our backend AND the timestamp of consent confirmations was exactly equal; ie: localConsentedOn === remoteConsentedOn
    // in both cases, bubble up the Local Consent object!
    return {
      source: 'local',
      consent: localConsent,
    }
  }
}
