import {
  OnboardingContentOverhaulDec24Experiment,
  OnboardingContentOverhaulDec24VariantValues,
  OnboardingContentOverhaulExperiment,
  OnboardingContentOverhaulVariantValues,
} from '@genoa/experiments'

import { useSecureLineOfCredit } from '../secure-line-of-credit'
import { useExperimentVariant } from '../use-experiment-variant'

type ContentOverhaul = {
  inOnboardingExperiment: boolean
  inOnboardingDec24Experiment: boolean
  onboardingEnabled: boolean
  onboardingDec24Enabled: boolean
}

export const useContentOverhaul = (): ContentOverhaul => {
  const { isFlagEnabledForCreditBuilder, isEnabledForSLC } = useSecureLineOfCredit()
  const onboardingVariant = useExperimentVariant(OnboardingContentOverhaulExperiment)
  const onboardingTreatment = onboardingVariant === OnboardingContentOverhaulVariantValues.Treatment
  const onboardingDec24Variant = useExperimentVariant(OnboardingContentOverhaulDec24Experiment)
  const onboardingDec24Treatment = onboardingDec24Variant === OnboardingContentOverhaulDec24VariantValues.Treatment

  return {
    inOnboardingExperiment: !!onboardingVariant && !isFlagEnabledForCreditBuilder && !isEnabledForSLC,
    inOnboardingDec24Experiment: !!onboardingDec24Variant && !isFlagEnabledForCreditBuilder && !isEnabledForSLC,
    onboardingEnabled: onboardingTreatment && !isFlagEnabledForCreditBuilder && !isEnabledForSLC,
    onboardingDec24Enabled: onboardingDec24Treatment && !isFlagEnabledForCreditBuilder && !isEnabledForSLC,
  }
}
