import React from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { Theme } from '@emotion/react'
import styled from '@emotion/styled'

type LiveSomewhereElseProps = {
  onPress: () => unknown
  cursor?: string
  buttonText?: string
  theme: Theme
}

export const LiveSomewhereElse = (props: LiveSomewhereElseProps) => (
  <Box key={`live-somewhere-else-container`} onClick={props.onPress} data-testid="LiveSomewhereElse">
    <Flex flex={1} flexDirection="row" alignItems="center" justifyContent="center">
      <Box minH="24px" />
      <StyledSmallText data-testid="LiveSomewhereElseText" color={props.theme.colors.brand[900]} cursor="pointer">
        {props.buttonText}
      </StyledSmallText>
    </Flex>
  </Box>
)

const StyledSmallText = styled(Flex)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-family: ${(props) => props.theme.fonts.heading};
  font-size: 18px;
`
