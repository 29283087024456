import React, { useEffect, useState } from 'react'
import { Analytics } from '@genoa/analytics'

import { useAuthState, useModal } from '../../../../contexts'
import { useReduxAction, useReduxSelector, useSecureLineOfCredit } from '../../../../hooks'
import { useHandleInitiateOffer } from '../../../../hooks/flex2/risk'
import { RentAmountState, RootState, setRentAmountAction } from '../../../../modules'
import { OfferState } from '../../../../modules/flex2/offer'
import { useAnalytics } from '../../../../providers'
import { SmallText } from '../../../components'
import { amountError } from './common'
import { InitiateOfferRentAmount } from './InitiateOfferRentAmount'

type InitiateOfferRentAmountContainerProps = {
  analyticsScreenName: Analytics.Screens
  analyticsEventCTAClicked: Analytics.Events
  analyticsEventCTAError: Analytics.Events
  onNext: () => unknown
  onBack?: () => unknown
}

export const InitiateOfferRentAmountContainer = (props: InitiateOfferRentAmountContainerProps) => {
  const userInfo = useAuthState()
  const analytics = useAnalytics()
  const modal = useModal()
  const { isRefetchingSLC } = useSecureLineOfCredit()

  const [showConfirmText, setShowConfirmText] = useState(false)
  const [errors, setErrors] = useState({})
  const [rentAmountChangeModalVisible, setRentAmountChangeModalVisible] = useState(false)

  const offer: OfferState = useReduxSelector((state: RootState) => state.offerState)
  const rentAmount: RentAmountState = useReduxSelector((state: RootState) => state.onboarding.rentAmount)

  const setRentAmount = useReduxAction(setRentAmountAction)

  const handleRentAmountError = () => {
    analytics.logEvent(props.analyticsEventCTAError, { rentAmount, error: amountError })
    setErrors({ amount: amountError })
  }

  const handleError = () => {
    modal.show({
      title: 'Whoops!',
      cta: 'Confirm',
      render: () => (
        <>
          <SmallText>
            We had some problems saving your monthly rent. Please try again or contact support if the problem persists.
          </SmallText>
        </>
      ),
    })
  }

  const [loading, initiateOffer] = useHandleInitiateOffer({
    componentName: 'InitiateOfferRentAmountContainer',
    handleSuccess: props.onNext,
    handleRentAmountError,
    handleError,
  })

  const handleRentAmountNext = async (amount: number, propertyName?: string) => {
    analytics.logEvent(props.analyticsEventCTAClicked, { rentAmount })
    setErrors({})
    await initiateOffer(amount, userInfo, propertyName)
  }

  useEffect(() => {
    if (offer.initialized && offer.offer.estimated_bill_amount_cent) {
      setRentAmount({ amount: (offer.offer.estimated_bill_amount_cent / 100).toFixed(2) })
      setShowConfirmText(true)
    }
  }, [])

  return (
    <InitiateOfferRentAmount
      analyticsScreenName={props.analyticsScreenName}
      rentAmount={rentAmount}
      submitRentAmount={setRentAmount}
      isLoading={loading || !!isRefetchingSLC}
      errors={errors}
      onNext={handleRentAmountNext}
      onBack={props.onBack}
      rentAmountChangeModalVisible={rentAmountChangeModalVisible}
      setRentAmountChangeModalVisible={setRentAmountChangeModalVisible}
      showConfirmText={showConfirmText}
    />
  )
}
