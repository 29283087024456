import { createReducer } from '@reduxjs/toolkit'

import * as Actions from './actions'
import { ScheduleOptionsState } from './types'

export const initialState: ScheduleOptionsState = {
  initialized: false,
  start: 0,
  end: 0,
}

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(Actions.setScheduleOptionsAction, (_state, action) => {
      return { ...action.payload }
    })
    .addCase(Actions.resetScheduleOptionsAction, (_state, _action) => {
      return { ...initialState }
    })
})
