import { RootState } from '../../modules'
import { useAmplitude } from '../../providers'
import { useReduxSelector } from '../use-redux-selector'
import { useIsEmbed } from './use-is-embed'

export const useEmbedAnalytics = () => {
  const {
    enhancedTracking: { getAllSharedEventProperties },
  } = useAmplitude()
  const isEmbed = useIsEmbed()
  const propertyLinking = useReduxSelector((state: RootState) => state.propertyLinking)
  const property = propertyLinking?.property
  const sharedEventProperties = getAllSharedEventProperties()
  const embedFlow = isEmbed ? 'yardi' : 'none'

  return {
    embedFlow,
    embedProperties: { ...sharedEventProperties, embedFlow },
    pmcName: property?.pmc_name,
    propertyName: property?.name,
    sharedEventPropertiesHaveLoaded: !!sharedEventProperties.customerId,
  }
}

export const useEmbedFlow = () => {
  const isEmbed = useIsEmbed()
  return isEmbed ? 'yardi' : 'none'
}
