// For more information on our design system, please view it here:
// https://www.figma.com/file/uiydnDkygP8DIz1MNrmF54/Design-System-(Cindy)?node-id=21008-1229&t=bdPmVRb4B7LgME8d-0

export default {
  // Purples
  jewelPurple: '#6A3DB8',
  flavender: '#B28CF4',
  softLilac: '#DDC6F9',

  // Greyscale
  midnight: '#0E0622',
  dusk: '#767184',
  storm: '#C7C3D1',
  cloud: '#ECEAF2',
  wildflower: '#F7F7FA',
  winterWhite: '#FFFFFF',

  // Greens
  evergreen: '#01795C',
  stem: '#25A77C',
  beetle: '#66D19E',
  openSea: '#E6F2ED',

  // Reds
  fireEngine: '#D6001A',
  papercut: '#E35157',
  fastCar: '#FC6F6F',
  pinkFrosting: '#FFEFED',

  // Yellows
  cider: '#A16B00',
  amber: '#C58301',
  sunflower: '#F1A91B',
  lightbulb: '#FFD789',
  mesa: '#FFF5E2',

  // Blues
  forwardBlue: '#1C53BA',

  // DO NOT USE ANY OF THE BELOW COLORS - THEY ARE FLEX 1 COLORS

  black: '#0E0622',
  cinderBlack: '#0F0912',
  grey: '#767184',
  frenchGrey: '#C7C4C9',
  aluminiumGrey: '#D5D1E0',
  mercuryGrey: '#E5E5E5',
  lightGrey: '#E9E7EF',
  lightestGrey: '#F5F5F6',
  whitesmoke: 'rgba(255,255,255,0.05)',
  alabasterGrey: '#F7F7F7',
  white: '#FFFFFF',
  catskillWhite: '#E2E8F0',
  carouselDots: '#6A3DB8',
  settings: '#F7F6F9',

  // accents
  brand: {
    100: 'rgb(100, 70, 164, 0.1)',
    200: '#6A3DB8',
    300: '#6A3DB8',
    400: '#6A3DB8',
    500: '#6A3DB8',
    600: '#6A3DB8',
    700: '#F0EDF6',
    800: '#0E0622',
    900: '#6A3DB8',
  },

  red: '#E53A3A',
}
