export type StripeErrorMessageContent = {
  declineCode: string
  message: string
  title: string
}

export type StripeErrorMessageTypes = {
  authentication_required: StripeErrorMessageContent
  approve_with_id: StripeErrorMessageContent
  call_issuer: StripeErrorMessageContent
  card_not_supported: StripeErrorMessageContent
  card_velocity_exceeded: StripeErrorMessageContent
  currency_not_supported: StripeErrorMessageContent
  do_not_honor: StripeErrorMessageContent
  do_not_try_again: StripeErrorMessageContent
  expired_card: StripeErrorMessageContent
  fraudulent: StripeErrorMessageContent
  generic_decline: StripeErrorMessageContent
  incorrect_number: StripeErrorMessageContent
  incorrect_cvc: StripeErrorMessageContent
  incorrect_pin: StripeErrorMessageContent
  insufficient_funds: StripeErrorMessageContent
  incorrect_zip: StripeErrorMessageContent
  invalid_account: StripeErrorMessageContent
  invalid_amount: StripeErrorMessageContent
  invalid_cvc: StripeErrorMessageContent
  invalid_expiry_month: StripeErrorMessageContent
  invalid_expiry_year: StripeErrorMessageContent
  invalid_pin: StripeErrorMessageContent
  issuer_not_available: StripeErrorMessageContent
  lost_card: StripeErrorMessageContent
  new_account_information_available: StripeErrorMessageContent
  no_action_taken: StripeErrorMessageContent
  not_permitted: StripeErrorMessageContent
  offline_pin_required: StripeErrorMessageContent
  online_or_offline_pin_required: StripeErrorMessageContent
  pickup_card: StripeErrorMessageContent
  pin_try_exceeded: StripeErrorMessageContent
  processing_error: StripeErrorMessageContent
  reenter_transaction: StripeErrorMessageContent
  restricted_card: StripeErrorMessageContent
  revocation_of_all_authorizations: StripeErrorMessageContent
  revocation_of_authorization: StripeErrorMessageContent
  security_violation: StripeErrorMessageContent
  service_not_allowed: StripeErrorMessageContent
  stolen_card: StripeErrorMessageContent
  stop_payment_order: StripeErrorMessageContent
  testmode_decline: StripeErrorMessageContent
  transaction_not_allowed: StripeErrorMessageContent
  try_again_later: StripeErrorMessageContent
  withdrawal_count_limit_exceeded: StripeErrorMessageContent
}

export const StripeDenialText =
  'We ran into an issue with your card issuer while adding your card. Please contact them for more information or try another card. A valid payment method must be provided in order to use Flex.'
export const StripeCardIssueText =
  'It looks like the card details you entered are invalid. Please double check your card information and try again. A valid payment method must be provided in order to use Flex.'
export const StripeProcessingErrorText =
  'We ran into a processing error while adding your card. Please wait a few moments, then try again. If you continue to get this error, please contact your card issuer. A valid payment method must be provided in order to use Flex.'
export const StripeTitleDenial = 'Something went wrong'
export const StripeTitleCardIssue = 'Card information invalid'
export const StripeTitleProcessingError = 'Something went wrong'

export const StripeErrorMapping: StripeErrorMessageTypes = {
  authentication_required: {
    declineCode: 'authentication_required',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  approve_with_id: {
    declineCode: 'approve_with_id',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  call_issuer: {
    declineCode: 'call_issuer',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  card_not_supported: {
    declineCode: 'card_not_supported',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  card_velocity_exceeded: {
    declineCode: 'card_velocity_exceeded',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  currency_not_supported: {
    declineCode: 'currency_not_supported',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  do_not_honor: {
    declineCode: 'do_not_honor',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  do_not_try_again: {
    declineCode: 'do_not_try_again',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  expired_card: {
    declineCode: 'expired_card',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  fraudulent: {
    declineCode: 'fraudulent',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  generic_decline: {
    declineCode: 'generic_decline',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  incorrect_number: {
    declineCode: 'incorrect_number',
    message: StripeCardIssueText,
    title: StripeTitleCardIssue,
  },
  incorrect_cvc: {
    declineCode: 'incorrect_cvc',
    message: StripeCardIssueText,
    title: StripeTitleCardIssue,
  },
  incorrect_pin: {
    declineCode: 'incorrect_pin',
    message: StripeCardIssueText,
    title: StripeTitleCardIssue,
  },
  insufficient_funds: {
    declineCode: 'insufficient_funds',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  incorrect_zip: {
    declineCode: 'incorrect_zip',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  invalid_account: {
    declineCode: 'invalid_account',
    message: StripeCardIssueText,
    title: StripeTitleCardIssue,
  },
  invalid_amount: {
    declineCode: 'invalid_amount',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  invalid_cvc: {
    declineCode: 'invalid_cvc',
    message: StripeCardIssueText,
    title: StripeTitleCardIssue,
  },
  invalid_expiry_month: {
    declineCode: 'invalid_expiry_month',
    message: StripeCardIssueText,
    title: StripeTitleCardIssue,
  },
  invalid_expiry_year: {
    declineCode: 'invalid_expiry_year',
    message: StripeCardIssueText,
    title: StripeTitleCardIssue,
  },
  invalid_pin: {
    declineCode: 'invalid_pin',
    message: StripeCardIssueText,
    title: StripeTitleCardIssue,
  },
  issuer_not_available: {
    declineCode: 'issuer_not_available',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  lost_card: {
    declineCode: 'lost_card',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  new_account_information_available: {
    declineCode: 'new_account_information_available',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  no_action_taken: {
    declineCode: 'no_action_taken',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  not_permitted: {
    declineCode: 'not_permitted',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  offline_pin_required: {
    declineCode: 'offline_pin_required',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  online_or_offline_pin_required: {
    declineCode: 'online_or_offline_pin_required',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  pickup_card: {
    declineCode: 'pickup_card',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  pin_try_exceeded: {
    declineCode: 'pin_try_exceeded',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  processing_error: {
    declineCode: 'processing_error',
    message: StripeProcessingErrorText,
    title: StripeTitleProcessingError,
  },
  reenter_transaction: {
    declineCode: 'reenter_transaction',
    message: StripeProcessingErrorText,
    title: StripeTitleProcessingError,
  },
  restricted_card: {
    declineCode: 'restricted_card',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  revocation_of_all_authorizations: {
    declineCode: 'revocation_of_all_authorizations',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  revocation_of_authorization: {
    declineCode: 'revocation_of_authorization',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  security_violation: {
    declineCode: 'security_violation',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  service_not_allowed: {
    declineCode: 'service_not_allowed',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  stolen_card: {
    declineCode: 'stolen_card',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  stop_payment_order: {
    declineCode: 'stop_payment_order',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  testmode_decline: {
    declineCode: 'testmode_decline',
    message: StripeCardIssueText,
    title: StripeTitleCardIssue,
  },
  transaction_not_allowed: {
    declineCode: 'transaction_not_allowed',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
  try_again_later: {
    declineCode: 'try_again_later',
    message: StripeProcessingErrorText,
    title: StripeTitleProcessingError,
  },
  withdrawal_count_limit_exceeded: {
    declineCode: 'withdrawal_count_limit_exceeded',
    message: StripeDenialText,
    title: StripeTitleDenial,
  },
}
