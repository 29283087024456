import React, { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'
import { EMBED_CONFIRM_SMS_TEXT as content } from '@genoa/screen-content'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { useAnalytics, useFlexLinks } from '../../../../providers'
import { ExtraSmallText, LinkText } from '../../../components'

export const EmbedConfirmSMSText = () => {
  const theme = useTheme()
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()

  const handleOpenPrivacyPolicy = useCallback(() => {
    analytics.logEvent(Analytics.Events.CONFIRM_SMS_PRIVACY_NOTICE_CLICKED)
    flexLinks.open(FlexLinks.privacyPolicy)
  }, [flexLinks.open])

  const handleOpenPrivacyNotice = useCallback(() => {
    analytics.logEvent(Analytics.Events.CONFIRM_SMS_PRIVACY_NOTICE_CLICKED)
    flexLinks.open(FlexLinks.privacyNotice)
  }, [flexLinks.open])

  return (
    <Box style={{ zIndex: 1, textAlign: 'justify' }}>
      <Box style={{ lineHeight: theme.lineHeights.xxs }}>
        <ExtraSmallText>
          {content.BODY1}
          <LinkText onClick={handleOpenPrivacyNotice} testID="EmbedPrivacyNoticeTextButton">
            <b>{content.NOTICE}</b>
          </LinkText>
          {content.BODY2}
          <LinkText onClick={handleOpenPrivacyPolicy} testID="EmbedPrivacyNoticeTextButton">
            <b>{content.POLICY}</b>
          </LinkText>
          {content.BODY3}
        </ExtraSmallText>
      </Box>
    </Box>
  )
}
