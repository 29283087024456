import { ReactNode, useCallback } from 'react'

import { useModal } from '../../contexts'
import { SmallText } from '../../views/components'

export const useShowErrorMessageModal = () => {
  const modal = useModal()

  const showErrorMessage = useCallback(
    (title: string, errorMessage: string | ReactNode, cta?: string, onCTAclicked?: () => unknown) => {
      modal.show({
        title,
        cta: cta || 'Okay',
        onClose: () => {},
        render: () => <SmallText>{errorMessage}</SmallText>,
        onCTAclicked,
      })
    },
    []
  )
  return { showErrorMessage }
}
