import React, { useMemo } from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { SmallText, TextInput } from '../../../../components'

interface PortalAddressEntryInputProps {
  readonly error?: FieldError
  readonly label: string
  readonly name: string
  readonly control: any
  readonly testID: string
  readonly maxLength?: number
}

export const PortalAddressEntryInput = (props: PortalAddressEntryInputProps) => {
  const theme = useTheme()

  const error = useMemo(() => {
    if (!props.error || !props.error.message) {
      return undefined
    }

    return props.error.message.replace(props.name, props.label)
  }, [props.error])

  return (
    <Controller
      control={props.control}
      defaultValue=""
      render={({ field: { onChange, onBlur, value } }) => (
        <>
          <SmallText>{props.label}</SmallText>
          <Box minH="5px" />
          <TextInput
            maxLength={props.maxLength}
            onBlur={onBlur}
            onTextChange={onChange}
            text={value}
            error={!!error}
            testID={props.testID}
          />
          <Box minH="5px" />
          <Box visibility={error ? 'visible' : 'hidden'} color={theme.colors.error}>
            <SmallText hasError={!!error}>{error}</SmallText>
          </Box>
        </>
      )}
      name={props.name}
    />
  )
}
