import React from 'react'
import { Analytics } from '@genoa/analytics'

import FlexAnywhereWhereYouPayContainer from '../../common/property-linking/FlexAnywhereWhereYouPayContainer'

type OnboardingFlexAnywhereWhereYouPayContainerProps = {}

export const OnboardingFlexAnywhereWhereYouPayContainer = (props: OnboardingFlexAnywhereWhereYouPayContainerProps) => {
  return (
    <FlexAnywhereWhereYouPayContainer {...props} analyticsScreenName={Analytics.Screens.ONBOARDING_OON_WHERE_YOU_PAY} />
  )
}
