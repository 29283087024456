import React from 'react'
import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Check } from '../../../assets'
import { FlexAnywhereWaitlistImage } from '../../../assets'
import { Headline1, InlineButton, PrimaryButton, Text } from '../../../components'
import { BasePageLayout } from '../../../layouts'

type FlexAnywhereWaitlistProps = {
  analyticsScreenName: Analytics.Screens
  onJoinWaitlist: () => void
  onLearnMore: () => void
  waitlistPushed: boolean
}

const FlexAnywhereWaitlist = (props: FlexAnywhereWaitlistProps) => {
  const theme = useTheme()

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Box minH="30px" />
      <Flex flexGrow={1} flexShrink={0} flexDirection="column" data-testid="OONWaitlistPage">
        <Flex align="center" justify="center">
          <FlexAnywhereWaitlistImage />
        </Flex>

        <Box minH={'36px'} />

        <Headline1 textAlign="center">Coming soon</Headline1>

        <Box minH={'16px'} />

        <Text textAlign="center">
          We can&rsquo;t offer you Flex service at the moment, but we&rsquo;re working on it! We&rsquo;ll notify you
          when more options become available.
        </Text>
        <Box minH={'32px'} />

        <Box minH={'32px'} />

        <Box>
          {props.waitlistPushed ? (
            <Flex justifyContent="center">
              <Box minH={'14px'} />
              <Box alignSelf="center">
                <InlineButton onClick={() => {}}>
                  <Flex justifyContent="center" align="center">
                    <CheckIcon style={{ marginRight: 8 }} />
                    <Text
                      fontSize={20}
                      color={theme.colors.jewelPurple}
                      fontWeight="bold"
                      textAlign="center"
                      fontFamily="RebrandDis"
                    >
                      You&rsquo;re on the waitlist!
                    </Text>
                  </Flex>
                </InlineButton>
              </Box>
            </Flex>
          ) : (
            <PrimaryButton onClick={props.onJoinWaitlist} testID="JoinWaitlistButton">
              Join the waitlist
            </PrimaryButton>
          )}
        </Box>
      </Flex>
    </BasePageLayout>
  )
}

export default FlexAnywhereWaitlist

const CheckIcon = styled(Check)`
  width: 18px;
  height: 18px;
`
