import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Attention, CheckCircleGreen } from '../../assets'
import { ExtraSmallText } from '../Typography'

export enum ReinforcementMessage {
  NEGATIVE = 'negative',
  POSITIVE = 'positive',
}

export type ReinforcementContent = {
  POSITIVE_FEEDBACK_HEADER: string
  POSITIVE_FEEDBACK_BODY: string
  NEGATIVE_FEEDBACK_HEADER: string
  NEGATIVE_FEEDBACK_BODY: string
}

export interface ReinforcementMessageWidgetProps {
  readonly content: ReinforcementContent
  readonly showReinforcementMessage?: ReinforcementMessage
}

export const ReinforcementMessageWidget = (props: ReinforcementMessageWidgetProps) => {
  if (!props.showReinforcementMessage) {
    return null
  }

  if (props.showReinforcementMessage === ReinforcementMessage.POSITIVE) {
    return (
      <Wrapper data-testid="PositiveReinforcementMessage">
        <ContentContainer>
          <IconWrapper>
            <CheckCircleGreen data-testid="PositiveReinforcementMessageIcon" />
          </IconWrapper>
          <Flex direction={'column'}>
            <ExtraSmallText fontWeight="bold">{props.content.POSITIVE_FEEDBACK_HEADER}</ExtraSmallText>
            <ExtraSmallText>{props.content.POSITIVE_FEEDBACK_BODY}</ExtraSmallText>
          </Flex>
        </ContentContainer>
      </Wrapper>
    )
  }

  return (
    <Wrapper data-testid="NegativeReinforcementMessage">
      <ContentContainer>
        <IconWrapper>
          <Attention data-testid="NegativeReinforcementMessageIcon" />
        </IconWrapper>
        <Flex direction={'column'}>
          <ExtraSmallText fontWeight="bold">{props.content.NEGATIVE_FEEDBACK_HEADER}</ExtraSmallText>
          <ExtraSmallText>{props.content.NEGATIVE_FEEDBACK_BODY}</ExtraSmallText>
        </Flex>
      </ContentContainer>
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  background-color: ${(props) => props.theme.colors.cloud};
  padding: ${(props) => props.theme.fixedSizes.spacing_75};
  border-radius: ${(props) => props.theme.radii.lg};
  align-content: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
`

const ContentContainer = styled(Flex)`
  flex-direction: row;
  align-content: center;
  gap: ${(props) => props.theme.fixedSizes.spacing_75};
`

const IconWrapper = styled(Flex)`
  align-items: center;
`
