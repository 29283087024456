import React from 'react'
import { Controller, FieldError } from 'react-hook-form'
import { Box } from '@chakra-ui/react'
import lowerCase from 'lodash/lowerCase'

import { SmallText, TextInput } from '../../components'

type FieldProps = {
  label: string
  name: string
  defaultValue?: string
  control: any
  error?: FieldError | string
  testID?: string
  autoComplete?: string
  onChange?: (value: string) => void
  fieldValue?: string
  fieldType?: string
  placeholder?: string
}

export function FormField(props: FieldProps) {
  const { control, label, error, name, defaultValue, testID, fieldValue, fieldType, placeholder } = props

  let errorMessage: string
  if (error && typeof error === 'object' && error.message) {
    const formatted = lowerCase(name)
    errorMessage = error.message.replace(name, formatted)
  } else if (typeof error === 'string') {
    errorMessage = error
  }

  return (
    <Controller
      control={control}
      render={({ field: { onChange, onBlur, value } }) => (
        <>
          <SmallText>{label}</SmallText>
          <Box minH="5px" />
          <TextInput
            onBlur={onBlur}
            onTextChange={(...events: any[]) => {
              if (props.onChange) {
                const value = events?.[0]
                props.onChange(value)
              }
              onChange(...events)
            }}
            text={fieldValue ?? value}
            error={!!errorMessage}
            testID={testID}
            autoComplete={props.autoComplete}
            type={fieldType}
            placeholder={placeholder}
          />
          <Box minH="5px" />
          <Box visibility={errorMessage ? 'visible' : 'hidden'} color="#E53A3A">
            <SmallText hasError={!!errorMessage}>{errorMessage}</SmallText>
          </Box>
        </>
      )}
      name={name}
      defaultValue={defaultValue || ''}
    />
  )
}
