import { OfferStates } from '@genoa/middle-end'

import { useLogger } from '../../providers'
import { useGetOffer } from '../flex2/risk'
import { ConnectingProperty } from './types'

type RelinkSuccessProps = {
  billerChanged: boolean
  relinkSamePropertyRedirect: () => void
  onConfirm: () => void
  setLoading: (value: boolean) => void
  billerAccountPublicId?: string
  connectingProperty: ConnectingProperty
}

export const useBillerConnection = () => {
  const loggerV2 = useLogger('use-biller-connection')
  const { getOffer } = useGetOffer()

  const handleRelinkSuccess = async ({
    billerChanged,
    relinkSamePropertyRedirect,
    onConfirm,
    setLoading,
    billerAccountPublicId,
    connectingProperty,
  }: RelinkSuccessProps) => {
    // Check if customer is relinking to the same property'
    if (!billerChanged) {
      if (!billerAccountPublicId) {
        loggerV2.error('No billerAccountPublicId found', 'no billerAccountPublicId on customer account', {
          billerChanged,
        })
        setLoading(false)
        relinkSamePropertyRedirect()
        return
      }
      const response = await getOffer(billerAccountPublicId)
      setLoading(false)

      if (response.status === 200 && response.data && response.data.offer_id) {
        const offer = response.data
        if (offer.offer_state !== OfferStates.ACCEPTED) {
          onConfirm()
          return
        } else {
          loggerV2.warn('Accepted offer found', undefined, {
            connectingProperty,
            billerChanged,
            offerId: offer?.offer_id,
          })
        }
      } else if (response.status === 404) {
        // no offer found, force full relink
        loggerV2.warn('No offer found', 'no offer found for biller account', { connectingProperty, billerChanged })
        onConfirm()
        return
      } else {
        // unhandled status code, not showing full relink flow
        loggerV2.error('Error retrieving offer', 'error retrieving offer for biller account', {
          billerChanged,
          status: response.status,
        })
      }
      relinkSamePropertyRedirect()
      return
    }
    // customer biller changed and we need to redirect to full relink
    setLoading(false)
    onConfirm()
  }

  return {
    handleRelinkSuccess,
  }
}
