import React from 'react'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { ExtraSmallText } from '../../../components'

export const ConfirmSMSText = () => {
  const theme = useTheme()

  return (
    <Box style={{ zIndex: 1, textAlign: 'justify' }}>
      <Box style={{ lineHeight: theme.lineHeights.xxs }}>
        <ExtraSmallText>
          By providing your phone number, you consent to receive a one-time text message from Flex. Standard message and
          data rates apply.
        </ExtraSmallText>
      </Box>
    </Box>
  )
}
