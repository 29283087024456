import React, { useMemo } from 'react'
import { FLEX_ANYWHERE_HOW_FLEX_WORKS_CONTENT as content } from '@genoa/screen-content'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useSecureLineOfCredit } from '../../../../hooks/secure-line-of-credit'
import { GuidedList } from '../../../components'

type FlexAnywhereHowFlexWorksGuidedListProps = {}

export const FlexAnywhereHowFlexWorksGuidedList = (props: FlexAnywhereHowFlexWorksGuidedListProps) => {
  const { isSimplifiedPaymentsEnabled, isEnabledForSLC } = useSecureLineOfCredit()

  const bullets = useMemo(() => {
    if (isSimplifiedPaymentsEnabled) {
      return content.SIMPLIFIED_PAYMENTS_BULLETS
    }
    if (isEnabledForSLC) {
      return content.SLC_BULLETS
    }
    return content.DEFAULT_BULLETS
  }, [isSimplifiedPaymentsEnabled, isEnabledForSLC])

  if (!isEnabledForSLC || isSimplifiedPaymentsEnabled) {
    return (
      <GuidedListContainer>
        <GuidedList
          items={bullets.map(({ HEADER, BODY }) => ({
            label: HEADER,
            content: BODY,
          }))}
        />
      </GuidedListContainer>
    )
  }

  return (
    <Box>
      <GuidedListContainer>
        <GuidedList title="First month" items={[{ label: bullets[0].HEADER, content: bullets[0].BODY }]} />
      </GuidedListContainer>
      <Box minH="24px" />
      <GuidedListContainer>
        <GuidedList
          title="Each month"
          items={bullets.slice(1, 3).map(({ HEADER, BODY }) => ({
            label: HEADER,
            content: BODY,
          }))}
        />
      </GuidedListContainer>
    </Box>
  )
}

const GuidedListContainer = styled(Box)`
  flex-direction: column;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  background-color: ${(props) => props.theme.colors.wildflower};
  border-radius: ${(props) => props.theme.radii.xl};
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
`
