import React from 'react'
import { Box } from '@chakra-ui/react'

import { PrimaryButton, SmallText } from '../../../components'
import { Modal } from '../../../components/Modal'

type RentAmountChangesModalProps = {
  visible: boolean
  onClose: () => unknown
  header?: string
  bodyOne?: string
  bodyTwo?: string
  cta?: string
}

export const RentAmountChanges = (props: RentAmountChangesModalProps) => {
  return (
    <Modal visible={props.visible} title={props.header || ''} canClose onClose={props.onClose}>
      <>
        <SmallText>{props.bodyOne}</SmallText>
        <Box minH="24px" />

        <SmallText>{props.bodyTwo}</SmallText>
        <Box minH="36px" />

        <PrimaryButton onClick={props.onClose}>{props.cta}</PrimaryButton>
      </>
    </Modal>
  )
}
