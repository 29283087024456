import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { ActionLogType } from '@genoa/domain'

import { useShowErrorMessageModal } from '../../../../hooks'
import { useHandleRegisterActionLog } from '../../../../hooks/flex2/onboarding-status'
import { useAnalytics, useLogger } from '../../../../providers'
import { useScreenView } from '../../../../providers/analytics/use-screen-view'
import { useContent } from '../../../../providers/content'
import * as Routes from '../../../../routing/constants'
import { Autopilot } from './AutoPilot'

export const AutopilotContainer = () => {
  const logger = useLogger('AutopilotContainer')
  const analytics = useAnalytics()
  const navigate = useNavigate()
  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()
  const { showErrorMessage } = useShowErrorMessageModal()
  const {
    content: { GENERIC_ERROR_MODAL },
  } = useContent()

  useScreenView(Analytics.Screens.ONBOARDING_AUTOPILOT)

  const handleErrors = (logMessage: string, logMetadata: any) => {
    logger.error(logMessage, undefined, logMetadata)
    showErrorMessage(GENERIC_ERROR_MODAL.TITLE, GENERIC_ERROR_MODAL.SUBTITLE)
  }

  const registerActionLog = async (action: ActionLogType) => {
    try {
      const response = await handleRegisterActionLog(action)
      if (response.status !== 204) {
        handleErrors('Unexpected status in register action log', { action, status: response.status })
        return false
      }

      return true
    } catch (error: any) {
      handleErrors('Unhandled error in register action log', { action })
      return false
    }
  }

  const handleNavigation = async () => {
    analytics.logEvent(Analytics.Events.AUTOPILOT_SET_UP_AUTOPAY_PRESSED)
    if (await registerActionLog(ActionLogType.SETUP_AUTOPILOT)) {
      navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE, { replace: true })
    }
  }

  const handleSkipAutopilot = async () => {
    analytics.logEvent(Analytics.Events.AUTOPILOT_SKIP_AUTOPAY_PRESSED)
    if (await registerActionLog(ActionLogType.SKIP_AUTOPILOT)) {
      navigate(Routes.Onboarding.CARD_STAGE_SELECTION)
    }
  }

  return (
    <Autopilot
      loading={loadingRegisterActionLog}
      handleNavigation={handleNavigation}
      handleSkipAutopilot={handleSkipAutopilot}
    />
  )
}
