import React, { useCallback, useEffect, useState } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks, LocalStorageProductType, OnboardingProductType } from '@genoa/domain'
import { AccountErrorCode, GeneralApiErrorCodes, setUserAccountAction, useQueryError } from '@genoa/state-management'

import { useAuthState, useModal } from '../../../../contexts'
import { useDefaultErrorModals, useIsEmbed, useReduxAction, useReduxSelector, useTriggerOnce } from '../../../../hooks'
import { usePrefills } from '../../../../hooks/prefills'
import { useEmbedPrefills } from '../../../../hooks/prefills/use-embed-prefills'
import { RootState } from '../../../../modules'
import { useUpsertUserAccountMutation } from '../../../../modules/flexApi'
import { ContactState } from '../../../../modules/onboarding'
import {
  OnboardingStatus,
  setOnboardingStatus,
  useAnalytics,
  useEnhancedTracking,
  useFlexLinks,
  useIterable,
  useLogger,
  useUserAccount,
} from '../../../../providers'
import { createAccountCreatedEvent } from '../../../../providers/iterable/user-events'
import { SmallText } from '../../../components'
import { CreateAccount } from './CreateAccount'

export interface CreateAccountContainerProps {
  readonly onNext: () => void
  readonly analyticsScreenName: Analytics.Screens
  readonly firstNameEditEvent: Analytics.Events
  readonly lastNameEditEvent: Analytics.Events
  readonly emailEditEvent: Analytics.Events
  readonly termsOfServiceClickedEvent: Analytics.Events
  readonly privacyPolicyClickedEvent: Analytics.Events
  readonly privacyNoticeClickedEvent: Analytics.Events
  readonly errorOnCTAEvent: Analytics.Events
  readonly accountCreationClickedEvent: Analytics.Events
  readonly emailAlreadyInUseEvent: Analytics.Events
  readonly hideTerms?: boolean
  readonly headerText?: string
  readonly subheaderText?: string
  readonly submitButtonText?: string
}

export function CreateAccountContainer(props: CreateAccountContainerProps) {
  const analytics = useAnalytics()
  const { trackAccountCreated } = useEnhancedTracking()
  const { user } = useAuthState()
  const logger = useLogger('CreateAccountContainer')

  const iterable = useIterable()
  const initialContact = useReduxSelector((state: RootState) => state.onboarding.contact)
  const [contact, setContact] = useState(initialContact)
  const isEmbed = useIsEmbed()
  const { handleEmbedAccountCreated } = useEmbedPrefills()

  const { userAccount } = useUserAccount()
  const { loadingPropertyPrefills } = usePrefills()

  const setUserAccount = useReduxAction(setUserAccountAction)

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState({})
  const modal = useModal()
  const { displayTooManyTriesModal } = useDefaultErrorModals()
  const flexLinks = useFlexLinks()

  const [upsertUserAccount, { isLoading: loadingUpsertUserAccount, error }] = useUpsertUserAccountMutation()

  const onFirstNameEdit = useTriggerOnce(() => analytics.logEvent(props.firstNameEditEvent))
  const onLastNameEdit = useTriggerOnce(() => analytics.logEvent(props.lastNameEditEvent))
  const onEmailEdit = useTriggerOnce(() => {
    analytics.logEvent(props.emailEditEvent)
  })

  const resolveProductType = (productType: any) => {
    switch (productType) {
      case LocalStorageProductType.CREDIT_BUILDER:
        return OnboardingProductType.CREDIT_BUILDER
      case LocalStorageProductType.RENT_SPLIT:
        return OnboardingProductType.CLASSIC
      default:
        return
    }
  }

  useEffect(() => {
    const hasNamePreviouslySet = !!userAccount.firstName && !!userAccount.lastName

    if (hasNamePreviouslySet && !contact.firstName) {
      setContact((prevContact: ContactState) => ({
        ...prevContact,
        firstName: userAccount.firstName || prevContact.firstName,
      }))
    }

    if (hasNamePreviouslySet && !contact.lastName) {
      setContact((prevContact: ContactState) => ({
        ...prevContact,
        lastName: userAccount.lastName || contact.lastName || prevContact.lastName,
      }))
    }
  }, [userAccount.firstName, userAccount.lastName])

  useEffect(() => {
    const hasEmailPreviouslySet = userAccount.email

    if (hasEmailPreviouslySet && !contact.email) {
      setContact((prevContact: ContactState) => ({
        ...prevContact,
        email: userAccount.email || prevContact.email,
      }))
    }
  }, [userAccount.email])

  useEffect(() => {
    setOnboardingStatus(OnboardingStatus.Onboarding)
    analytics.logScreenView(props.analyticsScreenName)
  }, [])

  const handleOpenTermsOfService = useCallback(() => {
    analytics.logEvent(props.termsOfServiceClickedEvent)
    flexLinks.open(FlexLinks.termsOfService)
  }, [flexLinks.open])

  const handleOpenPrivacyPolicy = useCallback(() => {
    analytics.logEvent(props.privacyPolicyClickedEvent)
    flexLinks.open(FlexLinks.privacyPolicy)
  }, [flexLinks.open])

  const handleOpenPrivacyNotice = useCallback(() => {
    analytics.logEvent(props.privacyNoticeClickedEvent)
    flexLinks.open(FlexLinks.privacyNotice)
  }, [flexLinks.open])

  const handleOpenESignConsent = useCallback(() => {
    flexLinks.open(FlexLinks.esignConsent)
  }, [flexLinks.open])

  const handleShowError = () => {
    analytics.logEvent(props.errorOnCTAEvent)

    modal.show({
      title: 'Something went wrong',
      cta: 'Try again',
      render: () => (
        <SmallText>
          We couldn&apos;t process your request. Please try again or contact support if the problem persists.
        </SmallText>
      ),
    })
  }

  const handleIterableAccountCreated = (firstName: string, lastName: string, phoneNumber: string) => {
    iterable.setContactDetails({
      first_name: firstName,
      last_name: lastName,
      phoneNumber: phoneNumber,
    })

    iterable.addEvent(createAccountCreatedEvent())
  }

  const handleDone = async (contact: ContactState) => {
    analytics.logEvent(props.accountCreationClickedEvent)
    setLoading(true)
    setErrors({})
    if (!contact.email || !contact.firstName || !contact.lastName || !user?.uid || !user.phoneNumber) {
      logger.warn('Called handleDone with a missing value')
      handleShowError()
      return
    }

    const requestBody = {
      customerId: user?.uid,
      first_name: contact.firstName.trim(),
      last_name: contact.lastName.trim(),
      email: contact.email.trim(),
      phone_number: user.phoneNumber.replace('+', ''),
    }

    const logAccountCreatedSuccessfully = () =>
      trackAccountCreated(requestBody.first_name, requestBody.last_name, requestBody.email)

    const productType = resolveProductType(localStorage.getItem('flexapp/product'))
    const response = await upsertUserAccount({
      ...requestBody,
      onboarding_product_type: resolveProductType(localStorage.getItem('flexapp/product')),
    }).unwrap()

    const userAccount = response.data
    setUserAccount({
      user_account: {
        ...userAccount,
        customer_public_id: userAccount.customer_public_id ?? user?.uid,
        email_verified: userAccount.email_verified ?? false,
      },
    })
    handleIterableAccountCreated(userAccount.first_name, userAccount.last_name, userAccount.phone_number)
    logAccountCreatedSuccessfully()

    const analyticsProductType = productType === OnboardingProductType.CREDIT_BUILDER ? 'credit-builder' : 'rent-split'
    analytics.logEvent(Analytics.Events.PRODUCT_ASSIGNMENT_EVENT, {
      product_type: analyticsProductType,
    })
    analytics.setUserProperty(Analytics.UserProperties.PRODUCT_TYPE, analyticsProductType)

    if (isEmbed) {
      return handleEmbedAccountCreated()
    }

    await props.onNext()
  }

  useQueryError(error, {
    onFlexApiError: ({ data: { error } }) => {
      setLoading(false)
      if (error.code === AccountErrorCode.AccountNotFound) {
        analytics.logEvent(Analytics.Events.SIGNUP_ACCOUNT_NOT_FOUND)
      } else if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        logger.error('Error upserting user account', 'Too many attempts')
        displayTooManyTriesModal()
      } else if (error.code === AccountErrorCode.EmailAlreadyInUse) {
        analytics.logEvent(props.emailAlreadyInUseEvent)
        setErrors({ email: 'The email address is already in use by another account.' })
      } else if (error.code === AccountErrorCode.EmailInvalid) {
        setErrors({ email: 'This looks like an invalid email address' })
      } else {
        logger.error('Error upserting user account', `Unhandled error: ${error?.message}`)
        handleShowError()
      }
    },
  })

  const resetContact = () => setContact({ firstName: '', lastName: '', email: '' })

  return (
    <CreateAccount
      contact={contact}
      isLoading={loadingPropertyPrefills || loadingUpsertUserAccount || loading}
      errors={errors}
      onOpenTermsOfService={handleOpenTermsOfService}
      onOpenPrivacyPolicy={handleOpenPrivacyPolicy}
      onOpenPrivacyNotice={handleOpenPrivacyNotice}
      onOpenESignConsent={handleOpenESignConsent}
      onDone={handleDone}
      resetContact={resetContact}
      onChangeFirstName={onFirstNameEdit}
      onChangeLastName={onLastNameEdit}
      onChangeEmail={onEmailEdit}
      hideTerms={props.hideTerms}
      headerText={props.headerText}
      subheaderText={props.subheaderText}
      submitButtonText={props.submitButtonText}
    />
  )
}
