import React from 'react'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { SmallText } from '../Typography'

type DisclaimerBoxProps = {
  content: string | React.ReactNode
  icon?: React.ReactNode
}

export const DisclaimerBox = (props: DisclaimerBoxProps) => {
  const isContentString = typeof props.content === 'string'

  return (
    <DisclaimerContainer>
      {props.icon && <IconWrapper>{props.icon}</IconWrapper>}
      <ComplexCardTextWrapper>
        {isContentString ? <SmallText>️{props.content}</SmallText> : props.content}
      </ComplexCardTextWrapper>
    </DisclaimerContainer>
  )
}

const DisclaimerContainer = styled(Box)`
  width: 100%;
  flex-direction: row;
  background-color: ${(props) => props.theme.colors.cloud};
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  border-radius: ${(props) => props.theme.fixedSizes.spacing_50};
  align-items: center;
  justify-content: center;
`

const IconWrapper = styled(Box)`
  justify-content: center;
  padding-right: ${(props) => props.theme.fixedSizes.spacing_75};
`

const ComplexCardTextWrapper = styled(Box)`
  flex-direction: column;
  justify-content: center;
  flex: 1;
`
