import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import * as Routes from '../../routing/constants'
import { useNavigateToOnboardingPropertySelection } from '../navigation'
import { useSecureLineOfCredit } from '../secure-line-of-credit'

interface UseHandleNavigationForCreditBuilderValuePropParams {
  source: string
}

export const useHandleNavigationForCreditBuilderValueProp = (
  params: UseHandleNavigationForCreditBuilderValuePropParams
) => {
  const { refetchSLC } = useSecureLineOfCredit()
  const navigate = useNavigate()
  const navigateToPropertySelection = useNavigateToOnboardingPropertySelection()

  const navigateToCreditBuilderValueProp = async () => {
    const { isFlagEnabledForCreditBuilder } = await refetchSLC()

    if (isFlagEnabledForCreditBuilder) {
      navigate(Routes.Onboarding.CREDIT_BUILDER_VALUE_PROP)
    } else {
      navigateToPropertySelection()
    }
  }

  const navigateToDirectIntegrationUnit = async () => {
    const { isFlagEnabledForCreditBuilder } = await refetchSLC()

    if (isFlagEnabledForCreditBuilder) {
      navigate(Routes.Onboarding.CREDIT_BUILDER_VALUE_PROP)
    } else {
      navigate(Routes.Onboarding.DIRECT_INTEGRATION_UNIT)
    }
  }

  const navigateNext = params.source === 'create' ? navigateToCreditBuilderValueProp : navigateToDirectIntegrationUnit

  return {
    navigateNext,
  }
}
