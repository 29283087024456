import { useEffect, useState } from 'react'
import { SingularConfig, singularSdk } from 'singular-sdk'

import { useAmplitude } from '../providers/amplitude/amplitude-provider'
import { Config } from '../providers/config/config'
import { logger } from '../providers/logger/logger'

export const useSingularInitializer = () => {
  const { deviceId } = useAmplitude()
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    if (initialized || !deviceId) return

    try {
      const config = new SingularConfig(Config.SINGULAR_KEY, Config.SINGULAR_SECRET, Config.SINGULAR_BUNDLE_ID)
      config.withPersistentSingularDeviceId(deviceId)

      singularSdk.init(config)
      setInitialized(true)
    } catch (error) {
      logger.error('useSingularInit', 'Error initializing Singular SDK', (error as Error).stack)
    }
  }, [deviceId, initialized])
}
