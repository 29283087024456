import { Analytics } from '@genoa/analytics'
import { BillerPropertyDetails, BillerPropertyUnit } from '@genoa/domain'
import { UNIT_ENTRY as content } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Gap, Headline1, Headline2, LargeText, PrimaryButton, SmallText, TextInput } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'

interface DirectIntegrationManualUnitEntryProps {
  readonly analyticsScreenName: Analytics.Screens
  readonly property: BillerPropertyDetails
  readonly userUnitInput: string
  readonly connectingBiller?: boolean
  readonly onUserUnitInput: (input: string) => unknown
  readonly onConfirm: () => void
}

export const DirectIntegrationManualUnitEntry = (props: DirectIntegrationManualUnitEntryProps) => {
  const theme = useTheme()

  return (
    <BasePageLayout
      analyticsScreenName={props.analyticsScreenName}
      analyticsScreenParams={{ property: props.property, manualUnitEntry: true }}
    >
      <Container>
        <Gap size="spacing_200" />
        <Box>
          <Headline2>{content.HEADER}</Headline2>
          <Gap size="spacing_200" />
          <LargeText>{content.SUB_HEADER}</LargeText>
          <Gap size="spacing_200" />
          <Box>
            <Flex direction="column">
              <LargeText>{props.property.name}</LargeText>
              <SmallText color={theme.colors.text.quiet}>{props.property.location?.address_line_1}</SmallText>
              {props.property.location?.address_line_2 && (
                <SmallText color={theme.colors.text.quiet}>{props.property.location?.address_line_2}</SmallText>
              )}
              <SmallText color={theme.colors.text.quiet}>
                {props.property.location?.city}
                {props.property.location?.state ? ', ' + props.property.location.state : ''}
                {props.property.location?.postal_code ? ' ' + props.property.location.postal_code : ''}
              </SmallText>
            </Flex>
          </Box>
          <Gap size="spacing_200" />

          <SmallText fontWeight="bold">{content.INPUT_LABEL}</SmallText>
          <Gap size="spacing_25" />
          <TextInput
            text={props.userUnitInput}
            onTextChange={props.onUserUnitInput}
            testID="DirectIntegrationManualUnitEntryTextInput"
          />
        </Box>
      </Container>
      <PrimaryButton
        testID="ConfirmButton"
        onClick={props.onConfirm}
        processing={props.connectingBiller}
        disabled={props.userUnitInput.length > 50}
      >
        {content.CTA}
      </PrimaryButton>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  flex-grow: 1;
`
