import { useTheme } from '@emotion/react'

export const useMarkdownStyles = () => {
  const theme = useTheme()

  return Object.freeze({
    theme: theme,
    paragraph: {
      fontFamily: theme.fonts.body,
      fontSize: 16,
      lineHeight: 27,
      opacity: 0.9,
      color: theme.colors.text,
    },
    heading: {
      fontFamily: theme.fonts.heading,
      fontSize: 24,
      lineHeight: 34,
      color: theme.colors.text,
    },
    heading1: {
      fontFamily: theme.fonts.heading,
      fontSize: 24,
      lineHeight: 34,
    },
    heading2: {
      fontSize: 22,
      lineHeight: 30,
    },
    heading3: {
      fontSize: 20,
      lineHeight: 28,
    },
    heading4: {
      fontSize: 18,
    },
    heading5: {
      fontSize: 16,
    },
    heading6: {
      fontSize: 14,
    },
    hr: {
      backgroundColor: '#ccc',
      height: 1,
    },
    em: {
      fontStyle: 'italic',
    },
    bold: {
      fontFamily: theme.fonts.body,
    },
    link: {
      color: theme.colors.jewelPurple,
    },
    list: {
      margin: 4,
      marginBottom: 16,
      color: theme.colors.text,
    },
    listItem: {
      flexDirection: 'row',
      fontSize: 14,
      lineHeight: 27,
      marginTop: 16,
      fontFamily: theme.fonts.body,
      color: theme.colors.text,
    },
    listItemNumber: {
      minWidth: 32,
      paddingRight: 4,
      fontSize: 14,
      lineHeight: 27,
      fontFamily: theme.fonts.body,
      color: theme.colors.text,
    },
    listItemBullet: {
      minWidth: 32,
      paddingRight: 4,
      fontSize: 16,
      lineHeight: 27,
      fontFamily: theme.fonts.body,
      color: theme.colors.text,
    },
    listItemOrderedContent: {
      flex: 1,
      fontSize: 16,
      lineHeight: 27,
      fontFamily: theme.fonts.body,
      color: theme.colors.text,
    },
    listItemUnorderedContent: {
      flex: 1,
      fontSize: 16,
      lineHeight: 27,
      fontFamily: theme.fonts.body,
      color: theme.colors.text,
    },
    table: {
      margin: 4,
      borderColor: '#222',
    },
    tableHeaderCell: {
      borderColor: '#222',
    },
    tableHeaderCellContent: {
      fontWeight: '700',
    },
    tableCell: {
      padding: 5,
    },
    tableCellOddRow: {
      backgroundColor: 'rgba(128, 128, 128, 0.1)',
    },
    tableCellEvenRow: {},
    tableCellLastRow: {
      borderBottomWidth: 0,
    },
    tableCellOddColumn: {},
    tableCellEvenColumn: {},
    tableCellLastColumn: {
      borderRightWidth: 0,
    },
    tableCellContent: {},
    tableCellContentOddRow: {},
    tableCellContentEvenRow: {},
    tableCellContentLastRow: {},
    tableCellContentOddColumn: {},
    tableCellContentEvenColumn: {},
    tableCellContentLastColumn: {},
    u: {
      textDecorationLine: 'underline',
    },
    colors: {
      markdown: theme.colors.black,
    },
  })
}
