import { DeepMap, FieldError } from 'react-hook-form'
import { EMBED_UPDATE_CARD_BILLING_ADDRESS as content } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { Headline2, SmallText } from '../../../../../components'
import { PrimaryButton } from '../../../../../components'
import {
  BillingAddress,
  BillingAddressFormProps,
} from '../../../../onboarding/payment-method/card/register-card/billing-address-types'
import { UpdateCardBillingAddressInput } from '../../../../onboarding/payment-method/card/update-card-billing-address/UpdateCardBillingAddressInput'

type UpdateCardBillingAddressProps = BillingAddressFormProps & {
  errors: Partial<DeepMap<BillingAddress, FieldError>>
  isVisible: boolean
  onPressCTA: () => unknown
}

export const UpdateCardBillingAddress = (props: UpdateCardBillingAddressProps) => (
  <BillingAddressArea isVisible={props.isVisible}>
    <Box minH="30px" px="lg" />
    <Headline2 mb={2}>{content.HEADER}</Headline2>
    <SmallText>{content.BODY}</SmallText>
    <Box mb={5} />
    <UpdateCardBillingAddressInput
      label="Name on card"
      name="name"
      control={props.control}
      error={props.errors.name}
      testID="UpdateCardBillingAddressNameInput"
    />
    <Box mb={5} />
    <UpdateCardBillingAddressInput
      label="Street address"
      name="streetAddress"
      control={props.control}
      error={props.errors.streetAddress}
      testID="UpdateCardBillingAddressAddressInput"
    />
    <Box mb={5} />
    <UpdateCardBillingAddressInput
      label="Apt, suite, or unit"
      name="unit"
      control={props.control}
      error={props.errors.unit}
      testID="UpdateCardBillingAddressUnitInput"
    />
    <Box mb={5} />
    <UpdateCardBillingAddressInput
      label="City"
      name="city"
      control={props.control}
      error={props.errors.city}
      testID="UpdateCardBillingAddressCityInput"
    />
    <Box mb={5} />
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Box>
        <UpdateCardBillingAddressInput
          label="State"
          name="state"
          control={props.control}
          error={props.errors.state}
          testID="UpdateCardBillingAddressStateInput"
        />
      </Box>
      <Box w="20px" />
      <Box>
        <UpdateCardBillingAddressInput
          label="Zip code"
          name="zip"
          control={props.control}
          error={props.errors.zip}
          testID="UpdateCardBillingAddressZipInput"
        />
      </Box>
    </Box>
    <Box mb={5} />
    <PrimaryButton onClick={props.onPressCTA} testID="UpdateCardBillingAddressSaveButton">
      {content.CTA}
    </PrimaryButton>
  </BillingAddressArea>
)

const BillingAddressArea = styled(Flex)<{ isVisible: boolean }>`
  height: 100%;
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  flex-direction: column;
`
