import { useCallback } from 'react'
import { browserName, browserVersion, isBrowser, isMobile } from 'react-device-detect'
import { Analytics } from '@genoa/analytics'

import { useAuthState } from '../contexts'
import { setCoBrandingPropertyAction } from '../modules'
import { useAnalytics } from '../providers'
import { useReduxAction } from './use-redux-action'

const getDeviceType = (): string => {
  const version = `${browserName}@${browserVersion}`
  if (isBrowser) {
    return `browser/${version}`
  }
  if (isMobile) {
    return `mobile/${version}`
  }
  return `unknown/${version}`
}

export interface UTMParams {
  readonly utm_campaign: string
  readonly utm_source: string
  readonly utm_medium: string
}

export interface ComarketingParams {
  readonly hub_user_id?: string
  readonly property_id?: string
  readonly pmc_id?: string
  readonly asset?: string
  readonly asset_type?: string
  readonly asset_name?: string
  readonly referrer_campaign?: string
  readonly referrer_medium?: string
}

export interface ApplicationParams extends UTMParams, ComarketingParams {
  readonly property_name?: string
  readonly amp_device_id?: string
}

const comarketingParams = [
  'hub_user_id',
  'property_id',
  'pmc_id',
  'asset',
  'asset_type',
  'asset_name',
  'referrer_campaign',
  'referrer_medium',
]

const applicationParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'property_name',
  'amp_device_id',
  ...comarketingParams,
]
const urlParamsToApplicationParams = (urlParams: URLSearchParams): ApplicationParams => {
  return applicationParams.reduce((previous, current) => {
    return {
      ...previous,
      [current]: urlParams.get(current),
    }
  }, {}) as ApplicationParams
}

export const useTriggerApplicationOpen = () => {
  const analytics = useAnalytics()
  const authState = useAuthState()
  const setCoBrandingProperty = useReduxAction(setCoBrandingPropertyAction)

  const extractUrlParams = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParamsToApplicationParams(urlParams)
  }

  const triggerApplicationOpen = useCallback(() => {
    const {
      utm_source,
      utm_medium,
      utm_campaign,
      property_name,
      amp_device_id,
      hub_user_id,
      property_id,
      pmc_id,
      asset,
      asset_type,
      asset_name,
      referrer_campaign,
      referrer_medium,
    } = extractUrlParams()
    const deviceType = getDeviceType()

    if (property_name) {
      setCoBrandingProperty(property_name)
    }
    if (amp_device_id) {
      analytics.setDeviceId(amp_device_id)
    }

    analytics.setUserProperty(Analytics.UserProperties.USERID, !authState.isAnonymous ? authState.uid : null)
    analytics.setUserProperty(Analytics.UserProperties.UTM_CAMPAIGN, utm_campaign)
    analytics.setUserProperty(Analytics.UserProperties.UTM_MEDIUM, utm_medium)
    analytics.setUserProperty(Analytics.UserProperties.UTM_SOURCE, utm_source)
    analytics.setUserProperty(Analytics.UserProperties.DEVICETYPE, deviceType)
    analytics.setUserProperty(Analytics.UserProperties.HUB_USER_ID, hub_user_id)
    analytics.setUserProperty(Analytics.UserProperties.PROPERTY_ID, property_id)
    analytics.setUserProperty(Analytics.UserProperties.PMC_ID, pmc_id)
    analytics.setUserProperty(Analytics.UserProperties.REFERRER_CAMPAIGN, referrer_campaign)
    analytics.setUserProperty(Analytics.UserProperties.REFERRER_MEDIUM, referrer_medium)

    const eventAttributes = {
      referrer_url: document?.referrer,
      referrer_campaign,
      referrer_medium,
      utm_campaign,
      utm_medium,
      utm_source,
      hub_user_id,
      property_id,
      pmc_id,
      asset,
      asset_type,
      asset_name,
    }

    analytics.logEvent(Analytics.Events.APPLICATION_OPEN, eventAttributes)
  }, [authState])

  return triggerApplicationOpen
}
