import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { StatusIconGreen, StatusIconRed } from '../../assets'
import { ShadowedFrame } from '../Frames'
import { LinkText, SmallText } from '../Typography'

export interface AutopayStatusButtonProps {
  readonly status: boolean
  readonly onChange: () => void
}

export const AutopayStatusButton = ({ status, onChange }: AutopayStatusButtonProps) => {
  return (
    <AutopayStatusContainer>
      <StatusTextContainer>
        {status ? <StatusIconGreen /> : <StatusIconRed />}
        <SmallText>
          Rent autopay is <SmallText fontWeight="bold">{status ? 'on' : 'off'}</SmallText>
        </SmallText>
      </StatusTextContainer>
      <ChangeText onClick={onChange}>Change</ChangeText>
    </AutopayStatusContainer>
  )
}

const ChangeText = styled(LinkText)`
  font-size: ${(props) => props.theme.fontSizes.xxs};
  font-weight: bold;
`

const StatusTextContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${(props) => props.theme.fixedSizes.spacing_50};
`

const AutopayStatusContainer = styled(ShadowedFrame)`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${(props) => props.theme.fixedSizes.spacing_75};
  padding-left: ${(props) => props.theme.fixedSizes.spacing_100};
  padding-right: ${(props) => props.theme.fixedSizes.spacing_100};
`
