import React from 'react'
import { Navigate, Route, RouteProps } from 'react-router-dom'

import { useAuthState } from '../contexts'

export const PublicOnlyRoute: React.FC<React.PropsWithChildren> = (props) => {
  const auth = useAuthState()
  const authed = auth.authenticated && !auth.isAnonymous && !auth.processingLogin

  if (auth.user === null) {
    return null
  }

  if (!authed) {
    return props.children as JSX.Element
  }

  return <Navigate to={{ pathname: '/onboarding' }} replace />
}
