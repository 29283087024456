import { Installment, ReimbursementMethod } from '@genoa/domain'
import { ActionLogType } from '@genoa/domain'
import { deepReplaceContent, SLC_HOW_TO_PAY_FLEX_BACK_CONTENT } from '@genoa/screen-content'
import { toFormattedDollars } from '@genoa/utils'
import { Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import {
  ExtraSmallText,
  Gap,
  Headline2,
  PaymentSchedule,
  Pill,
  PrimaryButton,
  SelectableCard,
} from '../../../../components'

export interface SLCHowToPayFlexBackProps {
  readonly firstInstallment: Installment
  readonly secondInstallment: Installment
  readonly creditLine: string
  readonly isPricingEnabled: boolean
  readonly confirmDisabled: boolean
  readonly selectedOption: ReimbursementMethod | undefined
  readonly onSelectOption: (option: ReimbursementMethod) => void
  readonly onConfirm: () => void
  readonly loadingSubmit: boolean
}

export const SLCHowToPayFlexBack = ({
  firstInstallment,
  secondInstallment,
  selectedOption,
  creditLine,
  isPricingEnabled,
  confirmDisabled,
  onSelectOption,
  onConfirm,
  loadingSubmit,
}: SLCHowToPayFlexBackProps) => {
  const theme = useTheme()

  const content = deepReplaceContent(SLC_HOW_TO_PAY_FLEX_BACK_CONTENT, {
    firstPayment: toFormattedDollars(firstInstallment.totalAmount),
    secondPayment: toFormattedDollars(secondInstallment.totalAmount),
    processingPercentage: firstInstallment.processingFeePercentage.toFixed(),
    processingFeeAmount: toFormattedDollars(firstInstallment.processingFeeAmount),
  })

  const firstPayment = {
    label: content.FIRST_PAYMENT.TITLE,
    amount: toFormattedDollars(firstInstallment.totalAmount),
    lineItems: [
      {
        label: isPricingEnabled
          ? content.FIRST_PAYMENT.PRICING_LINE_ITEM
          : content.FIRST_PAYMENT.BILL_PAYMENT_LINE_ITEM,
      },
    ],
  }

  const secondPayment = {
    label: content.SECOND_PAYMENT.TITLE,
    amount: toFormattedDollars(secondInstallment.totalAmount),
  }

  return (
    <Container>
      <Stack>
        <Headline2>{content.HEADER}</Headline2>
      </Stack>
      <Stack>
        <PaymentSchedule payments={[firstPayment]} />
        <PaymentSchedule payments={[secondPayment]}>
          <SelectableCard
            isSelected={selectedOption === ActionLogType.ENABLE_SIMPLIFIED_PAYMENTS}
            onClick={() => onSelectOption(ActionLogType.ENABLE_SIMPLIFIED_PAYMENTS)}
            title={content.DEPOSIT.TITLE}
          >
            <RepaymentContent>
              <Pill label="Recommended" />
              <ExtraSmallText color={theme.colors.dusk}>{content.DEPOSIT.CONTENT}</ExtraSmallText>
            </RepaymentContent>
          </SelectableCard>
          <SelectableCard
            isSelected={selectedOption === ActionLogType.DISABLE_SIMPLIFIED_PAYMENTS}
            onClick={() => onSelectOption(ActionLogType.DISABLE_SIMPLIFIED_PAYMENTS)}
            title={content.CARD.TITLE}
          >
            {content.CARD.CONTENT}
          </SelectableCard>
        </PaymentSchedule>
      </Stack>
      <Stack>
        <Gap size="spacing_100" />
        <PrimaryButton disabled={confirmDisabled || loadingSubmit} processing={loadingSubmit} onClick={onConfirm}>
          {content.CTA}
        </PrimaryButton>
      </Stack>
    </Container>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: ${(props) => props.theme.fixedSizes.spacing_200};
`

const Stack = styled(Container)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_100};
`

const RepaymentContent = styled(Container)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_50};
`
