import { Box } from '@chakra-ui/react'

import { useModal } from '../../contexts'
import { useContent } from '../../providers/content'
import { SmallText } from '../../views/components'
import { Gap } from '../../views/components/Gap'

export const useInterestFeeInfoModal = () => {
  const modal = useModal()
  const {
    content: { INTEREST_FEE_MODAL },
    replaceContent,
  } = useContent()

  const showInterestFeeInfoModal = (interestPercent: number) => {
    modal.show({
      title: INTEREST_FEE_MODAL.TITLE,
      cta: INTEREST_FEE_MODAL.CTA,
      render: () => (
        <Box>
          <SmallText>{INTEREST_FEE_MODAL.BODY_1}</SmallText>
          <Gap size={'spacing_125'} />
          <SmallText>
            {replaceContent(INTEREST_FEE_MODAL.BODY_2, {
              feePercent: interestPercent,
            })}
          </SmallText>
          <Gap size={'spacing_125'} />
          <SmallText>
            {replaceContent(INTEREST_FEE_MODAL.BODY_3, {
              annualPercentageRate: interestPercent * 12,
            })}
          </SmallText>
          <Gap size={'spacing_125'} />
          <SmallText>
            <SmallText fontWeight="bold">{INTEREST_FEE_MODAL.BODY_4_BOLD}</SmallText>
            <SmallText>{INTEREST_FEE_MODAL.BODY_4}</SmallText>
          </SmallText>
        </Box>
      ),
    })
  }

  return {
    showInterestFeeInfoModal,
  }
}
