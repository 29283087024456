import { FormProvider, useForm } from 'react-hook-form'
import { Analytics } from '@genoa/analytics'
import { P2P_WAITLIST_CONTENT } from '@genoa/screen-content'
import { Box, Stack } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { useAnalytics } from '../../../../providers'
import { AlarmClock } from '../../../assets'
import { Headline2, PrimaryButton, SmallText, Text } from '../../../components'
import { RadioGroup } from '../../../components/Form/RadioGroup'
import { BasePageLayout } from '../../../layouts'

const {
  BODY,
  CONTACT_FIELD_LABEL,
  CONTACT_FIELD_NAME,
  CONTACT_OPTIONS,
  CTA,
  ERROR,
  PAY_FIELD_LABEL,
  PAY_FIELD_NAME,
  PAY_OPTIONS,
  WEB_SUCCESS_BODY,
  SUCCESS_HEADING,
  TITLE,
} = P2P_WAITLIST_CONTENT

const schema = yup.object().shape({
  [CONTACT_FIELD_NAME]: yup.string().required(),
  [PAY_FIELD_NAME]: yup.string().required(),
})

export function P2PWaitlist() {
  const { colors } = useTheme()
  const useFormProps = useForm({ resolver: yupResolver(schema) })

  const {
    formState: { isValid, isSubmitted, isSubmitSuccessful, isSubmitting },
    handleSubmit,
  } = useFormProps
  const analytics = useAnalytics()

  const onSubmit = handleSubmit((data) => {
    analytics.logEvent(Analytics.Events.P2P_WAITLIST_CTA_CLICKED, data)
  })

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_P2P_WAITLIST}>
      <Stack spacing={5} alignItems="center" mt={8}>
        <AlarmClock />
        <Headline2>{TITLE}</Headline2>
        <Text align="center">{BODY}</Text>
        <Box w="full" backgroundColor={colors.wildflower} borderRadius={8} p={5}>
          {isSubmitSuccessful ? (
            <Stack>
              <Text fontWeight="bold">{SUCCESS_HEADING}</Text>
              <SmallText>{WEB_SUCCESS_BODY}</SmallText>
            </Stack>
          ) : (
            <FormProvider {...useFormProps}>
              <form onSubmit={onSubmit}>
                <Stack spacing={8}>
                  <RadioGroup name={CONTACT_FIELD_NAME} title={CONTACT_FIELD_LABEL} options={CONTACT_OPTIONS} />
                  <RadioGroup name={PAY_FIELD_NAME} title={PAY_FIELD_LABEL} options={PAY_OPTIONS} />
                  {!isValid && isSubmitted && (
                    <SmallText textAlign="center" color={colors.red}>
                      {ERROR}
                    </SmallText>
                  )}
                  <PrimaryButton processing={isSubmitting} type="submit">
                    {CTA}
                  </PrimaryButton>
                </Stack>
              </form>
            </FormProvider>
          )}
        </Box>
      </Stack>
    </BasePageLayout>
  )
}
