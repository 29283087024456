import { Analytics } from '@genoa/analytics'
import { Stack } from '@chakra-ui/react'

import { useContent } from '../../../providers/content'
import { Error } from '../../assets'
import { Headline1, SmallText } from '../../components'
import { AppStoreButton } from '../../components/AppStoreButton'
import { PlayStoreButton } from '../../components/PlayStoreButton'
import { BasePageLayout } from '../../layouts'

export function AppRedirectView() {
  const {
    content: { APP_REDIRECT },
  } = useContent()

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.APP_REDIRECT}>
      <Stack mt="20" alignItems="center">
        <Stack textAlign="center" alignItems="center">
          <Error />
          <Stack mt="6" spacing="5">
            <Headline1>{APP_REDIRECT.TITLE}</Headline1>
            <SmallText>{APP_REDIRECT.BODY}</SmallText>
          </Stack>
          <Stack
            spacing="2"
            rounded="xl"
            width="full"
            alignItems="center"
            py="8"
            px="10"
            mt="5"
            backgroundColor="gray.200"
          >
            <AppStoreButton transform="scale(0.7)" />
            <PlayStoreButton transform="scale(0.7)" />
          </Stack>
        </Stack>
      </Stack>
    </BasePageLayout>
  )
}
