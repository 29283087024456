import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { SLCType } from '@genoa/domain'

import { useAuthState } from '../../../contexts'
import { useIsEmbed, useSecureLineOfCredit } from '../../../hooks'
import { useLazyGetCreditBuilderConversionEligibilityQuery } from '../../../modules/flexApi'
import { useAnalytics } from '../../../providers'
import * as Routes from '../../../routing/constants'
import { FullScreenSpinnerLoading } from '../../components'
import { BasePageLayout } from '../../layouts'

export const UpsellEligibilityContainer = () => {
  const navigate = useNavigate()
  const analytics = useAnalytics()
  const { uid } = useAuthState()
  const isEmbed = useIsEmbed()
  const { slcType } = useSecureLineOfCredit()

  const [getCreditBuilderConversionEligibility] = useLazyGetCreditBuilderConversionEligibilityQuery()

  const handleCreditBuilderEligibility = async () => {
    analytics.logEvent(Analytics.Events.UPSELL_GET_ELIGIBILITY)
    try {
      const response = await getCreditBuilderConversionEligibility({ customerId: uid! }).unwrap()
      analytics.logEvent(Analytics.Events.UPSELL_GET_ELIGIBILITY_SUCC, response.data)

      if (response.data.eligible) {
        return navigate(Routes.Onboarding.CREDIT_BUILDER_UPSELL)
      }
    } catch {
      analytics.logEvent(Analytics.Events.UPSELL_GET_ELIGIBILITY_FAIL)
    }

    return navigate(Routes.Onboarding.NOT_APPROVED)
  }

  useEffect(() => {
    // Embed flow cannot be offered credit builder upsell
    if (isEmbed) {
      return navigate(Routes.Onboarding.NOT_APPROVED)
    }

    // If the offer is already credit builder dont offer upsell
    if (slcType === SLCType.CREDIT_BUILDER) {
      return navigate(Routes.Onboarding.NOT_APPROVED)
    }

    handleCreditBuilderEligibility()
  }, [])

  return (
    <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_UPSELL_ELIGIBILITY}>
      <FullScreenSpinnerLoading />
    </BasePageLayout>
  )
}
