import { useEffect } from 'react'
import * as cookie from 'cookie'

import { useLogger } from '../logger'

const MARKETING_DATA_KEY_CITY = 'city'
const MARKETING_DATA_KEY_REGION = 'region'

const CLOUDFRONT_VIEWER_HEADER_CITY = 'cloudfront-viewer-city'
const CLOUDFRONT_VIEWER_HEADER_REGION = 'cloudfront-viewer-country-region-name'

const MARKETING_DATA_KEYS_OF_INTEREST = new Set([
  // Click Identifiers
  'gclid', // Google
  'fbclid', // Facebook
  'ttclid', // TikTok
  'li_fat_id', // LinkedIn
  // UTM Campaign Data follows...
  'utm_campaign',
  'utm_content',
  'utm_id',
  'utm_medium',
  'utm_source',
  'utm_term',
  // Geolocation Information
  MARKETING_DATA_KEY_CITY,
  MARKETING_DATA_KEY_REGION,
])

const LOCALSTORAGE_MARKETING_DATA_KEY = 'marketing_campaign_data'

const retrieveStorageMarketingData = (storage: Storage) => {
  const storedValue = storage.getItem(LOCALSTORAGE_MARKETING_DATA_KEY)
  if (storedValue === null) {
    return {}
  }
  // attempt a JSON.parse on string value read; if parse fails, delete the corrupted key from storage
  try {
    return JSON.parse(storedValue)
  } catch (error) {
    storage.removeItem(LOCALSTORAGE_MARKETING_DATA_KEY)
    return {}
  }
}

const parseCookieData = (cookieValue: string) => cookie.parse(cookieValue)
const parseQueryStringData = (qs: string) => Object.fromEntries(new URLSearchParams(qs))

const collectedCampaignData: Record<string, string> = {}

export const useMarketingCampaignData = () => {
  const logger = useLogger('useMarketingCampaignData')

  useEffect(() => {
    const qsData = parseQueryStringData(window.location.search)
    const localStoreData = retrieveStorageMarketingData(localStorage)
    const cookieData = parseCookieData(document.cookie)

    for (const dataKey of MARKETING_DATA_KEYS_OF_INTEREST) {
      const resolvedValue =
        // first see if information is available as a query string parameter
        qsData[dataKey] ||
        // if not, then see if we have any information shared as cookies from the marketing website
        cookieData[dataKey] ||
        // finally, fall back to values from localStorage that would've been persisted from a previous visit
        localStoreData[dataKey]

      if (resolvedValue !== undefined) {
        collectedCampaignData[dataKey] = resolvedValue.toString()
      }
    }

    // once we've collected all known Marketing Campaign Data, time to persist it for future visits in Local Storage
    if (Object.keys(collectedCampaignData).length > 0) {
      localStorage.setItem(LOCALSTORAGE_MARKETING_DATA_KEY, JSON.stringify(collectedCampaignData))
    }

    // if geolocation information is incomplete or missing, fetch by making the necessary network calls
    if (
      collectedCampaignData[MARKETING_DATA_KEY_CITY] === undefined ||
      collectedCampaignData[MARKETING_DATA_KEY_REGION] === undefined
    ) {
      fetch('/index.html', { method: 'HEAD' })
        .then((response) => response.headers)
        .then((headers) => {
          if (headers.has(CLOUDFRONT_VIEWER_HEADER_CITY)) {
            collectedCampaignData[MARKETING_DATA_KEY_CITY] = headers.get(CLOUDFRONT_VIEWER_HEADER_CITY) || ''
          }
          if (headers.has(CLOUDFRONT_VIEWER_HEADER_REGION)) {
            collectedCampaignData[MARKETING_DATA_KEY_REGION] = headers.get(CLOUDFRONT_VIEWER_HEADER_REGION) || ''
          }
        })
        .catch((error) => {
          logger.error('fetch-HEAD-index', error)
        })
    }
  }, [])
}

export const getAllMarketingCampaignData = () =>
  Object.entries(collectedCampaignData).reduce(
    (accumulator, [key, value]) => ({
      ...accumulator,
      [key]: value,
    }),
    {}
  )
