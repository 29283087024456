import React from 'react'
import { Analytics } from '@genoa/analytics'

import FlexAnywhereHowYouPayContainer from '../../common/property-linking/FlexAnywhereHowYouPayContainer'

type OnboardingFlexAnywhereHowYouPayContainerProps = {}

export const OnboardingFlexAnywhereHowYouPayContainer = (props: OnboardingFlexAnywhereHowYouPayContainerProps) => {
  return (
    <FlexAnywhereHowYouPayContainer {...props} analyticsScreenName={Analytics.Screens.ONBOARDING_OON_HOW_YOU_PAY} />
  )
}
