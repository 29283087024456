import { GetUserAccountResponse, useGetUserAccount } from '@genoa/middle-end'
import {
  ResetLoadedPayload,
  resetUserAccountAction,
  setUserAccountAction,
  setUserAccountLoadedAction,
} from '@genoa/state-management'
import { AxiosPromise } from 'axios'

import { useLogger } from '../../../providers'
import { useReduxAction } from '../../use-redux-action'

export const useHandleGetUserAccount = () => {
  const loggerV2 = useLogger('useHandleGetUserAccount')
  const [, getUserAccount] = useGetUserAccount()
  const setUserAccountState = useReduxAction(setUserAccountAction)
  const reset = useReduxAction<ResetLoadedPayload | void>(resetUserAccountAction as any)
  const setLoaded = useReduxAction(setUserAccountLoadedAction)

  const handleGetUserAccount = (customerPublicId: string): AxiosPromise<GetUserAccountResponse> => {
    if (!customerPublicId) {
      loggerV2.error('handleGetUserAccount', 'handleGetUserAccount called without a customerPublicId')
      return Promise.resolve({}) as AxiosPromise<GetUserAccountResponse>
    }
    return getUserAccount({ customerPublicId })
      .then((response) => {
        if (response.status === 200 && response.data) {
          setUserAccountState({ user_account: response.data.user_account })
        } else if (response.status !== 404) {
          loggerV2.error('handleGetUserAccount', 'unexpected status', { status: response.status })
          reset({ loaded: true })
        } else {
          reset({ loaded: true })
        }
        return response
      })
      .catch((error: any) => {
        setLoaded(true)
        loggerV2.error('handleGetUserAccount', `error: ${error?.message}`)
        return error
      })
  }

  return {
    handleGetUserAccount,
  }
}
