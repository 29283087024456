import { ActionLogType, ReimbursementMethod } from '@genoa/domain'
import { CREDIT_BUILDER_HOW_TO_PAY_FLEX_BACK_CONTENT as content } from '@genoa/screen-content'
import { toFormattedDollars } from '@genoa/utils'
import { Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import {
  ExtraSmallText,
  Gap,
  Headline2,
  PaymentSchedule,
  Pill,
  PrimaryButton,
  SelectableCard,
  SmallText,
} from '../../../../components'

export interface CreditBuilderHowToPayFlexBackProps {
  readonly paymentAmount: string
  readonly confirmDisabled: boolean
  readonly selectedOption: ReimbursementMethod | undefined
  readonly onSelectOption: (option: ReimbursementMethod) => void
  readonly onConfirm: () => void
  readonly loadingSubmit: boolean
}

export const CreditBuilderHowToPayFlexBack = ({
  paymentAmount,
  selectedOption,
  confirmDisabled,
  onSelectOption,
  onConfirm,
  loadingSubmit,
}: CreditBuilderHowToPayFlexBackProps) => {
  const theme = useTheme()

  const payment = {
    label: content.PAYMENT_LABEL,
    amount: paymentAmount,
  }

  return (
    <Container>
      <Stack>
        <Headline2>{content.HEADER}</Headline2>
        <SmallText>{content.SUB_HEADER}</SmallText>
      </Stack>
      <Stack>
        <PaymentSchedule payments={[payment]}>
          <SelectableCard
            isSelected={selectedOption === ActionLogType.ENABLE_SIMPLIFIED_PAYMENTS}
            onClick={() => onSelectOption(ActionLogType.ENABLE_SIMPLIFIED_PAYMENTS)}
            title={content.DEPOSIT.TITLE}
          >
            <RepaymentContent>
              <Pill label="Recommended" />
              <ExtraSmallText color={theme.colors.dusk}>{content.DEPOSIT.CONTENT}</ExtraSmallText>
            </RepaymentContent>
          </SelectableCard>
          <SelectableCard
            isSelected={selectedOption === ActionLogType.DISABLE_SIMPLIFIED_PAYMENTS}
            onClick={() => onSelectOption(ActionLogType.DISABLE_SIMPLIFIED_PAYMENTS)}
            title={content.CARD.TITLE}
          >
            {content.CARD.CONTENT}
          </SelectableCard>
        </PaymentSchedule>
      </Stack>
      <Stack>
        <Gap size="spacing_100" />
        <PrimaryButton disabled={confirmDisabled || loadingSubmit} processing={loadingSubmit} onClick={onConfirm}>
          {content.CTA}
        </PrimaryButton>
      </Stack>
    </Container>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: ${(props) => props.theme.fixedSizes.spacing_200};
`

const Stack = styled(Container)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_100};
`

const RepaymentContent = styled(Container)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_50};
`
