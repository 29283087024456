import React, { useState } from 'react'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from '../../../../providers'
import FlexAnywhereWaitlist from './FlexAnywhereWaitlist'

type FlexAnywhereWaitlistContainerProps = {
  analyticsScreenName: Analytics.Screens
}

const FlexAnywhereWaitlistContainer = (props: FlexAnywhereWaitlistContainerProps) => {
  const analytics = useAnalytics()

  const [waitlistPushed, setWaitlistPushed] = useState<boolean>(false)

  const toggleWaitlistPushed = () => {
    setWaitlistPushed(!waitlistPushed)
  }

  const onJoinWaitlist = () => {
    analytics.logEvent(Analytics.Events.OON_WHERE_YOU_PAY_CTA_CLICKED)

    toggleWaitlistPushed()
  }

  const onLearnMore = () => {
    analytics.logEvent(Analytics.Events.OON_WHERE_YOU_PAY_CTA_CLICKED)
  }

  return (
    <FlexAnywhereWaitlist
      onJoinWaitlist={onJoinWaitlist}
      onLearnMore={onLearnMore}
      analyticsScreenName={props.analyticsScreenName}
      waitlistPushed={waitlistPushed}
    />
  )
}

export default FlexAnywhereWaitlistContainer
