import React from 'react'
import { Button, Flex } from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import styled from '@emotion/styled'

type LinkButtonProps = {
  children: React.ReactNode
  disabled?: boolean
  processing?: boolean
  onClick: () => unknown
  testID?: string
  background?: string
  foreground?: string
}

export const LinkButton = (props: LinkButtonProps) => {
  return (
    <Flex w={'100%'} flexDirection={'row'}>
      <ButtonStyle
        data-testid={props.testID}
        variant={'primary'}
        flexGrow={1}
        w={'100%'}
        _hover={{ bg: transparentize(props.background || 'brand.800', 0) }}
        _focusVisible={{
          boxShadow: '0px 0px 0px 2px white',
          outline: '2px solid #1C53BA',
          outlineOffset: '2px',
        }}
        isLoading={props.processing}
        isDisabled={props.disabled}
        onClick={props.onClick}
        testID={props.testID}
        color={props.foreground}
        backgroundColor={props.background}
      >
        {props.children}
      </ButtonStyle>
    </Flex>
  )
}

const ButtonStyle = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.jewelPurple};
`
