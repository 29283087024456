import { createAction } from '@reduxjs/toolkit'

import { ResetLoadedPayload } from '../types'
import { BasePricingOfferState } from './types'

export const resetPricingOfferAction = createAction<ResetLoadedPayload | void>('pricing-offer/reset-pricing-offer')

export const setPricingOfferAction = createAction<BasePricingOfferState>('pricing-offer/set-pricing-offer')

export const setPricingOfferLoadedAction = createAction<boolean>('pricing-offer/set-pricing-offer-loaded')
