import { useEffect, useState } from 'react'
import { NavigationType, useNavigate, useNavigationType } from 'react-router-dom'

import * as Routes from '../constants'
import { OnboardingRoutes } from './OnboardingRoutes'

export function OnboardingRoutesContainer() {
  const navigate = useNavigate()
  const [mounted, setMounted] = useState(false)
  const navType = useNavigationType()

  useEffect(() => {
    if (mounted && navType === NavigationType.Pop) {
      navigate(Routes.App.ONBOARDING, { replace: true })
    }
  }, [navType, mounted])

  // Prevents user from accessing pages directly via url
  useEffect(() => {
    navigate(Routes.App.ONBOARDING, { replace: true })
    setMounted(true)
  }, [])

  if (!mounted || navType === NavigationType.Pop) {
    return null
  }

  return <OnboardingRoutes />
}
