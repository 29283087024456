import React from 'react'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { CardElement } from '@stripe/react-stripe-js'
import { StripeCardElement } from '@stripe/stripe-js'

import { SmallText } from '../../../../../../components'
import { useStripeOptions } from './use-stripe-options'

type CardInputProps = {
  onCardInputReady: (cardElement: StripeCardElement) => void
  onCardChange: (isCompleted: boolean) => void
  cardFormError: string
}

export const CardInput = (props: CardInputProps) => {
  const { cardStyleOptions } = useStripeOptions()
  const theme = useTheme()

  return (
    <CardFieldContainer data-testid="RegisterCardInput">
      <CardElementContainer>
        <CardElement
          onChange={(change) => props.onCardChange(change.complete)}
          onReady={props.onCardInputReady}
          options={cardStyleOptions}
        />
      </CardElementContainer>
      <Box visibility={!!props.cardFormError ? 'visible' : 'hidden'} color={theme.colors.error}>
        <SmallText hasError={!!props.cardFormError}>{props.cardFormError}</SmallText>
      </Box>
    </CardFieldContainer>
  )
}

const CardFieldContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`

const CardElementContainer = styled(Box)`
  padding: 18px 16px;
  background-color: ${(props) => props.theme.colors.wildflower};
  border: 1px solid ${(props) => props.theme.colors.storm};
  border-radius: 8px;

  &:focus-within {
    border-color: red;
  }
`
