import { useTheme } from '@emotion/react'

import { CardIcon } from '../../assets'
import { SecondaryButton, SmallButton } from '../Buttons'
import { SmallText } from '../Typography'
import { BaseCardPaymentMethodProps, CardInfoContainer, CardInfoTextBox } from './common'

export interface AddCardPaymentMethodProps extends BaseCardPaymentMethodProps {
  readonly onClick: () => void
}

export const AddCardPaymentMethod = ({ onClick }: AddCardPaymentMethodProps) => {
  const theme = useTheme()

  return (
    <CardInfoContainer>
      <CardIcon />
      <CardInfoTextBox>
        <SmallText color={theme.colors.dusk}>Card info</SmallText>
      </CardInfoTextBox>
      <SmallButton onClick={onClick}>Add Card</SmallButton>
    </CardInfoContainer>
  )
}
