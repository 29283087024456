import { Analytics } from '@genoa/analytics'
import { CREDIT_BUILDER_UPSELL_NOT_APPROVED as content } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'

import { HeroChecklist as NotApprovedImage } from '../../../../assets'
import { Gap, Headline1, InlineButton, Text } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'

interface CreditBuilderUpsellNotApprovedProps {
  onMessageSupport: () => void
}

const imageSize = {
  maxHeight: '280px',
  minHeight: '200px',
}

export const CreditBuilderUpsellNotApproved = (props: CreditBuilderUpsellNotApprovedProps) => (
  <BasePageLayout analyticsScreenName={Analytics.Screens.ONBOARDING_NOT_APPROVED}>
    <Flex direction="column" align="center">
      <Box minH="60px" />

      <Flex {...imageSize}>
        <NotApprovedImage />
      </Flex>

      <Headline1 textAlign="center">{content.HEADER}</Headline1>

      <Gap size="spacing_150" />

      <Text textAlign="center" color={'dusk'}>
        {content.BODY}
      </Text>

      <Gap size="spacing_150" />

      <Flex>
        <InlineButton onClick={props.onMessageSupport}>
          <b>{content.CTA}</b>
        </InlineButton>
      </Flex>
    </Flex>
  </BasePageLayout>
)
