import { Analytics } from '@genoa/analytics'
import { ErrorResponseData, useAcceptOffer as useAcceptOfferInternal, useAssignCustomerDda } from '@genoa/middle-end'

import { useAuthState } from '../../../contexts'
import { RootState } from '../../../modules'
import { setOfferAction } from '../../../modules/flex2/offer'
import { useAnalytics, useLogger } from '../../../providers'
import { useAccount } from '../../use-account'
import { useReduxAction } from '../../use-redux-action'
import { useReduxSelector } from '../../use-redux-selector'

export interface UseAcceptOfferOptions {
  readonly onSuccess: () => void
  readonly onRateLimitExceeded: () => void
  readonly onDataError: (content: string) => void
  readonly onGenericError: () => void
}

export const useAcceptOffer = ({
  onSuccess,
  onRateLimitExceeded,
  onDataError,
  onGenericError,
}: UseAcceptOfferOptions) => {
  const logger = useLogger('useAcceptOffer')
  const analytics = useAnalytics()
  const { uid } = useAuthState()
  const { billerConnection } = useAccount()
  const [{ loading: acceptLoading }, acceptOfferInternal] = useAcceptOfferInternal()
  const [{ loading: assignCustomerDdaLoading }, assignCustomerDda] = useAssignCustomerDda()

  const offer = useReduxSelector((state: RootState) => state.offerState)
  const setOfferState = useReduxAction(setOfferAction)

  const assignDda = async () => {
    if (billerConnection?.biller.system === 'portal' && uid) {
      try {
        const assignCustomerDdaResponse = await assignCustomerDda({ customerId: uid })
        if (assignCustomerDdaResponse.status !== 201) {
          logger.error(
            'assignCustomerDda',
            `info: ${JSON.stringify({
              status: assignCustomerDdaResponse.status,
              data: assignCustomerDdaResponse.data,
            })}`
          )
        }
      } catch (error: any) {
        logger.error('assignCustomerDda - catch', `info: ${error?.message}}`)
      }
    }
  }

  const acceptOffer = async () => {
    if (uid && offer.initialized && offer.offer && billerConnection?.biller_account_public_id) {
      const requestData = {
        offerId: offer.offer.offer_id,
        customerPublicId: uid,
      }

      analytics.logEvent(Analytics.Events.ACCEPT_OFFER_REQUEST)
      const acceptOfferResponse = await acceptOfferInternal(requestData)

      if (acceptOfferResponse.status >= 200 && acceptOfferResponse.status < 300) {
        setOfferState({ initialized: true, offer: acceptOfferResponse.data })
        await assignDda()
        onSuccess()
      } else {
        analytics.logEvent(Analytics.Events.ACCEPT_OFFER_FAIL)

        const { status, data } = acceptOfferResponse

        if (status === 429) {
          logger.error('Rate limit exceeded')
          onRateLimitExceeded()
        } else if (status === 422) {
          const errorResponse = data as unknown as ErrorResponseData
          logger.error('Error', errorResponse?.reason)
          onDataError(errorResponse?.message)
        } else {
          logger.error('Generic Error', `status: ${status}`)
          onGenericError()
        }
      }
    }
  }

  return {
    acceptOffer,
    loading: acceptLoading || assignCustomerDdaLoading,
  }
}
