import { UserType } from '@genoa/domain'
import { AccountStatus, BillerType, GetStatusResponse } from '@genoa/middle-end'

export const useResolveCustomerType = () => {
  const resolveCustomerType = (customerStatus: GetStatusResponse) => {
    const flexAnywhereBillerTypes = [BillerType.FLEX_ANYWHERE, BillerType.OUT_OF_NETWORK]
    if (customerStatus.account_status !== AccountStatus.ACTIVE) {
      return UserType.INACTIVE
    } else {
      return flexAnywhereBillerTypes.includes(customerStatus.biller_type) ? UserType.FLEX_ANYWHERE : UserType.IN_NETWORK
    }
  }

  return {
    resolveCustomerType,
  }
}
