import { FC, PropsWithChildren } from 'react'
import { Navigate, Route, Routes as RouterRoutes } from 'react-router-dom'

import { useIsEmbed } from '../hooks'
import {
  EmbedAlreadyFlexingYourRent,
  EmbedBuildCreditInApp,
  EmbedCreditLineDeclined,
  EmbedCreditLineSuspended,
  EmbedNotRentDay,
  EmbedPropertyMismatch,
  EmbedRentSplitProgress,
  EmbedServiceIssue,
  EmbedSomethingWentWrong,
  EmbedStartPayment,
} from '../views/pages/common/embed'
import { EmbedSignup, VerifyEmbedUser } from '../views/pages/common/embed/manual-pay/landing'
import * as Routes from './constants'
import { getRouteSlugs } from './utils'

const routeSlugs = getRouteSlugs(Routes.App.EMBED_LANDING, Routes.EmbedLanding)

export const EmbedLandingRoutes: FC<PropsWithChildren> = () => {
  const isEmbed = useIsEmbed()

  if (!isEmbed) {
    return <Navigate to={{ pathname: '/' }} replace />
  }

  return (
    <RouterRoutes>
      <Route path={routeSlugs.VERIFY} Component={VerifyEmbedUser} />
      <Route path={routeSlugs.SIGNUP} Component={EmbedSignup} />

      <Route path={routeSlugs.ALREADY_FLEXING_YOUR_RENT} element={<EmbedAlreadyFlexingYourRent />} />
      <Route path={routeSlugs.BUILD_CREDIT_IN_APP} element={<EmbedBuildCreditInApp />} />
      <Route path={routeSlugs.NOT_RENT_DAY} element={<EmbedNotRentDay />} />
      <Route path={routeSlugs.RENT_SPLIT_PROGRESS} element={<EmbedRentSplitProgress />} />
      <Route path={routeSlugs.SERVICE_ISSUE} element={<EmbedServiceIssue />} />
      <Route path={routeSlugs.SOMETHING_WENT_WRONG} element={<EmbedSomethingWentWrong />} />
      <Route path={routeSlugs.PROPERTY_MISMATCH} element={<EmbedPropertyMismatch />} />
      <Route path={routeSlugs.CREDIT_LINE_DECLINED} element={<EmbedCreditLineDeclined />} />
      <Route path={routeSlugs.CREDIT_LINE_SUSPENDED} element={<EmbedCreditLineSuspended />} />
      <Route path={routeSlugs.START_PAYMENT} element={<EmbedStartPayment />} />
    </RouterRoutes>
  )
}
