import { useState } from 'react'
import { Analytics } from '@genoa/analytics'

/**
 * Enhanced Tracking controls if a custom Amplitude event is forwarded to Google Tag Manager with the Amplitude Google
 * Tag Manager Destination Plugin
 */

const { Events: E, Screens: S } = Analytics
const ALL_ENHANCED_TRACKING_EVENTS: Set<Analytics.Events | Analytics.Screens> = new Set([
  S.SIGNIN_PHONE_NUMBER,
  E.PHONE_VERIFIED,
  E.ACCOUNT_CREATED,
  E.BILL_CONNECTED,
  E.UNDERWRITING_REQUESTED,
  E.CUSTOMER_ACCEPTED_LOAN,
  E.PAYMENT_CONNECTED,
  E.SIGNUP_COMPLETED,
])

export const allowsEnhancedTracking = (evt: any) => ALL_ENHANCED_TRACKING_EVENTS.has(evt)

export type EnhancedTrackingState = {
  isEnabled: () => boolean
  addAndGetAllSharedEventProperties: (properties: Record<string, string>) => Record<string, string>
  getAllSharedEventProperties: () => Record<string, string>
}

export const useSharedEventProperties = () => {
  const [properties, setProperties] = useState<Record<string, string>>({})
  const addProperties = (toAdd: Record<string, string>) => {
    const newProperties = { ...properties, ...toAdd }
    setProperties(newProperties)
    return newProperties
  }

  return {
    properties,
    addProperties,
  }
}
