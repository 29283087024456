import { useCallback, useRef } from 'react'
import { ActionLogType, ProductOption } from '@genoa/domain'
import { deepReplaceContent, HOW_YOU_USE_FLEX_CONTENT } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import {
  ExtraSmallText,
  Gap,
  Headline2,
  LinkText,
  PrimaryButton,
  SelectableCard,
  SmallText,
  Text,
} from '../../../../components'

export interface HowYouUseFlexProps {
  readonly selectedOption: ProductOption | undefined
  readonly loading: boolean
  readonly onSelectOption: (option: ProductOption) => void
  readonly onConfirm: () => void
  readonly onLearnMore: () => void
  readonly creditBuilderFee: string
  readonly flexibleRentFee: string
  readonly estimatedRent: string
}

export const HowYouUseFlex = ({
  selectedOption,
  loading,
  onSelectOption,
  onConfirm,
  onLearnMore,
  creditBuilderFee,
  flexibleRentFee,
  estimatedRent,
}: HowYouUseFlexProps) => {
  const theme = useTheme()

  const confirmRef = useRef<HTMLDivElement>(null)
  const onSelect = useCallback(
    (option: ProductOption) => {
      onSelectOption(option)

      if (confirmRef.current && option) {
        confirmRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
      }
    },
    [confirmRef, onSelectOption]
  )

  const content = deepReplaceContent(HOW_YOU_USE_FLEX_CONTENT, { creditBuilderFee, flexibleRentFee, estimatedRent })
  return (
    <Container>
      <Stack>
        <Headline2>{content.HEADER}</Headline2>
        <SmallText>{content.SUB_HEADER}</SmallText>
      </Stack>
      <Stack>
        <SelectableCard
          isSelected={selectedOption === ActionLogType.SELECT_PRODUCT_CREDIT_BUILDER}
          onClick={() => onSelect(ActionLogType.SELECT_PRODUCT_CREDIT_BUILDER)}
          title={<CardTitleText fontWeight="bold">{content.CREDIT_BUILDER_PRODUCT.TITLE}</CardTitleText>}
        >
          <CardStack>
            <SmallText fontWeight="bold" color={theme.colors.forwardBlue}>
              {content.CREDIT_BUILDER_PRODUCT.SUB_TITLE}
            </SmallText>
            <ContentStack>
              <Gap size="spacing_50" />
              <ExtraSmallText color={theme.colors.dusk}>{content.CREDIT_BUILDER_PRODUCT.CONTENT}</ExtraSmallText>
            </ContentStack>
          </CardStack>
        </SelectableCard>
        <SelectableCard
          isSelected={selectedOption === ActionLogType.SELECT_PRODUCT_SPLIT_RENT}
          onClick={() => onSelect(ActionLogType.SELECT_PRODUCT_SPLIT_RENT)}
          title={<CardTitleText fontWeight="bold">{content.SPLIT_RENT_PRODUCT.TITLE}</CardTitleText>}
        >
          <CardStack>
            <SmallText fontWeight="bold" color={theme.colors.forwardBlue}>
              {content.SPLIT_RENT_PRODUCT.SUB_TITLE}
            </SmallText>
            <ContentStack>
              <Gap size="spacing_50" />
              <ExtraSmallText color={theme.colors.dusk}>{content.SPLIT_RENT_PRODUCT.CONTENT}</ExtraSmallText>
            </ContentStack>
          </CardStack>
        </SelectableCard>
        <ExtraSmallText color={theme.colors.dusk}>
          {content.CREDIT_BUILDER_DISCLAIMER}{' '}
          <LinkText fontWeight="bold" onClick={onLearnMore}>
            {content.CREDIT_BUILDER_LINK}
          </LinkText>
        </ExtraSmallText>
      </Stack>
      <Stack>
        <Gap size="spacing_100" />
        <PrimaryButton disabled={loading || selectedOption === undefined} onClick={onConfirm}>
          {content.CTA}
        </PrimaryButton>
        <Box ref={confirmRef} />
      </Stack>
    </Container>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: ${(props) => props.theme.fixedSizes.spacing_200};
`

const Stack = styled(Container)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_100};
`

const CardStack = styled(Container)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_50};
`

const ContentStack = styled(Container)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_0};
`

const CardTitleText = styled(Text)`
  font-size: 18px;
`
