import React from 'react'
import { Button } from '@chakra-ui/react'

type SecondaryButtonProps = {
  children: React.ReactNode
  disabled?: boolean
  onClick: () => unknown
  testID?: string
  foreground?: string
  variant?: string
}

export const SecondaryButton = (props: SecondaryButtonProps) => {
  return (
    <Button
      variant={props.variant ? props.variant : 'secondary'}
      data-testid={props.testID}
      onClick={props.onClick}
      isDisabled={props.disabled}
      color={props.foreground}
      _focusVisible={{
        boxShadow: '0px 0px 0px 2px white',
        outline: '2px solid #1C53BA',
        outlineOffset: '2px',
      }}
    >
      {props.children}
    </Button>
  )
}
