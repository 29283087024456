import { useNavigate } from 'react-router-dom'

import * as Routes from '../../../../routing/constants'
import { CreditBuilderUpsellContainer } from '../../common/credit-builder/upsell'

export const OnboardingCreditBuilderUpsellContainer = () => {
  const navigate = useNavigate()

  return (
    <CreditBuilderUpsellContainer
      onNext={() => navigate(Routes.Onboarding.CUSTOMIZE_SCHEDULE)}
      onNoThanks={() => navigate(Routes.Onboarding.CREDIT_BUILDER_UPSELL_NOT_APPROVED)}
    />
  )
}
