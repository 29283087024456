import React from 'react'
import { deepReplaceContent, SLC_APPROVED_MODAL_CONTENT } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { RocketShip } from '../../../assets'
import { Headline2, PrimaryButton, SmallText } from '../../../components'
import { Gap } from '../../../components/Gap'
import { Modal, ModalActions } from '../../../components/Modal'

type ApprovedModalSLCProps = {
  onClickContinue: () => void
  visible: boolean
  showModifyUtilizationText: boolean
  rentAmountFormatted: string
}

export const ApprovedModalSLC = ({ onClickContinue, visible, rentAmountFormatted }: ApprovedModalSLCProps) => {
  const content = deepReplaceContent(SLC_APPROVED_MODAL_CONTENT, { rentAmount: rentAmountFormatted })

  return (
    <Modal testID="ApprovedModalSLC" title="" canClose={false} onClose={() => {}} visible={visible}>
      <TitleContents>
        <RocketShip />
        <Headline2>{content.HEADER}</Headline2>
        <SmallText data-testid="ApprovedModalSubtitleValue">{content.BODY}</SmallText>
      </TitleContents>
      <Gap size="spacing_100" />
      <ModalActions>
        <PrimaryButton onClick={onClickContinue} testID="ApprovedModalContinueButton">
          {content.CTA}
        </PrimaryButton>
      </ModalActions>
    </Modal>
  )
}

const TitleContents = styled(Flex)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${(props) => props.theme.fixedSizes.spacing_100};
`
