import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { CardIcon } from '../../assets'
import { SmallText } from '../Typography'
import { BaseCardPaymentMethodProps, CardDetails, CardInfoContainer, CardInfoTextBox } from './common'

export interface ChangeCardPaymentMethodProps extends BaseCardPaymentMethodProps {
  readonly cardDetails: CardDetails
  readonly hideChange?: boolean
}

export const ChangeCardPaymentMethod = (props: ChangeCardPaymentMethodProps) => {
  const theme = useTheme()
  const cardInfo = `${props.cardDetails.cardNetwork} ${props.cardDetails.cardType} x${props.cardDetails.cardLastFourDigits}`

  return (
    <CardInfoContainer>
      <CardIcon />
      <CardInfoTextBox>
        <SmallText>{cardInfo}</SmallText>
      </CardInfoTextBox>
      {!props.hideChange && (
        <Box mr={theme.fixedSizes.spacing_75}>
          <StyledTextButton fontWeight={theme.fontWeights.bold} onClick={props.onClick}>
            Change
          </StyledTextButton>
        </Box>
      )}
    </CardInfoContainer>
  )
}

const StyledTextButton = styled(SmallText)`
  cursor: pointer;
  color: ${(props) => props.theme.colors.jewelPurple};
  transition: color 0.2s ease;

  :hover {
    color: ${(props) => props.theme.colors.flavender};
  }
`
