import { useEffect, useReducer, useState } from 'react'

import { useAuthState } from '../../contexts'
import { useRetryLock } from '../../hooks'
import { UpdateChannelSubscriptionRequest, updateSubscription } from '../growth'
import { logger } from '../logger'
import { useUserAccount } from '../user-account'

interface ResetAction {
  readonly type: 'reset'
}

interface AddSubscriptionAction {
  readonly type: 'add'
  readonly update: UpdateChannelSubscriptionRequest
}

interface UpdateAction {
  readonly type: 'update'
  readonly updates: readonly UpdateChannelSubscriptionRequest[]
}

type DeferredEventsAction = ResetAction | AddSubscriptionAction | UpdateAction

// how to do events one at a time tho?
const deferredEventsReducer = (
  state: readonly UpdateChannelSubscriptionRequest[],
  action: DeferredEventsAction
): readonly UpdateChannelSubscriptionRequest[] => {
  switch (action.type) {
    case 'add':
      return state.concat([action.update])
    case 'update':
      return action.updates
    case 'reset':
    default:
      return []
  }
}

export interface UseDeferredSubscriptionsOptions {
  readonly locked: boolean
}

export const useDeferredSubscriptions = ({ locked }: UseDeferredSubscriptionsOptions) => {
  const [inflightUpdate, setInflightUpdate] = useState<UpdateChannelSubscriptionRequest | undefined>(undefined)
  const [updates, dispatch] = useReducer(deferredEventsReducer, [])
  const { lock: retryLock, incrementFailures, resetFailures } = useRetryLock()
  const { token } = useAuthState()
  const { userAccount } = useUserAccount()

  const reset = () => {
    dispatch({ type: 'reset' })
  }

  useEffect(() => {
    if (!locked && !inflightUpdate && updates.length > 0) {
      const [update, ...otherUpdates] = updates
      setInflightUpdate(update)
      dispatch({ type: 'update', updates: otherUpdates })
    }
  }, [locked, retryLock, updates, inflightUpdate])

  useEffect(() => {
    if (!locked && !retryLock && inflightUpdate && token && userAccount.email) {
      updateSubscription(token, userAccount.email, inflightUpdate)
        .then(({ status }) => {
          if (status === 200) {
            logger.log(
              'use-deferred-events',
              `set ${inflightUpdate.channel} subscription to ${inflightUpdate.enabled} successfully`
            )
            setInflightUpdate(undefined)
            resetFailures()
          }
        })
        .catch(() => {
          incrementFailures()
        })
    }
  }, [locked, inflightUpdate, retryLock, token, userAccount.email])

  return {
    addUpdate: (update: UpdateChannelSubscriptionRequest) => dispatch({ type: 'add', update }),
    reset,
  }
}
