import { useCallback } from 'react'
import { AccountStates, AccountStatus, GetStatusResponse } from '@genoa/middle-end'

// Remove this hook once we migrate all orchestration calls to V2
export const useAccountStates = () => {
  const resolveCustomerStatusAccountState = useCallback(
    (customerStatus: GetStatusResponse): AccountStates | undefined => {
      switch (customerStatus.account_status) {
        case AccountStatus.ACTIVE:
          return AccountStates.ACTIVE
        case AccountStatus.PAUSED:
          return AccountStates.PAUSED
        case AccountStatus.CANCELED:
          return AccountStates.CANCELED
        case AccountStatus.SUSPENDED:
          return AccountStates.SUSPENDED
        default:
          return undefined
      }
    },
    []
  )

  const isPaused = useCallback((accountState: AccountStates | undefined) => {
    return accountState ? accountState === AccountStates.PAUSED : false
  }, [])

  const isCanceledOrSuspended = useCallback((accountState: AccountStates | undefined) => {
    return accountState ? [AccountStates.CANCELED, AccountStates.SUSPENDED].includes(accountState) : false
  }, [])

  const isInNonActiveState = useCallback((accountState: AccountStates | undefined): boolean => {
    return accountState
      ? [AccountStates.PAUSED, AccountStates.CANCELED, AccountStates.SUSPENDED].includes(accountState)
      : false
  }, [])

  return {
    resolveCustomerStatusAccountState,
    isInNonActiveState,
    isPaused,
    isCanceledOrSuspended,
  }
}
