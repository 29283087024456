import { PaymentCategory, SLCType } from '@genoa/domain'
import { PaymentSchedule } from '@genoa/middle-end'
import { ordinalSuffixFor } from '@genoa/utils'

export interface PaymentLabels {
  readonly paymentLabel: string
  readonly ordinalPaymentLabel: string
}

export const getPaymentLabelsFactory =
  (isSimplifiedPaymentsEnabled: boolean, isFirstPaymentDeposit: boolean = false, slcType?: SLCType) =>
  ({ payment_category }: Pick<PaymentSchedule, 'payment_category'>, secondPaymentIndex: number): PaymentLabels => {
    switch (payment_category) {
      case PaymentCategory.FIRST_PAYMENT:
        if (slcType === SLCType.CREDIT_BUILDER) {
          return { paymentLabel: 'Payment', ordinalPaymentLabel: 'payment' }
        }
        if (isFirstPaymentDeposit) {
          return { paymentLabel: 'Deposit', ordinalPaymentLabel: 'Deposit' }
        }

        return { paymentLabel: '1st payment', ordinalPaymentLabel: '1st rent payment' }
      case PaymentCategory.SECOND_PAYMENT:
        if (isSimplifiedPaymentsEnabled) {
          return { paymentLabel: 'Payment', ordinalPaymentLabel: 'payment' }
        }

        const buffer = isFirstPaymentDeposit ? 0 : 1
        return {
          paymentLabel: `${ordinalSuffixFor(secondPaymentIndex + buffer + 1)} payment`,
          ordinalPaymentLabel: `${ordinalSuffixFor(secondPaymentIndex + buffer + 1)} rent payment`,
        }
      case PaymentCategory.MEMBERSHIP_FEE:
        return { paymentLabel: 'Membership fee', ordinalPaymentLabel: 'Membership fee' }
      default:
        return { paymentLabel: '', ordinalPaymentLabel: '' }
    }
  }
