import { useCallback, useRef, useState } from 'react'

export const useGeometricInterval = (callback: (interval: number, elapsed: number) => unknown, intervalMs: number) => {
  const [timing, setTiming] = useState(intervalMs)
  const timeoutRef = useRef<NodeJS.Timeout>()
  const elapsed = useRef<number>(0)

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
  }, [timeoutRef])

  const start = useCallback(() => {
    clear()
    timeout(intervalMs)
  }, [intervalMs, clear])

  const stop = useCallback(() => {
    clear()
    setTiming(intervalMs)
  }, [intervalMs, clear])

  const timeout = useCallback(
    (ms: number) => {
      clear()

      const ref = setTimeout(() => {
        const newElapsed = elapsed.current + ms
        elapsed.current = newElapsed
        callback(ms, newElapsed)
        timeout(ms * 2)
      }, ms)

      //@ts-ignore
      timeoutRef.current = ref
    },
    [timing, clear, timeoutRef, callback, elapsed]
  )

  return { start, stop }
}
