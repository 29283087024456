import { createContext, ReactNode, useContext, useMemo } from 'react'
import { AmplitudeFeatureFlag } from '@genoa/experiments'

import { useAmplitudeFeatureFlag } from '../../hooks/use-amplitude-feature-flag'

/**
 * When using the `ProductTypeContext` value, the app currently only
 * contains logic around whether the product type is `MOVE_IN` or not.
 * However, this enum is used in other places within the app and will be
 * consolidated in https://getflex.atlassian.net/browse/APP-3089.
 */
export enum ProductType {
  RENT_SPLIT = 'rent-split',
  CREDIT_BUILDER = 'credit-builder',
  MOVE_IN = 'move-in',
}

export const QS_KEY = 'product'
export const LS_KEY = 'flexapp/product-type'

/**
 * Default to RENT_SPLIT as all logic in the app
 * is expected to treat all product types returned from this hook the same
 * except MOVE_IN.
 */
const defaultValue = ProductType.RENT_SPLIT

const ProductTypeContext = createContext<ProductType>(defaultValue)

const getProductTypeFromString = (value: string | null): ProductType | undefined => {
  const key = Object.keys(ProductType).find((key) => ProductType[key as keyof typeof ProductType] == value)
  return ProductType[key as keyof typeof ProductType]
}

export const ProductTypeProvider = ({ children }: { children: ReactNode }) => {
  const { enabled: moveInPilotEnabled } = useAmplitudeFeatureFlag(AmplitudeFeatureFlag.MoveInPilot)

  const qsProductType = useMemo(() => {
    const query = new URLSearchParams(window.location.search)
    const productType = getProductTypeFromString(query.get(QS_KEY))
    if (productType) {
      localStorage.setItem(LS_KEY, productType)
    }
    return productType
  }, [])

  const productType = useMemo(() => {
    if (!moveInPilotEnabled) {
      return defaultValue
    }
    const storedProductType = getProductTypeFromString(localStorage.getItem(LS_KEY))
    return qsProductType || storedProductType || defaultValue
  }, [moveInPilotEnabled, qsProductType])

  return <ProductTypeContext.Provider value={productType}>{children}</ProductTypeContext.Provider>
}

export const useProductType = () => useContext(ProductTypeContext)
