import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'

import * as Routes from '../../../../routing/constants'
import { CreditBuilderCustomizeScheduleContainer } from '../../common/credit-builder/customize-your-schedule'

export const OnboardingCreditBuilderCustomizeScheduleContainer = () => {
  const navigate = useNavigate()

  return (
    <CreditBuilderCustomizeScheduleContainer
      analyticsScreenName={Analytics.Screens.CUSTOMIZE_YOUR_SCHEDULE}
      onNext={() => navigate(Routes.Onboarding.OFFER_DETAILS)}
    />
  )
}
