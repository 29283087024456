import React, { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'

import { useAnalytics, useFlexLinks } from '../../../../providers'
import { ConsolidationDisclaimer } from './ConsolidationDisclaimer'

export const ConsolidationDisclaimerContainer = () => {
  const analytics = useAnalytics()
  const flexLinks = useFlexLinks()

  const handleClickTermsOfService = useCallback(() => {
    analytics.logEvent(Analytics.Events.SIGNUP_TERMS_OF_SERVICE_CLICKED, { screen: 'PhoneConsolidation' })
    flexLinks.open(FlexLinks.termsOfService)
  }, [flexLinks.open])

  const handleClickPrivacyNotice = useCallback(() => {
    analytics.logEvent(Analytics.Events.SIGNUP_PRIVACY_NOTICE_CLICKED, { screen: 'PhoneConsolidation' })
    flexLinks.open(FlexLinks.privacyNotice)
  }, [flexLinks.open])

  const handleClickPrivacyPolicy = useCallback(() => {
    analytics.logEvent(Analytics.Events.SIGNUP_PRIVACY_POLICY_CLICKED, { screen: 'PhoneConsolidation' })
    flexLinks.open(FlexLinks.privacyPolicy)
  }, [flexLinks.open])

  const handleClickESignConsent = useCallback(() => {
    analytics.logEvent(Analytics.Events.SIGNUP_E_SIGN_CONSENT_CLICKED, { screen: 'PhoneConsolidation' })
    flexLinks.open(FlexLinks.esignConsent)
  }, [flexLinks.open])

  return (
    <ConsolidationDisclaimer
      onClickTermsOfService={handleClickTermsOfService}
      onClickPrivacyPolicy={handleClickPrivacyPolicy}
      onClickPrivacyNotice={handleClickPrivacyNotice}
      onClickESignConsent={handleClickESignConsent}
    />
  )
}
