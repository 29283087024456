import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

export interface CardDetails {
  readonly cardLastFourDigits: string
  readonly cardType: string
  readonly cardNetwork: string
}

export interface BaseCardPaymentMethodProps {
  readonly onClick: () => void
}

export const CardInfoContainer = styled(Flex)`
  width: 100%;
  align-items: center;
  flex-direction: row;
  align-self: stretch;

  padding: ${(props) => `${props.theme.fixedSizes.spacing_75} ${props.theme.fixedSizes.spacing_50}`};
  gap: ${(props) => props.theme.fixedSizes.spacing_75};

  border-radius: ${(props) => props.theme.fixedSizes.spacing_50};
  background: ${(props) => props.theme.colors.wildflower};
`

export const CardInfoTextBox = styled(Flex)`
  flex: 1 0 0;
`
