import { Analytics } from '@genoa/analytics'

import { allowsEnhancedTracking } from '../amplitude'

export const retainHashedEmail = (originalData?: any, cleanedData?: any) => {
  if (originalData === undefined || !originalData.hasOwnProperty('email')) {
    return cleanedData
  }
  // original data has the email key; check to see if the value is hashed or not
  const originalEmail = originalData.email.toString()
  if (originalEmail.indexOf('@') === -1) {
    // a hashed email address would not contain the @ symbol, we can safely add the hashed email back and return
    return {
      ...cleanedData,
      email: originalEmail,
    }
  }
  // we don't want to retain plaintext email addresses, as detected by the presence of the @ symbol
  // return the cleaned data as that would have already stripped away the email key! (clean-log-data.ts)
  return cleanedData
}

const RE_ADD_ENHANCED_TRACKING_PROPERTIES = ['city']

export const retainEnhancedTrackingProperties = (event: Analytics.Events, originalData?: any, cleanedData?: any) => {
  if (originalData === undefined || cleanedData === undefined || !allowsEnhancedTracking(event)) {
    return cleanedData
  }
  // event allows enhanced tracking, add certain properties back from originalData if sanitized away in cleanedData
  let enhancedCleanData = { ...cleanedData }
  for (const property of RE_ADD_ENHANCED_TRACKING_PROPERTIES) {
    if (originalData.hasOwnProperty(property) && !enhancedCleanData.hasOwnProperty(property)) {
      enhancedCleanData[property] = originalData[property]
    }
  }
  // return the cleaned data that was enhanced back with some additional event properties
  return enhancedCleanData
}
