import { useCallback, useEffect } from 'react'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from './use-analytics'

export function useScreenView(screen: Analytics.Screens) {
  const analytics = useAnalytics()

  useEffect(
    useCallback(() => {
      analytics.logScreenView(screen)
    }, [])
  )
}
