import React, { useMemo } from 'react'
import { ordinalSuffixFor } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useContent } from '../../../../../providers/content'
import { WhenDesktop } from '../../../../../theme/media'
import { ChevronLeftWhite, ChevronRightWhite } from '../../../../assets'
import { LineDivider, SmallText } from '../../../../components'
import { PaymentListItem } from './PaymentListItem'

export const LIST_WRAPPER_ID = 'ListWrapper'

export type PaymentDay = {
  day: number
  ordinal: string
}

export type PaymentListBaseProps = {
  onSelectPaymentDay: (paymentDay: number) => void
  paymentDays: number[]
  daySelected?: number
  paymentTitle?: string
  forceTopRightText?: boolean
  topRightText?: string
}

type ArrowButtonStyleProps = {
  isDisabled: boolean
}

type ListWrapperTranslateX = {
  translateX: number
}

type PaymentListProps = PaymentListBaseProps &
  ListWrapperTranslateX & {
    isPreviousDaysListButtonDisabled: boolean
    isNextDaysListButtonDisabled: boolean
    onClickRightArrowButton: () => void
    onClickLeftArrowButton: () => void
    mostPopularDay: number
    itemSize: number
  }

export const PaymentList = (props: PaymentListProps) => {
  const {
    content: { CUSTOMIZE_YOUR_SCHEDULE },
  } = useContent()

  const paymentDaysWithSuffix = useMemo(() => {
    if (props.paymentDays.length === 0) {
      return []
    }
    return props.paymentDays.map((day) => {
      const ordinalSuffix = ordinalSuffixFor(day)
      const ordinal = ordinalSuffix.slice(ordinalSuffix.length - 2)
      return {
        day,
        ordinal,
      }
    })
  }, [props.paymentDays])

  return (
    <Flex direction="column">
      <PaymentScheduleBox>
        <HeaderWrapper>
          <Flex direction="row" justifyContent="space-between">
            <SmallText fontWeight="bold">{props.paymentTitle ?? CUSTOMIZE_YOUR_SCHEDULE?.SECOND_PAYMENT}</SmallText>
            <ChooseDateText>
              {props.daySelected && !props.forceTopRightText
                ? `${ordinalSuffixFor(props.daySelected)} ${CUSTOMIZE_YOUR_SCHEDULE?.SECOND_PAYMENT_SELECTED_INFO}`
                : props.topRightText}
            </ChooseDateText>
          </Flex>
          <LineDivider />
        </HeaderWrapper>
        <ListWrapper id={LIST_WRAPPER_ID} translateX={props.translateX}>
          {paymentDaysWithSuffix.map((paymentDayWithSuffix) => (
            <PaymentListItem
              onClick={() => props.onSelectPaymentDay(paymentDayWithSuffix.day)}
              isMostPopularDay={paymentDayWithSuffix.day === props.mostPopularDay}
              isSelected={props.daySelected === paymentDayWithSuffix.day}
              key={`${paymentDayWithSuffix.day}${paymentDayWithSuffix.ordinal}`}
              ordinal={paymentDayWithSuffix.ordinal}
              day={paymentDayWithSuffix.day}
              size={props.itemSize}
            />
          ))}
        </ListWrapper>
      </PaymentScheduleBox>
      <WhenDesktop>
        <ListControlButtons data-testid="PaymentListListControlButtons">
          <ArrowButton
            isDisabled={props.isPreviousDaysListButtonDisabled}
            data-testid="PaymentListLeftChevronButton"
            onClick={props.onClickLeftArrowButton}
            as="button"
          >
            <ChevronLeftWhite />
          </ArrowButton>
          <ArrowButton
            isDisabled={props.isNextDaysListButtonDisabled}
            data-testid="PaymentListRightChevronButton"
            onClick={props.onClickRightArrowButton}
            as="button"
          >
            <ChevronRightWhite />
          </ArrowButton>
        </ListControlButtons>
      </WhenDesktop>
    </Flex>
  )
}

const HeaderWrapper = styled(Box)`
  width: 100%;
  padding-left: ${(props) => props.theme.fixedSizes.md};
  padding-right: ${(props) => props.theme.fixedSizes.md};
`

const ListControlButtons = styled(Flex)`
  width: 600px;
  flex: 1;
  justify-content: space-between;
  align-self: center;
  margin-top: -70px;
`

const ListWrapper = styled(Flex)<ListWrapperTranslateX>`
  ${(props) => props.theme.media.desktop`
    width: 560px;
    transform: translateX(${props.translateX}px);
    transition: transform 330ms ease-in-out;
  `}
  ${(props) => props.theme.media.phone`
    overflow: auto;
    padding-bottom: ${props.theme.fixedSizes.md};
  `}
  ${(props) => props.theme.media.tablet`
    overflow: auto;
    padding-bottom: ${props.theme.fixedSizes.md};
  `}
`

const ArrowButton = styled(Box)<ArrowButtonStyleProps>`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => {
    const brandColorIntesity = props.isDisabled ? '700' : '200'
    return props.theme.colors.brand[brandColorIntesity]
  }};
  border-radius: ${(props) => props.theme.radii.rounded};
  margin-horizontal: -20px;
  margin-top: -20px;
`

const ChooseDateText = styled(SmallText)`
  color: ${(props) => props.theme.colors.dusk};
`

type PaymentScheduleBoxStyleProps = {
  withPaddingHorizontal?: boolean
}

export const PaymentScheduleBox = styled(Flex)<PaymentScheduleBoxStyleProps>`
  width: 100%;
  flex-direction: column;
  padding-top: ${(props) => props.theme.fixedSizes.sm};
  padding-bottom: ${(props) => props.theme.fixedSizes.spacing_12_5};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.radii.xl};
  overflow: hidden;
`
