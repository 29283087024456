import { Agreement } from '@genoa/domain'
import { toDollars } from '@genoa/utils'
import { Box } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { useContent } from '../../../../../providers/content'
import { ExtraSmallText, Headline1, PrimaryButton, SmallText } from '../../../../components'
import { DisclaimerBox } from '../../../../components/Disclaimer'
import { BasePageLayout } from '../../../../layouts'
import { AcceptanceOfferAuthorize } from '../AcceptanceOfferAuthorize'

type AcceptMembershipPoliciesProps = {
  handleNavigation: () => void
  toggleAcceptTerms: () => unknown
  termsAccepted: boolean
  offerFee: number
  agreements: Agreement[]
  loading: boolean
}
export const AcceptMembershipPolicies = (props: AcceptMembershipPoliciesProps) => {
  const theme = useTheme()
  const {
    content: { ACCEPT_MEMBERSHIP_POLICIES, ACCEPTANCE_OFFER_DETAILS },
    replaceContent,
  } = useContent()

  return (
    <BasePageLayout>
      <Box height={theme.fixedSizes.spacing_200} />

      <Headline1>{ACCEPT_MEMBERSHIP_POLICIES.HEADER}</Headline1>

      <Box minH={theme.fixedSizes.spacing_150} />

      <SmallText>{ACCEPT_MEMBERSHIP_POLICIES.BODY}</SmallText>

      <Box height={theme.fixedSizes.spacing_150} />

      {!!props.offerFee && (
        <>
          <DisclaimerBox
            content={
              <ExtraSmallText>
                {replaceContent(ACCEPT_MEMBERSHIP_POLICIES.DISCLAIMER, {
                  membershipFee: `$${toDollars(props.offerFee)}`,
                })}
              </ExtraSmallText>
            }
          />
          <Box height={theme.fixedSizes.spacing_150} />
        </>
      )}

      <AcceptanceOfferAuthorize
        agreeText={ACCEPTANCE_OFFER_DETAILS.AGREE}
        toggleAcceptTerms={props.toggleAcceptTerms}
        termsAccepted={props.termsAccepted}
        fetchedAgreements={props.agreements}
      />

      <Box minH={theme.fixedSizes.spacing_150} />

      <PrimaryButton
        testID="AcceptanceOfferDetailsNext"
        onClick={props.handleNavigation}
        disabled={!props.termsAccepted || props.loading}
        processing={props.loading}
      >
        {ACCEPT_MEMBERSHIP_POLICIES.CTA}
      </PrimaryButton>

      <Box minH={theme.fixedSizes.spacing_100} />
    </BasePageLayout>
  )
}
