import { Frame, PaymentScheduleItem, PaymentScheduleItemProps, PaymentScheduleTitle } from '../../../../components'

export interface SimplifiedPaymentsDisabledScheduleProps {
  readonly depositTitle: string
  readonly depositPayment: PaymentScheduleItemProps

  readonly recurringTitle: string
  readonly recurringPayments: readonly PaymentScheduleItemProps[]
}

export const SimplifiedPaymentsDisabledSchedule = ({
  depositTitle,
  depositPayment,
  recurringTitle,
  recurringPayments,
  children,
}: React.PropsWithChildren<SimplifiedPaymentsDisabledScheduleProps>) => (
  <Frame>
    <PaymentScheduleTitle title={depositTitle} />
    <PaymentScheduleItem {...depositPayment} />
    <PaymentScheduleTitle title={recurringTitle} />

    {recurringPayments.map((payment, index) => (
      <PaymentScheduleItem key={`payment-${index}`} {...payment} />
    ))}
    {children}
  </Frame>
)
