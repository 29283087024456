import { OfferStatus } from '@genoa/state-management'

import { useLazyGetAccountQuery } from '../../../../../../modules/flexApi'

export function useGetAccount() {
  const [getAccountQuery, { data: getAccountData, error: getAccountError, isLoading: isGetAccountLoading }] =
    useLazyGetAccountQuery()
  const data = getAccountData?.data
  const billerConnection = data?.biller_connection

  return {
    billerConnection,
    customer: data?.customer,
    getAccountError,
    isAccountActive: billerConnection?.offer_status === OfferStatus.ACCEPTED,
    getAccountQuery,
    isGetAccountLoading,
  }
}
