import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { getDateTime, getResumeDate, isAfterOnboardingCutoff, toFormattedDollars } from '@genoa/utils'
import { DateTime } from 'luxon'

import { useAgreements } from '../../../../../hooks'
import { useAcceptOffer } from '../../../../../hooks/flex2/risk'
import { usePaymentStatus } from '../../../../../hooks/payments/use-payment-status'
import { usePaymentStatusToSchedule } from '../../../../../hooks/payments/use-payment-status-to-schedule'
import {
  OnboardingStatus,
  setOnboardingStatus,
  useAnalytics,
  useEnhancedTracking,
  useFees,
  useIterable,
} from '../../../../../providers'
import { createSignUpCompletedEvent } from '../../../../../providers/iterable/user-events'
import { App } from '../../../../../routing/constants'
import { FullScreenSpinnerLoading, Gap, PaymentScheduleItemProps } from '../../../../components'
import { BasePageLayout } from '../../../../layouts'
import { CreditBuilderAgreementsModal } from './CreditBuilderAgreementsModal'
import { CreditBuilderConfirmSchedule } from './CreditBuilderConfirmSchedule'
import { useAcceptOfferModal } from './use-accept-offer-modal'
import { useCardDetails } from './use-card-details'

export interface CreditBuilderConfirmScheduleContainerProps {
  readonly analyticsScreenName: Analytics.Screens
  readonly onRegisterCard: () => void
  readonly onNext: () => void
}

export const CreditBuilderConfirmScheduleContainer = (props: CreditBuilderConfirmScheduleContainerProps) => {
  const analytics = useAnalytics()
  const { trackUserSignUpCompleted } = useEnhancedTracking()
  const navigate = useNavigate()
  const iterable = useIterable()
  const cardDetails = useCardDetails({ capitalizeCardNetwork: true })
  const {
    deposit,
    repayments,
    membershipFee,
    refetch: getPaymentStatus,
    isFetching: isFetchingPaymentStatus,
  } = usePaymentStatus()
  const payments = usePaymentStatusToSchedule(deposit, repayments, membershipFee)
  const acceptOfferModal = useAcceptOfferModal()
  const today = getDateTime()
  const resumeDate = getResumeDate(today)

  const [showAgreementsModal, setShowAgreementsModal] = useState(false)
  const [termsAccepted, setTermsAccepted] = useState(false)

  const handleSuccess = () => {
    analytics.logEvent(Analytics.Events.ACCEPT_OFFER_SUCCESS)
    iterable.addEvent(createSignUpCompletedEvent())
    trackUserSignUpCompleted()
    setOnboardingStatus(OnboardingStatus.Active)
    analytics.setUserProperty(
      Analytics.UserProperties.ONBOARDING_COMPLETION_TIMESTAMP,
      new Date().toISOString().slice(0, 19),
      true
    )

    return props.onNext()
  }

  const { loading: loadingAgreements, agreements } = useAgreements({ onError: () => navigate(App.TRY_AGAIN) })

  const { loading, acceptOffer } = useAcceptOffer({
    onSuccess: handleSuccess,
    onRateLimitExceeded: acceptOfferModal.showRateLimitExceededModal,
    onDataError: acceptOfferModal.showDataErrorModal,
    onGenericError: acceptOfferModal.showGenericErrorModal,
  })

  useEffect(() => {
    getPaymentStatus()
  }, [])

  const onContinue = () => {
    if (!cardDetails) {
      return
    }

    setShowAgreementsModal(true)
  }

  if (payments.length === 0 || loadingAgreements || isFetchingPaymentStatus) {
    return <FullScreenSpinnerLoading />
  }

  const dueDate = DateTime.fromISO(deposit?.due_date || '')
  const repaymentDate = dueDate.plus({ days: 1 }).endOf('day')
  const showDisclaimer = !isAfterOnboardingCutoff(today) && today > repaymentDate

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Gap size="spacing_300" />
      <CreditBuilderConfirmSchedule
        payments={payments}
        cardDetails={cardDetails}
        continueDisabled={cardDetails === undefined || !termsAccepted}
        paymentMonth={resumeDate.monthLong!}
        accepted={termsAccepted}
        repaymentDay={repaymentDate.day}
        showDisclaimer={showDisclaimer}
        onAddCard={props.onRegisterCard}
        onChangeCard={props.onRegisterCard}
        onContinue={onContinue}
        onToggleAccept={setTermsAccepted}
      />
      <CreditBuilderAgreementsModal
        visible={showAgreementsModal}
        agreements={agreements}
        loading={loading}
        onClose={() => setShowAgreementsModal(false)}
        onAccept={acceptOffer}
      />
    </BasePageLayout>
  )
}
