import { configureStore } from '@reduxjs/toolkit'
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'

import { Config } from '../providers/config'
import { rootApi } from './flexApi'
import { reducers, RootState } from './reducers'

const persistConfig = {
  key: 'root',
  storage: storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['auth', 'customer', rootApi.reducerPath],
}

const persistedReducer = persistReducer<RootState>(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: Config.ENVIRONMENT !== 'production',
  middleware: (getDefault) =>
    getDefault({
      // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoreState: true,
      },
    }).concat(rootApi.middleware),
})

export const persistor = persistStore(store)
