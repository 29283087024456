import React from 'react'
import { Analytics } from '@genoa/analytics'
import { CREDIT_BUILDER_NOT_YET_SUPPORTED_MODAL as content } from '@genoa/screen-content'
import { Box } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useModal } from '../../../../../contexts'
import { useAnalytics } from '../../../../../providers'
import { Gap, SmallText } from '../../../../components'

export const usePropertyNotSupportedModal = () => {
  const analytics = useAnalytics()
  const modal = useModal()

  const showPropertyNotSupportedModal = (propertyId: string) => {
    analytics.logEvent(Analytics.Events.PROPERTY_NOT_SUPPORTED_MODAL_OPENED, { propertyId })

    modal.show({
      title: content.HEADER,
      cta: content.CTA,
      render: () => (
        <MainContent flex="1" align-items={'flexStart'}>
          <SmallText>{content.LINE_1}</SmallText>
          <Gap size={'spacing_100'} />
          <SmallText>{content.LINE_2}</SmallText>
        </MainContent>
      ),
    })
  }
  return {
    showPropertyNotSupportedModal,
  }
}

const MainContent = styled(Box)`
  text-align: left;
`
