import { useEffect, useReducer, useState } from 'react'
import { stateAbbreviationToLongName } from '@genoa/utils'
import type { updateUser as updateUserType, updateUserEmail as updateUserEmailType } from '@iterable/web-sdk/dist/users'
import fromPairs from 'lodash/fromPairs'

import { useRetryLock } from '../../hooks'
import { logger } from '../logger'
import { IterableUserFields } from './user-fields'

const getFormattedFields = (fields: IterableUserFields) => {
  if (fields.state) {
    return { ...fields, state_long: stateAbbreviationToLongName(fields.state) }
  }

  return fields
}

interface ResetFieldsAction {
  readonly type: 'reset-fields'
}

interface ResetEmailAction {
  readonly type: 'reset-email'
}

interface ResetAction {
  readonly type: 'reset'
}

interface UpdateAction {
  readonly type: 'update'
  readonly state: IterableUserFields
}

type DeferredPropertyActions = ResetFieldsAction | ResetEmailAction | ResetAction | UpdateAction

const deferredPropertyReducer = (state: IterableUserFields, action: DeferredPropertyActions): IterableUserFields => {
  switch (action.type) {
    case 'reset-fields':
      return state.email !== undefined ? { email: state.email } : {}

    case 'reset-email':
      const { email, ...fields } = state
      return fields

    case 'update':
      const filteredState: IterableUserFields = fromPairs(
        Object.entries(action.state).filter(([, value]) => value !== undefined && value !== '')
      )

      return {
        ...state,
        ...filteredState,
      }

    case 'reset':
    default:
      return {}
  }
}

export interface UseDeferredPropertiesOptions {
  readonly updateUser: typeof updateUserType
  readonly locked: boolean
}

export const useDeferredProperties = ({ updateUser, locked }: UseDeferredPropertiesOptions) => {
  const [deferredProperties, dispatch] = useReducer(deferredPropertyReducer, {})
  const { lock: retryLock, incrementFailures, resetFailures, failures } = useRetryLock()

  const reset = () => {
    dispatch({ type: 'reset' })
  }

  useEffect(() => {
    if (!locked && !retryLock) {
      const { email, ...fields } = deferredProperties

      // if we have fields to update
      if (Object.keys(fields).length > 0) {
        const dataFields = getFormattedFields(fields)
        updateUser({ dataFields })
          .then((response) => {
            if (response.status === 200) {
              logger.log('use-deferred-properties', 'set user data successfully')
              dispatch({ type: 'reset-fields' })
              resetFailures()
            }
          })
          .catch((error) => {
            logger.error('use-deferred-properties', error?.message)
            incrementFailures()
          })

        return
      }
    }
  }, [locked, deferredProperties, retryLock, failures])

  return {
    updateDeferredProperties: (properties: IterableUserFields) => dispatch({ type: 'update', state: properties }),
    reset,
  }
}
