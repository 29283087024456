import { useCallback, useMemo } from 'react'
import { PaymentCategory, PaymentStatus, SLCType } from '@genoa/domain'
import { PaymentSchedule, settledPaymentStatuses } from '@genoa/middle-end'
import { DeepCamelCase, deepSnakeToCamelCase, getDateTime } from '@genoa/utils'
import { getDateTimeFromISO } from '@genoa/utils'
import { DateTime } from 'luxon'

import { useCustomerStatusState } from '../../modules/flex2/use-state'
import { useAccountStates } from '../flex2/identity/use-account-states'
import { useSecureLineOfCredit } from '../secure-line-of-credit'
import { getPaymentLabelsFactory } from './get-payment-labels'

export interface PaymentScheduleWithLabels extends DeepCamelCase<PaymentSchedule> {
  readonly paymentLabel: string
  readonly paymentDate: DateTime
  readonly displayDate: string
  readonly ordinalPaymentLabel: string
}

export interface UsePaymentsResult {
  readonly nonMembershipPayments: PaymentScheduleWithLabels[]
  readonly membershipPayment: PaymentScheduleWithLabels | undefined
  readonly failedPayment: PaymentScheduleWithLabels | undefined
  readonly upcomingPayment: PaymentScheduleWithLabels | undefined
  readonly firstPayment: PaymentScheduleWithLabels | undefined
  readonly nextPayNowPayment: PaymentScheduleWithLabels | undefined
  readonly secondPayments: PaymentScheduleWithLabels[]
  readonly unpaidSecondPayments: PaymentScheduleWithLabels[]
  /**
   * when a user makes a partial payment it'll create two payments of SECOND_PAYMENT type, one paid with the partial
   * payment amount and one scheduled with the remaining amount they owe. defaultSecondPayment does the logic to filter
   * for just the remaining scheduled payment in that case
   * */
  readonly defaultSecondPayment: PaymentScheduleWithLabels | undefined
  readonly isAbleToReschedule: (payment: PaymentScheduleWithLabels | undefined) => boolean
  readonly completedNonMembershipPayments: PaymentScheduleWithLabels[]
  readonly upcomingNonMembershipPayments: PaymentScheduleWithLabels[]
}

export function usePayments(): UsePaymentsResult {
  const { customerStatus, accountState } = useCustomerStatusState()
  const { isInNonActiveState } = useAccountStates()
  const { isSimplifiedPaymentsEnabled, isEnabledForSLC, slcType } = useSecureLineOfCredit()

  const firstPaymentInternal = useMemo(() => {
    return customerStatus.payments.find((p) => p.payment_category === PaymentCategory.FIRST_PAYMENT)
  }, [customerStatus])

  const getPaymentLabels = useCallback(
    getPaymentLabelsFactory(isSimplifiedPaymentsEnabled, firstPaymentInternal?.is_security_deposit, slcType),
    [isSimplifiedPaymentsEnabled, firstPaymentInternal]
  )

  const mapToPaymentWithLabels = (payment: PaymentSchedule, index: number = 0): PaymentScheduleWithLabels => {
    const { paymentLabel, ordinalPaymentLabel } = getPaymentLabels(payment, index)
    const paymentDate = getDateTimeFromISO(payment.bill_date)

    return {
      ...deepSnakeToCamelCase(payment),
      displayDate: `${paymentDate.weekdayLong}, ${paymentDate.monthShort} ${paymentDate.day}`,
      paymentLabel,
      ordinalPaymentLabel,
      paymentDate,
    }
  }

  const firstPayment: PaymentScheduleWithLabels | undefined = useMemo(() => {
    if (firstPaymentInternal) {
      // TODO: added can_pay_now to allow for pay now to succeed.
      return mapToPaymentWithLabels({ ...firstPaymentInternal, can_pay_now: true }!)
    }
  }, [getPaymentLabels, firstPaymentInternal])

  const secondPayments: PaymentScheduleWithLabels[] = useMemo(() => {
    return customerStatus.payments
      .filter((p) => p.payment_category === PaymentCategory.SECOND_PAYMENT)
      .map(mapToPaymentWithLabels)
  }, [customerStatus, getPaymentLabels])

  const membershipPayment: PaymentScheduleWithLabels | undefined = useMemo(() => {
    const payment = customerStatus.payments.find((p) => p.payment_category === PaymentCategory.MEMBERSHIP_FEE)
    if (payment) {
      return mapToPaymentWithLabels(payment)
    }
  }, [customerStatus])

  const failedPayment = useMemo(
    () => secondPayments.find((p) => p.paymentStatus === PaymentStatus.FAILED),
    [customerStatus, getPaymentLabels]
  )

  const nonMembershipPayments = useMemo(() => {
    return [firstPayment, ...secondPayments].filter((p): p is PaymentScheduleWithLabels => p !== undefined)
  }, [firstPayment, secondPayments])

  const completedNonMembershipPayments = useMemo(() => {
    return nonMembershipPayments.filter((p): p is PaymentScheduleWithLabels =>
      settledPaymentStatuses.includes(p.paymentStatus)
    )
  }, [nonMembershipPayments])

  const upcomingNonMembershipPayments = useMemo(() => {
    return nonMembershipPayments.filter(
      (p): p is PaymentScheduleWithLabels => !settledPaymentStatuses.includes(p.paymentStatus)
    )
  }, [nonMembershipPayments])

  const getNextPayment = useCallback(
    (canMakePaymentNow?: boolean) => {
      for (const payment of nonMembershipPayments) {
        if (!settledPaymentStatuses.includes(payment.paymentStatus) && (!canMakePaymentNow || payment.canPayNow)) {
          return payment
        }
      }
    },
    [nonMembershipPayments]
  )

  const upcomingPayment = useMemo(() => {
    return getNextPayment()
  }, [customerStatus])

  const nextPayNowPayment = useMemo(() => {
    return getNextPayment(true)
  }, [customerStatus])

  // logic coming over from reschedule-payment-details-navigation
  const unpaidSecondPayments = useMemo(
    () => secondPayments.filter((p) => p.paymentStatus === PaymentStatus.SCHEDULED),
    [secondPayments]
  )

  const defaultSecondPayment = useMemo(() => unpaidSecondPayments[0], [secondPayments])

  const isAbleToReschedule = useCallback(
    (selectedPayment?: PaymentScheduleWithLabels) => {
      if (!selectedPayment || !selectedPayment.userPaymentPublicId) {
        return false
      }

      const today = getDateTime()
      const isSecondPaymentCDQ = today >= getDateTimeFromISO(selectedPayment?.billDate!)
      const isSecondPaymentAlreadyPaid = settledPaymentStatuses.includes(selectedPayment.paymentStatus)
      const isUserInNotActiveState = isInNonActiveState(accountState)
      const isMostRecentPayment = defaultSecondPayment?.userPaymentPublicId === selectedPayment?.userPaymentPublicId
      const blockRescheduleForSLC = isEnabledForSLC && slcType !== SLCType.CLASSIC

      return (
        !blockRescheduleForSLC &&
        unpaidSecondPayments.length === 1 &&
        !isSecondPaymentAlreadyPaid &&
        !isSecondPaymentCDQ &&
        !isUserInNotActiveState &&
        isMostRecentPayment
      )
    },
    [defaultSecondPayment, accountState]
  )

  return {
    nonMembershipPayments,
    membershipPayment,
    failedPayment,
    upcomingPayment,
    firstPayment,
    nextPayNowPayment,
    secondPayments,
    unpaidSecondPayments,
    defaultSecondPayment,
    isAbleToReschedule,
    completedNonMembershipPayments,
    upcomingNonMembershipPayments,
  }
}
