import React from 'react'
import { PROPERTY_PASSTHROUGH_FEE } from '@genoa/screen-content'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { ExtraSmallText, InlineButton, SmallText } from '../../../../components'

interface PaymentScheduleItemProps {
  readonly onChangeDate?: () => unknown
  readonly onLearnMore?: () => unknown
  readonly payment: string
  readonly paymentLabel: string
  readonly paymentDateLabel: string
  readonly hideChangeDate?: boolean
  readonly showProcessingFeePercentage?: boolean
  readonly showInterestFee?: boolean
  readonly paymentBreakdown?: PaymentBreakdownItemProps[]
  readonly displayPropertyPassthroughFee?: boolean
}

interface PaymentBreakdownItemProps {
  readonly onTooltipPressed?: () => unknown
  readonly label: string
  readonly payment: string
}

export const PaymentScheduleItem = ({ onLearnMore, onChangeDate, ...props }: PaymentScheduleItemProps) => {
  const theme = useTheme()

  return (
    <>
      <PaymentScheduleLineItem {...{ onLearnMore, onChangeDate, ...props }} />
      {props.showProcessingFeePercentage && props.paymentBreakdown && (
        <>
          {props.paymentBreakdown.map((item, index) => (
            <>
              <Box minH={theme.fixedSizes.spacing_50} />
              <PaymentBreakdownItem key={`payment-breakdown-item-${index}`} {...item} />
            </>
          ))}
        </>
      )}
    </>
  )
}
export const PaymentScheduleLineItem = ({ onLearnMore, onChangeDate, ...props }: PaymentScheduleItemProps) => {
  const getDisclaimerIcons = () => {
    let icons = ''
    if (!props.showInterestFee && props.showProcessingFeePercentage) {
      icons += '*'
    }
    if (props.displayPropertyPassthroughFee && !props.paymentBreakdown) {
      icons += PROPERTY_PASSTHROUGH_FEE.PAYMENT_SCHEDULE_ICON
    }
    return icons
  }

  return (
    <PaymentScheduleItemContainer>
      <Flex alignItems="center">
        <Flex flexFlow={'column'}>
          <Flex alignItems={'center'}>
            <Circle />
            <SmallText>{props.paymentLabel}</SmallText>
            {onLearnMore && (
              <QuestionCircle onClick={onLearnMore}>
                <TextBadge>?</TextBadge>
              </QuestionCircle>
            )}
          </Flex>
          {props.paymentLabel !== '$0' && <ExtraSmallTextStyled>{props.paymentDateLabel}</ExtraSmallTextStyled>}
          {!props.hideChangeDate && onChangeDate && <ChangeDateButton onChangeDate={onChangeDate} />}
        </Flex>
      </Flex>
      <SmallText>
        ${props.payment}
        {getDisclaimerIcons()}
      </SmallText>
    </PaymentScheduleItemContainer>
  )
}

const PaymentBreakdownItem = ({ label, payment, onTooltipPressed }: PaymentBreakdownItemProps) => {
  const theme = useTheme()

  return (
    <PaymentScheduleItemContainer>
      <Flex alignItems="center">
        <Flex flexFlow={'column'}>
          <Flex alignItems={'center'}>
            <ExtraSmallTextStyled>{label}</ExtraSmallTextStyled>
            <Box minW={theme.fixedSizes.spacing_37_5} />
            {onTooltipPressed && (
              <QuestionCircle onClick={onTooltipPressed}>
                <TextBadge>?</TextBadge>
              </QuestionCircle>
            )}
          </Flex>
        </Flex>
      </Flex>
      <ExtraSmallTextStyled>${payment}</ExtraSmallTextStyled>
    </PaymentScheduleItemContainer>
  )
}
interface ChangeDateButtonProps {
  readonly onChangeDate: () => unknown
}

const ChangeDateButton = (props: ChangeDateButtonProps) => {
  return (
    <InlineButton onClick={props.onChangeDate} testID="PaymentScheduleItemChangeDateButton">
      <ChangeDateButtonText>
        <strong>Change date</strong>
      </ChangeDateButtonText>
    </InlineButton>
  )
}

const PaymentScheduleItemContainer = styled(Flex)`
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
`

const Circle = styled(Flex)`
  width: ${(props) => props.theme.fixedSizes.spacing_50};
  height: ${(props) => props.theme.fixedSizes.spacing_50};
  border-radius: ${(props) => props.theme.radii.rounded};
  background-color: ${(props) => props.theme.colors.brand['900']};
  margin-right: ${(props) => props.theme.fixedSizes.spacing_100};
`

const QuestionCircle = styled(Flex)`
  width: ${(props) => props.theme.fixedSizes.spacing_100};
  height: ${(props) => props.theme.fixedSizes.spacing_100};
  border-radius: ${(props) => props.theme.radii.rounded};
  background-color: ${(props) => props.theme.colors.jewelPurple};
  margin-left: ${(props) => props.theme.fixedSizes.spacing_25};
  align-items: center;
  justify-content: center;
`

const ExtraSmallTextStyled = styled(ExtraSmallText)`
  margin-left: ${(props) => props.theme.fixedSizes.spacing_150};
  color: ${(props) => props.theme.colors.dusk};
`

const ChangeDateButtonText = styled(ExtraSmallTextStyled)`
  color: ${(props) => props.theme.colors.brand['900']};
`

const TextBadge = styled(ExtraSmallText)`
  color: ${(props) => props.theme.colors.white};
`
