import { useEffect, useState } from 'react'

import { useAuthState } from '../../../../../../contexts'
import { useGetCards } from '../../../../../../hooks'
import { useCustomerWalletState } from '../../../../../../modules/flex2/use-state'
import { useLogger } from '../../../../../../providers'

export const useCustomerWalletCard = () => {
  const logger = useLogger('SimplifiedEpisodicContainer')
  const { uid } = useAuthState()

  const [cardLast4Digits, setCardLast4Digits] = useState('--')
  const [cardType, setCardType] = useState('--')
  const customerWalletState = useCustomerWalletState()
  const { getDefaultCard } = useGetCards({ componentName: 'SimplifiedEpisodicContainer' })

  const setCards = () => {
    const defaultCard = customerWalletState.defaultCard
    setCardLast4Digits(defaultCard.card_mask)
    defaultCard.card_type && setCardType(defaultCard.card_type)
  }

  useEffect(() => {
    if (!customerWalletState.initialized) {
      if (uid) {
        getDefaultCard(uid)
      } else {
        logger.error('Default card is missing on pay now')
      }
      return
    }
    setCards()
  }, [customerWalletState.initialized, uid])

  useEffect(() => {
    setCards()
  }, [customerWalletState])

  useEffect(() => {
    if (uid) {
      getDefaultCard(uid)
    }
  }, [])

  return {
    cardLast4Digits,
    cardType,
  }
}
