import React from 'react'
import { Input } from '@chakra-ui/react'

type TextInputProps = {
  text?: string
  placeholder?: string
  onTextChange: (text: string) => unknown
  onBlur?: () => unknown
  onFocus?: () => unknown
  error?: boolean
  password?: boolean
  disabled?: boolean
  autoCapitalize?: boolean
  background?: string
  foreground?: string
  maxLength?: number
  defaultValue?: string
  type?: string
  testID?: string
  autoComplete?: string
}

export const TextInput = (props: TextInputProps) => {
  const handleInputChangeEvent = (evt: React.ChangeEvent<HTMLInputElement>) => props.onTextChange(evt.target.value)

  return (
    <Input
      isDisabled={props.disabled}
      placeholder={props.placeholder}
      type={props.type || (props.password ? 'password' : 'text')}
      onFocus={props.onFocus}
      isInvalid={props.error}
      value={props.text}
      onChange={handleInputChangeEvent}
      textTransform={props.autoCapitalize ? 'capitalize' : 'none'}
      backgroundColor={props.background}
      color={props.foreground}
      maxLength={props.maxLength}
      defaultValue={props.defaultValue}
      data-testid={props.testID}
      autoComplete={props.autoComplete}
    />
  )
}
