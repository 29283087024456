import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Analytics } from '@genoa/analytics'
import { BusinessLogicExceptionErrorCode, GeneralApiErrorCodes, useQueryError } from '@genoa/state-management'
import { toDollars } from '@genoa/utils'

import { useAuthState } from '../../../../../../contexts'
import { useDefaultErrorModals, usePayNowErrorModals } from '../../../../../../hooks'
import { useEmbedAnalytics, usePayments } from '../../../../../../hooks'
import { useBillPayNowSubmitMutation } from '../../../../../../modules/flexApi'
import { useAnalytics } from '../../../../../../providers'
import * as Routes from '../../../../../../routing/constants'

interface useBillPayNowSubmitProps {
  downPaymentAmount: string
}

export const useBillPayNowSubmit = (props: useBillPayNowSubmitProps) => {
  const navigate = useNavigate()
  const analytics = useAnalytics()
  const { uid } = useAuthState()
  const { embedProperties } = useEmbedAnalytics()

  const { firstPayment } = usePayments()

  const { displayTooManyTriesModal } = useDefaultErrorModals()
  const {
    displayInsufficientFundsModal,
    displayGenericPaymentFailureModal,
    displayDefaultPaymentFailureModal,
    displayUpdateCardPaymentFailureModal,
    displayUnableToCompletePaymentModal,
  } = usePayNowErrorModals()

  const [
    billPayNowSubmit,
    { error: billPayNowSubmitError, isSuccess: isBillPayNowSubmitSuccess, isLoading: isBillPayNowSubmitLoading },
  ] = useBillPayNowSubmitMutation()

  useEffect(() => {
    if (isBillPayNowSubmitSuccess) {
      navigate(Routes.Embed.MANUAL_PAY_FINISH, { state: { downPaymentAmount: props.downPaymentAmount } })
    }
  }, [isBillPayNowSubmitSuccess])

  useQueryError(billPayNowSubmitError, {
    onFlexApiError({ data: { error } }) {
      if (error.code === GeneralApiErrorCodes.RATE_LIMIT) {
        displayTooManyTriesModal()
        return true
      } else {
        if (error.code === BusinessLogicExceptionErrorCode.B_INSUFFICIENT_FUNDS) {
          displayInsufficientFundsModal(props.downPaymentAmount)
          return true
        } else if (error.code === BusinessLogicExceptionErrorCode.B_PAYMENT_GENERIC_DECLINE) {
          displayGenericPaymentFailureModal(props.downPaymentAmount)
          return true
        } else if (error.code === BusinessLogicExceptionErrorCode.B_PAYMENT_UPDATE_CARD) {
          displayUpdateCardPaymentFailureModal(props.downPaymentAmount)
          return true
        } else if (error.code === BusinessLogicExceptionErrorCode.B_PAYMENT_DEFAULT_FAILURE) {
          displayDefaultPaymentFailureModal(props.downPaymentAmount)
          return true
        }
      }
    },
    onAllErrors() {
      analytics.logEvent(Analytics.Events.MANUAL_PAY_PAY_NOW_ERROR_MODAL)
      displayUnableToCompletePaymentModal({ amount: toDollars(Number(props.downPaymentAmount)) })
    },
  })

  const handlePayButtonPress = async () => {
    analytics.logEvent(Analytics.Events.EMBED_NON_SLC_PAY_RENT_BUTTON_CLICK, embedProperties)
    analytics.logEvent(Analytics.Events.MANUAL_PAY_PAY_NOW_CTA_CLICK, {
      source: '',
      paymentLabel: firstPayment?.paymentLabel,
    })

    if (uid) {
      await billPayNowSubmit({ customerId: uid })
    }
  }

  return { handlePayButtonPress, isBillPayNowSubmitLoading }
}
