import React from 'react'
import { Heading, HeadingProps, Link, LinkProps, Text as ChakraText, TextProps } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'

import { MarkdownView } from '../Markdown/MarkdownView'

type FlexHeadingProps = { markdown?: boolean } & HeadingProps
type FlexTextProps = { markdown?: boolean } & TextProps

type SmallTextProps = FlexTextProps & { hasError?: boolean; greyed?: boolean }
type BaseTextProps =
  | ({ typographyStyle: 'jumbo-headline' } & FlexHeadingProps)
  | ({ typographyStyle: 'headline' } & FlexHeadingProps)
  | ({ typographyStyle: 'title' } & FlexTextProps)
  | ({ typographyStyle: 'subtitle' } & FlexTextProps)
  | ({ typographyStyle: 'large-text' } & FlexTextProps)
  | ({ typographyStyle: 'text' } & FlexTextProps)
  | ({ typographyStyle: 'small-text' } & SmallTextProps)
  | ({ typographyStyle: 'extra-small-text' } & SmallTextProps)
  | ({ typographyStyle: 'link-text' } & LinkProps)

const BaseText = ({ typographyStyle, ...untypedProps }: BaseTextProps) => {
  const theme = useTheme()
  let styles: any = {}

  if (typographyStyle === 'jumbo-headline') {
    const props = untypedProps as FlexHeadingProps
    styles = {
      lineHeight: theme.lineHeights.lg,
      fontWeight: theme.fontWeights.bold,
      fontSize: theme.fontSizes.xl,
      fontFamily: theme.fonts.heading,
      color: theme.colors.text[900],
    }

    if (props.markdown) {
      return <MarkdownView styles={{ ...styles }}>{props.children as string}</MarkdownView>
    }

    return <Heading {...styles} {...props} />
  }

  if (typographyStyle === 'headline') {
    const props = untypedProps as FlexHeadingProps
    styles = {
      lineHeight: theme.lineHeights.md,
      fontWeight: theme.fontWeights.bold,
      fontSize: theme.fontSizes.lg,
      fontFamily: theme.fonts.heading,
      color: theme.colors.text[900],
    }

    if (props.markdown) {
      return <MarkdownView styles={{ ...styles }}>{props.children as string}</MarkdownView>
    }

    return <Heading {...styles} {...props} />
  }

  if (typographyStyle === 'title') {
    const props = untypedProps as FlexTextProps
    styles = {
      fontFamily: theme.fonts.body,
      margin: '16px',
      fontWeight: theme.fontWeights.bold,
    }

    if (props.markdown) {
      return <MarkdownView styles={{ ...styles }}>{props.children as string}</MarkdownView>
    }

    return <ChakraText {...styles} {...props} />
  }

  if (typographyStyle === 'subtitle') {
    const props = untypedProps as FlexTextProps
    styles = {
      fontFamily: theme.fonts.body,
      margin: '8px',
      fontWeight: theme.fontWeights.bold,
    }

    if (props.markdown) {
      return <MarkdownView styles={{ ...styles }}>{props.children as string}</MarkdownView>
    }

    return <ChakraText {...styles} {...props} />
  }

  if (typographyStyle === 'large-text') {
    const props = untypedProps as FlexTextProps
    styles = {
      lineHeight: theme.lineHeights.sm,
      fontSize: theme.fontSizes.md,
      fontFamily: theme.fonts.body,
      color: theme.colors.text[900],
      display: 'inline',
    }

    if (props.markdown) {
      return <MarkdownView styles={{ ...styles }}>{props.children as string}</MarkdownView>
    }

    return <ChakraText {...styles} {...props} />
  }

  if (typographyStyle === 'text') {
    const props = untypedProps as FlexTextProps
    const styles = {
      lineHeight: theme.lineHeights.sm,
      fontSize: theme.fontSizes.sm,
      fontFamily: theme.fonts.body,
      color: theme.colors.text[900],
      display: 'inline',
    }

    if (props.markdown) {
      return <MarkdownView styles={{ ...styles }}>{props.children as string}</MarkdownView>
    }

    return <ChakraText {...styles} {...props} />
  }

  if (['small-text', 'extra-small-text'].includes(typographyStyle)) {
    const props = untypedProps as SmallTextProps
    const styles = {
      lineHeight: typographyStyle === 'small-text' ? theme.lineHeights.xs : theme.lineHeights.xxs,
      fontSize: typographyStyle === 'small-text' ? theme.fontSizes.xs : theme.fontSizes.xxs,
      fontFamily: theme.fonts.body,
      color: props.hasError ? theme.colors.error : props.greyed ? theme.colors.text[100] : theme.colors.text[900],
      display: 'inline',
    }

    if (props.markdown) {
      return <MarkdownView styles={{ ...styles }}>{props.children as string}</MarkdownView>
    }
    return <ChakraText {...styles} {...props} />
  }

  if (typographyStyle === 'link-text') {
    const props = untypedProps as LinkProps
    const styles = {
      fontFamily: theme.fonts.body,
      color: theme.colors.brand[900],
    }

    return <Link onClick={props.onClick} {...styles} {...props} />
  }

  return null
}

export const Headline1 = (props: FlexHeadingProps) => <BaseText {...props} typographyStyle="jumbo-headline" />
export const Headline2 = (props: FlexHeadingProps) => <BaseText {...props} typographyStyle="headline" />
export const TextTitle = (props: FlexTextProps) => <BaseText {...props} typographyStyle="title" />
export const TextSubtitle = (props: FlexTextProps) => <BaseText {...props} typographyStyle="subtitle" />
export const LargeText = (props: FlexTextProps) => <BaseText {...props} typographyStyle="large-text" />
export const Text = (props: FlexTextProps) => <BaseText {...props} typographyStyle="text" />
export const SmallText = (props: SmallTextProps) => <BaseText {...props} typographyStyle="small-text" />
export const ExtraSmallText = (props: SmallTextProps) => <BaseText {...props} typographyStyle="extra-small-text" />
export const LinkText = (props: LinkProps & { testID?: string }) => <BaseText {...props} typographyStyle="link-text" />
