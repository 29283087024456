import { useEffect, useRef } from 'react'
import Select, { components, DropdownIndicatorProps, OptionProps, StylesConfig, ThemeConfig } from 'react-select'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Chevron, RadioCheckPurple } from '../../assets'
import { Pill } from '../Pill'

interface SelectOption<TItem> {
  readonly value: TItem
  readonly label: string
}

export interface DateDropdownProps<TItem> {
  readonly selected: TItem | undefined
  readonly selections: readonly TItem[]
  readonly placeholder: string
  readonly defaultCenter?: TItem
  readonly formatLabel: (value: TItem) => string
  readonly onSelect: (value: TItem) => void
  readonly isEqual: (value: TItem, comparison: TItem) => boolean
}

/** Flex themed implementation of react-select */
export const Dropdown = <TItem,>(props: DateDropdownProps<TItem>) => {
  const flexTheme = useTheme()

  const options = props.selections.map((date) => ({
    value: date,
    label: props.formatLabel(date),
  }))
  const value = props.selected === undefined ? null : options.find((value) => props.selected === value.value)

  // custom Option component which centers on selected or preferred elements
  const DateCenteringOption = (optionsProps: OptionProps<SelectOption<TItem>, false>) => {
    const ref: any = useRef(null)

    const isRecommended = props.defaultCenter ? props.isEqual(optionsProps.data?.value, props.defaultCenter) : false

    useEffect(() => {
      if (
        optionsProps.getValue().length > 0 &&
        props.isEqual(optionsProps.data?.value, optionsProps.getValue()[0].value)
      ) {
        ref.current?.scrollIntoView({ behavior: 'instant', block: 'center' })
      } else if (optionsProps.getValue().length == 0 && isRecommended) {
        ref.current?.scrollIntoView({ behavior: 'instant', block: 'center' })
      }
    }, [optionsProps.isSelected])

    return <CustomOption {...optionsProps} isRecommended={isRecommended} innerRef={ref} />
  }

  const customStyles: StylesConfig<SelectOption<TItem>, false> = {
    option: (provided, state) => ({
      ...provided,
      padding: flexTheme.fixedSizes.spacing_100,
      backgroundColor: state.isSelected ? flexTheme.colors.wildflower : provided.backgroundColor,
      color: state.isSelected ? flexTheme.colors.jewelPurple : flexTheme.colors.midnight,
      fontWeight: state.isSelected ? 600 : 400,
      ':active': {
        backgroundColor: flexTheme.colors.softLilac,
      },
    }),
  }

  const customTheme: ThemeConfig = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: flexTheme.colors.jewelPurple,
      primary25: flexTheme.colors.cloud,
    },
    borderRadius: 8,
    spacing: {
      ...theme.spacing,
      controlHeight: 54,
    },
  })

  return (
    <Select
      isSearchable={false}
      components={{ DropdownIndicator, IndicatorSeparator: () => null, Option: DateCenteringOption }}
      options={options}
      value={value}
      onChange={(value) => props.onSelect(value?.value!)}
      placeholder={props.placeholder}
      theme={customTheme}
      styles={customStyles}
    />
  )
}

const CustomOption = <TItem,>(optionsProps: OptionProps<SelectOption<TItem>, false> & { isRecommended: boolean }) => {
  return (
    <components.Option {...optionsProps}>
      <CustomOptionBox>
        <LabelFlexBox>
          {optionsProps.label}
          {optionsProps.isRecommended && <Pill label="Recommended" />}
        </LabelFlexBox>
        {optionsProps.isSelected ? <RadioCheckPurple /> : <Box />}
      </CustomOptionBox>
    </components.Option>
  )
}

const CustomOptionBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const LabelFlexBox = styled(Flex)`
  gap: ${(props) => props.theme.fixedSizes.spacing_25};
`

const DropdownIndicator = <TItem,>(props: DropdownIndicatorProps<SelectOption<TItem>, false>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDown />
    </components.DropdownIndicator>
  )
}

const ChevronDown = styled(Chevron)`
  transform: rotate(90deg);
`
