import { useCallback, useEffect, useState } from 'react'

const RETRY_FACTOR = 2 * 1000

export interface UseRetryLockOptions {
  readonly maxFailures?: number
}

export const useRetryLock = ({ maxFailures = 5 }: UseRetryLockOptions = {}) => {
  const [lock, setLock] = useState(false)
  const [failures, setFailures] = useState(0)

  const incrementFailures = useCallback(() => {
    setFailures(failures + 1)
    setLock(true)
  }, [failures])

  const resetFailures = () => setFailures(0)

  useEffect(() => {
    if (lock) {
      if (failures === 0) {
        setLock(false)
      }

      if (failures < maxFailures) {
        const timeout = setTimeout(() => setLock(false), failures * RETRY_FACTOR)

        return () => clearTimeout(timeout)
      }
    }
  }, [lock, failures])

  return {
    lock,
    failures,
    incrementFailures,
    resetFailures,
  }
}
