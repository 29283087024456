import React, { useCallback } from 'react'

import { useModal } from '../../../../../../../contexts'
import { SmallText } from '../../../../../../components'

const DEFAULT_MODAL_ERROR_MESSAGE =
  'Looks like we ran into some technical difficulties. Please check your internet connection and try again.'
const DEFAULT_MODAL_ERROR_TITLE = 'Something went wrong'

type UseCardResultMessagProps = {
  onCloseSuccessMessage?: () => void
  onShowSuccessMessage: () => void
  onShowErrorMessage: () => void
}

export const useCardResultMessage = (props: UseCardResultMessagProps) => {
  const modal = useModal()

  const showErrorMessage = useCallback(
    (
      title: string = DEFAULT_MODAL_ERROR_TITLE,
      errorMessage: string = DEFAULT_MODAL_ERROR_MESSAGE,
      onClose?: () => void
    ) => {
      props.onShowErrorMessage()

      modal.show({
        title,
        cta: 'Confirm',
        onClose: onClose,
        render: () => (
          <>
            <SmallText>{errorMessage}</SmallText>
          </>
        ),
      })
    },
    [props.onShowErrorMessage]
  )

  const showSuccessMessage = useCallback(
    (cardLast4Digits: string) => {
      props.onShowSuccessMessage()

      modal.show({
        title: 'We did it!',
        cta: 'Continue',
        onClose: () => props.onCloseSuccessMessage && props.onCloseSuccessMessage(),
        render: () => (
          <SmallText>
            Your card ending in <SmallText fontWeight={'bold'}>{cardLast4Digits}</SmallText> was successfully linked to
            Flex. Your upcoming payments will use this card.
          </SmallText>
        ),
      })
    },
    [props.onShowSuccessMessage, props.onCloseSuccessMessage]
  )

  return {
    showSuccessMessage,
    showErrorMessage,
  }
}
