import React, { useMemo } from 'react'
import { Analytics } from '@genoa/analytics'
import { SLCType } from '@genoa/domain'
import {
  FA_SLC_CONGRATS_CONTENT,
  replaceContent,
  SLC_CLASSIC_CONGRATS_CONTENT as classicContent,
  SLC_CLASSIC_CONGRATS_CONTENT,
  SLC_CONGRATS_CONTENT as legacyContent,
} from '@genoa/screen-content'
import { getDateTime, getResumeDate } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { useAutopilotEligibility, useGetAccount, useSecureLineOfCredit } from '../../../../../hooks'
import { HeroBouquet as SLCCongratsImage } from '../../../../assets'
import { FullScreenSpinnerLoading, Gap, Headline1, Text } from '../../../../components'
import { AppStoreButton } from '../../../../components/AppStoreButton'
import { PlayStoreButton } from '../../../../components/PlayStoreButton'
import { BasePageLayout } from '../../../../layouts'

type SLCCongratsProps = {
  analyticsScreenName: Analytics.Screens
}

const storeBadgeStyles = {
  maxHeight: '65px',
  minHeight: '65px',
  transform: 'scale(0.7)',
  cursor: 'pointer',
}

export const SLCCongrats = (props: SLCCongratsProps) => {
  const { isUserAutopayEnabled, isLoadingEligibility } = useAutopilotEligibility()
  const theme = useTheme()
  const { slcType, isADO } = useSecureLineOfCredit()
  useGetAccount()

  const content = useMemo(() => {
    if (slcType === SLCType.CLASSIC) {
      return SLC_CLASSIC_CONGRATS_CONTENT
    } else if (slcType === SLCType.FLEX_ANYWHERE) {
      return FA_SLC_CONGRATS_CONTENT
    }
    return legacyContent
  }, [slcType])

  const bodyText = () => {
    const today = getDateTime()
    const resumeDate = getResumeDate(today)
    const month = resumeDate.monthLong!
    const year = resumeDate.year!

    return isADO ? (
      <Text textAlign="center">{replaceContent(content.BODY, { month, year })}</Text>
    ) : (
      <Text textAlign="center">
        {isUserAutopayEnabled ? content.AUTOPAY.BODY : content.AUTOPAY_OFF.BODY}
        <Text>
          <strong>{` ${month}`}</strong>
        </Text>
        {isUserAutopayEnabled ? content.AUTOPAY.BODY_TWO : content.AUTOPAY_OFF.BODY_TWO}
      </Text>
    )
  }

  if (isLoadingEligibility) {
    return <FullScreenSpinnerLoading />
  }

  return (
    <BasePageLayout analyticsScreenName={props.analyticsScreenName}>
      <Gap size="spacing_100" />

      <Flex flexGrow={1} flexShrink={0} flexDirection="column" data-testid="SLCCongratsPage">
        <Flex align="center" justify="center">
          <SLCCongratsImage />
        </Flex>

        <Gap size="spacing_225" />

        <Headline1 textAlign="center">{content.HEADER}</Headline1>

        <Gap size="spacing_100" />
        {bodyText()}
        <Gap size="spacing_150" />

        <Text textAlign="center" fontWeight={'bold'}>
          {isUserAutopayEnabled ? content.AUTOPAY.DOWNLOAD : content.AUTOPAY_OFF.DOWNLOAD}
        </Text>

        <Gap size="spacing_200" />

        <AppButtonContainer backgroundColor={theme.colors.cloud}>
          <Box {...storeBadgeStyles}>
            <AppStoreButton />
          </Box>
          <Box minH={'24px'} />
          <Box {...storeBadgeStyles}>
            <PlayStoreButton />
          </Box>
        </AppButtonContainer>
      </Flex>

      <Gap size="spacing_200" />
    </BasePageLayout>
  )
}

const AppButtonContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.fixedSizes.spacing_100};
  border-radius: ${(props) => props.theme.radii.lg};
`
