import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setDeviceMetadataAction } from '@genoa/state-management'
import { v4 as uuidV4 } from 'uuid'

import { RootState } from '../modules'
import { useReduxSelector } from './use-redux-selector'

export function useSetDeviceMetadata() {
  const initialDeviceMetadata = useReduxSelector((state: RootState) => state.deviceMetadata)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!initialDeviceMetadata.initialized) {
      dispatch(setDeviceMetadataAction({ initialized: true, deviceId: uuidV4() }))
    }
  }, [initialDeviceMetadata.initialized])
}
