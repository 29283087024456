import { Flex, IconButton } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { QuestionCircle } from '../../assets'
import { ExtraSmallText } from '../Typography'

export interface PaymentLineItemFrameProps {
  readonly label: string
  readonly amount?: string
  readonly moreInfo?: () => void
}

export const PaymentLineItemFrame = (props: PaymentLineItemFrameProps) => (
  <ItemRow>
    <LineItemLabelContainer>
      <LineItemText>{props.label}</LineItemText>
      {props.moreInfo && (
        <MoreInfoButton aria-label="More info" icon={<QuestionCircle />} isRound onClick={props.moreInfo} size="8px" />
      )}
    </LineItemLabelContainer>
    <LineItemText>{props.amount ?? ''}</LineItemText>
  </ItemRow>
)

const MoreInfoButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.wildflower};
  border-color: ${({ theme }) => theme.colors.wildflower};
`

const ItemRow = styled(Flex)`
  justify-content: space-between;
  width: 100%;
`

const LineItemLabelContainer = styled(Flex)`
  gap: ${({ theme }) => theme.fixedSizes.spacing_50};
`

const LineItemText = styled(ExtraSmallText)`
  color: ${({ theme }) => theme.colors.dusk};
`
