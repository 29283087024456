import React, { Fragment } from 'react'
import { ordinalSuffixFor } from '@genoa/utils'
import { Box, Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { Check } from '../../../assets'
import { LineDivider as BaseLineDivider, LinkText, PrimaryButton, SmallText } from '../../../components'
import { Modal } from '../../../components/Modal'

type ConfirmDateModalProps = {
  onSelectDate: (indexDateSelected: number) => void
  isConfirmDateModalOpen: boolean
  onClickConfirmDate: () => void
  onCloseConfirmDate: () => void
  handleLearnMore: () => void
  indexDateSelected: number
  flexibleDays: number[]
  isLoading?: boolean
}

export const ConfirmDateModal = (props: ConfirmDateModalProps) => {
  const theme = useTheme()

  return (
    <Modal visible={props.isConfirmDateModalOpen} title="" canClose onClose={props.onCloseConfirmDate}>
      <>
        <ContentContainer>
          {props.flexibleDays.map((flexibleDay, index) => {
            const isSelected = props.indexDateSelected === index
            const color = isSelected ? theme.colors.brand['900'] : theme.colors.black
            const fontWeight = isSelected ? 'bold' : 'normal'

            return (
              <Fragment key={`paymentDay-${index}`}>
                <Flex alignItems="center" onClick={() => props.onSelectDate(index)} as="button">
                  {isSelected && (
                    <Box marginRight={theme.fixedSizes.sm}>
                      <Check />
                    </Box>
                  )}
                  <SmallText color={color} fontWeight={fontWeight} key={flexibleDay}>{`${ordinalSuffixFor(
                    flexibleDay
                  )} of each month`}</SmallText>
                </Flex>
                {index < props.flexibleDays.length - 1 && <LineDivider />}
              </Fragment>
            )
          })}
          <Message mx="lg">
            <SmallText>Your second payment date should be before the last week of the month.</SmallText>
            <Box h="4" />
            <LinkText onClick={props.handleLearnMore}>Learn more</LinkText>
          </Message>
        </ContentContainer>
        <PrimaryButton onClick={props.onClickConfirmDate} processing={props.isLoading} disabled={props.isLoading}>
          Confirm date
        </PrimaryButton>
      </>
    </Modal>
  )
}

const ContentContainer = styled(Box)`
  margin-bottom: ${(props) => props.theme.fixedSizes.md};
  overflow-y: scroll;
  height: 300px;
`

const LineDivider = styled(BaseLineDivider)`
  margin-top: ${(props) => props.theme.fixedSizes.md};
  margin-bottom: ${(props) => props.theme.fixedSizes.md};
`

const Message = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.fixedSizes.md};
  background-color: ${(props) => props.theme.colors.brand['100']};
  padding: ${(props) => props.theme.fixedSizes.md};
  align-items: center;
  border-radius: ${(props) => props.theme.radii.lg};
`
