import { ConnectionType } from '@genoa/state-management'

import { useAccount } from '../use-account'

export const useShouldRestartEmbedOnboarding = () => {
  const { billerConnection } = useAccount()
  const shouldRestartEmbedOnboarding = billerConnection && billerConnection.connection !== ConnectionType.EMBED

  return shouldRestartEmbedOnboarding
}
