import React from 'react'
import { ordinalSuffixFor } from '@genoa/utils'
import { Box, Flex, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useFees } from '../../../../providers'
import { ExtraSmallText, LineDivider, SmallText } from '../../../components'

export type SimplifiedPaymentChoice = 'on' | 'off'

export interface SimplifiedPaymentsProps {
  readonly checked: SimplifiedPaymentChoice | undefined
  readonly onCheck: (value: SimplifiedPaymentChoice) => void
  readonly onShowSimplifiedPaymentsInfoModal: () => void
}

export const SimplifiedPayments = ({
  checked,
  onCheck,
  onShowSimplifiedPaymentsInfoModal,
}: SimplifiedPaymentsProps) => {
  const fees = useFees()

  // For an SLC offer the installments are in reverse
  const secondInstallmentDay = fees.installments[0]?.day
  const secondPaymentOrdinal = secondInstallmentDay ? ordinalSuffixFor(secondInstallmentDay) : '27th'

  return (
    <SimplifiedPaymentsContainer withPaddingHorizontal>
      <SmallText fontWeight="bold">Rent Protect</SmallText>
      <Box minH="4px" />
      <StyledSubtext>Turn your deposit into a payment that pays off your credit line.</StyledSubtext>
      <Box minH="16px" />
      <HowDoesRentProtectWorkLink onClick={onShowSimplifiedPaymentsInfoModal}>
        How does Rent Protect work? →
      </HowDoesRentProtectWorkLink>
      <Box minH="16px" />
      <RadioGroup onChange={onCheck}>
        <Stack direction="column">
          <Radio colorScheme="purple" value="on" checked={checked === 'off'}>
            <SmallText>On</SmallText>
            <StyledSubtext> (recommended)</StyledSubtext>
          </Radio>
          <Radio colorScheme="purple" value="off" checked={checked === 'off'}>
            <SmallText>Off</SmallText>
          </Radio>
        </Stack>
      </RadioGroup>
      {checked === 'off' && (
        <>
          <LineDivider />
          <Flex direction="row" justifyContent="space-between">
            <SmallText fontWeight="bold">2nd payment</SmallText>
            <SmallText>{secondPaymentOrdinal} of the month</SmallText>
          </Flex>
        </>
      )}
    </SimplifiedPaymentsContainer>
  )
}

type PaymentScheduleBoxStyleProps = {
  withPaddingHorizontal?: boolean
}

const SimplifiedPaymentsContainer = styled(Flex)<PaymentScheduleBoxStyleProps>`
  width: 100%;
  flex-direction: column;
  padding: ${(props) => props.theme.fixedSizes.md};
  border-radius: ${(props) => props.theme.radii.xl};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
`

const HowDoesRentProtectWorkLink = styled(SmallText)`
  font-weight: bold;
  cursor: pointer;
  color: ${(props) => props.theme.colors.jewelPurple};
`

const StyledSubtext = styled(ExtraSmallText)`
  color: ${(props) => props.theme.colors.dusk};
`
