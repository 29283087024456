import React, { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useAnalytics } from '../../../../../providers'
import { useContent } from '../../../../../providers/content'
import { WhenDesktop } from '../../../../../theme/media'
import { ChevronLeftWhite, ChevronRightWhite } from '../../../../assets'
import { LineDivider as BaseLineDivider, SmallText } from '../../../../components'
import { SecondPaymentListItem } from './SecondPaymentListItem'
import { secondScrollPaymentListProps, useScrollSecondPaymentList } from './useScrollSecondPaymentList'

export const LIST_WRAPPER_ID = 'ListWrapper'

export type SecondPaymentDay = {
  day: number
  ordinal: string
}

export type SecondPaymentListBaseProps = {
  onSelectSecondPaymentDay: (secondPaymentDay: SecondPaymentDay) => void
  secondPaymentDays: SecondPaymentDay[]
  daySelected?: SecondPaymentDay
  paymentTitle?: string
  hideDateSelection?: boolean
  topRightText?: string
  onPress?: () => void
  mostPopularDay: number
}

type ArrowButtonStyleProps = {
  isDisabled: boolean
}

type ListWrapperTranslateX = {
  translateX?: number
}

type SecondPaymentListProps = SecondPaymentListBaseProps &
  ListWrapperTranslateX & {
    itemSize: number
  }

type ArrowButtonContainerProps = {
  scrollSecondPaymentList: secondScrollPaymentListProps
  isPreviousDaysListButtonDisabled: boolean
  isNextDaysListButtonDisabled: boolean
  hideDateSelection?: boolean
}

export const SecondPaymentListContainerV2 = (props: SecondPaymentListBaseProps) => {
  const scrollSecondPaymentList = useScrollSecondPaymentList({
    secondPaymentDays: props.secondPaymentDays,
    mostPopularDay: props.mostPopularDay,
  })

  const isNextDaysListButtonDisabled = scrollSecondPaymentList.translateX <= scrollSecondPaymentList.MAX_SLIDING_RIGHT

  const isPreviousDaysListButtonDisabled =
    scrollSecondPaymentList.translateX >= scrollSecondPaymentList.MAX_SLIDING_LEFT

  return (
    <Flex direction="column">
      <PaymentScheduleBox>
        <SecondPaymentListV2
          {...props}
          mostPopularDay={props.mostPopularDay}
          itemSize={scrollSecondPaymentList.listItemWidth}
          translateX={scrollSecondPaymentList.translateX}
        />
      </PaymentScheduleBox>
      <ArrowButtonContainer
        {...props}
        scrollSecondPaymentList={scrollSecondPaymentList}
        isNextDaysListButtonDisabled={isNextDaysListButtonDisabled}
        isPreviousDaysListButtonDisabled={isPreviousDaysListButtonDisabled}
      />
    </Flex>
  )
}

export const SecondPaymentListV2 = (props: SecondPaymentListProps) => {
  const {
    content: { CUSTOMIZE_YOUR_SCHEDULE },
  } = useContent()

  return (
    <>
      <HeaderWrapper>
        <Flex direction="row" justifyContent="space-between">
          <SmallText fontWeight="bold">{props.paymentTitle ?? CUSTOMIZE_YOUR_SCHEDULE?.SECOND_PAYMENT}</SmallText>
          <ChooseDateText>
            {props.daySelected
              ? `${props.daySelected?.day}${props.daySelected?.ordinal} ${CUSTOMIZE_YOUR_SCHEDULE?.SECOND_PAYMENT_SELECTED_INFO}`
              : props.topRightText}
          </ChooseDateText>
        </Flex>
        {!props.hideDateSelection && (
          <>
            <Box minH="18px" />
            <LineDivider />
          </>
        )}
      </HeaderWrapper>
      {!props.hideDateSelection && (
        <>
          <ListWrapper id={LIST_WRAPPER_ID} translateX={props.translateX}>
            {props.secondPaymentDays.map((secondPaymentDay) => (
              <SecondPaymentListItem
                onClick={() => props.onSelectSecondPaymentDay(secondPaymentDay)}
                isMostPopularDay={secondPaymentDay.day === props.mostPopularDay}
                isSelected={props.daySelected?.day === secondPaymentDay.day}
                key={`${secondPaymentDay.day}${secondPaymentDay.ordinal}`}
                ordinal={secondPaymentDay.ordinal}
                day={secondPaymentDay.day}
                size={props.itemSize}
              />
            ))}
          </ListWrapper>
        </>
      )}
    </>
  )
}

const ArrowButtonContainer = (props: ArrowButtonContainerProps) => {
  const analytics = useAnalytics()

  const handleClickLeftArrowButton = useCallback(() => {
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_LEFT_ARROW_CLICKED)
    props.scrollSecondPaymentList.translateXMovingLeft()
  }, [props.scrollSecondPaymentList.translateXMovingLeft])

  const handleClickRightArrowButton = useCallback(() => {
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_RIGHT_ARROW_CLICKED)
    props.scrollSecondPaymentList.translateXMovingRight()
  }, [props.scrollSecondPaymentList.translateXMovingRight])

  if (!props.hideDateSelection) {
    return (
      <WhenDesktop>
        <ListControlButtons data-testid="SecondPaymentListListControlButtons">
          <ArrowButton
            isDisabled={props.isPreviousDaysListButtonDisabled}
            data-testid="SecondPaymentListLeftChevronButton"
            onClick={props.isPreviousDaysListButtonDisabled ? undefined : handleClickLeftArrowButton}
            as="button"
          >
            <ChevronLeftWhite />
          </ArrowButton>
          <ArrowButton
            isDisabled={props.isNextDaysListButtonDisabled}
            data-testid="SecondPaymentListRightChevronButton"
            onClick={props.isNextDaysListButtonDisabled ? undefined : handleClickRightArrowButton}
            as="button"
          >
            <ChevronRightWhite />
          </ArrowButton>
        </ListControlButtons>
      </WhenDesktop>
    )
  }

  return null
}

const HeaderWrapper = styled(Box)`
  width: 100%;
  padding-left: ${(props) => props.theme.fixedSizes.md};
  padding-right: ${(props) => props.theme.fixedSizes.md};
`

const LineDivider = styled(BaseLineDivider)`
  margin-top: ${(props) => props.theme.fixedSizes.md};
  margin-bottom: ${(props) => props.theme.fixedSizes.md};
`

const ListControlButtons = styled(Flex)`
  width: 600px;
  flex: 1;
  justify-content: space-between;
  align-self: center;
  margin-top: -70px;
`

const ListWrapper = styled(Flex)<ListWrapperTranslateX>`
  ${(props) => props.theme.media.desktop`
    width: 560px;
    transform: translateX(${props.translateX}px);
    transition: transform 330ms ease-in-out;
  `}
  ${(props) => props.theme.media.phone`
    overflow: auto;
    padding-bottom: ${props.theme.fixedSizes.md};
  `}
  ${(props) => props.theme.media.tablet`
    overflow: auto;
    padding-bottom: ${props.theme.fixedSizes.md};
  `}
`

const ArrowButton = styled(Box)<ArrowButtonStyleProps>`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => {
    const brandColorIntesity = props.isDisabled ? '700' : '200'
    return props.theme.colors.brand[brandColorIntesity]
  }};
  border-radius: ${(props) => props.theme.radii.rounded};
  margin-horizontal: -20px;
  margin-top: -20px;
`

const ChooseDateText = styled(SmallText)`
  color: ${(props) => props.theme.colors.dusk};
`

type PaymentScheduleBoxStyleProps = {
  withPaddingHorizontal?: boolean
}

export const PaymentScheduleBox = styled(Flex)<PaymentScheduleBoxStyleProps>`
  width: 100%;
  flex-direction: column;
  padding-top: ${(props) => props.theme.fixedSizes.md};
  padding-right: ${(props) => (props.withPaddingHorizontal ? props.theme.fixedSizes.md : '0px')};
  padding-bottom: ${(props) => props.theme.fixedSizes.md};
  padding-left: ${(props) => (props.withPaddingHorizontal ? props.theme.fixedSizes.md : '0px')};
  border-radius: ${(props) => props.theme.radii.xl};
  overflow: hidden;
`
