import React, { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'

import { useAnalytics } from '../../../../../providers'
import { PaymentList, PaymentListBaseProps } from './PaymentList'
import { useScrollPaymentList } from './useScrollPaymentList'

export const PaymentListContainer = (props: PaymentListBaseProps) => {
  const scrollPaymentList = useScrollPaymentList({ paymentDays: props.paymentDays })
  const analytics = useAnalytics()

  const handleClickLeftArrowButton = useCallback(() => {
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_LEFT_ARROW_CLICKED)
    scrollPaymentList.translateXMovingLeft()
  }, [scrollPaymentList.translateXMovingLeft])

  const handleClickRightArrowButton = useCallback(() => {
    analytics.logEvent(Analytics.Events.CUSTOMIZE_YOUR_SCHEDULE_RIGHT_ARROW_CLICKED)
    scrollPaymentList.translateXMovingRight()
  }, [scrollPaymentList.translateXMovingRight])

  return (
    <PaymentList
      {...props}
      isNextDaysListButtonDisabled={scrollPaymentList.translateX === scrollPaymentList.MAX_SLIDING_RIGHT}
      isPreviousDaysListButtonDisabled={scrollPaymentList.translateX === scrollPaymentList.MAX_SLIDING_LEFT}
      mostPopularDay={scrollPaymentList.MOST_POPULAR_DAY}
      onClickRightArrowButton={handleClickRightArrowButton}
      onClickLeftArrowButton={handleClickLeftArrowButton}
      itemSize={scrollPaymentList.listItemWidth}
      translateX={scrollPaymentList.translateX}
      paymentTitle={props.paymentTitle}
    />
  )
}
