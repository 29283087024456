import { Analytics } from '@genoa/analytics'
import { ActionLogType, FlexLinks, OnboardingFlowName } from '@genoa/domain'

import { useConvertCreditBuilderOffer } from '../../../../../hooks'
import { useHandleRegisterActionLog } from '../../../../../hooks/flex2/onboarding-status'
import { useAnalytics, useHelpLinks } from '../../../../../providers'
import { useAcceptOfferModal } from '../confirm-schedule/use-accept-offer-modal'
import { CreditBuilderUpsell } from './CreditBuilderUpsell'

export interface CreditBuilderUpsellContainerProps {
  readonly onNext: () => void
  readonly onNoThanks: () => void
}

export const CreditBuilderUpsellContainer = ({ onNext, onNoThanks }: CreditBuilderUpsellContainerProps) => {
  const analytics = useAnalytics()
  const helpLinks = useHelpLinks()
  const { handleRegisterActionLog, loadingRegisterActionLog } = useHandleRegisterActionLog()
  const { convertOffer, isLoading: loadingConvertOffer } = useConvertCreditBuilderOffer()
  const errorModal = useAcceptOfferModal()

  const onClickLearnMore = () => {
    analytics.logEvent(Analytics.Events.CB_UPSELL_INTRO_LEARN_MORE_CTA)
    helpLinks.open(FlexLinks.creditBuilderCreditScore)
  }

  const onClickContinue = async () => {
    analytics.logEvent(Analytics.Events.CB_UPSELL_INTRO_SET_UP_CLICKED)
    await handleRegisterActionLog(ActionLogType.VALUE_PROP)

    const success = await convertOffer()
    if (!success) {
      return errorModal.showGenericErrorModal()
    }

    analytics.setUserProperty(Analytics.UserProperties.ONBOARDING_FLOW_NAME, OnboardingFlowName.SPLIT_RENT_REJECT_TO_CB)

    // remove the local store product type to prevent unexpected behavior after changing products
    localStorage.removeItem('flexapp/product')

    return onNext()
  }

  const onClickNoThanks = () => {
    analytics.logEvent(Analytics.Events.CB_UPSELL_INTRO_NO_THANKS_CLICKED)
    return onNoThanks()
  }

  return (
    <CreditBuilderUpsell
      onClickLearnMore={onClickLearnMore}
      onClickContinue={onClickContinue}
      onClickNoThanks={onClickNoThanks}
      loadingSubmit={loadingRegisterActionLog || loadingConvertOffer}
    />
  )
}
