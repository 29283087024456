import React, { useCallback } from 'react'
import { Analytics } from '@genoa/analytics'
import { BillerGenericPortal, FlexAnywhereGenericPortal } from '@genoa/domain'
import { setBillerGenericPortalAction } from '@genoa/state-management'

import { useReduxAction } from '../../../../hooks'
import { useGetGenericPortalsQuery } from '../../../../modules/flexApi'
import { useAnalytics } from '../../../../providers'
import { RentPortalSelection } from './RentPortalSelection'

type RentPortalSelectionContainerProps = {
  analyticsScreenName: Analytics.Screens
  analyticsEventBackClicked: Analytics.Events
  analyticsEventNoPortalSeenClicked: Analytics.Events
  analyticsEventPortalSelected: Analytics.Events
  onPortalSelected: () => unknown
  onPortalNotSeen: () => unknown
}

export const RentPortalSelectionContainer = (props: RentPortalSelectionContainerProps) => {
  const analytics = useAnalytics()

  const portals = useGetGenericPortalsQuery()

  const setBillerGenericPortal = useReduxAction(setBillerGenericPortalAction)

  const handleBack = useCallback(() => {
    analytics.logEvent(props.analyticsEventBackClicked)
  }, [])

  const handlePortalNotSeen = useCallback(async () => {
    analytics.logEvent(props.analyticsEventNoPortalSeenClicked)
    analytics.logEvent(props.analyticsEventPortalSelected, { portalId: FlexAnywhereGenericPortal.public_id })
    setBillerGenericPortal(FlexAnywhereGenericPortal)

    props.onPortalNotSeen()
  }, [setBillerGenericPortal])

  const handleSelectPortal = useCallback(
    async (portal: BillerGenericPortal) => {
      analytics.logEvent(props.analyticsEventPortalSelected, { portalId: portal.public_id })

      setBillerGenericPortal(portal)
      props.onPortalSelected()
    },
    [setBillerGenericPortal]
  )

  return (
    <RentPortalSelection
      onBack={handleBack}
      onSelectPortal={handleSelectPortal}
      onPortalNotSeen={handlePortalNotSeen}
      portals={portals.data?.data.portals}
      analyticsScreenName={props.analyticsScreenName}
    />
  )
}
