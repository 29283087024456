import { Analytics } from '@genoa/analytics'
import { FlexLinks } from '@genoa/domain'
import { CO_NOT_APPROVED, NOT_APPROVED } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { useContentOverhaul, useEmbedAnalytics } from '../../../../../../hooks'
import { useAnalytics, useHelpLinks } from '../../../../../../providers'
import { HeroChecklist } from '../../../../../assets'
import { Gap, Headline1, LargeText, LinkText } from '../../../../../components'
import { BasePageLayout } from '../../../../../layouts'

export const EmbedCreditLineDeclined = () => {
  const { onboardingEnabled } = useContentOverhaul()
  const content = onboardingEnabled ? CO_NOT_APPROVED : NOT_APPROVED

  const { embedFlow, embedProperties } = useEmbedAnalytics()
  const analytics = useAnalytics()
  const helpLinks = useHelpLinks()

  const onLeanMore = () => {
    analytics.logEvent(Analytics.Events.EMBED_BLOCKING_DECLINED_CTA, { embedFlow })
    helpLinks.open(FlexLinks.notApprovedInfo)
  }

  return (
    <BasePageLayout
      analyticsScreenName={Analytics.Screens.EMBED_BLOCKING_CREDIT_LINE_DECLINED}
      analyticsScreenParams={embedProperties}
    >
      <Container>
        <Gap size="spacing_300" />
        <HeroChecklist />
        <Gap size="spacing_200" />
        <Headline1>{content.HEADER}</Headline1>
        <Gap size="spacing_100" />
        <TextDescription>{content.BODY}</TextDescription>
        <Gap size="spacing_100" />
        <LinkText onClick={onLeanMore}>{content.MESSAGE_SUPPORT}</LinkText>
      </Container>
    </BasePageLayout>
  )
}

const Container = styled(Flex)`
  align-items: center;
  flex-direction: column;
  text-align: center;
`

const TextDescription = styled(LargeText)`
  align-items: center;
  color: ${({ theme }) => theme.colors.grey};
  flex-direction: column;
  text-align: center;
`
