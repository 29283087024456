import { Offer, useGetOfferByBillerAccount } from '@genoa/middle-end'
import { AxiosPromise } from 'axios'

import { useAuthState } from '../../../contexts'
import { resetOfferAction, setOfferAction } from '../../../modules/flex2/offer'
import { useLogger } from '../../../providers'
import { useReduxAction } from '../../use-redux-action'

export const useGetOffer = () => {
  const loggerV2 = useLogger('useGetOffer')
  const { uid } = useAuthState()
  const [, getOfferByBillerAccount] = useGetOfferByBillerAccount()

  const setOfferState = useReduxAction(setOfferAction)
  const resetOffer = useReduxAction<void>(resetOfferAction)

  const getOffer = (billerAccountPublicId: string): AxiosPromise<Offer> => {
    if (!billerAccountPublicId || !uid) {
      loggerV2.error('getOffers', 'getOffers called without a biller_account_public_id or customer_public_id')
      return Promise.resolve({}) as AxiosPromise<Offer>
    }

    return getOfferByBillerAccount({
      billerAccountPublicId,
      customerPublicId: uid,
    })
      .then((response) => {
        if (response.status === 200 && response.data) {
          setOfferState({ initialized: true, offer: response.data })
        } else {
          resetOffer()
        }
        return response
      })
      .catch((error: any) => {
        loggerV2.error('searchCustomerOwnedOffers', `error: ${error?.message}`)
        return error
      })
  }

  return {
    getOffer,
  }
}
