import { SetupAutopilotAction } from '@genoa/domain'
import { SLC_AUTOPAY_SETUP_CONTENT as content } from '@genoa/screen-content'
import { Flex } from '@chakra-ui/react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'

import { ExtraSmallText, Gap, Headline2, Pill, PrimaryButton, SelectableCard, SmallText } from '../../../../components'

export interface SLCAutopaySetupProps {
  readonly selectedOption: SetupAutopilotAction | undefined
  readonly confirmDisabled: boolean
  readonly onSelectOption: (option: SetupAutopilotAction) => void
  readonly onConfirm: () => void
}

export const SLCAutopaySetup = ({
  selectedOption,
  confirmDisabled,
  onSelectOption,
  onConfirm,
}: SLCAutopaySetupProps) => {
  const theme = useTheme()

  return (
    <Container>
      <Stack>
        <Headline2>{content.HEADER}</Headline2>
        <SmallText>{content.SUB_HEADER}</SmallText>
      </Stack>
      <Stack>
        <SelectableCard
          isSelected={selectedOption === SetupAutopilotAction.AUTOPAY_ON}
          onClick={() => onSelectOption(SetupAutopilotAction.AUTOPAY_ON)}
          title={content.AUTOPAY_ON.TITLE}
        >
          <RepaymentContent>
            <Pill label="Recommended" />
            <ExtraSmallText color={theme.colors.dusk}>{content.AUTOPAY_ON.CONTENT}</ExtraSmallText>
          </RepaymentContent>
        </SelectableCard>
        <SelectableCard
          isSelected={selectedOption === SetupAutopilotAction.EPISODIC_ON}
          onClick={() => onSelectOption(SetupAutopilotAction.EPISODIC_ON)}
          title={content.AUTOPAY_OFF.TITLE}
        >
          {content.AUTOPAY_OFF.CONTENT}
        </SelectableCard>
      </Stack>
      <Stack>
        <Gap size="spacing_100" />
        <PrimaryButton disabled={confirmDisabled} onClick={onConfirm}>
          {content.CTA}
        </PrimaryButton>
      </Stack>
    </Container>
  )
}

const Container = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: ${(props) => props.theme.fixedSizes.spacing_200};
`

const Stack = styled(Container)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_100};
`

const RepaymentContent = styled(Container)`
  width: 100%;
  gap: ${(props) => props.theme.fixedSizes.spacing_50};
`
